import styled from 'styled-components';
import { mixins } from '../../../themes';

export const Wrapper = styled.div<{ alignment: string }>`
    display: grid;
    row-gap: 5px;
    column-gap: 10px;
    grid-template-columns: 17px auto;
    align-items: ${(props) => (props.alignment === 'top' ? 'flex-start' : 'center')};
`;

/* The container */
export const StyledRadioButton = styled.input.attrs({ type: 'radio' })<{ isValid: boolean }>`
    --active: ${({ theme }) => theme.palette.primary.main};
    --active-inner: ${({ theme }) => theme.palette.primary.main};
    --focus: 2px rgba(39, 94, 254, 0.3);
    --border: ${(props) => (props.isValid ? '#000' : 'red')};
    --border-hover: ${({ theme }) => theme.palette.primary.main};
    --background: #fff;
    --disabled: #f6f8ff;
    --disabled-inner: #e1e6f9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 17px;
    min-height: 17px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--border-color, var(--border));
    background: var(--bg-color, var(--background));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;

    &:after {
        content: '';
        display: block;
        transition: transform var(--duration-time, 0.3s) var(--ease-duration, ease), opacity var(--opacity-duration, 0.2s);
    }

    &:checked {
        --bg-color: #fff;
        --border-color: var(--active);
        --opacity-duration: 0.3s;
        --duration-time: 0.6s;
        --ease-duration: cubic-bezier(0.2, 0.85, 0.32, 1.2);
    }

    &:disabled {
        --bg-color: var(--disabled);
        cursor: not-allowed;
        opacity: 0.9;
        &:checked {
            --bg-color: var(--disabled-inner);
            --border-color: var(--border);
        }
        & + label {
            cursor: not-allowed;
        }
    }

    &:hover {
        &:not(:checked) {
            &:not(:disabled) {
                --border-color: var(--border-hover);
            }
        }
    }

    &:focus {
        box-shadow: 0 0 0 var(--focus);
    }

    & {
        width: 17px;
        min-width: 17px;
        height: 17px;
        min-height: 17px;

        border-radius: 50%;
        padding: 3px;
        &:after {
            opacity: var(--current-opacity, 0);
            width: 100%;
            height: 100%;

            border-radius: 50%;
            background: var(--active-inner);
        }
        &:checked {
            --current-opacity: 1;
            --scale-value: 0.5;

            &:disabled {
                --active-inner: #000;
            }
        }
    }
`;

export const StyledLabel = styled.label`
    grid-column: 2 / span 1;
    display: inline-block;
    cursor: pointer;
    ${mixins.typography('inputLabel')};
    font-weight: 500;
`;

export const StyledSubLabel = styled.p`
    grid-column: 2 / span 1;
    ${mixins.typography('inputSubLabel')}
`;

export const StyledError = styled.p`
    display: flex;
    flex-direction: column;
    color: #d64469;
    font-size: ${({ theme: { typography } }) => typography.small.fontSize}px;
    margin-left: 10px;
`;
