import { VFC, useMemo } from 'react';
import { EquipmentDialogManagerStore } from '../../../lib/state/hessel-site/equipment-dialog-manager';
import { ProductDetailsStore } from '../../../lib/state/hessel-site/product-details';
import { hesselViewModels } from '../../../lib/view-models';
import { StandardEquipment } from '../standard-equipment';
import { StyledEquipmentCards, StyledEquipmentAndAddons } from './equipment-and-addons.styled';
import { EquipmentSection } from './equipment-card/equipment-card.component';
import { useScrollLock } from '../../../hooks/use-scroll-lock';

export type EquipmentAndAddonsProps = {
    className?: string;
};

export const EquipmentAndAddons: VFC<EquipmentAndAddonsProps> = ({ className }) => {
    // Product Details Store
    const { selectedProduct, includedEquipmentText, selectedTab } = ProductDetailsStore.useStoreState((state) => state);
    const { updateExtraEquipment, updateExtraEquipmentPackage, updateOptionalEquipment } = ProductDetailsStore.useStoreActions((actions) => actions);

    // Equipment Dialog Manager Store
    const { setShowAllEquipment, setSelectedEquipment, setSelectedOptionalEquipment, setSelectedEquipmentPackage } =
        EquipmentDialogManagerStore.useStoreActions((actions) => actions);

    const { hideScrollBars } = useScrollLock();

    const [includedExtraEquipment, extraEquipment] = useMemo(() => {
        const equipmentPerTab = selectedProduct?.extraEquipment?.[selectedTab];

        const extraIncluded = equipmentPerTab?.filter((equipment) => equipment.ownershipStatus === 'ExtraIncluded') ?? [];
        const extra =
            equipmentPerTab?.filter((equipment) => equipment.ownershipStatus === 'Extra' || equipment.ownershipStatus === 'ContactForPrice') ?? [];

        return [extraIncluded, extra] as const;
    }, [selectedProduct?.extraEquipment, selectedTab]);

    const [includedExtraEquipmentPackage, extraEquipmentPackage] = useMemo(() => {
        const equipmentPackagePerTab = selectedProduct?.extraEquipmentPackages?.[selectedTab];

        const extraPackageIncluded = equipmentPackagePerTab?.filter((equipmentPackage) => equipmentPackage.ownershipStatus === 'ExtraIncluded') ?? [];
        const extraPackage =
            equipmentPackagePerTab?.filter(
                (equipmentPackage) => equipmentPackage.ownershipStatus === 'Extra' || equipmentPackage.ownershipStatus === 'ContactForPrice'
            ) ?? [];

        return [extraPackageIncluded, extraPackage] as const;
    }, [selectedProduct?.extraEquipmentPackages, selectedTab]);

    return (
        <>
            <StyledEquipmentAndAddons className={className}>
                <StyledEquipmentCards>
                    {/* Included: Extra equipment and extra equipment package */}
                    {includedExtraEquipment.length > 0 || includedExtraEquipmentPackage.length > 0 ? (
                        <EquipmentSection
                            equipmentList={includedExtraEquipment}
                            equipmentPackage={includedExtraEquipmentPackage}
                            onEquipmentSelect={() => null}
                            onEquipmentPackageSelect={() => null}
                            showMoreInfoAboutEquipment={(arg, isPackage) => {
                                if (isPackage) {
                                    setSelectedEquipmentPackage(arg as hesselViewModels.EquipmentPackage);
                                } else {
                                    setSelectedEquipment(arg as hesselViewModels.Equipment);
                                }
                                hideScrollBars();
                            }}
                            header={'Ekstraudstyr inkluderet i bilen'}
                            equipmentIsIncluded={true}
                            includedEquipmentText={includedEquipmentText}
                        />
                    ) : null}

                    {/* Extra equipment and extra equipment package */}
                    {extraEquipment.length > 0 || extraEquipmentPackage.length > 0 ? (
                        <EquipmentSection
                            equipmentList={extraEquipment}
                            equipmentPackage={extraEquipmentPackage}
                            onEquipmentSelect={(id, isSelected) =>
                                updateExtraEquipment({
                                    id,
                                    isSelected,
                                })
                            }
                            onEquipmentPackageSelect={(id, isSelected) =>
                                updateExtraEquipmentPackage({
                                    id,
                                    isSelected,
                                })
                            }
                            showMoreInfoAboutEquipment={(arg, isPackage) => {
                                if (isPackage) {
                                    setSelectedEquipmentPackage(arg as hesselViewModels.EquipmentPackage);
                                } else {
                                    setSelectedEquipment(arg as hesselViewModels.Equipment);
                                }
                                hideScrollBars();
                            }}
                            header={'Muligt ekstraudstyr'}
                            equipmentIsIncluded={false}
                            includedEquipmentText={includedEquipmentText}
                        />
                    ) : null}

                    {selectedProduct?.optionalEquipment[selectedTab]?.length ? (
                        <EquipmentSection
                            onEquipmentSelect={(id, isSelected) =>
                                updateOptionalEquipment({
                                    id,
                                    isSelected,
                                })
                            }
                            showMoreInfoAboutEquipment={(arg, isPackage) => {
                                if (!isPackage) {
                                    hideScrollBars();
                                    setSelectedOptionalEquipment(arg as hesselViewModels.Equipment);
                                }
                            }}
                            header={'Tilvalg'}
                            equipmentList={selectedProduct?.optionalEquipment[selectedTab]}
                            equipmentIsIncluded={false}
                            includedEquipmentText={includedEquipmentText}
                        />
                    ) : null}

                    {selectedProduct ? (
                        <StandardEquipment
                            availability={selectedProduct.availability}
                            standardEquipmentList={selectedProduct.standardEquipment[selectedTab]}
                            standardEquipmentPackages={selectedProduct.standardEquipmentPackages[selectedTab]}
                            showAllEquipment={() => {
                                setShowAllEquipment(true);
                                hideScrollBars();
                            }}
                            setSelectedEquipment={(equipment) => {
                                setSelectedEquipment(equipment);
                                hideScrollBars();
                            }}
                            setSelectedEquipmentPackage={(equipmentPackage) => {
                                setSelectedEquipmentPackage(equipmentPackage);
                                hideScrollBars();
                            }}
                            equipmentNames={selectedProduct.equipmentNames}
                        />
                    ) : null}
                </StyledEquipmentCards>
            </StyledEquipmentAndAddons>
        </>
    );
};
