import { useMemo } from 'react';
import { uiTypes } from '../../../../lib';

type UseSpotHeaderReturnType = {
    headerSizes: uiTypes.SpotHeaderSize;
};

export const useSpotHeader = (headerSize?: uiTypes.HeaderSize): UseSpotHeaderReturnType => {
    const headerSizes: uiTypes.SpotHeaderSize = useMemo(() => {
        switch (headerSize) {
            case 'XX-small': {
                return {
                    mobile: '12px',
                    desktop: '12px',
                };
            }
            case 'X-small': {
                return {
                    mobile: '14px',
                    desktop: '14px',
                };
            }
            case 'Small': {
                return {
                    mobile: '17px',
                    desktop: '17px',
                };
            }
            case 'Medium': {
                return {
                    mobile: '25px',
                    desktop: '25px',
                };
            }
            case 'Large': {
                return {
                    mobile: '25px',
                    desktop: '30px',
                };
            }
            case 'X-large': {
                return {
                    mobile: '30px',
                    desktop: '40px',
                };
            }
            case 'XX-large': {
                return {
                    mobile: '40px',
                    desktop: '50px',
                };
            }

            default: {
                return {
                    mobile: '25px',
                    desktop: '30px',
                };
            }
        }
    }, [headerSize]);

    return {
        headerSizes,
    };
};
