type LeadEndpointContext = 'book-test-drive' | 'order-vehicle' | 'pre-book-test-drive';

type ApiResponse = {
    data: unknown | undefined;
    error: unknown | undefined;
};

const BASE_URL = process.env.NEXT_PUBLIC_API_URL;

const endpoints: Record<LeadEndpointContext, string> = {
    'book-test-drive': '/api/v3/Lead/book-test-drive',
    'order-vehicle': '/api/v3/Lead/order-vehicle',
    'pre-book-test-drive': '/api/v3/Lead/pre-book-test-drive',
};

export const submitLeadData = async <T>(context: LeadEndpointContext, payload: T): Promise<ApiResponse> => {
    try {
        const postUrl = new URL(endpoints[context], BASE_URL);
        const response = await fetch(postUrl.toString(), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }

        const data = await response.json();
        return { data, error: undefined };
    } catch (error) {
        return { data: undefined, error };
    }
};
