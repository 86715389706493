import { action, thunk } from 'easy-peasy';
import { getVehicleInformation, postBookServiceAppointment, getBenefitAgreement } from '../../api';
import { createContextStoreWithRuntimeModel } from '../helpers/context-store.helpers';
import { storeInputTypes } from '../_shared';
import { generateFormId, validateInput } from './booking-form.helpers';
import { FormActions, FormInjections, FormState, FormStore, FormThunks } from './booking-form.types';

const bookingFormDefaultState = (runtimeModel: Partial<FormState> | undefined): FormState => ({
    inputs: runtimeModel?.inputs ?? [],
    carInformationLoading: false,
    carInformationLoadStarted: false,
    isBookingComplete: false,
});

const updateSingleFormInput = <T extends storeInputTypes.SingleFormInput<string>>(input: T, payload: { id: string; value: string }): T =>
    input.id === payload.id ? { ...input, value: payload.value, isValid: validateInput({ ...input, ...payload }) } : { ...input };

const bookingFormActions = (): FormActions => ({
    onChange: action((state, payload) => {
        state.inputs = state.inputs.map((input) => (input.type !== 'address' ? updateSingleFormInput(input, payload) : input));
    }),
    onChangeMultiple: action((state, payload) => {
        state.inputs = state.inputs.map((input) =>
            input.id === payload.rootId && input.type === 'address'
                ? {
                      ...input,
                      isValid: validateInput({
                          ...input,
                          inputs: input.inputs.map((input) => updateSingleFormInput(input, payload)),
                      }),
                      inputs: input.inputs.map((input) => updateSingleFormInput(input, payload)),
                  }
                : input
        );
    }),
    onVehicleChange: action((state, payload) => {
        if (payload?.makeId != null) {
            payload.makeId = payload.makeId.toUpperCase();
        }
        state.vehicle = payload;
    }),
    onCustomerChange: action((state, payload) => {
        state.customer = payload;
    }),
    setCarInformationLoading: action((state, payload) => {
        state.carInformationLoading = payload;
    }),
    setCarInformationLoadStarted: action((state, payload) => {
        state.carInformationLoadStarted = payload;
    }),
    disableInput: action((state, payload) => {
        const input = state.inputs.find((x) => x.id === payload.id);

        if (input) {
            input.disabled = true;
        }
    }),
    setBookingComplete: action((state, payload) => {
        state.isBookingComplete = payload;
    }),
    setBookingAppointmentReference: action((state, payload) => {
        state.bookingAppointmentReference = payload;
    }),
    setCanValidate: action((state, payload) => {
        const inputToUpdate = state.inputs.find((x) => x.id === payload.id);

        if (inputToUpdate && !inputToUpdate.canValidate) {
            inputToUpdate.canValidate = payload.value;
        }
    }),
    setCanValidateAddress: action((state, payload) => {
        const addressInputs = state.inputs.find((x) => x.id === payload.rootId);

        if (!addressInputs || addressInputs?.type !== 'address') {
            return;
        }

        const input = addressInputs.inputs.find((x) => x.id === payload.id);

        if (input) {
            input.canValidate = true;
        }
    }),
    setIsFocused: action((state, payload) => {
        const input = state.inputs.find((i) => i.id === payload.id);

        if (input) {
            input.isFocused = payload.value;
        }
    }),
});

const bookingFormThunks = (): FormThunks => ({
    getVehicle: thunk(async (actions, payload, { injections }) => {
        const { pushError } = injections;
        actions.setCarInformationLoadStarted(true);
        actions.setCarInformationLoading(true);

        const [result, error] = await getVehicleInformation({ licensePlate: payload.licensePlate });
        if (result && !error) {
            actions.onVehicleChange(result);
        } else if (error) {
            pushError(error.errorType);
        }

        actions.setCarInformationLoading(false);
    }),
    getCustomer: thunk(async (actions, payload, { injections }) => {
        const { pushError } = injections;
        const [result, error] = await getBenefitAgreement({ email: payload.email, make: payload.make });

        if (result && !error) {
            actions.onCustomerChange(result);
        } else if (error) {
            pushError(error.errorType);
        }
    }),
    clearCustomer: thunk((actions) => {
        actions.onCustomerChange(undefined);
    }),
    clearVehicle: thunk((actions) => {
        actions.onVehicleChange(undefined);
        actions.onChange({ id: generateFormId('YourCar', 'Workshop'), value: '' });
    }),
    confirmBooking: thunk(async (actions, payload, { injections }) => {
        const { pushError } = injections;
        const [response, error] = await postBookServiceAppointment(payload);

        if (error) {
            pushError(error.errorType);
            return error;
        }

        actions.setBookingComplete(true);
        actions.setBookingAppointmentReference(response?.appointmentReference ?? '');
    }),
});

export const BookingFormStore = createContextStoreWithRuntimeModel<FormStore, Partial<FormState>, FormInjections>(
    (runtimeModel) => ({
        ...bookingFormDefaultState(runtimeModel),
        ...bookingFormActions(),
        ...bookingFormThunks(),
    }),
    { name: 'form', injections: { pushError: (e) => console.log('test', e) } }
);
