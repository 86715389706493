import { animated } from 'react-spring';
import styled from 'styled-components';
import { mixins } from '../../../../themes';

export const StyledTextInput = styled(animated.input)`
    border: none;
    background-color: rgba(0, 0, 0, 0);
    &:focus {
        outline: none;
    }
    ${mixins.typography('inputValue')}
    ::placeholder {
        ${mixins.typography('inputPlaceholder')}
    }
    width: 100%;
`;
