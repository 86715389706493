import { FC } from 'react';
import { contentSpotTypes } from '../../../lib/api';
import { HeroVideo } from '../../shared/hero-video';
import { CampaignHeader, CampaignContent, CtaListWrapper, CampaignContentWrapper, StyledSpotHeader } from './campaign.styled';
import { useMediaQuery } from '../../../hooks/use-media-query';
import { sharedTypes } from '../../../lib/api/models/umbraco';
import { useCtaHandler } from '../../plus-sites-shared/hooks';
import { CoverImage } from '../../shared/cover-image/cover-image.component';
import { uiTypes } from '../../../lib';
import { SharedStylePreserver } from '../../shared/style-preserver/style-preserver.styled';
import { isNullOrEmpty } from '../../../utils/helpers';
import { getSpotOpacity } from '../helpers';
import { SpotOverlay } from '../../overlay/overlay.component';
import { hexToRGBA } from '../../../utils/helpers/color.helpers';

type Props = {
    imageUrl?: string;
    imageMobileUrl?: string;
    videoUrl?: string;
    videoMobileUrl?: string;
    headlineText: string;
    headerType: uiTypes.HeaderType;
    headerSize: uiTypes.HeaderSize;
    textColor: string;
    backgroundColor?: string;
    contentPosition: contentSpotTypes.Position;
    verticalAlign?: contentSpotTypes.VerticalAlignment;
    columnSpan?: number;
    slideCta?: Array<sharedTypes.MixedCta>;
    isAboveFold: boolean;
    carouselIndex: number;
    ctaBoxBackground: {
        color: string;
        opacity: contentSpotTypes.SpotOpacity;
    };
    overlay?: contentSpotTypes.SpotOverlaySetting;
};

export const Campaign: FC<Props> = ({
    imageUrl,
    imageMobileUrl,
    videoUrl,
    videoMobileUrl,
    headlineText,
    headerType,
    headerSize,
    textColor,
    backgroundColor,
    contentPosition,
    verticalAlign,
    columnSpan,
    slideCta,
    isAboveFold,
    carouselIndex,
    children: ctas,
    ctaBoxBackground,
    overlay,
}) => {
    const isMobile = useMediaQuery({ target: 'tablet' });

    const { handleCtaClick } = useCtaHandler();

    return (
        <CampaignContentWrapper
            backgroundColor={backgroundColor}
            verticalAlignment={verticalAlign && verticalAlign.length > 0 ? verticalAlign : 'Top'}
            horizontalAlignment={contentPosition}
            onClick={() => (slideCta && slideCta.length > 0 ? handleCtaClick(slideCta[0]) : null)}
            showCursor={slideCta && slideCta.length > 0 ? true : false}
        >
            {!videoUrl && !videoMobileUrl ? (
                <CoverImage
                    src={isMobile && !!imageMobileUrl ? imageMobileUrl : imageUrl}
                    loading={isAboveFold && carouselIndex === 0 ? 'eager' : 'lazy'}
                    styles={overlay?.variant === 'Full width with gaussian blur' ? { filter: 'blur(5px)' } : {}}
                />
            ) : null}

            {overlay ? <SpotOverlay key={Math.random()} overlay={overlay} /> : null}

            <CampaignContent
                position={contentPosition}
                style={{
                    background:
                        ctaBoxBackground && ctaBoxBackground.color && ctaBoxBackground.color.length > 0
                            ? hexToRGBA(ctaBoxBackground.color, getSpotOpacity(ctaBoxBackground.opacity))
                            : '',
                    padding: '35px',
                }}
            >
                {!isMobile ? videoUrl ? <HeroVideo videoUrl={videoUrl} /> : null : null}
                {isMobile ? videoMobileUrl ? <HeroVideo videoUrl={videoMobileUrl} /> : null : null}
                {isMobile && !videoMobileUrl ? videoUrl ? <HeroVideo videoUrl={videoUrl} /> : null : null}

                {isNullOrEmpty(headerSize) ? (
                    <SharedStylePreserver corporateFont={true}>
                        <CampaignHeader as={headerType} textColor={`#${textColor}`} position={contentPosition} columnSpan={columnSpan}>
                            {headlineText}
                        </CampaignHeader>
                    </SharedStylePreserver>
                ) : (
                    <StyledSpotHeader
                        headerType={headerType}
                        headerSize={headerSize}
                        textColor={`#${textColor}`}
                        position={contentPosition}
                        columnSpan={columnSpan}
                    >
                        {headlineText}
                    </StyledSpotHeader>
                )}

                {ctas ? <CtaListWrapper>{ctas}</CtaListWrapper> : null}
            </CampaignContent>
        </CampaignContentWrapper>
    );
};
