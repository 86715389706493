import { action } from 'easy-peasy';
import { createContextStoreWithRuntimeModel } from '../helpers/context-store.helpers';
import {
    ServiceAgreementCalculatorState,
    ServiceAgreementCalculatorStore,
    ServiceAgreementCalculatorStoreAction,
} from './service-agreement-calculator.types';

const defaultStates = (): ServiceAgreementCalculatorState => ({
    customerInfo: {
        email: '',
        fullName: '',
        isPolicyAccepted: false,
        isTermsAccepted: false,
    },
    serviceAgreements: undefined,
    vehicleInformation: {
        licensePlate: '',
    },
    modalOpen: false,
});

const actions = (): ServiceAgreementCalculatorStoreAction => ({
    setVehicleInformation: action((state: ServiceAgreementCalculatorState, payload) => {
        state.vehicleInformation = payload;
    }),
    setServiceAgreements: action((state: ServiceAgreementCalculatorState, payload) => {
        state.serviceAgreements = payload;
    }),
    setLicensePlate: action((state: ServiceAgreementCalculatorState, payload) => {
        state.vehicleInformation.licensePlate = payload;
    }),
    setCustomerInfo: action((state: ServiceAgreementCalculatorState, payload) => {
        state.customerInfo = payload;
    }),
    setSelectedCompleteCare: action((state: ServiceAgreementCalculatorState, payload) => {
        state.selectedCompleteCare = payload;
    }),
    setSelectedServiceCare: action((state: ServiceAgreementCalculatorState, payload) => {
        state.selectedServiceCare = payload;
    }),
    setSelectedWarrantyExtension: action((state: ServiceAgreementCalculatorState, payload) => {
        state.selectedWarrantyExtension = payload;
    }),
    clearAllAgreements: action((state: ServiceAgreementCalculatorState) => {
        state.selectedCompleteCare = undefined;
        state.selectedServiceCare = undefined;
        state.selectedWarrantyExtension = undefined;
    }),
    setModalOpen: action((state: ServiceAgreementCalculatorState, payload: boolean) => {
        state.modalOpen = payload;
    }),
});

export const ServiceAgreementCalculatorModalStore = createContextStoreWithRuntimeModel<ServiceAgreementCalculatorStore>(
    () => ({
        ...defaultStates(),
        ...actions(),
    }),
    { name: 'serviceAgreementCalculator' }
);
