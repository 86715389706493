import { useEffect, useMemo, useState } from 'react';
import { hesselApiTypes } from '../../lib/api/models/hessel-api';
import { LeadDurationDeliveryStore } from '../../lib/state/hessel-site/lead-checkout/duration-delivery';
import { HireCheckoutMode, LeadCheckoutFormsStore } from '../../lib/state/hessel-site/lead-checkout/forms';
import { generateHireFormId } from '../../lib/state/hessel-site/lead-checkout/forms/hire-forms.helpers';
import { LeadCheckoutStepsStore } from '../../lib/state/hessel-site/lead-checkout/steps';
import { ProductDetailsStore } from '../../lib/state/hessel-site/product-details';
import { hesselViewModels } from '../../lib/view-models';
import { DateStyle, formatDate, formInputValue } from '../../utils/helpers';

export const useHireLeadCheckoutData = (): hesselApiTypes.HireLeadCheckoutSubmit | undefined => {
    const [checkoutData, setCheckoutData] = useState<hesselApiTypes.HireLeadCheckoutSubmit>();

    // Lead Checkout Steps Store
    const { steps } = LeadCheckoutStepsStore.useStoreState((state) => state);
    const { inputs, checkoutMode } = LeadCheckoutFormsStore.useStoreState((state) => state);

    // Duration & Delivery Store
    const { termsAccepted, employees, selectedDepartmentId, selectedEmployeeId, optionalCompany, deliveryMode } =
        LeadDurationDeliveryStore.useStoreState((state) => state);

    const selectedEmployee = useMemo(() => employees.find((x) => x.employeeId === selectedEmployeeId), [employees, selectedEmployeeId]);

    // Forms values (Step 1)
    const { city, email, familyName, givenName, mobile, postalCode, streetName, company, cvr } = useMemo(() => {
        const address = inputs
            .map(
                (input) =>
                    input.id === generateHireFormId('checkoutStepUserInformation', 'Address') && input.type === 'address' ? input.inputs : undefined,
                []
            )
            .filter((val) => (val ? true : false))
            .reduce((acc, val) => (val ? [...(acc ? acc : []), ...val] : acc), []);

        const cityInput = address?.find(({ id }) => id === generateHireFormId('checkoutStepUserInformation', 'Address', 'city'));
        const zipCodeInput = address?.find(({ id }) => id === generateHireFormId('checkoutStepUserInformation', 'Address', 'zip'));
        const streetInput = address?.find(({ id }) => id === generateHireFormId('checkoutStepUserInformation', 'Address', 'address'));

        const emailInput = inputs.find(({ id }) => id === generateHireFormId('checkoutStepUserInformation', 'Email'));
        const mobileInput = inputs.find(({ id }) => id === generateHireFormId('checkoutStepUserInformation', 'Telephone number'));

        const nameInput = inputs.find(({ id }) => id === generateHireFormId('checkoutStepUserInformation', 'Name'));
        const [familyName, ...reversed] = (nameInput ? formInputValue(nameInput).trim() : '').split(' ').reverse();

        const companyInput = inputs.find(({ id }) => id === generateHireFormId('checkoutStepUserInformation', 'Company (Business)'));
        const cvrInput = inputs.find(({ id }) => id === generateHireFormId('checkoutStepUserInformation', 'CVR (Business)'));

        return {
            city: cityInput ? formInputValue(cityInput) : '',
            postalCode: zipCodeInput ? formInputValue(zipCodeInput) : '',
            streetName: streetInput ? formInputValue(streetInput) : '',
            email: emailInput ? formInputValue(emailInput) : '',
            mobile: mobileInput ? formInputValue(mobileInput) : '',
            givenName: reversed.reverse().join(' '),
            familyName,
            cvr: cvrInput ? formInputValue(cvrInput) : '',
            company: companyInput ? formInputValue(companyInput) : '',
        };
    }, [inputs]);

    // Duration & Delivery step: Consent data
    const consentText = useMemo(
        () =>
            steps
                .map((step) => (step.type === 'checkoutStepDurationAndDelivery' ? step.content.termsContent : ''))
                .find((content) => (content ? true : false)),
        [steps]
    );

    const consentCode = useMemo(
        () =>
            steps
                .map((step) => (step.type === 'checkoutStepDurationAndDelivery' ? step.content.termsCode : 0))
                .find((content) => (content ? true : false)),
        [steps]
    );

    // Product Details Store
    const {
        selectedProduct,
        selectedLeasingPeriod,
        selectedStartupDate,
        selectedColor,
        extraEquipmentPriceSum,
        extraEquipmentPackagePriceSum,
        optionalEquipmentPriceSum,
    } = ProductDetailsStore.useStoreState((state) => state);

    // Gather Equipment Data
    const selectedOptionalEquipments = useMemo(
        () =>
            selectedProduct?.optionalEquipment?.['Car HiRE']
                .filter((x) => x.isSelected === true)
                .map((optionalEq) => {
                    return {
                        equipmentCode: optionalEq.id,
                        equipmentName: optionalEq.name,
                        equipmentPrice: optionalEq.monthlyHirePrice ?? 0,
                    };
                }),
        [selectedProduct?.optionalEquipment]
    );

    const selectedExtraEquipments = useMemo(
        () =>
            (selectedProduct?.availability === 'InStock'
                ? []
                : selectedProduct?.extraEquipment?.['Car HiRE'].filter((x) => x.isSelected === true)
            )?.map((extraEq) => {
                return {
                    equipmentCode: extraEq.id,
                    equipmentName: extraEq.name,
                    equipmentPrice: extraEq.monthlyHirePrice ?? 0,
                };
            }),
        [selectedProduct?.availability, selectedProduct?.extraEquipment]
    );

    const selectedExtraEquipmentPackages = useMemo(
        () =>
            (selectedProduct?.availability === 'InStock'
                ? []
                : selectedProduct?.extraEquipmentPackages?.['Car HiRE'].filter((x) => x.isSelected === true)
            )?.map((extraEquPackage) => {
                return {
                    equipmentCode: extraEquPackage.packageIdKey,
                    equipmentName: extraEquPackage.name ?? '',
                    equipmentPrice: extraEquPackage.monthlyHirePrice ?? 0,
                };
            }),
        [selectedProduct?.availability, selectedProduct?.extraEquipmentPackages]
    );

    const allEquipments = useMemo(
        () => [...(selectedOptionalEquipments ?? []), ...(selectedExtraEquipments ?? []), ...(selectedExtraEquipmentPackages ?? [])],
        [selectedExtraEquipmentPackages, selectedExtraEquipments, selectedOptionalEquipments]
    );

    const vehicleImageUrl = useMemo(() => {
        const matchedResource = selectedProduct?.resources.filter((x) => x.type === 'image' && x.colorCode === selectedColor?.code);

        if (!matchedResource || matchedResource.length === 0) {
            return (selectedProduct?.resources?.find((x) => x.type === 'image') as hesselViewModels.Image)?.url ?? '';
        }

        const matched = matchedResource[0] as hesselViewModels.Image;
        return matched.url.replace('productdetailsfullsize', 'email').replace('{IMAGE-SIZE}', 'email');
    }, [selectedColor?.code, selectedProduct?.resources]);

    const totalPriceInEntireDuration = useMemo(() => {
        const totalPriceWithMoms =
            (selectedProduct?.purchaseTypes?.hire?.baseMonthlyPrice ?? 0) +
            extraEquipmentPriceSum +
            extraEquipmentPackagePriceSum +
            optionalEquipmentPriceSum +
            (selectedColor?.monthlyHirePrice ?? 0);

        const hireDownPayment = 0;
        const durationAsNumber = selectedLeasingPeriod ? +selectedLeasingPeriod.value : -1;
        const totalPriceWithMomsTimesDuration = totalPriceWithMoms * durationAsNumber;
        return totalPriceWithMomsTimesDuration + hireDownPayment;
    }, [
        extraEquipmentPackagePriceSum,
        extraEquipmentPriceSum,
        optionalEquipmentPriceSum,
        selectedColor?.monthlyHirePrice,
        selectedLeasingPeriod,
        selectedProduct?.purchaseTypes?.hire?.baseMonthlyPrice,
    ]);

    useEffect(() => {
        setCheckoutData({
            name: `${givenName} ${familyName}`,
            email: email,
            phone: mobile,
            address: streetName,
            postalCode: +postalCode,
            city: city,
            customerType: checkoutMode,
            company: checkoutMode === HireCheckoutMode.Business ? company : optionalCompany,
            cvr: checkoutMode === HireCheckoutMode.Business ? cvr : undefined,
            departmentId: selectedDepartmentId ?? null,
            employee: selectedEmployee ? { id: selectedEmployee.employeeId, name: selectedEmployee.name } : null,
            vehicle: {
                carId: selectedProduct?.entityId ?? 0,
                referenceNumber: selectedProduct?.referenceNumber ?? selectedProduct?.id ?? '-',
                brand: selectedProduct?.brand ?? '',
                model: selectedProduct?.modelTitle ?? '',
                variant: selectedProduct?.variantTitle ?? '',
                kmPerMonth: selectedProduct?.purchaseTypes.hire.kilometersPerMonth ?? 0,
                leasingStart: selectedStartupDate ? formatDate(selectedStartupDate, DateStyle.yyyy_mm_dd) : '',
                leasingMonths: selectedLeasingPeriod ? +selectedLeasingPeriod.value : -1,
                price: totalPriceInEntireDuration,
                monthlyPrice: selectedProduct?.purchaseTypes.hire.baseMonthlyPrice ?? 0,
                establishmentFee: selectedProduct?.purchaseTypes.hire.establishmentFee ?? 0,
                equipment: allEquipments,
                color: selectedColor?.name ?? '',
                colorPrice: selectedColor?.monthlyHirePrice ?? 0,
                colorUrl:
                    selectedColor?.resource?.data.replace('icon', 'colorpreview').replace('{IMAGE-SIZE}', 'colorpreview').replace('.webp', '.png') ??
                    '',
                vehicleImageUrl: vehicleImageUrl,
            },
            consentDetails: {
                text: consentText ?? '',
                consent: termsAccepted ?? false,
                dateTime: formatDate(new Date(), DateStyle.yyyy_mm_dd),
                code: consentCode ?? 0,
            },
            homeDelivery: deliveryMode === 'deliverToAddress',
        });
    }, [
        allEquipments,
        checkoutMode,
        city,
        company,
        consentCode,
        consentText,
        cvr,
        deliveryMode,
        email,
        familyName,
        givenName,
        mobile,
        optionalCompany,
        postalCode,
        selectedColor?.monthlyHirePrice,
        selectedColor?.name,
        selectedColor?.resource?.data,
        selectedDepartmentId,
        selectedEmployee,
        selectedLeasingPeriod,
        selectedProduct?.brand,
        selectedProduct?.id,
        selectedProduct?.modelTitle,
        selectedProduct?.purchaseTypes.hire.establishmentFee,
        selectedProduct?.purchaseTypes.hire.kilometersPerMonth,
        selectedProduct?.purchaseTypes.hire.baseMonthlyPrice,
        selectedProduct?.referenceNumber,
        selectedProduct?.entityId,
        selectedProduct?.variantTitle,
        selectedStartupDate,
        streetName,
        termsAccepted,
        vehicleImageUrl,
        totalPriceInEntireDuration,
    ]);

    return checkoutData;
};
