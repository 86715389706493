import { action, computed, thunk } from 'easy-peasy';
import { DurationDeliveryActions, DurationDeliveryInjections, DurationDeliveryState, DurationDeliveryStoreModel, DurationDeliveryThunks } from '.';
import { getHireEmployees } from '../../../../api/hire/hessel-hire-lead-checkout-api';
import { createContextStoreWithRuntimeModel } from '../../../helpers/context-store.helpers';

const durationDeliveryDefaultState = (runtimeModel: Partial<DurationDeliveryState> | undefined): DurationDeliveryState => ({
    deliveryMode: runtimeModel?.deliveryMode ?? 'dealershipPickup',
    deliveryStepIsValid: computed(({ privacyPolicyAccepted, showEmployees, selectedDepartmentId }) => {
        let employeeCheck = true;

        if (showEmployees) {
            employeeCheck = !!selectedDepartmentId;
        }

        return !!privacyPolicyAccepted && employeeCheck;
    }),
    loadingEmployees: false,
    employees: [],
});

const durationDeliveryActions = (): DurationDeliveryActions => ({
    setDeliveryMode: action((state, payload) => {
        state.deliveryMode = payload;
    }),
    setPrivacyPolicyAccepted: action((state, payload) => {
        state.privacyPolicyAccepted = payload;
    }),
    setTermsAccepted: action((state, payload) => {
        state.termsAccepted = payload;
    }),
    setShowEmployees: action((state, payload) => {
        state.showEmployees = payload;
    }),

    setOptionalCompany: action((state, payload) => {
        state.optionalCompany = payload;
    }),
    setLoadingEmployees: action((state, payload) => {
        state.loadingEmployees = payload;
    }),
    setEmployees: action((state, payload) => {
        state.employees = payload;
    }),
    setSelectedDepartmentId: action((state, payload) => {
        state.selectedDepartmentId = payload;
    }),
    setSelectedEmployeeId: action((state, payload) => {
        state.selectedEmployeeId = payload;
    }),
});

const LeadDurationDeliveryThunks = (): DurationDeliveryThunks => ({
    getEmployeesThunk: thunk(async (actions, payload, { injections }) => {
        const { pushError } = injections;
        actions.setLoadingEmployees(true);

        const [result, error] = await getHireEmployees(payload.departmentId, payload.brand, payload.params);
        if (result && !error) {
            actions.setEmployees(result.filter((x) => x.workDepartment === 'Salg'));
        } else if (error) {
            pushError(error.errorType);
        }

        actions.setLoadingEmployees(false);
    }),
});

export const LeadDurationDeliveryStore = createContextStoreWithRuntimeModel<
    DurationDeliveryStoreModel,
    Partial<DurationDeliveryState>,
    DurationDeliveryInjections
>(
    (runtimeModel) => ({
        ...durationDeliveryDefaultState(runtimeModel),
        ...durationDeliveryActions(),
        ...LeadDurationDeliveryThunks(),
    }),
    { name: 'leadCheckoutDeliveryStore' }
);
