import { VFC } from 'react';
import { useGtmTracking } from '../../../gtm-tracking/hooks/use-gtm-tracking';
import { UmbracoForm } from '../../../lib/api/models/umbraco';
import { ComparisonModule } from '../../../lib/api/models/umbraco/content-spot';
import { FormsManagerStore } from '../../../lib/state/plus-sites/forms-display-manager';
import { ReepayStore } from '../../../lib/state/reepay-form/reepay-form.store';
import { handleUrlClick } from '../../../utils/helpers';
import { isUmbracoFormType } from '../../../utils/helpers/umbraco-forms.helper';
import { Button } from '../../shared';
import { useUI } from '../../ui';

import { CollapsibleCell } from './collapsible-cell';
import {
    BorderBox,
    ColumnHeader,
    ColumnSubHeader,
    ColumnTextWrapper,
    StickyTh,
    StyledIcon,
    Table,
    Tbody,
    Td,
    CtaForTd,
    Th,
    Thead,
    Tr,
    TrWithCta,
    FootnoteText,
    FootNoteWrapper,
    StickyFootNoteTh,
} from './comparison-table.styled';

type Props = {
    tableData: ComparisonModule;
};

const MEDIA_URL = process.env.NEXT_PUBLIC_MEDIA_URL;

export const ComparisonTable: VFC<Props> = ({ tableData }) => {
    const { modalOpen } = ReepayStore.useStoreState((state) => state);
    const { setSelectedAgreement, setModalOpen } = ReepayStore.useStoreActions((actions) => actions);
    const { setFormVisibility } = FormsManagerStore.useStoreActions((actions) => actions);

    const { trackMembership } = useGtmTracking();
    const tracker = trackMembership();
    const uiHandler = useUI();

    const tableHasDisclaimerText =
        tableData.columns && tableData.columns.length > 0 && tableData.columns.some((x) => x.footnote && x.footnote.length > 0);

    const tableHasCta = tableData.columns && tableData.columns.length > 0 && tableData.columns.some((x) => x.cta && x.cta.length > 0);

    return (
        <BorderBox isCentered={true}>
            <Table width={tableData.tableWidth}>
                <Thead>
                    <Tr>
                        <StickyTh></StickyTh>
                        {tableData?.columns.map((col, index) => {
                            return (
                                <Th key={`column-${index}`}>
                                    <ColumnTextWrapper>
                                        <ColumnHeader>{col.headerText}</ColumnHeader>
                                        <ColumnSubHeader>{col.subheaderText}</ColumnSubHeader>
                                    </ColumnTextWrapper>
                                </Th>
                            );
                        })}
                    </Tr>
                </Thead>
                <Tbody>
                    {tableData.rows?.map((row, index) => {
                        return (
                            <Tr key={`row-${index}`} isHighlighted={index % 2 !== 0}>
                                <StickyTh>
                                    <CollapsibleCell row={row} isAboveFold={!!tableData.isAboveFold} />
                                </StickyTh>

                                {row.cells?.map((cell, cellIndex) => {
                                    return (
                                        <Td key={`cell-${cellIndex}`}>
                                            {cell.icon ? (
                                                <StyledIcon
                                                    src={`${MEDIA_URL}${cell.icon?.src}`}
                                                    alt={row.icon?.name}
                                                    loading={tableData.isAboveFold ? 'eager' : 'lazy'}
                                                />
                                            ) : (
                                                <p>
                                                    <strong>{cell.text}</strong>
                                                </p>
                                            )}
                                        </Td>
                                    );
                                })}
                            </Tr>
                        );
                    })}

                    {tableHasCta || tableHasDisclaimerText ? (
                        <TrWithCta>
                            {tableHasDisclaimerText || tableHasCta ? (
                                <StickyFootNoteTh>
                                    <FootNoteWrapper>
                                        {tableData.columns.map((col, index) => {
                                            return <FootnoteText key={`col-footnote-${index}`}>{col.footnote}</FootnoteText>;
                                        })}
                                    </FootNoteWrapper>
                                </StickyFootNoteTh>
                            ) : null}

                            {tableHasCta
                                ? tableData.columns.map((col, index) => {
                                      return (
                                          <td key={`cta-cell-${index}`}>
                                              {col.cta && col.cta.length > 0 ? (
                                                  <CtaForTd>
                                                      <Button
                                                          onClick={() => {
                                                              const btn = col.cta[0];
                                                              if (btn.alias === 'cTAWithLink') {
                                                                  handleUrlClick(btn.linkUrl);
                                                              } else if (
                                                                  btn.alias === 'cTAWithAction' &&
                                                                  btn.action === 'Select benefit agreement' &&
                                                                  btn.benefitAgreementToSelect
                                                              ) {
                                                                  setSelectedAgreement(btn.benefitAgreementToSelect);
                                                                  if (!modalOpen) {
                                                                      tracker.openSignUp();
                                                                      setModalOpen(true);
                                                                      uiHandler.removeScroll();
                                                                  }
                                                              } else if (
                                                                  btn.alias === 'cTAWithAction' &&
                                                                  btn.action === 'Open form' &&
                                                                  btn.formToOpen
                                                              ) {
                                                                  uiHandler.removeScrollAndLock();
                                                                  setFormVisibility({
                                                                      id: isUmbracoFormType(btn)
                                                                          ? (btn.formToOpen as UmbracoForm).id
                                                                          : (btn.formToOpen as string),
                                                                      value: true,
                                                                  });
                                                              } else {
                                                                  throw new Error('Not implemented');
                                                              }
                                                          }}
                                                          variant={col.cta[0].color}
                                                          unsetMinWidth={true}
                                                      >
                                                          <p>{col.cta[0].text}</p>
                                                      </Button>
                                                  </CtaForTd>
                                              ) : null}
                                          </td>
                                      );
                                  })
                                : null}
                        </TrWithCta>
                    ) : null}
                </Tbody>
            </Table>
        </BorderBox>
    );
};
