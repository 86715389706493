import { DeliveryType } from '../../lib/enums';
import { FC } from 'react';
import { Wrapper } from './time-slot-picker.styled';
import { CustomerDeliveryAndPickup } from './customer-delivery-and-pickup';
import { CustomerStays } from './customer-stays';
import { VendorDelivery } from './vendor-delivery';
import { BookingStepWorkshop } from '../../lib/api/models/umbraco';
import { BookingServiceProductsStore } from '../../lib/state/booking-service-products';
import { BookingWorkshopsStore } from '../../lib/state/booking-workshops';

type Props = {
    deliveryType: DeliveryType;
    workshopContent: BookingStepWorkshop;
};

export const TimeSlotPicker: FC<Props> = ({ deliveryType, workshopContent }) => {
    const { serviceTooLong } = BookingServiceProductsStore.useStoreState((state) => state);
    const customerHasChosenDeliveryType = BookingWorkshopsStore.useStoreState((state) => state.customerHasChosenDeliveryType);

    const getDeliveryUi = (type: DeliveryType) => {
        if (serviceTooLong) {
            return <CustomerDeliveryAndPickup workshopContent={workshopContent} />;
        }

        switch (type) {
            case DeliveryType.SelfDeliverAndPickup:
                return <CustomerDeliveryAndPickup workshopContent={workshopContent} />;
            case DeliveryType.CustomerStays:
                return <CustomerStays workshopContent={workshopContent} />;
            case DeliveryType.ByVendor:
                return <VendorDelivery workshopContent={workshopContent} />;

            default:
                throw new Error('eh.spa: Invalid delivery type.');
        }
    };

    return customerHasChosenDeliveryType || serviceTooLong ? <Wrapper>{getDeliveryUi(deliveryType)}</Wrapper> : null;
};
