import { useMemo, VFC } from 'react';
import { hesselViewModels } from '../../../lib/view-models';
import { isNullOrEmpty } from '../../../utils/helpers';
import { StyledStandardEquipment, StyledHeader, StyledEquipmentList, StyledEquipment, StyledSeeMore } from './standard-equipment.styled';

export type StandardEquipmentProps = {
    className?: string;
    availability: hesselViewModels.VehicleAvailability;
    standardEquipmentList?: Array<hesselViewModels.Equipment>;
    standardEquipmentPackages?: Array<hesselViewModels.EquipmentPackage>;
    visibleAmount?: number;
    showAllEquipment: () => void;
    setSelectedEquipment: (equipmentPackage: hesselViewModels.Equipment) => void;
    setSelectedEquipmentPackage: (equipmentPackage: hesselViewModels.EquipmentPackage) => void;
    equipmentNames: Array<string>;
};

export const StandardEquipment: VFC<StandardEquipmentProps> = ({
    className,
    availability,
    standardEquipmentList,
    standardEquipmentPackages,
    visibleAmount = 13,
    showAllEquipment,
    setSelectedEquipment,
    setSelectedEquipmentPackage,
    equipmentNames,
}) => {
    const numberOfEquipmentToDisplay = useMemo(() => {
        if (!standardEquipmentPackages) {
            return standardEquipmentList?.length ?? 0;
        }
        return standardEquipmentPackages.length > visibleAmount ? 0 : Math.abs(visibleAmount - standardEquipmentPackages.length);
    }, [standardEquipmentList?.length, standardEquipmentPackages, visibleAmount]);

    const numberOfEquipmentPackagesToDisplay = useMemo(() => {
        if (!standardEquipmentPackages) {
            return 0;
        }
        if (standardEquipmentPackages.length > visibleAmount) {
            return visibleAmount;
        }
        return standardEquipmentPackages.length;
    }, [standardEquipmentPackages, visibleAmount]);

    const numberOfEquipmentNamesToDisplay = useMemo(() => {
        if (!equipmentNames) {
            return 0;
        }
        if (equipmentNames.length > visibleAmount) {
            return visibleAmount;
        }
        return equipmentNames.length;
    }, [equipmentNames, visibleAmount]);

    return (
        <>
            <StyledStandardEquipment className={className}>
                <StyledHeader>{availability === 'Used' || availability === 'Engros' ? 'Udstyr som bilen står med' : 'Bilens udstyr'}</StyledHeader>

                <StyledEquipmentList>
                    {availability !== 'Used' && availability !== 'Engros'
                        ? standardEquipmentList
                              ?.filter(Boolean)
                              .slice(0, numberOfEquipmentToDisplay)
                              .map((equipment, index) => {
                                  return (
                                      <StyledEquipment
                                          key={`equipment-${equipment.id}-${index}`}
                                          text={equipment.name}
                                          hasDescription={!isNullOrEmpty(equipment.description)}
                                          onClick={
                                              equipment.description && equipment.description.length > 0
                                                  ? () => {
                                                        setSelectedEquipment(equipment);
                                                    }
                                                  : undefined
                                          }
                                      />
                                  );
                              })
                        : null}

                    {availability !== 'Used' && availability !== 'Engros'
                        ? standardEquipmentPackages
                              ?.filter(Boolean)
                              .slice(0, numberOfEquipmentPackagesToDisplay)
                              .map((eqPackage, index) => {
                                  return (
                                      <StyledEquipment
                                          key={`equipment-package-${eqPackage.id}-${eqPackage.packageIdKey}-${index}`}
                                          text={eqPackage.name ?? ''}
                                          hasDescription={!isNullOrEmpty(eqPackage.description) || eqPackage.equipment?.length > 0}
                                          onClick={() => {
                                              setSelectedEquipmentPackage(eqPackage);
                                          }}
                                      />
                                  );
                              })
                        : null}

                    {availability === 'Used' || availability === 'Engros'
                        ? equipmentNames.slice(0, numberOfEquipmentNamesToDisplay).map((eqName, index) => {
                              return (
                                  <StyledEquipment key={`used-car-equipment-name-${eqName}-${index}`} text={eqName ?? ''} hasDescription={false} />
                              );
                          })
                        : null}

                    <StyledSeeMore text="Se alle" onClick={() => showAllEquipment()} />
                </StyledEquipmentList>
            </StyledStandardEquipment>
        </>
    );
};
