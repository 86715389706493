import { FC, useEffect, useState } from 'react';
import { Wrapper, ZipCodeAndCity } from './address-input.styled';
import { TextInput } from '../../../forms/inputs';
import { getAPI } from '../../../../lib/api/helpers';
import { storeInputTypes } from '../../../../lib/state/_shared';

type Props = {
    id: string;
    onChange: (args: { value: string; id: string }) => void;
    inputs: storeInputTypes.MultipleFormInput<string>['inputs'];
    canValidateInputField: boolean;
    setCanValidate: ({ rootId, id, value }: { rootId: string; id: string; value: boolean }) => void;
    required?: boolean;
};

type DawaResponse = {
    navn: string;
};

export const AddressInput: FC<Props> = ({ id, inputs, canValidateInputField, onChange, setCanValidate, required }) => {
    const address = inputs.find(({ multiformInputName }) => multiformInputName === 'address');
    const zipcode = inputs.find(({ multiformInputName }) => multiformInputName === 'zipcode');
    const city = inputs.find(({ multiformInputName }) => multiformInputName === 'city');

    const [latestZipParams, setLatestZipParams] = useState<string>();

    useEffect(() => {
        const getZipInfo = async () => {
            const [response, error] = await getAPI<DawaResponse>(`https://api.dataforsyningen.dk/postnumre/${zipcode?.value}`);
            if (response && !error && city) {
                onChange({ id: city.id, value: response.navn });
            } else if (error && city) {
                onChange({ id: city.id, value: '' });
            }
        };

        if (zipcode?.value.length === 4 && zipcode?.value !== latestZipParams) {
            getZipInfo();
            setLatestZipParams(zipcode?.value || '');
        } else if (zipcode?.value.length !== 4) {
            setLatestZipParams('');
        }
    }, [city, latestZipParams, onChange, zipcode]);

    return (
        <>
            {address && zipcode && city && (
                <Wrapper>
                    <TextInput
                        id={`${id}:`}
                        label={address.label}
                        onChange={(e) => {
                            onChange({ id: address.id, value: e.target.value });
                        }}
                        type="text"
                        placeholder={address.placeholder}
                        value={address.value}
                        isValid={address.isValid}
                        canValidateInputField={address.canValidate || canValidateInputField}
                        validationMessage={address.validationMessage}
                        onInputBlur={() => setCanValidate({ rootId: id, id: address.id, value: true })}
                        required={required}
                    />

                    <ZipCodeAndCity>
                        <TextInput
                            id={zipcode.id}
                            label={zipcode.label}
                            onChange={(e) => {
                                onChange({ id: zipcode.id, value: e.target.value });
                            }}
                            type="text"
                            placeholder={zipcode.placeholder}
                            value={zipcode.value}
                            isValid={zipcode.isValid && zipcode.value.length === 4}
                            canValidateInputField={zipcode.canValidate || canValidateInputField}
                            onInputBlur={() => setCanValidate({ rootId: id, id: zipcode.id, value: true })}
                            validationMessage={zipcode.validationMessage}
                            pattern="[0-9]*"
                            required={required}
                        />
                        <TextInput
                            id={city.id}
                            label={city.label}
                            onChange={(e) => {
                                onChange({ id: city.id, value: e.target.value });
                            }}
                            type="text"
                            placeholder={city.placeholder}
                            value={city.value}
                            isValid={city.isValid}
                            canValidateInputField={city.canValidate || canValidateInputField}
                            onInputBlur={() => setCanValidate({ rootId: id, id: city.id, value: true })}
                            validationMessage={city.validationMessage}
                            required={required}
                        />
                    </ZipCodeAndCity>
                </Wrapper>
            )}
        </>
    );
};
