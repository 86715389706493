import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useCarouselControls } from '../../../../hooks/use-carousel-controls';
import { hesselViewModels } from '../../../../lib/view-models';
import { next } from '../../../../utils/helpers/incremental-integer.helper';
import { mapSlides } from '../carousel-utils';
import {
    StyledTabbedCarousel,
    StyledTabbedCarouselControls,
    StyledTabbedCarouselNextButton,
    StyledTabbedCarouselPreviousButton,
    StyledTabbedCarouselTab,
    StyledTabbedCarouselTabImage,
    StyledTabbedCarouselTabList,
    StyledTabbedCarouselTabVideo,
    StyledTabbedInnerCarousel,
    StyledPlayButton,
    StyledTabbedCarouselTabYouTube,
    StyledYouTubeImageAndIcon,
    StyledYouTubeImageAndIconOverlay,
} from './tabbed-carousel.styled';

export type TabbedCarouselProps = {
    className?: string;
    initialIndex?: number;
    // imageAspectRatio?: string;
    slides: Array<hesselViewModels.PdpResource>;
};

export const TabbedCarousel: FC<TabbedCarouselProps> = (props) => {
    const { activeIndex, bindGestures, dragOffset, nextSlide, onKeyDown, previousSlide, setActiveIndex } = useCarouselControls(
        props.slides.length,
        props.initialIndex
    );
    const [carouselDomId] = useState(`tabbbed-carousel-${next()}`);
    const carouselItems = useMemo(() => mapSlides(props.slides, carouselDomId), [carouselDomId, props.slides]);
    const tabListRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (tabListRef.current && tabListRef.current.contains(document.activeElement)) {
            (tabListRef.current.children[activeIndex] as HTMLButtonElement).focus();
        }
    }, [activeIndex]);

    return (
        <StyledTabbedCarousel aria-roledescription="carousel" className={props.className} onKeyDown={(e) => onKeyDown(e)} role="region">
            {props.slides[activeIndex].type === 'image' ? (
                <StyledTabbedCarouselControls>
                    {props.slides.length > 1 ? <StyledTabbedCarouselPreviousButton label={'Forrige'} onClick={previousSlide} /> : <div />}
                    {props.slides.length > 1 ? <StyledTabbedCarouselNextButton label={'Næste'} onClick={nextSlide} /> : <div />}
                </StyledTabbedCarouselControls>
            ) : null}

            <StyledTabbedInnerCarousel
                activeIndex={activeIndex}
                dragOffset={dragOffset}
                id={carouselDomId}
                isTabbed={true}
                items={carouselItems}
                rootNodeAttrs={bindGestures}
            />

            <StyledTabbedCarouselTabList aria-label="Slides" ref={tabListRef} role="tablist">
                {carouselItems.map(({ id, slide }, carouselItemIndex) => (
                    <StyledTabbedCarouselTab
                        isActive={carouselItemIndex === activeIndex}
                        key={id}
                        onClick={() => {
                            setActiveIndex(carouselItemIndex);
                        }}
                        role="tab"
                        tabIndex={carouselItemIndex === activeIndex ? undefined : -1}
                        type="button"
                    >
                        {slide.type === 'image' && <StyledTabbedCarouselTabImage src={slide.thumbnailUrl || slide.url} />}

                        {slide.type === 'video' && (
                            <>
                                <StyledTabbedCarouselTabVideo preload="metadata" loop={false} playsInline={true} autoPlay={false} muted={true}>
                                    <source src={`${slide.url}#t=0.001`} type="video/mp4"></source>
                                    Your browser does not support HTML5 video.
                                </StyledTabbedCarouselTabVideo>
                                <StyledPlayButton src="/icons/play.svg" />
                            </>
                        )}

                        {slide.type === 'youTube' && (
                            <StyledYouTubeImageAndIcon>
                                <StyledYouTubeImageAndIconOverlay />
                                <StyledTabbedCarouselTabYouTube src={`https://img.youtube.com/vi/${slide.youTubeId}/0.jpg`} />
                                <StyledPlayButton src="/icons/play.svg" />
                            </StyledYouTubeImageAndIcon>
                        )}
                    </StyledTabbedCarouselTab>
                ))}
            </StyledTabbedCarouselTabList>
        </StyledTabbedCarousel>
    );
};
