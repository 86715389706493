import { FC, useEffect, useState } from 'react';
import { useSpring, config, animated } from 'react-spring';
import { Circle } from '../shapes/circle';
import { SvgIcon } from '../svg-icon';
import {
    StepWrapper,
    StepHeader,
    Header,
    StepContent,
    StepAction,
    IconAndText,
    StyledFootNote,
    ChildrenWrapper,
    PointerEvents,
    CircleText,
    StyledCheckMarkWrapper,
} from './step.styled';
import { useMeasure } from 'react-use';
import { Button } from '../button';
import { IStepProps } from './step.props';
import { StepType } from '../../../lib/state/booking-steps';
import { BookingFormStore } from '../../../lib/state/booking-form';
import { BookingWorkshopsStore } from '../../../lib/state/booking-workshops';
import { scrollToBookingStep } from '../../../utils/helpers/booking/steps.helper';
import { BookingServiceProductsStore } from '../../../lib/state/booking-service-products';

export const Step: FC<IStepProps> = ({
    title,
    nextStep = '',
    stepNumber,
    totalSteps,
    isCurrentStep,
    isCompleted,
    goNext,
    edit,
    isStepValid,
    isStepLoading,
    setCanValidateStep,
    footNote = '',
    stepType,
    children,
}) => {
    const defaultHeight: string | undefined = '0px';

    // Gets the height of the element (ref)
    const [heightRef, { height }] = useMeasure<HTMLDivElement>();

    const [contentHeight, setContentHeight] = useState(defaultHeight || height);

    const { isBookingComplete } = BookingFormStore.useStoreState((state) => state);
    const { cancelApiCalls, createNewAbortController } = BookingWorkshopsStore.useStoreActions((action) => action);
    const { selectedServiceProductsIds } = BookingServiceProductsStore.useStoreState((state) => state);

    const getOpacity = (): number => {
        if (isCurrentStep) {
            return 1;
        }

        if (isCompleted) {
            return 0.4;
        }

        return 0;
    };

    useEffect(() => {
        //Sets initial height
        setContentHeight(height);
    }, [height]);

    const [canGoNext, setCanGoNext] = useState(false);
    const [canGoToPrevious, setCanGoToPrevious] = useState(false);

    const expand = useSpring({
        config: { ...config.slow, friction: 50 },
        height: isCurrentStep || isCompleted ? `${contentHeight}px` : defaultHeight,
        onRest: () => {
            if (canGoNext) {
                scrollToBookingStep(stepNumber + 1, 0);
                setCanGoNext(false);
            }

            if (canGoToPrevious) {
                scrollToBookingStep(stepNumber, 0);
                setCanGoToPrevious(false);
            }
        },
    });

    const fadeContent = useSpring({
        config: { ...config.slow, friction: 50 },
        opacity: getOpacity(),
        delay: 300,
    });

    const isStepDisabled = (stepType: StepType, loading: boolean, isValid: boolean) => {
        if (stepType === 'Services') {
            return loading || selectedServiceProductsIds.length === 0;
        }

        if (stepType === 'YourCar' || stepType === 'ContactInfo') {
            return loading;
        }

        return !isValid || loading;
    };

    const stepPrefix = 'step-wrapper';

    return (
        <StepWrapper id={`${stepPrefix}-${stepNumber}`} isCurrentStep={isCurrentStep} isCompleted={isCompleted}>
            <StepHeader isCurrentStep={isCurrentStep} isCompleted={isCompleted}>
                <IconAndText>
                    {isCompleted ? (
                        <Circle xOffset={0} yOffset={0}>
                            <StyledCheckMarkWrapper>
                                <SvgIcon iconName="checkmark" color="primary" />
                            </StyledCheckMarkWrapper>
                        </Circle>
                    ) : (
                        <Circle xOffset={0} yOffset={0}>
                            <CircleText isCurrentStep={isCurrentStep}>
                                <strong style={{ fontWeight: '500', color: '#0B0B0B' }}>{stepNumber}</strong>/{totalSteps}
                            </CircleText>
                        </Circle>
                    )}

                    <Header>{title}</Header>
                </IconAndText>

                {isCompleted && !isBookingComplete && (
                    <PointerEvents>
                        <Button
                            variant="link"
                            onClick={() => {
                                if (stepType !== 'Receipt') {
                                    cancelApiCalls();
                                    createNewAbortController();
                                }

                                setCanGoToPrevious(true);
                                edit();
                            }}
                        >
                            <p>Rediger</p>
                        </Button>
                    </PointerEvents>
                )}
            </StepHeader>

            <animated.div style={{ ...expand, overflow: !isCurrentStep && !isCompleted ? 'hidden' : '' }}>
                <StepContent ref={heightRef}>
                    <animated.div style={fadeContent}>
                        <ChildrenWrapper>{children}</ChildrenWrapper>
                        {!isCompleted && stepNumber !== totalSteps && (
                            <StepAction>
                                <Button
                                    variant="primary"
                                    onClick={() => {
                                        if (isStepValid) {
                                            setCanGoNext(true);
                                            goNext();
                                            if (stepType === 'Workshop') {
                                                cancelApiCalls();
                                            }
                                        } else {
                                            setCanValidateStep();
                                            if (stepType === 'Services') {
                                                scrollToBookingStep(stepNumber, 0);
                                            }
                                        }
                                    }}
                                    disabled={isStepDisabled(stepType, isStepLoading, isStepValid)}
                                    loading={isStepLoading}
                                >
                                    <p>{nextStep}</p>
                                </Button>
                                <StyledFootNote>{footNote}</StyledFootNote>
                            </StepAction>
                        )}
                    </animated.div>
                </StepContent>
            </animated.div>
        </StepWrapper>
    );
};
