import { FC } from 'react';
import { useMediaQuery } from '../../../hooks/use-media-query';
import { FooterDesktop } from './desktop';
import { FooterMobile } from './mobile';
import { umbraco } from '../../../lib/api';
import { MenuContext } from '../../mega-menu/mega-menu.component';

type IProps = umbraco.FooterContent & {
    menuContext?: MenuContext;
};

export const AppFooter: FC<IProps> = (settings) => {
    const isMobile = useMediaQuery({ target: 'tablet' });

    return !isMobile ? <FooterDesktop {...settings} /> : <FooterMobile {...settings} />;
};
