import styled from 'styled-components';
import { device } from '../../../lib/media-query';

export const CheckboxBarWrapper = styled.article`
    background-color: #f1f1f2;

    display: flex;
    flex-direction: column;

    padding: 8px 20px;

    @media (max-width: 370px) {
        padding: 8px 5px;
    }

    @media ${device.mobile_tablet} {
        display: grid;
        grid-template-columns: minmax(350px, 40%) 1fr;
        align-items: center;
        justify-content: start;
    }
`;

export const TitleAndDescription = styled.article`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const StyledLabel = styled.p<{ selected: boolean }>`
    color: #0b0b0b;
    font-size: 14px;
    font-weight: ${(props) => (props.selected ? '500' : 'normal')};
    letter-spacing: 0;
    white-space: nowrap;

    @media (max-width: 370px) {
        font-size: 13px;
    }
`;

export const StyledValue = styled.p`
    color: #807f80;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    justify-self: end;
    align-self: flex-start;
    margin-left: 27px;

    white-space: nowrap;

    @media (max-width: 370px) {
        font-size: 13px;
    }

    @media ${device.mobile_tablet} {
        align-self: revert;
        justify-self: end;
        align-self: flex-end;
        margin-left: revert;
    }
`;

export const StyledButtonText = styled.p`
    white-space: nowrap;
    font-size: 13px;
`;
