import { useEffect, useMemo, useState } from 'react';
import { ShopProductDetails } from '../../lib/api/models/shop';
import { ShopFacetGroup } from '../../lib/api/models/shop/product-list';
import { FilterUpdateNotification } from '../../lib/api/models/hessel-api/hire';
import { formatTileText, getActiveFiltersFromQuery, handleSortingQuery, pushActiveFiltersToUrl } from '../../utils/helpers';
import { ShopProductlistRequest, getShopProductsByCategory } from '../../lib/api/shop/shop-product-api';
import { createShopProductListData } from '../../lib/mappers/shop/product-list.mapper';
import { ProductListData } from '../../lib/api/models/umbraco/content-spot';
import { GlobalProductListSettings, SortingLabel } from '../../lib/api/models/umbraco';
import { filterTile } from './use-vehicle-productlist';

type Params = {
    categoryId: string;
    ssrState: ProductListData;
    globalProductlistSettings: GlobalProductListSettings;
};

type ShopProductlistConfig = {
    products: ShopProductDetails[];
    facets: ShopFacetGroup[];
    filterUpdate: (filterUpdates: FilterUpdateNotification[]) => void;
    total: number;
    loadMore: (from: number, take: number) => void;
    filterTiles: filterTile[];
    resetFilters: () => void;
    isLoading: boolean;
    selectedSorting: string;
    sortOptions: SortingLabel[];
    updateSorting: (sorting: string) => void;
    activeFiltersCount: number;
};

export const useShopProductlist = (params: Params): ShopProductlistConfig => {
    const getInitialFilters = (filters: string | string[]) => {
        if (Array.isArray(filters)) return filters;
        if (filters.length > 0) return [filters];
        return [];
    };
    const [activeFilters, setActiveFilters] = useState<string[]>([...getActiveFiltersFromQuery(params.ssrState.filters ?? [])]);
    const [productListRequest, setProductListRequest] = useState<ShopProductlistRequest>({
        categoryId: params.categoryId,
        take: params.globalProductlistSettings.shopPaginationSize,
        from: params.ssrState.products.length,
        filters: getInitialFilters(params.ssrState.filters),
        sort: params.ssrState.selectedSorting.length > 0 ? params.ssrState.selectedSorting : undefined,
    });
    const [products, setProducts] = useState<ShopProductDetails[]>(params.ssrState.products);
    const [facets, setFacets] = useState<ShopFacetGroup[]>(params.ssrState.facets);
    const [selectedSorting, setSelectedSorting] = useState(params.ssrState.selectedSorting.length > 0 ? params.ssrState.selectedSorting : '');
    const [total, setTotal] = useState(params.ssrState.total);
    const [isLoading, setIsLoading] = useState(false);

    const [userHasInteracted, setUserHasInteracted] = useState(false);

    const filterUpdate = (updates: FilterUpdateNotification[]) => {
        let accumulator = activeFilters;
        updates.forEach((update) => {
            if (update.action === 'Add') {
                accumulator = [...accumulator, update.query];
            } else if (update.action === 'Remove') {
                accumulator = [...accumulator.filter((x) => x !== update.query)];
            } else if (update.action === 'Replace') {
                const baseQuery = update.query.split('__')[0];
                accumulator = [...accumulator.filter((x) => !x.startsWith(baseQuery)), update.query];
            } else if (update.action === 'Remove all in key') {
                const baseQuery = update.query.split('__')[0];
                accumulator = [...accumulator.filter((x) => !x.startsWith(baseQuery))];
            }
        });
        setActiveFilters(accumulator);
        setUserHasInteracted(true);
    };

    const loadMore = (from: number, take: number) => {
        setUserHasInteracted(true);
        pushActiveFiltersToUrl(activeFilters, (from / take + 1).toString());
        setProductListRequest({
            ...productListRequest,
            from,
            take,
        });
    };

    const resetFilters = () => {
        setActiveFilters([]);
    };

    useEffect(() => {
        const getProductList = async () => {
            setIsLoading(true);
            const [response, error] = await getShopProductsByCategory(productListRequest);
            if (response && !error) {
                const mappedResponse = createShopProductListData(response.aggregates, params.globalProductlistSettings);
                setProducts(productListRequest.from > 0 ? [...products, ...response.products] : response.products);
                setFacets(mappedResponse);
                setTotal(response.total);
            }
            setIsLoading(false);
        };
        if (userHasInteracted) {
            getProductList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productListRequest]);

    const filterTiles = useMemo(() => {
        if (facets) {
            const filters = activeFilters;
            const filterTilesObjects = filters.map((x) => {
                const facetKey = x.split('__')[0];
                const facetMatch = facets.find((x) => x.key.indexOf(facetKey) >= 0);
                if (facetMatch) {
                    const labelTranslation = params.globalProductlistSettings.facetGroupTranslations.find((x) => x.facetGroupKey === facetMatch.key);
                    const tileText = formatTileText(x, '--');
                    return {
                        label: labelTranslation?.facetGroupLabelText ?? facetMatch.label,
                        value: tileText,
                        filterUpdateNotification: { action: 'Remove', query: x } as FilterUpdateNotification,
                        valid: true,
                    };
                }
                return null;
            });
            const reducedFilterTiles: filterTile[] = filterTilesObjects.reduce((acc, curr) => {
                if (curr) {
                    const matchingEntry = acc.find((x) => x.label === curr.label && x.valid === curr.valid);
                    if (matchingEntry) {
                        matchingEntry.values = [...matchingEntry.values, curr.value];
                        matchingEntry.filterUpdateNotifications = [...matchingEntry.filterUpdateNotifications, curr.filterUpdateNotification];
                        return acc.map((x) => (x.label === matchingEntry.label && x.valid === matchingEntry.valid ? matchingEntry : x));
                    }
                    return [
                        ...acc,
                        { label: curr.label, filterUpdateNotifications: [curr.filterUpdateNotification], values: [curr.value], valid: curr.valid },
                    ];
                }
                return acc;
            }, new Array<filterTile>());
            return reducedFilterTiles;
        }
        return [];
    }, [activeFilters, facets, params.globalProductlistSettings.facetGroupTranslations]);

    useEffect(() => {
        if (userHasInteracted) {
            pushActiveFiltersToUrl(
                activeFilters.sort((a, b) => a.localeCompare(b, 'da-DK')),
                '0'
            );
            setProductListRequest({ ...productListRequest, filters: activeFilters, from: 0 });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeFilters]);

    const sortOptions = useMemo(() => {
        return params.ssrState.sortOptions.map((x) => {
            const translationMatch = params.globalProductlistSettings.sortingTranslations.find((lb) => lb.sortingKey === x);
            return {
                sortingKey: x,
                labelText: translationMatch ? translationMatch.labelText : x,
            };
        });
    }, [params.globalProductlistSettings.sortingTranslations, params.ssrState.sortOptions]);

    const updateSorting = (sorting: string) => {
        if (!userHasInteracted) {
            setUserHasInteracted(true);
        }
        setSelectedSorting(sorting);
        handleSortingQuery(sorting, params.ssrState.selectedSorting);
        setProductListRequest({
            ...productListRequest,
            from: 0,
            sort: sorting,
        });
    };

    const activeFiltersCount = useMemo(() => {
        return activeFilters.length;
    }, [activeFilters.length]);

    return {
        products,
        facets,
        filterUpdate,
        total,
        loadMore,
        filterTiles,
        resetFilters,
        isLoading,
        sortOptions,
        selectedSorting,
        updateSorting,
        activeFiltersCount,
    };
};
