import styled from 'styled-components';
import { device } from '../../../lib/media-query';
import { theme } from '../../../themes/default-theme';
import { SpotHeader } from '../../shared/typography/spot-header/spot-header.component';
import { mixins } from '../../../themes';

const mediaBottomMargin = '23px';

export const StyledDynamicContentBoxContainer = styled.section<{
    backgroundColor: string;
    fontColor: string;
}>`
    background-color: ${(props) => (props.backgroundColor.length ? `#${props.backgroundColor}` : '#f7f7f8')};
    padding: 30px 0;
    color: ${(props) => (props.fontColor.length ? `#${props.fontColor}` : `${theme.palette.primary}`)};

    @media ${device.tablet} {
        padding: 64px 0;
    }
`;

export const StyledDynamicContentBoxGrid = styled.div<{ columns: number; divisible: boolean; singleBoxJustifiedCenter: boolean }>`
    display: grid;
    gap: 40px;

    ${(props) =>
        props.divisible
            ? `@media ${device.tablet} {
                grid-template-columns: repeat(${props.columns / 2}, 1fr);
            }`
            : `@media ${device.tablet} {
                grid-template-columns: repeat(${props.columns}, 1fr);
            }`}

    @media ${device.laptopS} {
        grid-template-columns: repeat(${(props) => props.columns}, 1fr);
    }
    @media ${device.tablet} {
        ${(props) => (props.singleBoxJustifiedCenter ? 'grid-template-columns: 50%;justify-content:center;' : '')}
    }
`;

export const StyledContentBoxImage = styled.img<{ clickable: boolean; useSquaredCorners?: boolean }>`
    max-width: 100%;
    display: block;
    margin: 0 auto ${mediaBottomMargin};
    cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
    ${({ useSquaredCorners }) => mixins.useSquaredCorners(useSquaredCorners)}
`;

export const StyledContentBoxTitle = styled.p`
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    color: unset;
`;

export const StyledDynamicSpotTitle = styled(SpotHeader)`
    color: unset;
`;

export const StyledContentBoxSubtitle = styled.p`
    font-size: 12px;
    color: unset;
    opacity: 0.5;
`;

export const StyledContentBoxText = styled.div`
    p {
        margin-top: 5px;
        font-size: 14px;
    }
`;

export const StyledContentBoxCtaWrapper = styled.div<{ alignment: string }>`
    display: flex;
    gap: 20px;
    margin-top: 15px;
    flex-direction: column;
    flex-wrap: revert;
    align-items: ${(props) => props.alignment};

    @media ${device.mobile_tablet} {
        flex-direction: revert;
        flex-wrap: wrap;
        align-items: revert;
    }
`;

export const StyledDynamicContentBoxHeader = styled.p`
    font-family: 'Corporate E';
    font-size: 30px;
    font-weight: 700;
    color: unset;
    margin-bottom: 10px;
    line-height: 24px;
`;

export const StyledSpotHeader = styled(SpotHeader)`
    margin-bottom: 10px;
`;

export const StyledIframeContainer = styled.div<{ useSquaredCorners?: boolean }>`
    width: 100%;
    aspect-ratio: 16/9;
    margin-bottom: ${mediaBottomMargin};

    iframe {
        border: none;
        display: block;
        ${({ useSquaredCorners }) => mixins.useSquaredCorners(useSquaredCorners)};
    }
`;

export const StyledVideoContainer = styled.div<{ useSquaredCorners?: boolean }>`
    aspect-ratio: 16 / 9;

    video {
        margin-bottom: ${mediaBottomMargin};
        ${({ useSquaredCorners }) => mixins.useSquaredCorners(useSquaredCorners)};
    }
`;
