import { FC, useMemo } from 'react';
import { Button } from '..';
import { StyledLoadMoreContainer, StyledSummaryText } from './load-more.styled';
import { useRouter } from 'next/router';

type IProps = {
    onClick: () => void;
    total: number;
    take: number;
    current: number;
    loadMoreText: string;
};

export const LoadMore: FC<IProps> = ({ onClick, total, take, current, loadMoreText }) => {
    const toTake = useMemo(() => {
        if (total > current + take) {
            return take;
        } else {
            return total - current;
        }
    }, [current, take, total]);

    const buttonText = useMemo(() => loadMoreText.replace('{{amount}}', toTake.toString()), [loadMoreText, toTake]);
    const router = useRouter();
    const loadMoreUrl = useMemo(() => {
        if (router.asPath.indexOf('page=') > -1) {
            const [noPageUrl, pageParam] = router.asPath.split('page=');
            return `${noPageUrl}page=${(+pageParam + 1).toString()}`;
        }
        return `${router.asPath}${router.asPath.indexOf('?') > -1 ? '&' : '?'}page=2`;
    }, [router.asPath]);

    if (total <= current) {
        return null;
    }

    return (
        <StyledLoadMoreContainer>
            <StyledSummaryText>{`${current} / ${total}`}</StyledSummaryText>
            <a href={loadMoreUrl} onClick={(e) => e.preventDefault()}>
                <Button variant="primary" onClick={() => onClick()}>
                    <p>{buttonText}</p>
                </Button>
            </a>
        </StyledLoadMoreContainer>
    );
};
