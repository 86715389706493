import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { useCarouselControls } from '../../../../hooks/use-carousel-controls';
import { hesselViewModels } from '../../../../lib/view-models';
import { next } from '../../../../utils/helpers/incremental-integer.helper';
import { mapSlides } from '../carousel-utils';

import {
    StyledSimpleCarousel,
    StyledSimpleCarouselControls,
    StyledSimpleCarouselNextButton,
    StyledSimpleCarouselPreviousButton,
    StyledSimpleInnerCarousel,
    StyledSimpleInnerCarouselCaption,
} from './simple-carousel.styled';

export type SimpleCarouselProps = {
    renderControls?: (activeIndex: number) => ReactNode;
    className?: string;
    imageAspectRatio?: string;
    slides: hesselViewModels.PdpResource[];
    resetCarousel?: boolean;
    onCarouselReset?: () => void;
    showImageAsCover?: boolean;
    isAboveFold: boolean;
    useSquaredCorners?: boolean;
};

export const SimpleCarousel: FC<SimpleCarouselProps> = (props) => {
    const { activeIndex, bindGestures, dragOffset, nextSlide, onKeyDown, previousSlide, setActiveIndex } = useCarouselControls(props.slides.length);
    const [carouselDomId] = useState(`simple-carousel-${next()}`);
    const carouselItems = useMemo(() => mapSlides(props.slides, carouselDomId), [carouselDomId, props.slides]);

    useEffect(() => {
        if (props.resetCarousel) {
            setActiveIndex(0);
            props.onCarouselReset?.();
        }
    }, [props, props.resetCarousel, setActiveIndex]);

    return props.slides ? (
        <StyledSimpleCarousel aria-roledescription="carousel" className={props.className} role="region">
            <StyledSimpleCarouselControls onKeyDown={(e) => onKeyDown(e)}>
                {props.slides.length > 1 ? <StyledSimpleCarouselPreviousButton label={'Forrige'} onClick={previousSlide} /> : <div />}
                {props.renderControls && carouselItems.length > 0 && carouselItems[activeIndex].slide.type === 'image'
                    ? props.renderControls(activeIndex)
                    : null}
                {props.slides.length > 1 ? <StyledSimpleCarouselNextButton label={'Næste'} onClick={nextSlide} /> : <div />}
            </StyledSimpleCarouselControls>
            <StyledSimpleInnerCarousel
                activeIndex={activeIndex}
                aspectRatio={props.imageAspectRatio}
                dragOffset={dragOffset}
                id={carouselDomId}
                items={carouselItems}
                rootNodeAttrs={bindGestures}
                renderCaption={(slide) => {
                    if (slide.type === 'youTube') {
                        return null;
                    }

                    return slide.caption && <StyledSimpleInnerCarouselCaption>{slide.caption}</StyledSimpleInnerCarouselCaption>;
                }}
                showImageAsCover={props.showImageAsCover}
                isAboveFold={props.isAboveFold}
                useSquaredCorners={props.useSquaredCorners}
            />
        </StyledSimpleCarousel>
    ) : null;
};
