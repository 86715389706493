import styled from 'styled-components';
import { KeyValueTable } from '../../shared';
import { CompactCarousel } from '../../shared/carousels/compact-carousel/compact-carousel.component';
import { SimpleCarousel } from '../../shared/carousels/simple-carousel/simple-carousel.component';

export const StyledBenefitsDialogHeaderWrapper = styled.header`
    margin-bottom: 20px;
`;

export const StyledBenefitsDialogTitle = styled.p`
    font: 700 25px/30px 'Corporate E', Serif;
    margin: 0 0 14px;
`;

export const StyledBenefitsDialogSubtitle = styled.div`
    font: 400 14px/23px ${({ theme }) => theme.typography.fontFamily}, Sans-Serif;
    margin: 0 0 3px;
`;

export const StyledBenefitsDialogBody = styled.div`
    font: 400 14px/23px ${({ theme }) => theme.typography.fontFamily}, Sans-Serif;

    h2 {
        font: inherit;
        font-weight: 700;
        margin: 0 0 5px;
    }

    p {
        font: inherit;
        margin: 0 0 9px;
    }

    & ul,
    & ol {
        list-style: revert;
        padding: revert;
        margin: revert;
    }
`;

export const StyledBenefitsDialogTable = styled(KeyValueTable)`
    font: 500 12px/16px ${({ theme }) => theme.typography.fontFamily}, Sans-Serif;
    margin-bottom: 35px;
    margin-top: 20px;
`;

export const StyledBenefitsDialogCarousel = styled(SimpleCarousel)`
    margin-bottom: 102px;
`;

export const StyledCompactCarousel = styled(CompactCarousel)`
    margin-bottom: 102px;
`;
