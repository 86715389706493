import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { getParcelShops } from '../../../../../lib/api/shop/parcel-shop.api';
import { ParcelShop } from '../../../../../lib/api/models/shop/parcel-shops';
import {
    StyledPickupDestination,
    StyledPickupDestinationHeader,
    StyledPickupDestinationsWrapper,
    StyledScrollMoreIndicator,
    StyledSpinnerWrapper,
    StyledUserZipWrapper,
} from './shop-pickup.styled';
import { TextInput } from '../../../../forms/inputs';
import { CartStoreContext } from '../../../../../lib/state/shop/cart/cart.store';
import { SvgIcon } from '../../../../shared/svg-icon';
import { Button } from '../../../../shared/button';
import { Spinner } from '../../../../shared/spinner';
import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { ParcelShopMap } from './parcel-shop-pickup-map.component';

export const ParcelShopPickup: FC = () => {
    const [parcelShops, setParcelShops] = useState<ParcelShop[]>([]);
    const [selectedParcelShop, setSelectedParcelShop] = useState<ParcelShop>();
    const { cart } = CartStoreContext.useStoreState((state) => state);
    const [zip, setZip] = useState(cart?.customer?.customerAddress.zipCode ?? '');

    const getParcelShopsAsync = useCallback(async () => {
        if (zip.length === 4) {
            const [result, error] = await getParcelShops(zip);
            if (result && !error) {
                setParcelShops(result);
                setSelectedParcelShop(result[0]);
            }
        }
    }, [zip]);

    useEffect(() => {
        getParcelShopsAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (zip.length === 4) {
            getParcelShopsAsync();
        }
    }, [getParcelShopsAsync, zip]);

    const mapsRender = (status: Status) => {
        switch (status) {
            case Status.LOADING:
                return (
                    <StyledSpinnerWrapper>
                        <Spinner size="large" variant="dark" />
                    </StyledSpinnerWrapper>
                );
            case Status.FAILURE:
                return <p>Error loading google maps</p>;
            case Status.SUCCESS: {
                return (
                    <>
                        {selectedParcelShop ? (
                            <ParcelShopMap
                                parcelShops={parcelShops}
                                selectedParcelShop={selectedParcelShop}
                                setSelectedParcelShop={(newShop) => setSelectedParcelShop(newShop)}
                            />
                        ) : (
                            <StyledSpinnerWrapper>
                                <Spinner size="large" variant="dark" />
                            </StyledSpinnerWrapper>
                        )}
                    </>
                );
            }
        }
    };

    const mapKey = useMemo(() => {
        const allIds = [...parcelShops.map((x) => x.id)];
        return allIds.join('-');
    }, [parcelShops]);

    return (
        <div>
            <StyledUserZipWrapper>
                <TextInput
                    canValidateInputField={false}
                    id="zip-search"
                    isValid={false}
                    type="text"
                    label="POST NR."
                    onChange={(e) => {
                        setZip(e.target.value);
                    }}
                    value={zip}
                />
            </StyledUserZipWrapper>
            <StyledPickupDestinationsWrapper>
                {parcelShops.map((x) => {
                    const selected = x === selectedParcelShop;
                    return (
                        <StyledPickupDestination key={x.id} selected={selected} id={`destination-${x.id}`}>
                            <div>
                                <StyledPickupDestinationHeader>{x.name}</StyledPickupDestinationHeader>
                                <p>
                                    {x.address}, {x.zipcode} {x.city}
                                </p>
                            </div>
                            <div>
                                <Button variant={selected ? 'light' : 'primary'} onClick={() => setSelectedParcelShop(x)}>
                                    {selected ? (
                                        <span style={{ display: 'flex', gap: '5px', alignItems: 'center', marginRight: '-7px' }}>
                                            <span>Valgt</span>
                                            <SvgIcon iconName="checkmark" />
                                        </span>
                                    ) : (
                                        'Afhent her'
                                    )}
                                </Button>
                            </div>
                        </StyledPickupDestination>
                    );
                })}
                <StyledScrollMoreIndicator />
            </StyledPickupDestinationsWrapper>
            {parcelShops.length === 0 ? (
                <StyledSpinnerWrapper>
                    <Spinner size="large" variant="dark" />
                </StyledSpinnerWrapper>
            ) : (
                <Wrapper
                    id="shop-pickup-maps"
                    apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY ?? ''}
                    render={(status) => mapsRender(status)}
                    key={mapKey}
                />
            )}
        </div>
    );
};
