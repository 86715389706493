import styled from 'styled-components';
import { device } from '../../../../lib/media-query';

export const StyledEquipmentSection = styled.div`
    & > div:first-of-type {
        border-top: 1px solid #f1f1f2;
    }

    & > div {
        border-bottom: 1px solid #f1f1f2;
    }
`;

export const StyledHeader = styled.h3`
    color: #0b0b0b;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    margin-bottom: 10px;
`;

export const AddOnInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    padding: 15px 0px;
`;

export const CheckboxAndReadMore = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export const StyledPrice = styled.p`
    color: #807f80;
    font-size: 13px;
    letter-spacing: 0;
    white-space: nowrap;
    padding-left: 28px;
    @media ${device.tablet} {
        font-size: 14px;
    }
`;

export const StyledButtonText = styled.p`
    white-space: nowrap;
    font-size: 13px;
    @media ${device.tablet} {
        font-size: 14px;
    }
`;

export const StyledEquipmentText = styled.p`
    font-size: 13px;
    @media ${device.tablet} {
        font-size: 14px;
    }
`;
