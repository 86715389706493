import styled from 'styled-components';
import { device } from '../../../lib/media-query';
import { Alert, MapPin, Clock, Phone } from '../../icons';
import { Button } from '../button';

export const ContentWrapper = styled.article<{ selected: boolean }>`
    display: grid;
    grid-template-columns: 0px 1fr;
    padding: 24px 25px;
    background-color: ${(props) => (props.selected ? 'hsla(0, 0%, 85%, 0.3)' : '#ffffff')};
    cursor: pointer;

    @media ${device.mobile_tablet} {
        grid-template-columns: 40px 1fr;
    }

    &:hover {
        background-color: hsla(0, 0%, 85%, 0.3);
    }
`;

export const StyledDealershipInfoCard = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Header = styled.p`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 0;
    margin-bottom: 10px;
`;

export const IconAndTextWrapper = styled.div`
    display: flex;
    gap: 10px;
    margin-bottom: 5px;
`;

export const StyledClickableText = styled.a`
    color: ${({ theme }) => theme.palette.primary.main};
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 14px;
    letter-spacing: 0;
    cursor: pointer;
    font-weight: normal;
`;

export const StyledMapPin = styled(MapPin)`
    margin-left: -6px;
`;

export const StyledClock = styled(Clock)`
    margin-left: -6px;
    min-width: 20px;
`;

export const StyledPhone = styled(Phone)`
    margin-left: -6px;
`;

export const StyledAlert = styled(Alert)`
    margin-left: -4.5px;
`;

export const StyledHoursWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledText = styled.p`
    font-weight: normal;
    color: #0b0b0b;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 14px;
    letter-spacing: 0;
    line-height: 23px;
`;

export const SeeMoreBtn = styled(Button)`
    width: 40%;
    @media ${device.tablet} {
        width: 117px;
    }
`;

export const BookTimeBtn = styled(Button)`
    width: 60%;

    @media (max-width: 500px) {
        width: 100%;
    }

    @media ${device.tablet} {
        width: revert;
    }
`;

export const CtaWrapper = styled.div`
    display: flex;
    gap: 11px;
    flex-direction: column;
    margin-top: 16px;

    @media ${device.tablet} {
        flex-direction: revert;
        gap: 10px;
    }
`;

export const ButtonText = styled.p`
    font-size: 13px;
`;

export const StyledDepartmentName = styled.span`
    font-weight: 500;
`;
