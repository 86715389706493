import { umbraco } from '../../api';
import { PickStepType, Step, StepType } from './booking-steps.types';

export const convertBookingStepToStepState = (step: umbraco.BookingStep): Step => {
    switch (step.alias) {
        case 'bookingStepYourCar':
            return {
                type: 'YourCar',
                content: step,
                isComplete: false,
                isValid: false,
                isStepLoading: false,
                canValidateStep: false,
            };

        case 'bookingStepServices':
            return {
                type: 'Services',
                content: step,
                isComplete: false,
                isValid: false,
                isStepLoading: false,
                canValidateStep: false,
            };

        case 'bookingStepWorkshop':
            return {
                type: 'Workshop',
                content: step,
                isComplete: false,
                isValid: false,
                isStepLoading: false,
                canValidateStep: false,
            };
        case 'bookingStepContactInfo':
            return {
                type: 'ContactInfo',
                content: step,
                isComplete: false,
                isValid: false,
                isStepLoading: false,
                canValidateStep: false,
            };

        case 'bookingStepReceipt':
            return {
                type: 'Receipt',
                content: step,
                isComplete: false,
                isValid: false,
                isStepLoading: false,
                canValidateStep: false,
            };
        default:
            return {
                type: 'Unknown',
                content: {
                    alias: 'Unknown',
                    submitButtonText: '',
                    titleText: '',
                    hideBlock: false,
                    fromDate: new Date(),
                    toDate: new Date(),
                    scrollAnchorId: '',
                },
                isComplete: false,
                isValid: false,
                isStepLoading: false,
                canValidateStep: false,
            };
    }
};

export const filterStepType =
    <T extends StepType>(type: T) =>
    (step: Step): step is PickStepType<T> =>
        step.type === type;
