import { useEffect, useMemo, useState } from 'react';
import { DeliveryType } from '../../../lib';
import { hesselApi } from '../../../lib/api';
import { BookingContactInfoStore } from '../../../lib/state/booking-contact-info';
import { BookingFormStore } from '../../../lib/state/booking-form';
import { generateFormId } from '../../../lib/state/booking-form/booking-form.helpers';
import { BookingServiceProductsStore } from '../../../lib/state/booking-service-products';
import { BookingStepsStore } from '../../../lib/state/booking-steps';
import { filterStepType } from '../../../lib/state/booking-steps/booking-steps.helpers';
import { BookingWorkshopsStore } from '../../../lib/state/booking-workshops';
import { DateStyle, formatDate, formInputValue, removeDuplicateProducts } from '../../../utils/helpers';

export const useBookAppointmentData = (): hesselApi.BookServiceAppointment | undefined => {
    const [data, setData] = useState<hesselApi.BookServiceAppointment>();

    const { isTermsAccepted } = BookingContactInfoStore.useStoreState((state) => state);
    const { vehicle, inputs } = BookingFormStore.useStoreState((state) => state);
    const { comment, selectedServiceProductsIds, serviceTooLong, flattenedProductsAndSubProducts, flattenedProductsOptions } =
        BookingServiceProductsStore.useStoreState((state) => state);
    const { steps } = BookingStepsStore.useStoreState((state) => state);
    const { deliveryType, rentalCar, ownRisk, selectedTimeSlot, selectedTimeSlotToStay, selectedWorkshop } = BookingWorkshopsStore.useStoreState(
        (state) => state
    );
    const { city, postalCode, streetName, email, mobile } = BookingContactInfoStore.useStoreState((state) => state.contactInfo);

    const { familyName, givenName, mileage, licensePlate } = useMemo(() => {
        const mileageInput = inputs.find(({ id }) => id === generateFormId('Services', 'Driven Kilometers'));

        const nameInput = inputs.find(({ id }) => id === generateFormId('ContactInfo', 'Name'));
        const [familyName, ...reversed] = (nameInput ? formInputValue(nameInput).trim() : '').split(' ').reverse();
        const mileage = mileageInput ? Number(formInputValue(mileageInput)) : null;
        const licensePlateInput = inputs.find((s) => s.id === generateFormId('YourCar', 'Car Registration Number'));

        return {
            givenName: reversed.reverse().join(' '),
            familyName,
            mileage: mileage === 0 ? null : mileage,
            licensePlate: licensePlateInput ? formInputValue(licensePlateInput) : null,
        };
    }, [inputs]);

    const productIdsToSubmit: string[] = useMemo(() => {
        const selectedProductsWithoutOptions = removeDuplicateProducts(flattenedProductsAndSubProducts) // This is necessary because "Dækopbevaring" is a duplicated product
            .filter((product) => (product.productOptions ?? []).length === 0)
            .filter(({ id }) => selectedServiceProductsIds.includes(id));

        const selectedOptions = flattenedProductsOptions.filter(({ id }) => selectedServiceProductsIds.includes(id));

        return selectedProductsWithoutOptions.concat(selectedOptions).map((product) => product.id);
    }, [flattenedProductsAndSubProducts, flattenedProductsOptions, selectedServiceProductsIds]);

    const productIdsForAddTirehotel = useMemo(
        () =>
            steps
                .filter(filterStepType('Services'))
                .find(() => true)
                ?.content.tirehotelProductIds?.map(({ productId }) => productId),
        [steps]
    );

    const consentText = useMemo(
        () => steps.map((step) => (step.type === 'ContactInfo' ? step.content.termsContent : '')).find((content) => (content ? true : false)),
        [steps]
    );

    const consentCode = useMemo(
        () => steps.map((step) => (step.type === 'ContactInfo' ? step.content.consentCode : 0)).find((content) => (content ? true : false)),
        [steps]
    );

    const { customerStays, date } = useMemo(
        () => ({
            customerStays: deliveryType === DeliveryType.CustomerStays,
            date: deliveryType === DeliveryType.CustomerStays ? selectedTimeSlotToStay?.startDateTime : selectedTimeSlot?.startDateTime,
        }),
        [deliveryType, selectedTimeSlot?.startDateTime, selectedTimeSlotToStay?.startDateTime]
    );

    useEffect(() => {
        setData({
            customer: {
                address: {
                    city,
                    countryCode: 'DK',
                    postalCode,
                    streetName,
                },
                contactInformation: {
                    email,
                    mobile,
                },
                names: {
                    familyName,
                    givenName,
                },
            },
            details: {
                consent: isTermsAccepted ?? false,
                consentText: consentText ?? '',
                consentCode: consentCode ?? 0,
                customerNotes: comment ?? '',
                dueInDateTime: formatDate(date ?? new Date(), DateStyle.booking_format),
            },
            bookingOptions: {
                customerRentalCar: rentalCar ?? false,
                rentalCarInsurance: ownRisk ? true : false,
                customerStays: customerStays,
                pickUpCustomerCar: deliveryType === DeliveryType.ByVendor,
                serviceTooLong: serviceTooLong,
                customerBringsTires: !vehicle?.hasStoredTires,
                addTirehotelToOrder: selectedServiceProductsIds.some((product) => productIdsForAddTirehotel?.includes(product)),
            },
            serviceProducts: productIdsToSubmit.filter((product) => !productIdsForAddTirehotel?.includes(product)),
            storeId: Number(selectedWorkshop ?? '0'),
            vehicle: {
                class: vehicle?.class ?? '',
                identification: {
                    licensePlate: licensePlate ?? '',
                },
                makeId: vehicle?.makeId ?? '',
                mileage: {
                    value: mileage,
                },
            },
        });
    }, [
        city,
        comment,
        consentCode,
        consentText,
        customerStays,
        date,
        deliveryType,
        email,
        familyName,
        givenName,
        isTermsAccepted,
        licensePlate,
        mileage,
        mobile,
        ownRisk,
        postalCode,
        productIdsForAddTirehotel,
        productIdsToSubmit,
        rentalCar,
        selectedServiceProductsIds,
        selectedWorkshop,
        serviceTooLong,
        streetName,
        vehicle?.class,
        vehicle?.hasStoredTires,
        vehicle?.makeId,
    ]);

    return data;
};
