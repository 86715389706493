import { FC } from 'react';
import { umbraco } from '../../../../../lib/api';
import { BookingContactInfoStore } from '../../../../../lib/state/booking-contact-info';
import { handleUrlClick } from '../../../../../utils/helpers';
import { Button, CheckBox } from '../../../../shared';
import { PoliciesWrapper, ContentWrapper, StyledText } from './policies.styled';

type Props = {
    content: umbraco.BookingStepContactInfo;
    canValidate: boolean;
    showTerms: () => void;
};

export const Policies: FC<Props> = ({ content, showTerms, canValidate }) => {
    const { isPolicyAccepted, isTermsAccepted } = BookingContactInfoStore.useStoreState((state) => state);
    const { setPolicyAccepted, setTermsAccepted } = BookingContactInfoStore.useStoreActions((actions) => actions);

    return (
        <PoliciesWrapper>
            <CheckBox
                id="dataPolicy"
                value="0"
                checked={isPolicyAccepted || false}
                onChange={(checked) => {
                    setPolicyAccepted(checked);
                }}
                required={true}
                isValid={!canValidate || isPolicyAccepted}
                validationMessage={content.privacyPolicyValidationMessage}
                textAlign="top"
            >
                <ContentWrapper>
                    <StyledText>
                        {content.privacyPolicyLabel}{' '}
                        <Button variant="link" onClick={() => handleUrlClick(content.privacyPolicyUrl)}>
                            {content.privacyPolicyLinkText}
                        </Button>
                    </StyledText>
                </ContentWrapper>
            </CheckBox>

            <CheckBox
                id="terms"
                value="1"
                checked={isTermsAccepted || false}
                onChange={(checked) => {
                    setTermsAccepted(checked);
                }}
                textAlign="top"
            >
                <ContentWrapper>
                    <StyledText>
                        {content.termsLabel}{' '}
                        <Button variant="link" onClick={() => showTerms()}>
                            {content.termsLinkText}
                        </Button>
                    </StyledText>
                </ContentWrapper>
            </CheckBox>
        </PoliciesWrapper>
    );
};
