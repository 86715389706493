import Link from 'next/link';
import { FC } from 'react';
import { InspirationalLink as InspirationalLinkType } from '../../../lib/api/models/umbraco/mega-menu.types';
import { cmsUrlWithoutSiteId, getCmsImageUrl } from '../../../utils/helpers';
import { StyledHeader, StyledInspirationalImage, StyledInspirationalLinkBox, StyledSubText } from './inspirational-link.styled';

type IProps = {
    link: InspirationalLinkType;
    onLinkClick: () => void;
};

export const InspirationalLink: FC<IProps> = ({ link, onLinkClick }) => {
    return (
        <Link href={cmsUrlWithoutSiteId(link.linkDestination.url)} passHref>
            <StyledInspirationalLinkBox onClick={() => onLinkClick()}>
                <StyledInspirationalImage useSquaredCorners={link.useSquaredCorners} src={getCmsImageUrl(link.image.src)} />
                <StyledHeader>{link.header}</StyledHeader>
                {link.subText.length > 0 ? <StyledSubText>{link.subText}</StyledSubText> : null}
            </StyledInspirationalLinkBox>
        </Link>
    );
};
