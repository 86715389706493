import styled from 'styled-components';
import { device } from '../../../lib/media-query';

export const BorderBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    padding-bottom: 150px;

    @media ${device.tablet} {
        gap: 1.5rem;
    }
    @media ${device.desktop} {
        padding-bottom: 200px;
    }
`;

export const Close = styled.div`
    display: flex;
    align-content: center;
    width: auto;
    align-self: flex-end;
    cursor: pointer;
`;

export const Header = styled.p`
    font-family: 'Corporate E';
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 25px;
`;

export const StyledText = styled.p`
    font-family: ${({ theme }) => theme.typography.fontFamily};
    color: #0b0b0b;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 23px;
`;

export const Footnote = styled.p`
    max-width: 361px;
    opacity: 0.5;
    color: #0b0b0b;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 11px;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: 0.4rem;
`;

export const SavingsResultWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media ${device.tablet} {
        flex-direction: row;
        justify-content: space-between;
    }
`;

export const CtasAndValues = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;

    @media ${device.tablet} {
        align-items: flex-end;
    }
`;

export const ResultsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @media ${device.tablet} {
        flex-direction: row;
        justify-content: space-between;
    }
`;

export const ResultWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media ${device.tablet} {
        align-items: flex-end;
    }
`;

export const Result = styled.p`
    color: ${({ theme }) => theme.palette.primary.main};
    font-family: 'Corporate E';
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
`;

export const ResultDescription = styled.p`
    color: #0b0b0b;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 15px;
    letter-spacing: 0;
    line-height: 23px;
`;
export const Ctas = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.1rem;
    align-self: stretch;

    & > button:nth-of-type(1) {
        order: 2;
    }

    & > button:nth-of-type(2) {
        order: 1;
    }

    @media ${device.tablet} {
        flex-direction: row;
        justify-content: space-between;
        align-self: revert;

        & > button:nth-of-type(1) {
            order: 1;
        }

        & > button:nth-of-type(2) {
            order: 2;
        }
    }
`;
