import React, { useEffect, useMemo, useState, VFC } from 'react';
import { useSpring, config, animated } from 'react-spring';
import { useMeasure } from 'react-use';
import { useMediaQuery } from '../../../../hooks/use-media-query';
import { CompareCarsLeasingTypesModal } from '../../../../lib/api/models/umbraco/product-details';
import { sanitizeMarkup } from '../../../../utils/helpers';
import { Button } from '../../../shared';
import { CompactCarousel } from '../../../shared/carousels/compact-carousel/compact-carousel.component';
import { SimpleCarousel } from '../../../shared/carousels/simple-carousel/simple-carousel.component';
import { SidePanel } from '../../../shared/modals/side-panel';
import { SidePanelLayout } from '../../../shared/modals/side-panel-layout/side-panel-layout.component';
import { StickyTable } from '../../../shared/tables/sticky-table/sticky-table.component';
import {
    DescriptionAndMedia,
    DisclaimersWrapper,
    Header,
    MediaWrapper,
    ReadMoreContent,
    StyledDescription,
    StyledDisclaimer,
} from './cars-leasing-types-modal.styled';
import { useCarLeasingTypesModal } from './use-car-leasing-types-modal';

type CarsLeasingTypesModalProps = {
    visible: boolean;
    onClose: () => void;
    modalSettings: CompareCarsLeasingTypesModal;
};

const CarsLeasingTypesModalMemo: VFC<CarsLeasingTypesModalProps> = ({ visible, onClose, modalSettings }) => {
    // Gets the height of the element (ref)
    const isMobile = useMediaQuery({ target: 'tablet' });
    const defaultHeight: string | undefined = isMobile ? '70px' : '50px';
    const [heightRef, { height }] = useMeasure<HTMLDivElement>();
    const [contentHeight, setContentHeight] = useState(defaultHeight || height);

    useEffect(() => {
        //Sets initial height
        setContentHeight(height);
    }, [height]);

    const [showEntireContent, setShowEntireContent] = useState(false);

    // Animations
    const expand = useSpring({
        config: { ...config.slow, friction: 50 },
        height: showEntireContent ? `${contentHeight}px` : defaultHeight,
        overflow: 'hidden',
    });

    const textToDisplay = useMemo(
        () => (showEntireContent ? modalSettings.description : modalSettings?.description.substring(0, isMobile ? 125 : 250) + '...'),
        [showEntireContent, modalSettings.description, isMobile]
    );

    const { comparisonTableData, carouselItems } = useCarLeasingTypesModal(modalSettings);

    if (!modalSettings) {
        return null;
    }

    return (
        <SidePanel cancelAction={onClose} isVisible={visible} variant="70%">
            <SidePanelLayout closeSidePanel={onClose}>
                <Header>{modalSettings.header}</Header>

                <ReadMoreContent>
                    <animated.div style={expand}>
                        <DescriptionAndMedia ref={heightRef}>
                            <StyledDescription {...sanitizeMarkup(textToDisplay)} />
                            {carouselItems && carouselItems.length > 0 ? (
                                <MediaWrapper>
                                    {isMobile ? (
                                        <CompactCarousel slides={carouselItems} isAboveFold={false} />
                                    ) : (
                                        <SimpleCarousel slides={carouselItems} isAboveFold={false} />
                                    )}
                                </MediaWrapper>
                            ) : null}
                        </DescriptionAndMedia>
                    </animated.div>

                    <Button variant="link" onClick={() => setShowEntireContent(!showEntireContent)}>
                        <p>{showEntireContent ? 'Læs mindre' : 'Læs mere'}</p>
                    </Button>
                </ReadMoreContent>

                <StickyTable rowHeaderSize="medium" rowPadding="15px 20px" tableData={comparisonTableData} />

                <DisclaimersWrapper>
                    {modalSettings.disclaimers.map((disclaimer, index) => {
                        return <StyledDisclaimer key={`car-disclaimer-${index}`}>{disclaimer.text}</StyledDisclaimer>;
                    })}
                </DisclaimersWrapper>
            </SidePanelLayout>
        </SidePanel>
    );
};

export const CarsLeasingTypesModal = React.memo(CarsLeasingTypesModalMemo);
