import { FC, useEffect, useMemo } from 'react';
import type { Props } from './select-workshop.props';
import { BookingWorkshopsStore } from '../../../../lib/state/booking-workshops';
import { DropdownInput } from '../../../forms/inputs';
import { DropdownOption } from '../../../forms/inputs/dropdown/dropdown-input.props';
import { BookingFormStore } from '../../../../lib/state/booking-form';
import { generateFormId } from '../../../../lib/state/booking-form/booking-form.helpers';

const SelectWorkshop: FC<Props> = ({
    id,
    label,
    placeholder,
    canValidateInputField,
    validationMessage,
    infoMessage,
    onChange,
    onInputBlur,
    required,
}) => {
    const { workshops, selectedWorkshop } = BookingWorkshopsStore.useStoreState(({ workshops, selectedWorkshop }) => ({
        workshops,
        selectedWorkshop,
    }));
    const { setSelectedWorkshop } = BookingWorkshopsStore.useStoreActions(({ setSelectedWorkshop }) => ({ setSelectedWorkshop }));

    const options = useMemo<DropdownOption<string>[]>(() => {
        return workshops.map(({ name, sabId }) => ({ displayValue: name, value: sabId }));
    }, [workshops]);

    const selected = useMemo(() => {
        return options.find(({ value }) => value === selectedWorkshop);
    }, [options, selectedWorkshop]);

    const isValid = useMemo(() => (selected?.value ? true : false), [selected]);

    const { onChange: onInitialWorkshopSelection } = BookingFormStore.useStoreActions((actions) => actions);

    useEffect(() => {
        if (selectedWorkshop) {
            onInitialWorkshopSelection({ id: generateFormId('YourCar', 'Workshop'), value: selectedWorkshop });
            setSelectedWorkshop({ sabId: selectedWorkshop, isFavourite: true });
        }
    }, [onInitialWorkshopSelection, selectedWorkshop, setSelectedWorkshop]);

    return (
        <DropdownInput
            options={options}
            value={selected}
            id={id}
            label={label}
            placeholder={placeholder}
            disabled={options.length === 0}
            onChange={(option) => {
                if (option) {
                    onChange(option.value);
                    setSelectedWorkshop({ sabId: option.value, isFavourite: true });
                }
            }}
            isValid={isValid}
            canValidateInputField={canValidateInputField}
            validationMessage={validationMessage}
            infoMessage={infoMessage}
            onInputBlur={onInputBlur}
            required={required}
        />
    );
};

export default SelectWorkshop;
