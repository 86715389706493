import { VFC } from 'react';
import { TagBorderBox } from './tag.styled';

export type TagProps = {
    className?: string;
    text: string;
    onClick?: () => void;
};

export const Tag: VFC<TagProps> = ({ className, text, onClick }) => {
    return (
        <TagBorderBox className={className} onClick={() => onClick?.()}>
            {text}
        </TagBorderBox>
    );
};
