import styled from 'styled-components';
import { device } from '../../../lib/media-query';
import { SortingDropdown } from '../../shared/filter-dropdown/filter-dropdown.component';
import { CenteredBlock } from '../../structural-blocks/centered-block.component';
import { Responsive } from '../../shared';
import { SpotHeader } from '../../shared/typography/spot-header/spot-header.component';

export const StyledCenteredBlock = styled(CenteredBlock)`
    background-color: #f7f7f8;
`;

export const StyledSpotWrapper = styled.div`
    padding: 33px 0;

    @media ${device.tablet} {
        padding: 85px 0;
    }
`;

export const ContentSection = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;

    @media ${device.tablet} {
        align-items: center;
    }
`;

export const StyledHeader = styled.p`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    text-align: center;
    margin-bottom: 8px;

    @media ${device.tablet} {
        font-size: 30px;
        line-height: 35px;
    }
`;

export const StyledSpotHeader = styled(SpotHeader)`
    text-align: center;
    margin-bottom: 8px;
`;

export const StyledText = styled.p`
    color: #0b0b0b;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-weight: normal;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 21px;
    text-align: center;
    margin-bottom: 17px;

    @media ${device.tablet} {
        font-size: 17px;
        line-height: 27px;
        margin-bottom: 0;
    }
`;

export const GeoInputFilterWrapper = styled.form`
    display: grid;
    grid-template-columns: 1fr 90px;
    justify-content: space-between;
    align-items: center;
    gap: 6px;

    @media ${device.tablet} {
        display: flex;
        margin-top: 25px;
        gap: 15px;
    }
`;

export const DropdownsWrapper = styled.section`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 15px;
    margin-bottom: 19px;

    @media (min-width: 360px) {
        flex-direction: row;
        gap: 20px;
        margin-bottom: 18px;
        margin-top: 30px;
    }

    @media ${device.tablet} {
        margin-top: 46px;
    }
`;

export const BrandDropdown = styled(SortingDropdown)`
    height: 30px;
    isolation: isolate;
    z-index: 12;

    @media (max-width: 500px) {
        min-width: 46%;
    }
`;

export const DestinationDropdown = styled(SortingDropdown)`
    height: 30px;

    @media (max-width: 500px) {
        min-width: 46%;
    }
`;

export const StyledInfoBoxToggle = styled.p`
    text-align: center;
    color: ${({ theme }) => theme.palette.primary.main};
    text-decoration: underline;
    margin-bottom: 10px;
    padding: 10px;
    padding-top: 0;
    cursor: pointer;

    @media ${device.tablet} {
        display: none;
    }
`;

export const StyledButtonText = styled.p`
    color: #fafafa;
    font-size: 13px;
    text-align: center;
`;

export const StyledFilterError = styled.p`
    font-size: 12px;
    text-align: center;
    color: #ff0000;
    margin-top: 10px;

    @media ${device.desktop} {
        width: 30%;
    }
`;

export const StyledResponsiveFrame = styled(Responsive)`
    margin-top: 18px;
`;

export const StyledSpotButtonWrapper = styled.div`
    margin: 5px auto;
    margin-bottom: 30px;

    @media ${device.tablet} {
        margin-top: 20px;
        margin-bottom: 10px;
    }
`;
