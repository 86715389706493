import { addDays, differenceInHours } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { FilterConfigurationKey } from '../../../constants/site-consts';
import { umbraco } from '../../../lib/api';
import { DealershipInformationPage } from '../../../lib/api/models/umbraco';
import { VehicleType } from '../../../lib/api/models/umbraco/organization.types';
import { getProductList } from '../../../lib/api/vehicle/hessel-vehicle-plp-api';
import { mapCarDetailsToProductCard } from '../../../lib/mappers/vehicle/product-card.mapper';
import { hesselViewModels } from '../../../lib/view-models';
import { DateStyle, createTimeSlots, formatDate, getClosedDaysList, getDaysToFirstAvailableDate } from '../../../utils/helpers';
import { DropdownOption } from '../../shared/date-picker-dropdown/date-picker-dropdown.component';

type BookTestDriveModalForm = {
    fullName?: string;
    email?: string;
    phone?: string;
    canValidate?: boolean;
    dealership?: DropdownOption<string>;
    testDriveDate?: Date;
    timeSlot?: DropdownOption<string>;
    termsAccepted?: boolean;
    message?: string;
    submitted: boolean;
};

type UseBookTestDriveModalDataReturnType = {
    formValues: BookTestDriveModalForm;
    setFormValues: (formValues: BookTestDriveModalForm) => void;
    demoVehicles: Array<hesselViewModels.ProductCard>;
    setDemoVehicles: (demoVehicles: Array<hesselViewModels.ProductCard>) => void;
    dealershipOptions: Array<DropdownOption<string>>;
    timeSlotOptions: Array<DropdownOption<string>>;
    availableDealerships: Array<DealershipInformationPage>;
    fallBackDealerships: Array<DealershipInformationPage>;
    disabledDays: number[];
};

export function useBookTestDriveModalData(
    allDealerships: Array<DealershipInformationPage>,
    isUsedCar: boolean,
    currentDealership?: DealershipInformationPage,
    initialDealership?: DealershipInformationPage,
    configurationId?: string,
    vehicleItemNumber?: string,
    vehicleType?: string,
    brand?: hesselViewModels.Brands,
    locationId?: string,
    vehicleAvailability?: hesselViewModels.VehicleAvailability,
    specialDays?: umbraco.orgTypes.SpecialDay[]
): UseBookTestDriveModalDataReturnType {
    const [formValues, setFormValues] = useState<BookTestDriveModalForm>({
        dealership: undefined,
        testDriveDate: new Date(),
        submitted: false,
    });

    const [demoVehicles, setDemoVehicles] = useState<Array<hesselViewModels.ProductCard>>([]);

    const salesType = useMemo(() => {
        const convertedVehicleType = vehicleType ? (vehicleType as hesselViewModels.VehicleType) : undefined;
        return convertedVehicleType === 'Van' ? 'Vans' : 'Cars';
    }, [vehicleType]);

    /**
     * If brand is Mercedes-Benz:
     *  - We need to filter dealerships that come from CMS so that we only display the ones that have demo vehicles related to the itemNumber
     * Otherwise:
     * - We need to filter dealerships that come from CMS so that we only display the ones that support the brand
     */
    const availableDealerships = useMemo(() => {
        if (isUsedCar) {
            return allDealerships.filter((x) => x.hovedafdelingId === locationId?.toString());
        }

        const combinedDealerships = currentDealership
            ? [currentDealership, ...allDealerships.filter((x) => x.hovedafdelingId !== currentDealership.hovedafdelingId)]
            : allDealerships;

        if (brand === 'Mercedes-Benz') {
            const filteredByVehiclesInSales = combinedDealerships.filter((x) =>
                x.supportedBrands.some((y) => y.brand === 'Mercedes-Benz' && y.vehiclesInSales.some((z) => z === salesType))
            );

            return filteredByVehiclesInSales.filter((x) => demoVehicles.some((y) => y.type === 'detailed' && y.locationId === x.hovedafdelingId));
        }

        return combinedDealerships.filter((x) => x.supportedBrands?.some((y) => y.brand === brand && y.vehiclesInSales.some((z) => z === salesType)));
    }, [allDealerships, brand, currentDealership, demoVehicles, isUsedCar, locationId, salesType]);

    // set initial dealership based on the dealership that is passed in, only if we have
    // 1 dealership available for test drive
    // If there are more than 1 dealerships available, we don't want to set the initial dealership
    useEffect(() => {
        if (initialDealership) {
            setFormValues((prev) => ({
                ...prev,
                dealership: {
                    displayValue: initialDealership.displayName,
                    value: initialDealership.hovedafdelingId,
                },
            }));
        }
    }, [availableDealerships.length, initialDealership]);

    /**
     * Note that itemNumber can be the same for different vehicles, thats why we are sending a request for all vehicles with the same itemNumber.
     * This way we can figure out which vehicles are available for test drive at the dealership across the country.
     */
    useEffect(() => {
        const fetchDemoVehiclesByItemNumber = async (configurationId: string, vItemNumber: string) => {
            const [demoVehicles, demoVehiclesFetchError] = await getProductList(
                {
                    from: 0,
                    take: 1000,
                    configurationId: configurationId,
                    filters: [`${FilterConfigurationKey.itemNumber}${vItemNumber}`, `${FilterConfigurationKey.availability_demo}`],
                },
                vehicleType ?? ''
            );

            if (demoVehicles && !demoVehiclesFetchError) {
                setDemoVehicles(demoVehicles.vehicles.map(mapCarDetailsToProductCard));
            } else {
                setDemoVehicles([]);
            }
        };

        if (configurationId && vehicleItemNumber && brand === 'Mercedes-Benz' && vehicleType && !isUsedCar) {
            fetchDemoVehiclesByItemNumber(configurationId, vehicleItemNumber);
        }
    }, [brand, configurationId, isUsedCar, vehicleItemNumber, vehicleType]);

    useEffect(() => {
        if (vehicleAvailability === 'Demo' && initialDealership) {
            const waitingDays = initialDealership.bookTestDriveWaitingDays === 0 ? 1 : initialDealership.bookTestDriveWaitingDays;

            const closedDaysList = initialDealership ? getClosedDaysList(initialDealership) : [];

            const closedDaysHits = getDaysToFirstAvailableDate(new Date(), waitingDays, closedDaysList, specialDays);

            const newFormDate = addDays(new Date(), waitingDays + closedDaysHits);

            setFormValues((prev) => ({
                ...prev,
                testDriveDate: newFormDate,
            }));
        }
    }, [initialDealership, vehicleAvailability]);

    const dealershipOptions = useMemo(() => {
        return availableDealerships
            .map<DropdownOption<string>>((x) => ({
                displayValue: `${x.displayName}`,
                value: `${x.hovedafdelingId}`,
            }))
            .sort((a, b) => a.displayValue.localeCompare(b.displayValue));
    }, [availableDealerships]);

    const timeSlotOptions = useMemo(() => {
        const timeSlots = createTimeSlots(
            allDealerships.find((x) => x.hovedafdelingId === formValues.dealership?.value) ?? currentDealership,
            formValues.testDriveDate
        );

        return timeSlots
            .filter((x) => {
                return differenceInHours(x, new Date()) >= 24;
            })
            .map<DropdownOption<string>>((ts) => {
                const formattedDate = formatDate(ts, DateStyle.HH_mm);
                return {
                    displayValue: formattedDate,
                    value: formattedDate,
                };
            });
    }, [allDealerships, currentDealership, formValues.dealership?.value, formValues.testDriveDate]);

    const disabledDays = useMemo(() => {
        const _return = [];
        const selectedDealership = allDealerships.find((x) => x.hovedafdelingId === formValues.dealership?.value) ?? currentDealership;
        const dealershipToTest = selectedDealership ?? initialDealership;
        if (dealershipToTest && dealershipToTest.testDriveOpeningHours && dealershipToTest.testDriveOpeningHours.length > 0) {
            const closedDays = dealershipToTest.testDriveOpeningHours[0].openingHours.filter((x) => x.closed);
            if (closedDays && closedDays.length > 0) {
                for (const day of closedDays) {
                    switch (day.dayOfWeek) {
                        case 'Sunday':
                            _return.push(0);
                            break;
                        case 'Monday':
                            _return.push(1);
                            break;
                        case 'Tuesday':
                            _return.push(2);
                            break;
                        case 'Wednesday':
                            _return.push(3);
                            break;
                        case 'Thursday':
                            _return.push(4);
                            break;
                        case 'Friday':
                            _return.push(5);
                            break;
                        case 'Saturday':
                            _return.push(6);
                            break;
                        default:
                            break;
                    }
                }
            }
        } else {
            _return.push(0, 1, 2, 3, 4, 5, 6);
        }
        return _return;
    }, [allDealerships, currentDealership, formValues.dealership, initialDealership]);

    const fallBackDealerships = useMemo(() => {
        const vType: VehicleType = vehicleType === 'Car' ? 'Cars' : 'Vans';
        return allDealerships.filter((x) => x.supportedBrands.some((y) => y.brand === brand && y.vehiclesInSales.includes(vType)));
    }, [allDealerships, brand, vehicleType]);

    return {
        formValues,
        setFormValues,
        demoVehicles,
        setDemoVehicles,
        dealershipOptions,
        timeSlotOptions,
        availableDealerships,
        disabledDays,
        fallBackDealerships,
    };
}
