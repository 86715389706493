import styled from 'styled-components';

export const StyledBrand = styled.div`
    text-transform: uppercase;
    color: #fff;
    font-size: 1.5rem;
    margin-bottom: 1.5625rem;
    letter-spacing: 4px;
    font-family: 'Corporate E';
    font-weight: bold;
`;

export const SmallText = styled.p<{ showAsItalic?: boolean }>`
    font-style: ${(props) => (props.showAsItalic ? 'italic' : '')};
    white-space: nowrap;
`;

export const TextGroup = styled.div`
    display: flex;
    gap: 10px;
`;

export const SmallLink = styled.a`
    color: inherit;
    display: block;
    text-decoration: underline;
    cursor: pointer;
`;

export const ContentTitle = styled.p`
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 0;
    font-family: 'Corporate E';
    margin-bottom: 1.75rem;
`;

export const OpeningHoursTitle = styled.p`
    margin-bottom: 0.5rem;
`;

export const StyledFooterLogo = styled.img`
    width: 100%;
    max-width: 250px;
    max-height: 50px;
    display: block;
    object-fit: cover;
    object-position: center center;
    margin-bottom: 30px;
`;
