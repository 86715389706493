import styled from 'styled-components';
import { device } from '../../../lib/media-query';

export const StyledSpecsRibbonItem = styled.li`
    color: ${(props) => props.theme.palette.text.primary};
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    list-style: none;
    line-height: 1.25;
    gap: 8px;
`;

export const StyledSpecsRibbonItemLabel = styled.div`
    font-weight: 500;
    color: #858585;
    white-space: nowrap;
    text-transform: uppercase;
    font-size: 9px;
    line-height: 10px;

    @media ${device.tablet} {
        font-size: 12px;
    }
`;

export const StyledSpecsRibbonItemValue = styled.div`
    font-size: 1.42em;
    font-weight: 500;
    white-space: nowrap;

    @media ${device.tablet} {
        font-size: 1.79em;
    }
`;

export const StyledSpecsRibbonItemUnit = styled.span`
    font-size: inherit;
    font-weight: inherit;
    white-space: nowrap;
`;
