import styled from 'styled-components';

export const StyledWrapper = styled.section`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const StyledHeader = styled.h3`
    margin-bottom: 15px;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 27px;
`;
