import { VFC } from 'react';
import { SvgIcon } from '../svg-icon';
import { StyledKeyValueCard, StyledKey, StyledValue, StyledKeyAndIcon, StyledExternalIcon } from './key-value-card.styled';

export type KeyValueCardProps = {
    className?: string;
    label: string;
    value: string;
    selected: boolean;
    iconUrl?: string;
    externalIcon?: {
        url: string;
        name: string;
    };
    disabled?: boolean;
    onClick: () => void;
};

export const KeyValueCard: VFC<KeyValueCardProps> = ({ className, label, iconUrl, externalIcon, value, selected, disabled, onClick }) => {
    return (
        <StyledKeyValueCard type="button" className={className} disabled={disabled} selected={selected} onClick={() => (disabled ? null : onClick())}>
            <StyledKeyAndIcon disabled={!!disabled}>
                {iconUrl ? <SvgIcon iconName={iconUrl} /> : null}
                {externalIcon ? <StyledExternalIcon disabled={!!disabled} alt={externalIcon.name} src={externalIcon.url} /> : null}
                <StyledKey disabled={!!disabled}>{label}</StyledKey>
            </StyledKeyAndIcon>
            <StyledValue selected={selected} disabled={!!disabled}>
                {value}
            </StyledValue>
        </StyledKeyValueCard>
    );
};
