import { useState, VFC } from 'react';
import { useMediaQuery } from '../../hooks/use-media-query';
import { uiTypes } from '../../lib';
import { contentSpotTypes } from '../../lib/api';
import { sharedTypes } from '../../lib/api/models/umbraco';
import { handleUrlClick, isNullOrEmpty, sanitizeMarkup } from '../../utils/helpers';
import { SidePanel } from '../shared/modals/side-panel';
import { SidePanelLayout } from '../shared/modals/side-panel-layout/side-panel-layout.component';
import { UspTemplate } from '../shared/usp-template/usp-template.component';
import { CenteredBlock } from '../structural-blocks/centered-block.component';
import { useUI } from '../ui';
import {
    StyledCloseButton,
    StyledCta,
    StyledDescription,
    StyledHeader,
    StyledSpotDescription,
    StyledSpotHeader,
    StyledSpotHeaderComponent,
    StyledUspList,
    UspBarListWrapper,
} from './usp-bar-list.styled';

export type UspBarListProps = {
    uspBarList: contentSpotTypes.UspBar[];
    uspPerRow: number;
    spotHeader: string;
    headerType: uiTypes.HeaderType;
    headerSize: uiTypes.HeaderSize;
    spotDescription: string;
    cta: sharedTypes.CtaUrl;
    ctaColor: uiTypes.Variant;
    scrollAnchorId: string;
    isAboveFold: boolean;
};

export const UspBarList: VFC<UspBarListProps> = ({
    uspBarList,
    uspPerRow,
    scrollAnchorId,
    spotHeader,
    headerType,
    headerSize,
    spotDescription,
    cta,
    ctaColor,
    isAboveFold,
}) => {
    const uiHandler = useUI();
    const isMobile = useMediaQuery({ target: 'tablet' });
    const [sidePanelInfo, setSidePanelInfo] = useState<{
        visible: boolean;
        header?: string;
        headerType?: uiTypes.HeaderType;
        description?: string;
        closeButtonLabel?: string;
    }>({
        visible: false,
    });

    const hideScrollBars = () => {
        if (isMobile) {
            uiHandler.removeScrollAndLock();
        } else {
            uiHandler.removeScroll();
        }
    };

    const closeSidePanel = () => {
        uiHandler.applyScroll();
        setSidePanelInfo({ visible: false, header: undefined, description: undefined, closeButtonLabel: undefined });
    };

    return (
        <>
            <CenteredBlock>
                <StyledUspList>
                    {spotHeader && spotHeader.length > 0 ? (
                        isNullOrEmpty(headerSize) ? (
                            <StyledSpotHeader as={headerType}>{spotHeader}</StyledSpotHeader>
                        ) : (
                            <StyledSpotHeaderComponent headerType={headerType} headerSize={headerSize}>
                                {spotHeader}
                            </StyledSpotHeaderComponent>
                        )
                    ) : null}
                    {spotDescription && spotDescription.length > 0 ? <StyledSpotDescription {...sanitizeMarkup(spotDescription)} /> : null}

                    <UspBarListWrapper
                        uspPerRow={Math.max(4, uspPerRow)}
                        id={scrollAnchorId}
                        style={{ marginTop: spotHeader?.length > 0 || spotDescription?.length > 0 ? 33 : 0 }}
                    >
                        {uspBarList.map((x, index) => (
                            <UspTemplate
                                key={`usp-bar-template-${x.alias}-${index}`}
                                header={x.label}
                                description={x.description}
                                topSideIcon={x.icon}
                                bottomSideIcon={x.footerIcon}
                                onUspClick={
                                    x.alias === 'richUspBar' && x.text && x.text.length > 0
                                        ? () => {
                                              hideScrollBars();
                                              setSidePanelInfo({
                                                  visible: true,
                                                  header: x.headerText,
                                                  headerType: x.headerType,
                                                  description: x.text,
                                                  closeButtonLabel: x.closeButtonLabel,
                                              });
                                          }
                                        : undefined
                                }
                                isAboveFold={isAboveFold}
                            />
                        ))}
                    </UspBarListWrapper>

                    {cta ? (
                        <StyledCta variant={ctaColor} onClick={() => handleUrlClick(cta)}>
                            <p>{cta.name}</p>
                        </StyledCta>
                    ) : null}
                </StyledUspList>
            </CenteredBlock>

            {uspBarList.some((x) => x.alias === 'richUspBar') ? (
                <SidePanel isVisible={sidePanelInfo.visible} cancelAction={() => closeSidePanel()}>
                    <SidePanelLayout closeSidePanel={() => closeSidePanel()}>
                        <StyledHeader as={sidePanelInfo.headerType}>{sidePanelInfo.header}</StyledHeader>
                        <StyledDescription {...sanitizeMarkup(sidePanelInfo.description)} />
                        <StyledCloseButton variant="primary" onClick={() => closeSidePanel()}>
                            <p>{sidePanelInfo.closeButtonLabel}</p>
                        </StyledCloseButton>
                    </SidePanelLayout>
                </SidePanel>
            ) : null}
        </>
    );
};
