import React, { VFC } from 'react';
import { config, useSpring } from 'react-spring';
import { Separator } from '../../../shared';
import { StickySummarySubComponentProps } from '../sticky-summary.component';
import {
    ButtonText,
    CarInfoWrapper,
    BrandTitle,
    VariantTitle,
    Prices,
    StyledButton,
    StyledStickySummary,
    TextWrapper,
    Title,
    TopSection,
    Value,
} from './mobile.styled';
import { formatPrice } from '../../../../utils/helpers';
import { Abbreviations } from '../../../../constants/units-and-abbreviations';

export const StickySummaryMobileMemo: VFC<StickySummarySubComponentProps> = ({
    visible,
    variantTitle,
    brandTitle,
    period,
    primaryCta,
    selectedOwnershipTab,
    priceInfo,
    isCompanyCar,
}) => {
    const slideIn = useSpring({
        config: { ...config.slow, friction: 45 },
        top: visible ? '0' : '-300px',
        delay: 500,
    });

    const { price, downPayment, monthly, vatIncluded, overrideText, priceTextOverride, isAFromPrice } = priceInfo;

    const cardTextAlignment: React.CSSProperties = { textAlign: selectedOwnershipTab === 'Car HiRE' ? 'right' : 'left' };

    return (
        <StyledStickySummary style={slideIn}>
            <TopSection>
                <CarInfoWrapper>
                    <BrandTitle>{brandTitle}</BrandTitle>
                    <VariantTitle>{variantTitle}</VariantTitle>
                </CarInfoWrapper>

                <TextWrapper>
                    {overrideText ? (
                        <Value>{overrideText}</Value>
                    ) : (
                        <>
                            <Title style={cardTextAlignment}>
                                {isAFromPrice ? 'Fra ' : null}
                                {formatPrice(price)} {monthly ? Abbreviations.KR_SLASH_MD : Abbreviations.KR}
                            </Title>
                            {downPayment !== undefined && downPayment !== null ? (
                                <Value style={cardTextAlignment}>Udbetaling {formatPrice(downPayment)}&nbsp;kr.</Value>
                            ) : null}
                            {priceTextOverride ? (
                                <Value style={cardTextAlignment}>{priceTextOverride}</Value>
                            ) : (
                                <Value style={cardTextAlignment}>
                                    {!isCompanyCar ? `Priser er ${vatIncluded ? 'ekskl.' : 'inkl.'} moms.` : `Pris inkl. moms og afgifter`}
                                </Value>
                            )}
                        </>
                    )}
                </TextWrapper>
            </TopSection>

            <Separator marginTop="13px" marginBottom="13px" />

            <Prices>
                {selectedOwnershipTab === 'Car HiRE' ? (
                    <TextWrapper>
                        <Title>HesselHire</Title>
                        <Value>{period}</Value>
                    </TextWrapper>
                ) : null}

                <StyledButton onClick={() => primaryCta.action?.()} variant="primary" unsetMinWidth={true}>
                    <ButtonText>{primaryCta.label}</ButtonText>
                </StyledButton>
            </Prices>
        </StyledStickySummary>
    );
};

export const StickySummaryMobile = React.memo(StickySummaryMobileMemo);
