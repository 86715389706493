import { animated } from 'react-spring';
import styled, { DefaultTheme } from 'styled-components';
import { Button } from '../../../shared';
import { GenericShape } from '../../../shared/shapes/generic-shape/generic-shape.component';
import { CampaignList } from '../../../shared/campaign-list/campaign-list.component';
import { device } from '../../../../lib/media-query';

type BadgeColor = 'Primary' | 'Dark' | 'Gray';

function getBackgroundColor(color: BadgeColor, theme: DefaultTheme) {
    switch (color) {
        case 'Primary':
            return theme.palette.primary.main;
        case 'Dark':
            return '#0b0b0b';
        case 'Gray':
            return '#688488';
        default:
            return theme.palette.primary.main;
    }
}

export const Card = styled.div<{ autoDesktopCampaign: boolean }>`
    background-color: white;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    ${(props) => (props.autoDesktopCampaign ? 'border: 4px solid #688488;position:relative;' : 'padding: 4px;')}
`;

export const StyledAutoDesktopLabel = styled.span`
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    font-size: 10px;
    background-color: #688488;
    color: white;
    line-height: 10px;
    font-weight: 400;
`;

export const CardImageArea = styled.div`
    background-color: ${({ theme: { palette } }) => palette.grey.main};
    padding: 10px;
`;

export const BrandModel = styled.p`
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
`;

export const CarVariant = styled.p<{ usedCar: boolean; widthPx: number }>`
    font-size: 17px;
    font-weight: 600;
    height: 25px;
    ${(props) =>
        props.usedCar
            ? `
            white-space: nowrap;
            width: ${props.widthPx}px;
            overflow: hidden;
            text-overflow: ellipsis;
    `
            : ''}
`;

export const CarImageWrapper = styled.div<{ usedCard: boolean }>`
    display: flex;
    justify-content: center;
    aspect-ratio: ${(props) => (props.usedCard ? '4/3' : '1.77 / 1')};
    img {
        display: block;
        ${(props) =>
            props.usedCard
                ? `
            width: 100%;
            object-fit:cover;
        `
                : `
            max-width: 100%;
        `}
    }
`;

export const BadgeContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 24px;
`;

export const MoreVariantsIndicator = styled.div`
    flex-grow: 1;
    text-align: right;
    font-weight: 500;
    font-size: 10px;
`;

export const DetailsLink = styled.div<{ clickable: boolean }>`
    text-decoration: none;
    color: ${({ theme: { palette } }) => palette.common.black};
    cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};

    &:hover {
        text-decoration: none;
    }
`;

export const CardInformationWrapper = styled.div`
    padding: 6px 10px 0;
`;

export const PrimarySpecs = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
`;

export const Spec = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 auto;
    border-left: 1px solid ${({ theme: { palette } }) => palette.grey.medium};

    &:first-of-type {
        border-left: none;
    }
`;

export const SpecKey = styled.p`
    font-size: 9px;
    font-weight: 500;
    line-height: 10px;
    color: #858585;
`;

export const SpecValue = styled.p`
    font-size: 14px;
    font-weight: 600;
`;

export const ExtraEquipment = styled.div`
    margin: 15px 0;

    p {
        font-size: 10px;
    }
`;

export const Price = styled.p`
    font-size: 17px;
    font-weight: 600;
    color: #0b0b0b;
    cursor: help;
`;

export const PriceInfo = styled.div<{ withToolTip: boolean }>`
    position: relative;
    padding: 0 10px 10px;
    padding-bottom: 0;
    margin-top: 18px;
    ${(props) => (props.withToolTip ? 'cursor: help;' : 'cursor: pointer;')}
    display: flex;
    column-gap: 5px;
    align-items: center;
    justify-content: flex-end;
    color: #0b0b0b;
`;

export const FinancingInfo = styled.div`
    position: relative;
    font-size: 10px;
    line-height: 10px;
    padding: 0 10px 10px 30px;
    cursor: help;
    display: flex;
    column-gap: 5px;
    align-items: center;
    justify-content: flex-end;
`;

export const DisclaimerContainer = styled.div`
    position: relative;
    display: flex;
`;

export const DisclaimerText = styled(animated.div)`
    position: absolute;
    bottom: calc(100% + 10px);
    right: -10px;
    background-color: #d8d8d8;
    padding: 15px;
    color: #636363;
    max-width: 250px;
    min-width: 250px;
    line-height: 16px;
    font-size: 11px;

    &:after {
        content: ' ';
        width: 15px;
        height: 15px;
        background-color: #d8d8d8;
        position: absolute;
        bottom: 0;
        right: 10px;
        transform: translateY(50%) rotate(45deg);
    }
`;

export const StyledDemoBadges = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`;

export const StyledBadge = styled.div<{ color: BadgeColor }>`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => getBackgroundColor(props.color, props.theme)};
    padding: 0px 6px;
    width: max-content;
`;

export const StyledBadgeText = styled.p`
    color: #fcfcfc;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0;
    font-weight: normal;
`;

export const StyledColorPreview = styled.img`
    height: 9px;
    width: 9px;
    margin-right: 2px;
    object-fit: none;
    border-radius: 100%;
    border: 1px solid #999999;
`;

export const CampaignLabelsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    max-width: 75%;
`;

export const StyledCampaignBadge = styled(GenericShape)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 6px;
    width: max-content;
    height: 16px;

    & p {
        font-size: 10px;
        font-weight: normal;
        letter-spacing: 0;
    }
`;

export const StyledLabelsWrapper = styled.div`
    display: flex;
    margin-top: 5px;
    gap: 5px;
    position: absolute;
`;

export const StyledLabelContainer = styled.div<{ backgroundColor: string }>`
    background-color: ${(props) => props.backgroundColor};
`;

export const StyledLabel = styled.p<{ textColor: string }>`
    color: ${(props) => props.textColor};
    font-size: 10px;
    line-height: 10px;
    font-weight: 400;
    padding: 4px 6px;
`;

export const StyledProductCardCta = styled(Button)`
    margin: auto 10px 10px;
    align-self: flex-start;
`;

export const StyledCampaignsList = styled(CampaignList)`
    transform: translate(5px, -50px);

    @media ${device.tablet} {
        transform: none;
    }
`;

export const StyledDimensionsWrapper = styled.div`
    white-space: nowrap;
`;
