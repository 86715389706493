import styled from 'styled-components';
import { device } from '../../../lib/media-query';
import { MagnifyingGlass } from '../../icons';
import { Button, ShortInfoCard } from '../../shared';
import { IconButton } from '../../shared/icon-button/icon-button.component';
import { Spinner } from '../../shared/spinner';
import { ToggleUi } from '../../shared/toggle-ui';
import { CenteredBlock } from '../../structural-blocks/centered-block.component';
import { PdpUspTemplateBar } from '../pdp-usp-template-bar/pdp-usp-template-bar.component';
import { ProductDetailsHeader } from '../product-details-header/product-details-header.component';
import { SpecsRibbon } from '../specs-ribbon/specs-ribbon.component';

export const StyledProductDetailsPageMain = styled.main`
    --pdp-margin: 12px;
    --spot-bg: #f7f7f8;
    background-color: white;
    padding: 20px 10px;
    padding-top: 0;
`;

export const StyledProductDetailsPage = styled.div`
    @media ${device.laptop} {
        display: grid;
        grid: auto auto auto 1fr / 3fr 2fr;
        gap: 0 90px;
    }
`;

export const StyledProductDetailsHeader = styled(ProductDetailsHeader)`
    grid-area: 1 / 1 / 2 / 2;
    margin: 0 0 10px;
`;

export const StyledCarouselContainer = styled.div`
    aspect-ratio: 16 / 9;
    grid-area: 2 / 1 / 3 / 2;
    margin: 0 0 10px;
`;

export const StyledFlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;

    align-self: flex-start;
    grid-area: 1 / 2 / -1 / 3;
    margin: 6em 0 0;
`;

export const StyledZoomButton = styled(IconButton).attrs({
    children: MagnifyingGlass({}),
})``;

export const StyledOrderCarDisclaimer = styled.p`
    grid-area: 3 / 1 / 4 / 2;
    justify-self: center;
    align-self: center;
    text-align: center;
    font-size: clamp(12px, 2vw, 14px);
    margin-top: 35px;

    @media ${device.tablet} {
        margin-top: 10px;
    }
`;

export const StyledSpecsRibbon = styled(SpecsRibbon).attrs((attrs) => ({
    ref: attrs.ref,
}))`
    grid-area: 4 / 1 / 5 / 2;
    height: min-content;
    align-self: start;
`;

export const StyledSidePanel = styled.div`
    align-self: flex-start;
    grid-area: 1 / 2 / -1 / 3;
    margin: 1em 0 0;

    @media ${device.tablet} {
        margin: 6em 0 0;
    }
`;

export const StyledInfoCardsWrapper = styled.section`
    margin-top: 35px;
    margin-bottom: 35px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const StyledShortInfoCard = styled(ShortInfoCard)`
    @media ${device.tablet} {
        padding: 0 20px;
        width: 90%;
    }
`;

export const StyledSpinner = styled(Spinner)`
    position: relative;
    top: 80px;
    left: 38%;
    transform: translate(50%, 50%);
    margin-bottom: 200px;

    @media ${device.tablet} {
        top: 50px;
        left: 50%;
    }
`;

export const StyledBundleWrapper = styled.div`
    margin: var(--pdp-margin, 12px) 0;
    padding: 20px 0;
`;

export const StyledPdpUspTemplateBar = styled(PdpUspTemplateBar)`
    margin-top: var(--pdp-margin, 12px);
`;

export const OwnershipWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`;

export const StyledOwnershipToggle = styled(ToggleUi)`
    width: 50%;

    @media ${device.tablet} {
        width: 60%;
    }
`;

export const FullEquipmentListLinkAndIcon = styled.div`
    display: flex;
    gap: 5px;
    margin-top: 13px;
`;

export const FullEquipmentLink = styled.a`
    color: #0b0b0b;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0;
`;

export const FullEquipmentListIcon = styled.img`
    height: 25px;
    object-fit: contain;
    width: 25px;
`;

export const StyledSizeGuideButton = styled(Button)`
    margin-top: 20px;
`;

export const StyledAcknowledgementContainer = styled.div``;

export const StyledCenteredBlock = styled(CenteredBlock)`
    background-color: var(--spot-bg, #f7f7f8);
    padding: 20px 0;
`;

export const StyledPromotionsWrapper = styled.div`
    margin: var(--pdp-margin, 12px) 0;
`;
