import styled from 'styled-components';

export const StyledKeyValueCard = styled.button<{ disabled?: boolean; selected: boolean }>`
    align-items: center;
    background-color: ${({ theme }) => theme.palette.common.white};
    border: 2px solid ${(props) => (props.selected ? '#0b0b0b' : '#E2E2E4')};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    display: flex;
    justify-content: space-between;
    padding: 8px 9px;
`;

export const StyledKeyAndIcon = styled.div<{ disabled: boolean }>`
    display: flex;
    gap: 10px;
    align-items: center;

    & svg path {
        stroke: ${(props) => (props.disabled ? '#E2E2E4' : 'revert')};
        fill: ${(props) => (props.disabled ? '#E2E2E4' : 'revert')};
    }
`;

export const StyledKey = styled.p<{ disabled: boolean }>`
    color: ${(props) => (props.disabled ? '#E2E2E4' : 'revert')};
`;

export const StyledValue = styled.p<{ selected: boolean; disabled: boolean }>`
    color: ${(props) => (props.disabled ? '#E2E2E4' : props.selected ? '#0b0b0b' : '#aeaeaf')};
    font-size: 12px;
    letter-spacing: 0;
    text-align: right;
`;

export const StyledExternalIcon = styled.img<{ disabled: boolean }>`
    opacity: ${(props) => (props.disabled ? 0.3 : 1)};
    width: 22px;
    height: 22px;
`;
