import { FC, useEffect, useState } from 'react';
import { useSpring, config, animated } from 'react-spring';
import { StepWrapper, StepHeader, Header, StepContent, StepAction, IconAndText, StyledFootNote, ChildrenWrapper, PointerEvents } from './step.styled';
import { useMeasure } from 'react-use';
import { Circle } from '../../../shared/shapes/circle';
import { SvgIcon } from '../../../shared/svg-icon';
import { Button } from '../../../shared';
import { HireStepType } from '../../../../lib/api/models/umbraco/hire-site/lead-checkout';
import { handleUrlClick } from '../../../../utils/helpers';
import { sharedTypes } from '../../../../lib/api/models/umbraco';

type Props = {
    title: string;
    nextStep?: string;
    stepNumber: number;
    totalSteps: number;
    isCurrentStep: boolean;
    isCompleted: boolean;
    goNext: () => void;
    edit: () => void;
    setCanValidateStep: () => void;
    footNote?: string;
    isStepValid: boolean;
    isStepLoading: boolean;
    stepType: HireStepType;
    isCheckoutComplete: boolean;
    submitButtonLink?: sharedTypes.CtaUrl;
    hideCta?: boolean;
};

export const Step: FC<Props> = ({
    title,
    nextStep = '',
    stepNumber,
    totalSteps,
    isCurrentStep,
    isCompleted,
    goNext,
    edit,
    isStepValid,
    isStepLoading,
    setCanValidateStep,
    footNote = '',
    stepType,
    isCheckoutComplete,
    submitButtonLink,
    children,
    hideCta = false,
}) => {
    const defaultHeight: string | undefined = '0px';

    // Gets the height of the element (ref)
    const [heightRef, { height }] = useMeasure<HTMLDivElement>();

    const [contentHeight, setContentHeight] = useState(defaultHeight || height);

    const getOpacity = (): number => {
        if (isCurrentStep) {
            return 1;
        }

        if (isCompleted) {
            return 0.4;
        }

        return 0;
    };

    useEffect(() => {
        //Sets initial height
        setContentHeight(height);
    }, [height]);

    // Animations
    const expand = useSpring({
        config: { ...config.slow, friction: 50 },
        height: isCurrentStep || isCompleted ? `${contentHeight}px` : defaultHeight,
    });

    const fadeContent = useSpring({
        config: { ...config.slow, friction: 50 },
        opacity: getOpacity(),
        delay: 300,
    });

    const isStepDisabled = (sType: HireStepType, loading: boolean, isValid: boolean) => {
        if (sType === 'checkoutStepUserInformation' || sType === 'checkoutStepDurationAndDelivery') {
            return loading;
        }

        return !isValid || loading;
    };

    const stepPrefix = 'hire-step-wrapper';

    return (
        <StepWrapper id={`${stepPrefix}-${stepNumber}`} isCurrentStep={isCurrentStep} isCompleted={isCompleted}>
            <StepHeader isCurrentStep={isCurrentStep} isCompleted={isCompleted}>
                <IconAndText>
                    {isCompleted ? (
                        <Circle xOffset={-1} yOffset={0}>
                            <SvgIcon iconName="checkmark" color="primary" />
                        </Circle>
                    ) : (
                        <Circle xOffset={0} yOffset={0}>
                            <strong>{stepNumber}</strong>/{totalSteps}
                        </Circle>
                    )}

                    <Header>{title}</Header>
                </IconAndText>

                {isCompleted && !isCheckoutComplete ? (
                    <PointerEvents>
                        <Button
                            variant="link"
                            onClick={() => {
                                edit();
                            }}
                        >
                            <p>Rediger</p>
                        </Button>
                    </PointerEvents>
                ) : null}
            </StepHeader>

            <animated.div style={{ ...expand, overflow: !isCurrentStep && !isCompleted ? 'hidden' : '' }}>
                <StepContent ref={heightRef}>
                    <animated.div style={fadeContent}>
                        <ChildrenWrapper>{children}</ChildrenWrapper>
                        {!isCompleted && !hideCta ? (
                            <StepAction>
                                <Button
                                    variant="primary"
                                    onClick={() => {
                                        if (stepType === 'checkoutStepReceipt') {
                                            handleUrlClick(submitButtonLink);
                                            return;
                                        }

                                        if (isStepValid) {
                                            goNext();
                                        } else {
                                            setCanValidateStep();
                                        }
                                    }}
                                    disabled={isStepDisabled(stepType, isStepLoading, isStepValid)}
                                    loading={isStepLoading}
                                >
                                    {nextStep}
                                </Button>
                                <StyledFootNote>{footNote}</StyledFootNote>
                            </StepAction>
                        ) : null}
                    </animated.div>
                </StepContent>
            </animated.div>
        </StepWrapper>
    );
};
