import { useState, VFC } from 'react';
import { Button } from '..';
import { sanitizeMarkup } from '../../../utils/helpers';
import { useUI } from '../../ui';
import { SidePanel } from '../modals/side-panel';
import { SidePanelLayout } from '../modals/side-panel-layout/side-panel-layout.component';
import {
    StyledShortInfoCardContent,
    StyledHeader,
    StyledDescription,
    StyledButtonText,
    SidebarHeader,
    StyledHeaderAndDescription,
} from './short-info-card.styled';
import sanitizeHtml from 'sanitize-html';

export type ShortInfoCardProps = {
    className?: string;
    header: string;
    description: string;
    buttonText?: string;
    sidebarHeader: string;
    numberOfCharactersToShow?: number;
};

export const ShortInfoCard: VFC<ShortInfoCardProps> = ({
    className,
    header,
    description,
    buttonText,
    sidebarHeader,
    numberOfCharactersToShow = 100,
}) => {
    const [showSidebar, setShowSidebar] = useState(false);
    const uiHandler = useUI();

    const sanitizedDescription = description ? sanitizeHtml(description, { allowedTags: [] }) : undefined;

    return (
        <>
            <StyledShortInfoCardContent className={className}>
                <StyledHeader>{header}</StyledHeader>

                {sanitizedDescription ? (
                    <StyledDescription>
                        {sanitizedDescription.length > numberOfCharactersToShow
                            ? `${sanitizedDescription.substring(0, numberOfCharactersToShow)}...`
                            : sanitizedDescription}
                        &nbsp;
                        {sanitizedDescription.length > numberOfCharactersToShow ? (
                            <Button
                                variant="link"
                                onClick={() => {
                                    setShowSidebar(true);
                                    uiHandler.removeScroll();
                                }}
                            >
                                <StyledButtonText>{buttonText ?? 'Læs mere'}</StyledButtonText>
                            </Button>
                        ) : null}
                    </StyledDescription>
                ) : null}
            </StyledShortInfoCardContent>

            <SidePanel
                isVisible={showSidebar}
                cancelAction={() => {
                    setShowSidebar(false);
                    uiHandler.applyScroll();
                }}
            >
                <SidePanelLayout
                    closeSidePanel={() => {
                        setShowSidebar(false);
                        uiHandler.applyScroll();
                    }}
                >
                    <StyledHeaderAndDescription>
                        <SidebarHeader>{sidebarHeader}</SidebarHeader>

                        <StyledDescription {...sanitizeMarkup(description)} />
                    </StyledHeaderAndDescription>
                </SidePanelLayout>
            </SidePanel>
        </>
    );
};
