import { useMemo } from 'react';
import { ProductDetailsPage } from '../../../lib/api/models/umbraco';
import { hesselViewModels } from '../../../lib/view-models';
import { OpenDialog, usePdpModals } from '../product-details-page/product-details-page.context';
import { ProductDetailsStore } from '../../../lib/state/hessel-site/product-details';

type CtaConfig = Partial<{
    [key in hesselViewModels.VehicleAvailability]: {
        labels: {
            primary?: string;
            secondary?: string;
            footerLink?: string;
        };
        actions: {
            primary?: () => void;
            secondary?: () => void;
            footerLink?: () => void;
        };
    };
}>;

export const useVanCtaConfig = (page: ProductDetailsPage): CtaConfig => {
    const { openDialog } = usePdpModals();
    const { dealershipHasTestDrives } = ProductDetailsStore.useStoreState((state) => state);
    const CtaConfig: CtaConfig = useMemo(
        () => ({
            Order: {
                labels: {
                    primary: page.bookAdvisorLabel,
                    secondary: page.bookTrialLabel,
                    footerLink: page.contactLabel,
                },
                actions: {
                    primary: () => openDialog({ dialog: OpenDialog.BookVanAdvisor }),
                    secondary: () => openDialog({ dialog: OpenDialog.BookTrial, sidebarOpened: 'Book Prøvetur' }),
                    footerLink: () => openDialog({ dialog: OpenDialog.VanContactUs, sidebarOpened: 'Kontakt Hessel' }),
                },
            },
            Used: {
                labels: {
                    primary: `${page.contactLabel}`,
                    secondary: undefined,
                    footerLink: undefined,
                },
                actions: {
                    primary: () => openDialog({ dialog: OpenDialog.VanContactDealership }),
                    secondary: undefined,
                    footerLink: dealershipHasTestDrives
                        ? () => openDialog({ dialog: OpenDialog.BookTrial, sidebarOpened: 'Book Prøvetur' })
                        : undefined,
                },
            },
            Engros: {
                labels: {
                    primary: `${page.contactLabel}`,
                    secondary: undefined,
                    footerLink: undefined,
                },
                actions: {
                    primary: () => openDialog({ dialog: OpenDialog.VanContactDealership }),
                    secondary: undefined,
                    footerLink: undefined,
                },
            },
        }),
        [dealershipHasTestDrives, openDialog, page.bookAdvisorLabel, page.bookTrialLabel, page.contactLabel]
    );

    return CtaConfig;
};
