import useSWR from 'swr';
import { getPageByDataTypeAlias } from '../../../../lib/api';
import { DealershipInformationPage } from '../../../../lib/api/models/umbraco';
import { ProductDetailsStore } from '../../../../lib/state/hessel-site/product-details';

type PdpDealershipsReturnType = {
    dealerships: Array<DealershipInformationPage> | undefined;
    error: Error | undefined;
};

export const usePdpDealerships = (): PdpDealershipsReturnType => {
    const { selectedProduct } = ProductDetailsStore.useStoreState((state) => state);

    const fetcher = async () => {
        const [listOfDealerships, listOfDealershipsError] = await getPageByDataTypeAlias('dealershipInformation');

        if (!listOfDealerships || listOfDealerships.length === 0 || listOfDealershipsError) {
            throw new Error('No dealerships for dealershipInformation data type alias.');
        }

        return listOfDealerships as Array<DealershipInformationPage>;
    };

    const { data, error } = useSWR(['dealershipInformation', selectedProduct?.id], fetcher, { dedupingInterval: Infinity });

    return {
        dealerships: data,
        error,
    };
};
