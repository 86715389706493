import { FC } from 'react';
import { StyledCustomerInfo, StyledDeliveryAddressHeader } from './home-delivery.styled';
import { CartStoreContext } from '../../../../../lib/state/shop/cart/cart.store';

export const HomeDelivery: FC = () => {
    const { cart } = CartStoreContext.useStoreState((state) => state);
    if (cart === null || cart.customer === null) return null;
    return (
        <>
            <StyledDeliveryAddressHeader>Leveringsadresse</StyledDeliveryAddressHeader>
            <StyledCustomerInfo>{cart.customer.firstName}</StyledCustomerInfo>
            <StyledCustomerInfo>{cart.customer.customerAddress.addressLine1}</StyledCustomerInfo>
            <StyledCustomerInfo>
                {cart.customer.customerAddress.zipCode} {cart.customer.customerAddress.city}
            </StyledCustomerInfo>
            <StyledCustomerInfo>{cart.customer.customerAddress.countryName}</StyledCustomerInfo>
        </>
    );
};
