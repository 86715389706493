import Link from 'next/link';
import { VFC } from 'react';
import { Button } from '../../../shared';
import {
    Container,
    ImageWrapper,
    StyledImage,
    DetailsWrapper,
    StyledBadge,
    StyledModel,
    StyledPrice,
    StyledBadgeText,
    StyledText,
} from './bundle-card.styled';
import { ProductOwnershipStore } from '../../../../lib/state/hessel-site/product-ownership/product-ownership.store';

export type BundleCardProps = {
    className?: string;
    badgeText?: string;
    image?: string;
    model?: string;
    kmPerMonth?: string;
    onLinkClick: () => void;
    vehicleUrl: string;
    isAboveFold?: boolean;
};

export const BundleCard: VFC<BundleCardProps> = ({
    className,
    onLinkClick,
    image,
    model,
    kmPerMonth,
    badgeText,
    vehicleUrl,
    isAboveFold = false,
}) => {
    const { setOwnershipMode, setOwnershipTab } = ProductOwnershipStore.useStoreActions((actions) => actions);
    return (
        <Container className={className}>
            <ImageWrapper>
                {image ? (
                    <Link href={vehicleUrl}>
                        <a
                            href={vehicleUrl}
                            onClick={() => {
                                setOwnershipMode('Leasing');
                                setOwnershipTab('Car HiRE');
                            }}
                        >
                            <StyledImage src={image} loading={isAboveFold ? 'eager' : 'lazy'} />
                        </a>
                    </Link>
                ) : null}
            </ImageWrapper>
            <DetailsWrapper>
                <StyledBadge>
                    <StyledBadgeText>{badgeText}</StyledBadgeText>
                </StyledBadge>

                <StyledModel>{model}</StyledModel>

                <StyledPrice>{kmPerMonth}</StyledPrice>

                <Button variant="link" onClick={onLinkClick}>
                    <StyledText>Se alt udstyr inkluderet i bilen</StyledText>
                </Button>
            </DetailsWrapper>
        </Container>
    );
};
