import styled from 'styled-components';
import { CompactCarousel } from '../../shared/carousels/compact-carousel/compact-carousel.component';
import { SimpleCarousel } from '../../shared/carousels/simple-carousel/simple-carousel.component';

export const StyledSpecsDialogTitle = styled.p`
    font: 700 25px/30px 'Corporate E', Serif;
    margin-bottom: 1.5rem; ;
`;

export const StyledSpecsDialogHeading = styled.p`
    font: 700 14px/19px ${({ theme }) => theme.typography.fontFamily}, Sans-serif;
    margin-bottom: 18px;
`;

export const StyledSpecsDialogTable = styled.table`
    display: block;
    font: 500 12px/16px ${({ theme }) => theme.typography.fontFamily}, Sans-serif;
    margin-bottom: 2rem;
`;

export const StyledSpecsDialogTableBody = styled.tbody`
    display: block;
`;

export const StyledSpecsDialogTableRow = styled.tr`
    background: #f1f1f2;
    display: grid;
    grid: auto / auto 1fr;

    &:nth-child(even) {
        background: #f7f7f8;
    }
`;

export const StyledSpecsDialogLabel = styled.td`
    display: block;
    padding: 10px;
    justify-self: flex-start;
`;

export const StyledSpecsDialogValue = styled.td`
    display: block;
    padding: 10px;
    justify-self: flex-end;
    text-align: right;
    white-space: nowrap;
`;

export const StyledSpecsDialogCarousel = styled(SimpleCarousel)`
    margin-bottom: 15px;
`;

export const StyledCompactCarousel = styled(CompactCarousel)`
    margin-bottom: 25px;
`;

export const StyledSpecsDialogDisclaimer = styled.p`
    color: #807f80;
    font: 500 12px/16px ${({ theme }) => theme.typography.fontFamily}, Sans-serif;
`;
