import { FC, useMemo, useState } from 'react';
import { SizeGuideSpot } from '../../../lib/api/models/umbraco/content-spot';
import { SizeGuideImage, SizeGuideSpecificationTab, SizeGuideYoutubeVideo } from '../../../lib/api/models/umbraco/size-guide.types';
import { getCmsImageUrl, isNullOrEmpty } from '../../../utils/helpers';
import { CompactCarousel } from '../../shared/carousels/compact-carousel/compact-carousel.component';
import { ToggleUi } from '../../shared/toggle-ui';
import { CenteredBlock } from '../../structural-blocks/centered-block.component';
import {
    StyledMediaArea,
    StyledSizeGuideBg,
    StyledSizeGuideContentWrapper,
    StyledSizeGuideDescription,
    StyledSizeGuideGrid,
    StyledSizeGuideHeader,
    StyledSpecificationLabel,
    StyledSpecificationsArea,
    StyledSpecificationsDisclaimer,
    StyledSpecificationsHeader,
    StyledSpecificationValue,
    StyledSpecificationWrapper,
} from './size-guide.styled';
import { PdpResource } from '../../../lib/view-models/vehicle/pdp-media';
import { Dropdown } from '../../shared/dropdown/dropdown.component';
import { useMediaQuery } from '../../../hooks/use-media-query';
import { SpotHeader } from '../../shared/typography/spot-header/spot-header.component';
export type SizeGuideProps = {
    spot: SizeGuideSpot;
    isPdpPlugin?: boolean;
};

export const SizeGuide: FC<SizeGuideProps> = ({ spot, isPdpPlugin = false }) => {
    const [activeSection, setActiveSection] = useState('0');

    const selectedTab = useMemo(() => {
        const tab = spot.tabs.find((_, idx) => idx.toString() === activeSection);
        if (tab) return tab;
        return spot.tabs[0];
    }, [activeSection, spot.tabs]);

    const getSlides = (tab: SizeGuideSpecificationTab): PdpResource[] => {
        return tab.mediaSection.map((x) => {
            if (x.alias === 'specificationImage') {
                return {
                    type: 'image',
                    url: getCmsImageUrl((x as SizeGuideImage).image.src),
                } as PdpResource;
            } else {
                return {
                    type: 'youTube',
                    baseUrl: 'https://www.youtube.com/embed/',
                    youTubeId: (x as SizeGuideYoutubeVideo).youtubeVideoId,
                } as PdpResource;
            }
        });
    };

    const isDevice = useMediaQuery({ target: 'laptop_tablet_landscape' });

    const optionObjects = useMemo(() => {
        return spot.tabs.map((x, idx) => {
            return {
                label: x.tabName.length > 0 ? x.tabName : x.header,
                value: idx.toString(),
            };
        });
    }, [spot.tabs]);

    return (
        <StyledSizeGuideBg id={spot.scrollAnchorId} isPdpPlugin={isPdpPlugin}>
            <CenteredBlock>
                {isNullOrEmpty(spot.headerSize) ? (
                    <StyledSizeGuideHeader as={spot.headerType}>{spot.header}</StyledSizeGuideHeader>
                ) : (
                    <SpotHeader headerType={spot.headerType} headerSize={spot.headerSize}>
                        {spot.header}
                    </SpotHeader>
                )}

                <StyledSizeGuideGrid>
                    <StyledSizeGuideDescription>{spot.description}</StyledSizeGuideDescription>
                    {isDevice ? (
                        <Dropdown
                            label={spot.dropdownLabel.length > 0 ? spot.dropdownLabel : 'Vælg'}
                            onChange={(selection) => setActiveSection(selection)}
                            options={optionObjects}
                            selected={activeSection}
                        />
                    ) : (
                        <ToggleUi options={optionObjects} onChange={(selection) => setActiveSection(selection)} selectedOption={activeSection} />
                    )}
                </StyledSizeGuideGrid>
                <StyledSizeGuideContentWrapper>
                    <StyledMediaArea>
                        {spot.tabs.map((x, idx) => {
                            return (
                                <div key={idx} style={{ display: idx.toString() === activeSection ? 'block' : 'none' }}>
                                    <CompactCarousel
                                        slides={getSlides(x)}
                                        hideCaption={true}
                                        hideMarkerList={true}
                                        carouselControls="bottom right"
                                        customStyles={{ aspectRatio: '1.69 / 1' }}
                                        isAboveFold={spot.isAboveFold}
                                    />
                                </div>
                            );
                        })}
                    </StyledMediaArea>
                    <StyledSpecificationsArea>
                        <StyledSpecificationsHeader>{selectedTab.header}</StyledSpecificationsHeader>
                        {selectedTab.specifications.map((x, idx) => {
                            return (
                                <StyledSpecificationWrapper key={`${x.value}-${idx}`}>
                                    <img
                                        style={{ width: '50px' }}
                                        src={getCmsImageUrl(x.icon.src)}
                                        alt={x.label}
                                        loading={spot.isAboveFold ? 'eager' : 'lazy'}
                                    />
                                    <div>
                                        <StyledSpecificationLabel>{x.label}</StyledSpecificationLabel>
                                        <StyledSpecificationValue>{x.value}</StyledSpecificationValue>
                                    </div>
                                </StyledSpecificationWrapper>
                            );
                        })}
                        {selectedTab.disclaimerText.length > 0 ? (
                            <StyledSpecificationsDisclaimer>{selectedTab.disclaimerText}</StyledSpecificationsDisclaimer>
                        ) : null}
                    </StyledSpecificationsArea>
                </StyledSizeGuideContentWrapper>
            </CenteredBlock>
        </StyledSizeGuideBg>
    );
};
