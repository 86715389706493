import { useEffect, useMemo, useState, VFC } from 'react';
import { hireCheckoutTypes } from '../../../../../lib/api/models/umbraco';
import { LeadDurationDeliveryStore } from '../../../../../lib/state/hessel-site/lead-checkout/duration-delivery';
import { HireCheckoutMode, LeadCheckoutFormsStore } from '../../../../../lib/state/hessel-site/lead-checkout/forms';
import { LeadCheckoutStepsStore } from '../../../../../lib/state/hessel-site/lead-checkout/steps';
import { ProductDetailsStore } from '../../../../../lib/state/hessel-site/product-details';
import { DateStyle, formatDate, handleUrlClick, sanitizeMarkup } from '../../../../../utils/helpers';
import { generateRange } from '../../../../../utils/helpers/array.helpers';
import { DropdownInput, TextInput } from '../../../../forms/inputs';
import { DropdownOption } from '../../../../forms/inputs/dropdown/dropdown-input.props';
import { Button, CheckBox, DatePickerDropdown, LabelWithLinkComponent, SelectableCard, SimpleModal } from '../../../../shared';
import { useUI } from '../../../../ui';
import { HireInputs } from '../../hire-inputs';
import { StepQuestion } from '../../typography';
import { listOfDepartments } from './department-list';
import {
    BorderBox,
    Section,
    Content,
    SectionNote,
    DeliveryModeWrapper,
    TermsAndConditions,
    EditSection,
    StyledHeader,
    DurationTermsModalContent,
} from './duration-delivery.styled';

type Props = {
    stepNumber: number;
    content: hireCheckoutTypes.HireStepDelivery;
    canValidateStep: boolean;
};

const MEDIA_URL = process.env.NEXT_PUBLIC_MEDIA_URL;

export const DurationDelivery: VFC<Props> = ({ stepNumber, content, canValidateStep }) => {
    // Duration & delivery store
    const {
        deliveryMode,
        privacyPolicyAccepted: termsAccepted,
        termsAccepted: subscribed,
        showEmployees,
        deliveryStepIsValid,
        optionalCompany,
        employees,
        selectedDepartmentId,
        selectedEmployeeId,
    } = LeadDurationDeliveryStore.useStoreState((state) => state);

    const {
        setDeliveryMode,
        setPrivacyPolicyAccepted: setTermsAccepted,
        setTermsAccepted: setSubscribed,
        setShowEmployees,
        setOptionalCompany,
        getEmployeesThunk,
        setSelectedDepartmentId,
        setSelectedEmployeeId,
    } = LeadDurationDeliveryStore.useStoreActions((actions) => actions);

    // Forms Store
    const { userInfoInputs, checkoutMode } = LeadCheckoutFormsStore.useStoreState((state) => ({
        userInfoInputs: state.inputs.filter(({ step }) => step === 'checkoutStepUserInformation'),
        checkoutMode: state.checkoutMode,
    }));

    // Steps Store
    const { setStepValidity } = LeadCheckoutStepsStore.useStoreActions((actions) => actions);

    // Product details store
    const { selectedLeasingPeriod, selectedStartupDate, selectedProduct } = ProductDetailsStore.useStoreState((state) => state);
    const { setSelectedLeasingPeriod, setSelectedStartupDate } = ProductDetailsStore.useStoreActions((actions) => actions);

    useEffect(() => {
        setStepValidity({ isValid: !!selectedStartupDate && deliveryStepIsValid, stepNumber });
    }, [deliveryStepIsValid, selectedStartupDate, setStepValidity, stepNumber]);

    // Terms Modal
    const [showTerms, setShowTerms] = useState(false);
    const uiHandler = useUI();

    // Leasing Period
    const leasingMonths = useMemo(() => {
        return [selectedProduct?.purchaseTypes.hire.durationFrom ?? 0, selectedProduct?.purchaseTypes.hire.durationTo ?? 0];
    }, [selectedProduct]);

    const leasingPeriodOptions = useMemo(() => {
        const [start, stop] = leasingMonths;

        const fullList = generateRange(start, stop, 1);

        return fullList
            .sort((a, b) => a - b)
            .map<DropdownOption<string>>((p) => ({
                displayValue: `${p} mdr.`,
                value: `${p}`,
            }));
    }, [leasingMonths]);

    const selectedLeasingPeriodIsValid = useMemo(() => (selectedLeasingPeriod?.value ? true : false), [selectedLeasingPeriod?.value]);

    // Department
    const dealershipOptions = useMemo<DropdownOption<string>[]>(() => {
        const filtered = listOfDepartments.filter((x) => {
            if (x.id === 5 && selectedProduct?.brand === 'Mercedes-Benz') {
                return false;
            }

            if (x.id === 14 && selectedProduct?.brand !== 'Mercedes-Benz') {
                return false;
            }

            return true;
        });

        return filtered.map((x) => ({ displayValue: x.city, value: `${x.id}` }));
    }, [selectedProduct?.brand]);

    const selectedDepartment = useMemo(() => {
        return dealershipOptions.find(({ value }) => value === `${selectedDepartmentId}`);
    }, [dealershipOptions, selectedDepartmentId]);

    const selectedDepartmentIsValid = useMemo(() => (selectedDepartment?.value ? true : false), [selectedDepartment?.value]);

    // Get Employees
    useEffect(() => {
        if (selectedDepartment) {
            getEmployeesThunk({
                departmentId: +selectedDepartment?.value,
                brand: selectedProduct?.brand ?? '',
                params: { sort: 'asc', vehicleType: 'Personbil' },
            });
        }
    }, [getEmployeesThunk, selectedDepartment, selectedProduct?.brand]);

    // Employees
    const departmentEmployeesOptions = useMemo<DropdownOption<string>[]>(() => {
        return employees.map((x) => ({ displayValue: x.name, value: `${x.employeeId}` }));
    }, [employees]);

    const selectedEmployee = useMemo(() => {
        return departmentEmployeesOptions.find(({ value }) => value === `${selectedEmployeeId}`);
    }, [departmentEmployeesOptions, selectedEmployeeId]);

    const selectedEmployeeIsValid = useMemo(() => (selectedEmployee?.value ? true : false), [selectedEmployee?.value]);

    return (
        <BorderBox>
            <Section>
                <StepQuestion>
                    {content.durationSectionHeadline.replace('{{car_info}}', `${selectedProduct?.brand} ${selectedProduct?.variantTitle}` ?? '')}
                </StepQuestion>
                <Content>
                    <DropdownInput
                        options={leasingPeriodOptions}
                        value={selectedLeasingPeriod}
                        id="duration-and-delivery-periods-dropdown"
                        label={content.durationDropdownLabel}
                        placeholder={content.durationDropdownPlaceholder}
                        disabled={leasingPeriodOptions.length === 0}
                        onChange={(option) => {
                            if (option) {
                                setSelectedLeasingPeriod(option);
                            }
                        }}
                        isValid={selectedLeasingPeriodIsValid}
                        canValidateInputField={canValidateStep}
                        validationMessage={content.durationDropdownValidationMessage}
                        onInputBlur={() => null}
                    />
                </Content>
            </Section>

            <Section>
                <StepQuestion>{content.deliverySectionHeadline}</StepQuestion>

                <DeliveryModeWrapper>
                    <SelectableCard
                        text={content.pickupAtDealershipText}
                        icon={content.pickupAtDealershipIcon}
                        isSelected={deliveryMode === 'dealershipPickup'}
                        onClick={() => {
                            setDeliveryMode('dealershipPickup');
                        }}
                    />
                    <SelectableCard
                        text={content.deliveryToAddressText}
                        icon={content.deliveryToAddressIcon}
                        isSelected={deliveryMode === 'deliverToAddress'}
                        onClick={() => setDeliveryMode('deliverToAddress')}
                    />
                </DeliveryModeWrapper>

                {deliveryMode === 'dealershipPickup' ? (
                    <>
                        <Content>
                            <DropdownInput
                                id="dealership-list-dropdown"
                                options={dealershipOptions}
                                value={selectedDepartment}
                                label={content.dealershipDropdownLabel}
                                placeholder={content.dealershipDropdownPlaceholder}
                                disabled={leasingPeriodOptions.length === 0}
                                onChange={async (option) => {
                                    if (option) {
                                        setSelectedDepartmentId(+option.value);

                                        await getEmployeesThunk({
                                            departmentId: +option.value,
                                            brand: selectedProduct?.brand ?? '',
                                            params: { sort: 'asc', vehicleType: 'Personbil' },
                                        });
                                    }
                                }}
                                isValid={selectedDepartmentIsValid}
                                canValidateInputField={canValidateStep}
                                validationMessage={content.dealershipDropdownValidationMessage}
                                onInputBlur={() => null}
                            />
                        </Content>
                        <Button onClick={() => setShowEmployees(!showEmployees)} variant="link" alignSelf="left">
                            <p>{showEmployees ? 'Skjul medarbejdere' : content.showEmployeesDropdownText}</p>
                        </Button>
                    </>
                ) : null}
            </Section>

            {/* Employees dropdown */}
            {showEmployees && deliveryMode === 'dealershipPickup' ? (
                <Section>
                    <StepQuestion>{content.employeesDropdownHeadline}</StepQuestion>
                    <Content>
                        <DropdownInput
                            options={departmentEmployeesOptions}
                            value={selectedEmployee}
                            id="dealership-employees"
                            label={content.employeesDropdownLabel}
                            placeholder={content.employeesDropdownPlaceholder}
                            disabled={departmentEmployeesOptions.length === 0}
                            onChange={(option) => {
                                if (option) {
                                    setSelectedEmployeeId(+option.value);
                                }
                            }}
                            isValid={selectedEmployeeIsValid}
                            canValidateInputField={false}
                        />
                    </Content>
                </Section>
            ) : null}

            {deliveryMode === 'deliverToAddress' ? (
                <Section>
                    <EditSection>
                        {content.addressBoxHeadline && content.addressBoxHeadline.length > 0 ? (
                            <StyledHeader>{content.addressBoxHeadline}</StyledHeader>
                        ) : null}

                        {checkoutMode === HireCheckoutMode.Private ? (
                            <TextInput
                                id="private-mode-optional-company"
                                label={content.optionalCompanyInputLabel}
                                onChange={(e) => {
                                    setOptionalCompany(e.target.value);
                                }}
                                type="text"
                                placeholder={content.optionalCompanyInputPlaceholder}
                                value={optionalCompany}
                                disabled={false}
                                isValid={false}
                                canValidateInputField={false}
                            />
                        ) : null}

                        <HireInputs
                            inputs={userInfoInputs.filter((x) => x.type === 'address' || x.type === 'name' || x.type === 'company (business)')}
                            canValidate={canValidateStep}
                        />
                    </EditSection>
                </Section>
            ) : null}

            {/* Date  */}
            <Section>
                <StepQuestion>{content.leasingStartHeadline}</StepQuestion>
                <Content>
                    <DatePickerDropdown
                        id="delivery-date-dropdown"
                        placeholder={content.deliveryDateDropdownPlaceholder}
                        value={
                            selectedStartupDate
                                ? {
                                      displayValue: formatDate(selectedStartupDate, DateStyle.dk_full_text),
                                      value: formatDate(selectedStartupDate, DateStyle.dk_full_text),
                                  }
                                : undefined
                        }
                        isValid={!!selectedStartupDate}
                        canValidateInputField={canValidateStep}
                        label={content.deliveryDateDropdownLabel}
                        onChange={() => null}
                        selectedDay={selectedStartupDate}
                        onDayChange={setSelectedStartupDate}
                        validationMessage={content.deliveryDateDropdownValidationMessage}
                        waitingDays={selectedProduct?.availability === 'InStock' ? content.inStockCarWaitingDays : content.orderCarWaitingDays}
                        includeWeekends={false}
                    />
                </Content>
                <SectionNote>{content.deliveryDateDropdownDisclaimer}</SectionNote>
            </Section>

            <TermsAndConditions>
                <CheckBox
                    id="personal-data-policy"
                    value="0"
                    checked={!!termsAccepted}
                    onChange={(checked) => setTermsAccepted(checked)}
                    textAlign="top"
                    isValid={!canValidateStep || termsAccepted}
                    required={true}
                    validationMessage={content.privacyPolicyValidationMessage}
                >
                    <LabelWithLinkComponent
                        labelText={content.privacyPolicyLabel}
                        termsLinkText={content.privacyPolicyLinkText}
                        onTermsLinkClick={() => handleUrlClick(content.privacyPolicyLink)}
                    />
                </CheckBox>

                <CheckBox
                    id="news-letter-subscription"
                    value="1"
                    checked={!!subscribed}
                    onChange={(checked) => setSubscribed(checked)}
                    textAlign="top"
                    isValid={true}
                >
                    <LabelWithLinkComponent
                        labelText={content.termsLabel}
                        termsLinkText={content.termsLinkText}
                        onTermsLinkClick={() => setShowTerms(true)}
                    />
                </CheckBox>

                <SimpleModal
                    headerText={content.termsModalHeader}
                    isVisible={showTerms}
                    closeAction={() => {
                        setShowTerms(false);
                        uiHandler.applyScroll();
                    }}
                    closeText={content.termsModalCloseButtonText}
                >
                    <DurationTermsModalContent {...sanitizeMarkup(content.termsContent.replace('/media/', `${MEDIA_URL}/media/`))} />
                </SimpleModal>
            </TermsAndConditions>
        </BorderBox>
    );
};
