import styled from 'styled-components';

export const StyledShopProductCard = styled.article`
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 1);
    padding: 4px;
    min-height: 365px;
    height: 100%;
`;

export const StyledText = styled.p`
    font-size: 12px;
    font-weight: 300;
    color: #0b0b0b;
`;

export const StyledName = styled.p`
    font-size: 16px;
    color: #0b0b0b;
    font-weight: 600;
    max-width: 287px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const ImageAndBadgeWrapper = styled.div`
    position: relative;
    background-color: rgb(241, 241, 242);
    padding: 4px;
`;

export const StyledProductInformationWrapper = styled.div`
    margin-top: 5px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 6px;
`;

export const StyledImage = styled.img`
    display: block;
    width: 100%;
    object-fit: contain;
    aspect-ratio: 16 / 9;
    margin-bottom: 5px;
`;

export const StyledBadgeWrapper = styled.div`
    position: absolute;
    left: 10px;
    bottom: 11px;

    padding: 4px;

    display: flex;
    gap: 4px;
`;

export const StyledBadge = styled.p`
    background-color: #0089d1;
    color: white;
    font-size: 10px;
    line-height: 10px;
    font-weight: 500;
    padding: 3px 5px;
`;

export const StyledFooter = styled.footer`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-grow: 1;
    margin-top: 20px;
`;

export const StyledPriceNowAndThen = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-bottom: 4px;
`;

export const StyledPriceNow = styled.p<{ color: 'primary' | 'default' }>`
    font-size: 17px;
    line-height: 18px;
    color: ${({ color }) => (color === 'primary' ? '#0089d1' : '#0b0b0b')};
    font-weight: 600;
`;

export const StyledPriceThen = styled.p`
    font-size: 12px;
    line-height: 18px;
    color: #aeaeaf;
    font-weight: 500;
    text-decoration: line-through;
`;
