import { FC } from 'react';
import { umbraco } from '../../../lib/api';
import { GlobalProductListSettings } from '../../../lib/api/models/umbraco';
import { ContentSpots } from '../../spots';

type IProps = {
    page: umbraco.ShopCategoryPage;
    globalPlpSettings: GlobalProductListSettings;
};

export const ShopCategoryPage: FC<IProps> = ({ page, globalPlpSettings }) => {
    return (
        <div>
            <ContentSpots
                contentSpotSettings={page.filteredContentSpots ? page.filteredContentSpots : page.contentSpotsSettings}
                context={{ pageId: page.id }}
                globalPlpSettings={globalPlpSettings}
            />
        </div>
    );
};
