import styled, { DefaultTheme } from 'styled-components';
import { animated } from 'react-spring';
import { device } from '../../../lib/media-query';
import { AccordionVariant, Borders, ComponentHeaderSize } from '../../../lib/ui-types';
import { SpotHeader } from '../typography/spot-header/spot-header.component';

const getBackgroundColor = (variant: AccordionVariant, theme: DefaultTheme, background: string) => {
    if (background && background !== '') {
        return `#${background}`;
    }

    switch (variant) {
        case 'primary':
            return theme.palette.background.default;
        case 'secondary':
            return theme.palette.common.darkBlue;
        case 'dark':
            return '#000';
        default:
            break;
    }
};

const getBorder = (variant: AccordionVariant, theme: DefaultTheme) => {
    switch (variant) {
        case 'primary':
            return `1px solid ${theme.palette.grey.medium}`;
        case 'secondary':
        case 'dark':
            return `1px solid hsla(0, 0%, 99%, 0.3)`;
        default:
            break;
    }
};

const getHeaderSize = (variant: ComponentHeaderSize) => {
    switch (variant) {
        case 'sm':
            return '0.875rem';
        case 'md':
            return '17px';
        default:
            break;
    }
};

const getTextColor = (variant: AccordionVariant, theme: DefaultTheme, textColor: string) => {
    if (textColor && textColor !== '') {
        return `#${textColor}`;
    }

    switch (variant) {
        case 'primary':
            return theme.palette.common.black;
        case 'secondary':
        case 'dark':
            return theme.palette.common.white;
        default:
            break;
    }
};

export const StyledAccordion = styled.div<{ variant: AccordionVariant; border: Borders; background: string }>`
    background: ${(props) => getBackgroundColor(props.variant, props.theme, props.background)};
    border-top: ${(props) => (props.border === 'top' || props.border === 'top and bottom' ? getBorder(props.variant, props.theme) : 'none')};
    border-bottom: ${(props) => (props.border === 'bottom' || props.border === 'top and bottom' ? getBorder(props.variant, props.theme) : 'none')};
    padding-top: ${(props) => (props.border === 'top and bottom' ? '2px' : '0')};
`;

export const AnimatedWrapper = styled(animated.div)`
    padding-bottom: 4px;
`;

export const StyledContent = styled.div<{ variant: AccordionVariant; contentPadding: number; textColor: string }>`
    color: ${(props) => getTextColor(props.variant, props.theme, props.textColor)};
    padding: 0;

    padding: 0 ${(props) => props.contentPadding}px;
`;

export const StyledHeader = styled.div<{ contentPadding: number; alignCenter: boolean }>`
    display: flex;
    justify-content: ${({ alignCenter }) => (alignCenter ? 'center' : 'space-between')};
    align-items: center;
    cursor: pointer;

    padding: 0 ${(props) => props.contentPadding}px;
`;

export const Header = styled.h3<{ variant: AccordionVariant; size: ComponentHeaderSize; textColor: string; alignCenter: boolean }>`
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 15px;
    font-weight: ${(props) => (props.size === 'sm' ? '500' : '600')};
    letter-spacing: 0;
    line-height: ${(props) => (props.size === 'sm' ? '1.5rem' : '1.6875rem')};
    color: ${(props) => getTextColor(props.variant, props.theme, props.textColor)};
    width: ${({ alignCenter }) => (alignCenter ? 'max-content' : '95%')};

    @media ${device.tablet} {
        font-size: ${(props) => getHeaderSize(props.size)};
    }
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
`;

export const StyledAccordionHeaderSpot = styled(SpotHeader)<{
    variant: AccordionVariant;
    size: ComponentHeaderSize;
    textColor: string;
    alignCenter: boolean;
}>`
    line-height: ${(props) => (props.size === 'sm' ? '1.5rem' : '1.6875rem')};
    color: ${(props) => getTextColor(props.variant, props.theme, props.textColor)};
    width: ${({ alignCenter }) => (alignCenter ? 'max-content' : '95%')};

    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
`;

export const StyledWrapper = styled(animated.div)`
    padding-bottom: 10px;
`;

export const StyledIcon = styled(animated.div)`
    align-self: center;
    padding-top: 5px;
    padding-right: 5px;

    @media ${device.mobileL} {
        padding-right: revert;
    }
`;

export const PlusWrapper = styled.div`
    position: relative;
    width: 20px;
`;

export const PlusIndicator = styled(animated.div)`
    position: absolute;
    height: 2px;
    width: 10px;
    background-color: ${({ theme: { palette } }) => palette.common.black};
    transition: all 0.2s ease-in-out;
`;
