import { useEffect, useState, VFC } from 'react';
import { HireCheckoutMode } from '../../../../lib/state/hessel-site/lead-checkout/forms';
import { getFormattedValue } from '../../../../utils/helpers';
import { KeyValueTableRow } from '../../../shared/tables/key-value-table/row';
import { Tables, StyledTableWrapper, StyledTableHeader, StyledPaymentPlanTable, PaymentPlanTableTotalRow } from './payment-plan-details.styled';

type RowData = {
    label: string;
    value: number;
    iconUrl?: string;
};

export type PaymentPlanDetailsProps = {
    oneMonthLeasingCost: number;
    handlingFee: number;
    numberOfNoCostMonths: number;
    numberOfMonths: number;
    checkoutMode?: HireCheckoutMode;
};

export const PaymentPlanDetails: VFC<PaymentPlanDetailsProps> = ({
    oneMonthLeasingCost,
    handlingFee,
    numberOfNoCostMonths,
    numberOfMonths,
    checkoutMode,
}) => {
    const [rowData, setRowData] = useState<Array<RowData>>();

    useEffect(() => {
        const result: Array<RowData> = [];

        for (let index = 0; index < numberOfMonths - numberOfNoCostMonths; index++) {
            // Month 1 = setup cost + 4 month leasing cost
            if (index === 0) {
                result.push({ label: `${index + 1}. Ydelse`, value: handlingFee + 4 * oneMonthLeasingCost });
            } else {
                result.push({ label: `${index + 1}. Ydelse`, value: oneMonthLeasingCost });
            }
        }

        // The last 3 months of a HiRE period are always without cost
        for (let index = 0; index < numberOfNoCostMonths; index++) {
            result.push({ label: `${index + 1 + numberOfMonths - numberOfNoCostMonths}. Ydelse`, value: 0 });
        }

        setRowData(result);
    }, [handlingFee, numberOfMonths, numberOfNoCostMonths, oneMonthLeasingCost]);

    const totalPrice = rowData?.reduce((acc, val) => val.value + acc, 0) ?? 0;

    const establishmentFee = handlingFee + 3 * oneMonthLeasingCost + oneMonthLeasingCost;

    return (
        <Tables>
            <StyledTableWrapper>
                <StyledTableHeader>Første ydelse</StyledTableHeader>

                <StyledPaymentPlanTable>
                    {[
                        { label: 'Etableringsgebyr', value: `${getFormattedValue(handlingFee)}` },
                        { label: '3 mdr. forudbetalt leasingydelse', value: `${getFormattedValue(3 * oneMonthLeasingCost)}` },
                        { label: '1. måneds leasingydelse', value: `${getFormattedValue(oneMonthLeasingCost)}` },
                    ].map((row, i) => {
                        const { label, value } = row;
                        return <KeyValueTableRow {...{ label, value: `${value} kr.` }} key={i} />;
                    })}

                    <PaymentPlanTableTotalRow label="I alt ved oprettelse:" value={`${getFormattedValue(establishmentFee)} kr.`} />

                    {checkoutMode === HireCheckoutMode.Business ? (
                        <KeyValueTableRow label="I alt ved oprettelse eksklusiv moms:" value={`${getFormattedValue(establishmentFee * 0.8)} kr.`} />
                    ) : null}
                </StyledPaymentPlanTable>
            </StyledTableWrapper>

            <StyledTableWrapper>
                <StyledTableHeader>Betalingsplan</StyledTableHeader>
                <StyledPaymentPlanTable>
                    {rowData?.map((row, i) => {
                        const { label, value } = row;
                        return <KeyValueTableRow {...{ label, value: `${getFormattedValue(value)} kr.` }} key={i} />;
                    })}

                    <PaymentPlanTableTotalRow label="Totalpris i perioden:" value={`${getFormattedValue(totalPrice)} kr.`} />

                    {checkoutMode === HireCheckoutMode.Business ? (
                        <KeyValueTableRow label="Pris eksklusiv moms:" value={`${getFormattedValue(totalPrice * 0.8)} kr.`} />
                    ) : null}
                </StyledPaymentPlanTable>
            </StyledTableWrapper>
        </Tables>
    );
};
