import { useCallback, useMemo } from 'react';
import { ProductDetailsPage } from '../../../lib/api/models/umbraco';
import { hesselViewModels } from '../../../lib/view-models';
import { ProductDetails, TestDriveAvailability } from '../../../lib/view-models/vehicle';
import { OpenDialog, usePdpModals } from '../product-details-page/product-details-page.context';
import {
    CarLeasingOwnershipPrivateLeasingTab,
    CarPurchaseOwnershipCashTab,
    CarPurchaseOwnershipFinanceTab,
} from '../../../lib/api/models/umbraco/product-types/cars.types';
import { ProductDetailsStore } from '../../../lib/state/hessel-site/product-details';

type CtaConfig = Partial<{
    [key in hesselViewModels.VehicleAvailability]: {
        labels: {
            primary?: string;
            secondary?: string;
            footerLink?: string | undefined;
        };
        actions: {
            primary?: () => void;
            secondary?: () => void;
            footerLink?: () => void;
        };
    };
}>;

export const useCarCtaConfig = (
    page: ProductDetailsPage,
    ownershipTab: hesselViewModels.OwnershipTab,
    selectedProduct?: ProductDetails
): CtaConfig => {
    const { openDialog } = usePdpModals();
    const { dealershipHasTestDrives } = ProductDetailsStore.useStoreState((state) => state);
    const openContactForm = useCallback(() => {
        const brand = selectedProduct?.brand;
        let id;

        switch (brand) {
            case 'Mercedes-Benz':
                id = page.mercedesBenzContactForm[0].form.id;
                break;
            case 'Dacia':
                id = page.daciaContactForm[0]?.form.id;
                break;
            case 'Renault':
                id = page.renaultContactForm[0]?.form.id;
                break;
            case 'Ford':
                id = page.fordContactForm[0]?.form.id;
                break;
            default:
                id = page.generalContactForm[0]?.form.id;
        }

        !!id && openDialog({ dialog: OpenDialog.ContactHessel, sidebarOpened: 'Kontakt Hessel' });
    }, [
        openDialog,
        page.daciaContactForm,
        page.fordContactForm,
        page.generalContactForm,
        page.mercedesBenzContactForm,
        page.renaultContactForm,
        selectedProduct?.brand,
    ]);

    const ctaLabelBasedOnOwnership = useMemo(() => {
        const carCashTabCms = page.carFinancialSetup.find((x) => x.alias === 'carPurchaseOwnershipCashTab') as CarPurchaseOwnershipCashTab;
        const financeTabCms = page.carFinancialSetup.find((x) => x.alias === 'carPurchaseOwnershipFinanceTab') as CarPurchaseOwnershipFinanceTab;
        // Get the CMS stuff
        const carPrivateLeasingCms = page.carFinancialSetup.find(
            (x) => x.alias === 'carLeasingOwnershipPrivateLeasingTab'
        ) as CarLeasingOwnershipPrivateLeasingTab;

        let ctaLabel = '';

        switch (ownershipTab) {
            case 'Car HiRE':
                ctaLabel = page.orderCarLabel;
                break;

            case 'Car Privat Leasing':
                ctaLabel = carPrivateLeasingCms.ctaText;
                break;

            case 'Car Finansiering':
                ctaLabel = financeTabCms?.ctaText;
                break;
            case 'Car Kontant':
                ctaLabel = carCashTabCms?.ctaText;
                break;
            default:
                ctaLabel = page.orderCarLabel;
        }

        return ctaLabel;
    }, [ownershipTab, page.carFinancialSetup, page.orderCarLabel]);

    const testDriveAvailability: TestDriveAvailability = selectedProduct?.testDrive ?? 'Available';

    const CtaConfig: CtaConfig = useMemo(
        () => ({
            Order: {
                labels: {
                    primary: ctaLabelBasedOnOwnership,
                    secondary:
                        testDriveAvailability !== 'NotAvailable'
                            ? testDriveAvailability === 'AvailablePreBooking'
                                ? 'Forudbestil prøvetur'
                                : page.bookTrialLabel
                            : page.contactLabel,
                    footerLink: testDriveAvailability !== 'NotAvailable' ? page.contactLabel : undefined,
                },
                actions: {
                    primary: () => (ownershipTab !== 'Car HiRE' ? openDialog({ dialog: OpenDialog.OrderCarFormModal }) : undefined),
                    secondary: () => {
                        if (testDriveAvailability !== 'NotAvailable') openDialog({ dialog: OpenDialog.BookTrial, sidebarOpened: 'Book Prøvetur' });
                        else openContactForm();
                    },
                    footerLink: () => {
                        if (testDriveAvailability !== 'NotAvailable') openContactForm();
                    },
                },
            },
            InStock: {
                labels: {
                    primary: ctaLabelBasedOnOwnership,
                    secondary: page.bookTrialLabel,
                    footerLink: page.contactLabel,
                },
                actions: {
                    primary: () => (ownershipTab !== 'Car HiRE' ? openDialog({ dialog: OpenDialog.OrderCarFormModal }) : undefined),
                    secondary: () => openDialog({ dialog: OpenDialog.BookTrial, sidebarOpened: 'Book Prøvetur' }),
                    footerLink: () => openContactForm(),
                },
            },
            Used: {
                labels: {
                    primary: selectedProduct?.starmarkLink ? page.viewAtStarmarkLabel : 'Forespørg på bilen',
                    secondary: selectedProduct?.starmarkLink ? undefined : page.bookTrialLabel,
                    footerLink: `${page.contactLabel}${selectedProduct?.starmarkLink ? ', Starmark' : ''}`,
                },
                actions: {
                    primary: () =>
                        selectedProduct?.starmarkLink
                            ? window.open(selectedProduct.starmarkLink, '_blank')
                            : openDialog({ dialog: OpenDialog.OrderCarFormModal }),
                    secondary:
                        selectedProduct?.starmarkLink || !dealershipHasTestDrives
                            ? undefined
                            : () => openDialog({ dialog: OpenDialog.BookTrial, sidebarOpened: 'Book Prøvetur' }),
                    footerLink: () => openContactForm(),
                },
            },
        }),
        [
            ctaLabelBasedOnOwnership,
            page.bookTrialLabel,
            page.contactLabel,
            page.viewAtStarmarkLabel,
            selectedProduct?.starmarkLink,
            dealershipHasTestDrives,
            ownershipTab,
            openDialog,
            openContactForm,
            testDriveAvailability,
        ]
    );

    return CtaConfig;
};
