import { FC, useMemo, useState } from 'react';
import { useMediaQuery } from '../../../hooks/use-media-query';
import { ExplorationCard, ExplorationCardData, ExplorationContentCard } from '../../../lib/api/models/umbraco/car-exploration-module.types';
import { CarExplorationModule as CarExplorationModuleType } from '../../../lib/api/models/umbraco/content-spot';
import { formatPrice, getImageCropUrl, isNullOrEmpty } from '../../../utils/helpers';
import { Dropdown } from '../../shared/dropdown/dropdown.component';
import { ToggleUi } from '../../shared/toggle-ui';
import { CenteredBlock } from '../../structural-blocks/centered-block.component';
import { StyledBgWrapper, StyledCardWrapper, StyledHeader, StyledSubHeader } from './car-exploration.styled';
import { CarExplorationCard } from './car-exploration-card/car-exploration-card.component';
import { MEDIA_URL } from '../../../utils/environment-constants';
import { SpotHeader } from '../../shared/typography/spot-header/spot-header.component';
import { Abbreviations } from '../../../constants/units-and-abbreviations';

type CarExplorationModuleProps = {
    spot: CarExplorationModuleType;
};

const getCardMetaData = (card: ExplorationCard) => {
    // Data is a map, so we create the key based on a contract with BE.
    const [_, category, ...brand] = card.alias.split('x');
    const idSourceProps = [category, card.model, card.bodyType];
    return { brand: brand.join('-'), id: idSourceProps.join('|') };
};

/**
 * Important note: spot is enriched with cardData in ssr-helpers.ts -> ssrForProductSpots
 * @param param0
 * @returns
 */
export const CarExplorationModule: FC<CarExplorationModuleProps> = ({ spot }) => {
    const [activeSection, setActiveSection] = useState('0');
    const isMobile = useMediaQuery({ target: 'tablet' });

    const tabsWithContent = useMemo(() => {
        return spot.tabs.filter((tab) => {
            return tab.cards.some((card) => {
                const { id } = getCardMetaData(card);

                // Note that cards can be static content cards, which we want to show regardless of whether we have data for them.
                return spot.cardData?.[id] !== undefined || card.alias === 'staticContentCard';
            });
        });
    }, [spot.cardData, spot.tabs]);

    const options = useMemo(() => {
        return tabsWithContent.map((x, idx) => {
            return {
                label: x.tabName,
                value: idx.toString(),
            };
        });
    }, [tabsWithContent]);

    const isMonthlyPrice = spot.priceType !== 'Cash';

    if (tabsWithContent.length === 0) {
        return null;
    }

    return (
        <div id={spot.scrollAnchorId}>
            <StyledBgWrapper>
                <CenteredBlock>
                    {isNullOrEmpty(spot.headerSize) ? (
                        <StyledHeader as={!isNullOrEmpty(spot.headerType) ? spot.headerType : 'p'}>{spot.header}</StyledHeader>
                    ) : (
                        <SpotHeader headerType={spot.headerType} headerSize={spot.headerSize}>
                            {spot.header}
                        </SpotHeader>
                    )}

                    {spot.subHeader ? <StyledSubHeader>{spot.subHeader}</StyledSubHeader> : null}
                    {options.length > 1 ? (
                        <div style={{ marginTop: '20px' }}>
                            {isMobile ? (
                                <Dropdown options={options} selected={activeSection} onChange={setActiveSection} label="Vis biltype" />
                            ) : (
                                <ToggleUi options={options} selectedOption={activeSection} onChange={setActiveSection} />
                            )}
                        </div>
                    ) : null}
                    {tabsWithContent.map((x, idx) => {
                        return (
                            <div key={idx} style={{ display: activeSection === idx.toString() ? 'block' : 'none' }}>
                                <StyledCardWrapper cardsPerRow={x.numberOfCardsPerRow}>
                                    {x.cards.map((card, i) => {
                                        if (card.alias === 'staticContentCard') {
                                            const cardWithTypeCast = card as ExplorationContentCard;
                                            return (
                                                <CarExplorationCard
                                                    key={i}
                                                    description={cardWithTypeCast.description}
                                                    imageAlt={cardWithTypeCast.model}
                                                    imageUrl={`${MEDIA_URL}${cardWithTypeCast?.image?.src ?? ''}`}
                                                    link={cardWithTypeCast.link}
                                                    linkText={cardWithTypeCast.link.name}
                                                    manchet={cardWithTypeCast.manchet}
                                                    name={cardWithTypeCast.model}
                                                    price={cardWithTypeCast.price}
                                                    useSquaredCornersForImages={spot.useSquaredCornersForImages}
                                                />
                                            );
                                        }
                                        const cardWithTypeCast = card as ExplorationCard;
                                        const { brand, id } = getCardMetaData(cardWithTypeCast);
                                        const cardData: ExplorationCardData | undefined = spot.cardData?.[id];
                                        return cardData ? (
                                            <CarExplorationCard
                                                key={i}
                                                description={cardData.description}
                                                imageAlt={`${brand}-${cardData.model}`}
                                                imageUrl={getImageCropUrl(cardData.imageUrl, 'productdetails')}
                                                link={cardWithTypeCast.link}
                                                linkText={'SE UDVALG'}
                                                manchet={`Fås som ${(spot.manchetType === 'Propellant'
                                                    ? cardData.fuelTypes
                                                    : cardData.bodyTypes
                                                ).join(', ')}`}
                                                name={`${cardData.model}${cardWithTypeCast.bodyType ? `, ${cardWithTypeCast.bodyType}` : ''}`}
                                                price={
                                                    cardData.price && cardData.price > 0
                                                        ? `Fra ${formatPrice(cardData.price)} ${
                                                              isMonthlyPrice ? Abbreviations.KR_SLASH_MD : Abbreviations.KR
                                                          }`
                                                        : ''
                                                }
                                                useSquaredCornersForImages={spot.useSquaredCornersForImages}
                                            />
                                        ) : null;
                                    })}
                                </StyledCardWrapper>
                            </div>
                        );
                    })}
                </CenteredBlock>
            </StyledBgWrapper>
        </div>
    );
};
