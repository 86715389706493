import styled from 'styled-components';
import { ArrowLeft, ArrowRight } from '../../../icons';
import { IconButton } from '../../icon-button/icon-button.component';
import { StyledCarouselAspectRatioProps } from '../carousel.types';
import { InnerCarousel } from '../inner-carousel/inner-carousel.component';

export const StyledSimpleCarousel = styled.section`
    position: relative;

    &:focus-within > div,
    &:hover > div {
        opacity: 1;
    }
`;

export const StyledSimpleCarouselControls = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    left: 12px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 300ms;
    z-index: 2;

    & > * {
        pointer-events: all;
    }
`;

export const StyledSimpleCarouselPreviousButton = styled(IconButton).attrs({
    children: ArrowLeft({}),
})``;

export const StyledSimpleCarouselNextButton = styled(IconButton).attrs({
    children: ArrowRight({}),
})``;

export const StyledSimpleInnerCarousel = styled(InnerCarousel).attrs<StyledCarouselAspectRatioProps>((attrs) => ({
    style: {
        aspectRatio: attrs.aspectRatio || '16 / 9',
    },
}))<StyledCarouselAspectRatioProps>`
    z-index: 1;
`;

export const StyledSimpleInnerCarouselCaption = styled.figcaption`
    font: 500 12px/14px ${({ theme }) => theme.typography.fontFamily}, Sans-serif;
    margin: 10px 0 0;
`;
