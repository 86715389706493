import styled from 'styled-components';
import { device } from '../../../lib/media-query';
import { GenericCircle } from '../../shared/shapes/generic-circle';

export const StyledAccordionHeader = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;

    @media ${device.tablet} {
        width: 91%;
    }
`;

export const Label = styled.p`
    color: #0b0b0b;
    font-size: clamp(12px, 2vw, 14px);
    font-weight: 500;
    letter-spacing: 0;
`;

export const SelectedConfigWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
`;

export const StyledConfigPrice = styled.p`
    color: #807f80;
    font-size: clamp(12px, 2vw, 14px);
    letter-spacing: 0;
    text-align: right;
    margin-left: auto;
`;

export const StyledConfigText = styled.p`
    color: #807f80;
    font-size: clamp(12px, 2vw, 14px);
    font-weight: 500;
    letter-spacing: 0;
`;

export const StyledCircle = styled(GenericCircle)<{ imageUrl?: string }>`
    position: relative;
    width: 20px;
    height: 20px;
    background-image: ${(props) => (props.imageUrl ? `url('${props.imageUrl}')` : 'none')};
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    flex-shrink: 0;
`;
