import { deleteAPI, getAPI, postAPI, putAPI } from '../helpers';
import { CartCustomer, CartObject, ReepayCheckoutRequest, ReepayCheckoutResponse } from '../models/shop/cart';
import { DeliveryOptionType } from '../models/shop/shop-api.models';
import { APIResponse } from '../types';

export const addProductToCart = async (productSku: string, cartId: string | null): APIResponse<CartObject> => {
    const postUrl = cartId ? `/api/v1/commerce/basket/${cartId}/sku/${productSku}` : `/api/v1/commerce/basket/sku/${productSku}`;
    return postAPI<CartObject>(postUrl, 1);
};

export const updateOrderlineQuantity = async (productSku: string, quantity: number, cartId: string): APIResponse<CartObject> => {
    return putAPI<CartObject>(`/api/v1/commerce/basket/${cartId}/sku/${productSku}`, quantity);
};

export const deleteOrderline = async (productSku: string, cartId: string): APIResponse<CartObject> => {
    return deleteAPI<CartObject>(`/api/v1/commerce/basket/${cartId}/sku/${productSku}`);
};

export const addCustomerToCart = async (customerInfo: CartCustomer, cartId: string): APIResponse<CartObject> => {
    return postAPI<CartObject>(`/api/v1/commerce/basket/${cartId}/customer`, customerInfo);
};

export const startCheckoutPayment = async (request: ReepayCheckoutRequest): APIResponse<ReepayCheckoutResponse> => {
    return postAPI<ReepayCheckoutResponse>(`/api/v1/payment/startcheckout`, request);
};

export const getDeliveryOptions = async (cartId: string): APIResponse<DeliveryOptionType[]> => {
    return getAPI<DeliveryOptionType[]>(`/api/v1/commerce/delivery-options/${cartId}`);
};

export const setDeliveryOption = async (cartId: string, deliveryOption: string): APIResponse<CartObject> => {
    return postAPI<CartObject>(`/api/v1/commerce/delivery-options/${cartId}`, { name: deliveryOption });
};
