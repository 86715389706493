import React, { VFC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { hesselViewModels } from '../../../../lib/view-models';
import { ProductDetailsStore } from '../../../../lib/state/hessel-site/product-details';
import { sortProductCardsByProductPrice } from '../../../../utils/helpers';
import { PriceByOwnership } from '../../hooks/price-by-ownership.types';
import { RelatedVehiclesRibbon } from '../../related-vehicles-ribbon/related-vehicles-ribbon.component';
import { ProductDetailsPage as UmbracoProductDetailsPage } from '../../../../lib/api/models/umbraco';
import { getProductList } from '../../../../lib/api/vehicle/hessel-vehicle-plp-api';
import { mapCarDetailsToProductCard } from '../../../../lib/mappers/vehicle/product-card.mapper';
import { FilterConfigurationKey } from '../../../../constants/site-consts';

type RelatedVehiclesRibbonProps = {
    priceInformation: PriceByOwnership;
    isCompanyCar: boolean;
    page: UmbracoProductDetailsPage;
};

export const PdpRelatedVehiclesRibbonMemo: VFC<RelatedVehiclesRibbonProps> = ({ priceInformation, isCompanyCar, page }) => {
    const { selectedProduct } = ProductDetailsStore.useStoreState((state) => state);

    const [relatedVehicles, setRelatedVehicles] = useState<hesselViewModels.ProductCard[]>([]);

    const relatedVehiclesExceptCurrent = relatedVehicles.filter((vehicle) => vehicle.id !== selectedProduct?.id);

    useEffect(() => {
        if (!selectedProduct) {
            return;
        }
        const fetchRelatedVehicles = async () => {
            if (page.pdpRelatedCars) {
                const baseFilter = [
                    `${FilterConfigurationKey.availability}${selectedProduct.availability}`,
                    `${FilterConfigurationKey.bodyType}${selectedProduct.carType}`,
                    `${FilterConfigurationKey.modelTitle}${selectedProduct.modelTitle}`,
                ];

                const relatedCarsFilter = !isCompanyCar ? baseFilter : [...baseFilter, FilterConfigurationKey.purchaseTypes_taxation_show];

                const [relatedVehicles, relatedVehiclesFetchError] = await getProductList(
                    {
                        from: 0,
                        take: 20,
                        configurationId: page.pdpRelatedCars.id,
                        filters: relatedCarsFilter,
                    },
                    selectedProduct.vehicleType
                );
                if (relatedVehicles && !relatedVehiclesFetchError) {
                    const newLocal = isCompanyCar
                        ? relatedVehicles.vehicles.filter((x) => x.purchaseTypes.taxation.show).map(mapCarDetailsToProductCard)
                        : relatedVehicles.vehicles.map(mapCarDetailsToProductCard);
                    setRelatedVehicles(newLocal);
                } else {
                    setRelatedVehicles([]);
                }
            }
        };

        fetchRelatedVehicles();
    }, [isCompanyCar, page.pdpRelatedCars, selectedProduct]);

    return relatedVehiclesExceptCurrent && relatedVehiclesExceptCurrent.length > 0 ? (
        <StyledRelatedCarsSpotWrapper key={Math.random()}>
            <RelatedVehiclesRibbon
                vehicles={sortProductCardsByProductPrice(relatedVehiclesExceptCurrent, priceInformation.price)}
                header={`Andre ${selectedProduct?.brand} ${selectedProduct?.modelTitle}`}
                description={'Udforsk andre varianter indenfor samme model'}
                isAboveFold={false}
                showAsCompanyCars={isCompanyCar}
            />
        </StyledRelatedCarsSpotWrapper>
    ) : null;
};

export const PdpRelatedVehiclesRibbon = React.memo(PdpRelatedVehiclesRibbonMemo);

const StyledRelatedCarsSpotWrapper = styled.div`
    background-color: var(--spot-bg, #f7f7f8);
    margin: var(--pdp-margin, 12px) 0;
`;
