import styled from 'styled-components';
import { device } from '../../../../lib/media-query';

export const StyledBrandAndServiceTable = styled.div`
    display: none;
    @media ${device.tablet} {
        display: grid;
    }
    grid-template-rows: 37px auto;
    row-gap: 12px;
`;

export const StyledHeaderRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    place-content: center;
    padding: 10px 0;
`;

export const StyledTableColumn = styled.div`
    justify-self: center;
`;

export const HeaderText = styled.div`
    color: #0b0b0b;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
`;
