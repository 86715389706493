import { FC, useMemo } from 'react';
import ServiceAgreementContainer from '../service-agreement-container/service-agreement-container.component';
import { StyledLabel } from '../service-agreement-step.styled';
import { RadioButton } from '../../../../shared/radio-button';
import { ServiceAgreementCalculatorModalStore } from '../../../../../lib/state/service-agreement-calculator/service-agreement-calculator.store';
import { StyledHr } from '../../service-agreement-calculator-modal.styled';
import { WarrantyExtensionAgreement } from '../../../../../lib/api/models/hessel-api';

type Props = {
    agreements: WarrantyExtensionAgreement[];
    description?: string;
};

export const WarrantyExtensionAgreementOption: FC<Props> = ({ agreements, description }) => {
    const { selectedWarrantyExtension } = ServiceAgreementCalculatorModalStore.useStoreState((states) => states);
    const { setSelectedWarrantyExtension, clearAllAgreements } = ServiceAgreementCalculatorModalStore.useStoreActions((actions) => actions);

    const formattedPrice = useMemo(() => {
        const price = selectedWarrantyExtension?.price ?? Math.min(...agreements.map((agreement) => agreement.price));
        return `${price} kr.`;
    }, [agreements, selectedWarrantyExtension?.price]);

    return (
        <ServiceAgreementContainer
            id="warrantyExtension"
            onChecked={() => {
                clearAllAgreements();
                setSelectedWarrantyExtension([...agreements].sort((a, b) => (a.price > b.price ? 1 : -1))[0]);
            }}
            onUnchecked={() => {
                setSelectedWarrantyExtension(undefined);
            }}
            checked={!!selectedWarrantyExtension}
            title="Mercedes-Benz WarrantyExtension"
            titleDescription={description}
            priceText={formattedPrice}
        >
            <StyledHr />
            <StyledLabel>Hvor lang tid ønsker du din garantiforlængelse?</StyledLabel>

            {agreements.map((agreement, index) => {
                return (
                    <RadioButton
                        key={agreement.months}
                        value={''}
                        id={`warrantyExtensions${index}`}
                        groupName="warrantyExtensions"
                        checked={agreement === selectedWarrantyExtension}
                        action={() => {
                            setSelectedWarrantyExtension(agreement);
                        }}
                    >
                        <label>+{agreement.months} måneder</label>
                    </RadioButton>
                );
            })}
        </ServiceAgreementContainer>
    );
};
