import { orgTypes } from '../../../lib/api/models/umbraco';
import { contentSpotTypes, umbraco } from '../../../lib/api';
import { HesselDealership, UmbracoStarmarkDealership } from '../../../lib/api/organization/hessel-dealership-api';
import { ContactPage } from '../../page-entry-point/dealership/kontakt-page';
import { getLetter } from '../../../utils/helpers/array.helpers';
import { FC, useMemo } from 'react';

type FindDealershipPageProps = {
    emergencyPhoneNumbers: Array<orgTypes.DepartmentEmergencyNumbers>;
    contentSpotSettings: contentSpotTypes.ContentSpotSettings[];
    demoCarNotAvailableModal: Array<orgTypes.DemoCarNotAvailableModal>;
    metaMenu: umbraco.PageMetaMenu | null;
    page: umbraco.Page;
    settings?: umbraco.SiteSettings;
};

type MemoizedList = { allDealerships: Array<orgTypes.DealershipWithGeoInfo> };

const FindDealershipPage: FC<FindDealershipPageProps> = (props) => {
    const { allDealerships } = useMemo<MemoizedList>(() => {
        const typedPageChildren = props.page?.children as Array<HesselDealership | UmbracoStarmarkDealership>;

        const dealerships: orgTypes.DealershipWithGeoInfo[] = typedPageChildren
            .filter((x) => x.contentTypeAlias === 'dealershipInformation')
            .map((x, index) => {
                const typeValue = x as HesselDealership;
                return {
                    dealershipId: typeValue.autolineId,
                    hovedafdelingId: typeValue.hovedafdelingId,
                    header: typeValue.displayName,
                    lat: typeValue.latitude,
                    lng: typeValue.longitude,
                    city: typeValue.city,
                    zipcode: typeValue.zipcode,
                    phone: typeValue.phone,
                    address: typeValue.address,
                    email: typeValue.eMail,
                    url: x.url,
                    departments: typeValue.departments,
                    supportedBrands: typeValue.supportedBrands,
                    writeUsForm: null,
                    certificates: null,
                    letter: getLetter(index),
                    displayName: typeValue.displayName,
                    testDriveOpeningHours: {
                        openingHours: typeValue.testDriveOpeningHours?.[0]?.openingHours ?? [],
                        specialDays: typeValue.testDriveOpeningHours?.[0]?.specialDays ?? [],
                    },
                    bookWorkshopModalTexts: {
                        bookWorkshopHeader: typeValue.bookWorkshopHeader,
                        bookWorkshopDescription: typeValue.bookWorkshopDescription,
                        bookWorkshopModalButtonText: typeValue.bookWorkshopModalButtonText,
                    },
                    dealershipImage: typeValue.dealershipImage,
                };
            });

        return {
            allDealerships: dealerships,
        };
    }, [props.page?.children]);

    const { departmentList, brandList, dealershipAndTruckNumbers } = useMemo(() => {
        const departmentList: Array<orgTypes.DealershipDepartment> = [];
        const brandList: Array<orgTypes.SupportedBrand> = [];
        let dealershipAndTruckNumbers: Array<orgTypes.DealerShipAndTruck> = [];

        if (props.page.children) {
            for (let index = 0; index < props.page.children.length; index++) {
                const dealerShip = props.page.children[index];

                if (dealerShip.contentTypeAlias !== 'dealershipInformation') {
                    continue;
                }

                dealerShip.departments.forEach((dep) => {
                    if (!departmentList.some((x) => x.departmentType === dep.departmentType)) {
                        departmentList.push(dep);
                    }
                });

                dealerShip.supportedBrands.forEach((sb) => {
                    if (!brandList.some((x) => x.brand === sb.brand)) {
                        brandList.push(sb);
                    }
                });
            }

            const mercedesDealerships = (props.page?.children as Array<HesselDealership | UmbracoStarmarkDealership>).filter(
                (x) =>
                    x.contentTypeAlias === 'dealershipInformation' &&
                    (x as HesselDealership).supportedBrands.some((sb) => sb.brand === 'Mercedes-Benz')
            );

            dealershipAndTruckNumbers = mercedesDealerships.map((x) => {
                return {
                    emergencyNumber: (x as HesselDealership).truckEmergencyNumber,
                    autolineId: x.autolineId,
                    displayName: x.displayName,
                };
            });

            return { departmentList, brandList, dealershipAndTruckNumbers };
        }

        return { departmentList, brandList, dealershipAndTruckNumbers };
    }, [props.page.children]);

    return (
        <ContactPage
            allDealerships={allDealerships}
            dealershipAndTruckNumbers={dealershipAndTruckNumbers}
            config={{ brands: brandList, departments: departmentList }}
            emergencyPhoneNumbers={props.emergencyPhoneNumbers}
            contentSpotSettings={props.contentSpotSettings}
            pageId={props.page.id}
            metaMenu={props.metaMenu ?? undefined}
        />
    );
};

export default FindDealershipPage;
