import React, { FC } from 'react';
import { FilterUpdateNotification } from '../../../../../lib/api/models/hessel-api/hire';
import { FacetOption } from '../../../../../lib/view-models/vehicle';
import { CheckboxRenderer } from './checbox-renderer/checkbox-renderer.component';
import { OptionText, OptionWrapper, StyledOptionIcon } from './checkox-filter.styled';
import { Image } from '../../../../../lib/api/models/umbraco/shared-types';

type IProps = {
    facetOptions: FacetOption[];
    onChange: (filterUpdate: FilterUpdateNotification[]) => void;
    maximumSubfacetDepth?: number;
    singleUnselectableOption?: boolean;
};

const MEDIA_URL = process.env.NEXT_PUBLIC_MEDIA_URL;

export const CheckboxFilter: FC<IProps> = ({ facetOptions, onChange, maximumSubfacetDepth = 99, singleUnselectableOption = false }) => {
    const getOptionText = (option: FacetOption) => {
        if (option.icon) {
            return (
                <>
                    <OptionText>{`${option.key} (${option.count})`}</OptionText>
                    <StyledOptionIcon src={`${MEDIA_URL}/${(option.icon as Image).src}`} alt={option.key} />
                </>
            );
        } else {
            return <OptionText>{`${option.key} (${option.count})`}</OptionText>;
        }
    };

    const renderOptionAndSubFacets = (
        option: FacetOption,
        currentSubfacetDepth: number,
        onCheckNotifications?: FilterUpdateNotification[],
        onUncheckNotification?: FilterUpdateNotification
    ) => {
        return (
            <React.Fragment>
                <CheckboxRenderer
                    onUpdate={(updates) => onChange(updates)}
                    onUncheckNotification={onUncheckNotification}
                    option={option}
                    onCheckNotifications={onCheckNotifications}
                    disableInteraction={singleUnselectableOption && currentSubfacetDepth === 1}
                >
                    {getOptionText(option)}
                </CheckboxRenderer>
                {option.subFacets && option.subFacets.length > 0 && option.selected && currentSubfacetDepth <= maximumSubfacetDepth ? (
                    <OptionWrapper disabled={false} style={{ marginLeft: '25px' }}>
                        {option.subFacets.map((y) => {
                            return (
                                <div key={y.filterQueryValue} style={{ marginBottom: '20px' }}>
                                    {renderOptionAndSubFacets(
                                        y,
                                        currentSubfacetDepth + 1,
                                        [...(onCheckNotifications ?? []), { query: option.filterQueryValue, action: 'Remove' }],
                                        singleUnselectableOption && currentSubfacetDepth === 1
                                            ? undefined
                                            : { query: option.filterQueryValue, action: 'Add' }
                                    )}
                                </div>
                            );
                        })}
                    </OptionWrapper>
                ) : null}
            </React.Fragment>
        );
    };
    return (
        <>
            {facetOptions.map((x) => {
                return <React.Fragment key={x.filterQueryValue}>{renderOptionAndSubFacets(x, 1)}</React.Fragment>;
            })}
        </>
    );
};

export default CheckboxFilter;
