import styled from 'styled-components';

export const StyledFooterDesktop = styled.footer`
    width: 100%;
    color: #fcfcfc;
    background-color: #002134;
    padding: 3.125rem 0;
    font-size: 0.75rem;
    letter-spacing: 0.5px;
    line-height: 16px;
    position: relative;
`;

export const FooterFlexContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const CustomerInfoDesktop = styled.section<{ columns: number }>`
    display: grid;
    grid-template-columns: repeat(${(props) => props.columns}, 1fr);
    justify-self: center;
    grid-gap: 3rem;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
`;

export const OpeningHoursWrapper = styled.section`
    margin: 1.5rem 0;
`;

export const OpeningHoursContent = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 175px;
`;

export const PaymentWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const CreditsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 200px;
`;

export const CreditCard = styled.div`
    width: 40px;
    height: 40px;

    & img {
        display: block;
        height: 100%;
        width: 100%;
    }
`;

export const CtaHandlerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const SmallTextDesktop = styled.p<{ showAsItalic?: boolean }>`
    font-style: ${(props) => (props.showAsItalic ? 'italic' : '')};
    white-space: nowrap;
`;
