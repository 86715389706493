import { FC, useEffect, useMemo, useState } from 'react';
import { Wrapper, TermsModalContent } from './contact-info.styled';
import { SimpleModal } from '../../../shared';
import { umbraco } from '../../../../lib/api';
import { Policies } from './policies';
import { BookingStepsStore } from '../../../../lib/state/booking-steps';
import { BookingContactInfoStore } from '../../../../lib/state/booking-contact-info';
import { BookingFormStore } from '../../../../lib/state/booking-form';
import { BookingInputs } from '../../inputs';
import { formInputValue, replaceLineBreaks } from '../../../../utils/helpers';
import { generateFormId } from '../../../../lib/state/booking-form/booking-form.helpers';
import { ContactInfoSummary } from './summary';
import { useGTMBookingData } from '../../../../hooks/booking/booking/use-gtm-booking-data';
import { useUI } from '../../../ui';
import { useGtmTracking } from '../../../../gtm-tracking/hooks/use-gtm-tracking';
import { ContactInfo } from '../../../../lib/state/booking-contact-info/booking-contact-info.types';
import { BookingReceiptStore } from '../../../../lib/state/booking-receipt';
import { filterStepType } from '../../../../lib/state/booking-steps/booking-steps.helpers';

type Props = {
    isCompleted: boolean;
    content: umbraco.BookingStepContactInfo;
    stepNumber: number;
    canValidateStep: boolean;
};

export const BookingContactInfo: FC<Props> = ({ isCompleted, content, stepNumber, canValidateStep }) => {
    const [showTerms, setShowTerms] = useState(false);
    const [newCustomerInfo, setNewCustomerInfo] = useState({
        email: '',
        tlf: '',
    });

    const { fetchBenefitAgreementContentAsync, setRelevantPromotion } = BookingReceiptStore.useStoreActions((actions) => actions);

    const { inputs, yourCarInputs, customer, makeId, customerHasBenefitAgreement } = BookingFormStore.useStoreState((state) => ({
        inputs: state.inputs.filter(({ step }) => step === 'ContactInfo'),
        yourCarInputs: state.inputs.filter(({ step }) => step === 'YourCar'),
        customer: state.customer,
        makeId: state.vehicle?.makeId ?? '',
        customerHasBenefitAgreement: state?.customer?.benefitAgreement !== null,
    }));

    const { onChange, onChangeMultiple, disableInput } = BookingFormStore.useStoreActions(({ onChange, onChangeMultiple, disableInput }) => ({
        onChange,
        onChangeMultiple,
        disableInput,
    }));

    const { contactInfoStepIsValid } = BookingContactInfoStore.useStoreState((state) => state);
    const { setContactInfo } = BookingContactInfoStore.useStoreActions((actions) => actions);

    const { currentStep, steps } = BookingStepsStore.useStoreState((state) => state);
    const { setStepValidity } = BookingStepsStore.useStoreActions((actions) => actions);

    useEffect(() => {
        const emailForm = yourCarInputs.find((input) => input.type === 'email');
        const tlfForm = yourCarInputs.find((input) => input.type === 'tel');

        if (emailForm && tlfForm) {
            const email = formInputValue(emailForm);
            const tlf = formInputValue(tlfForm);

            if (email !== newCustomerInfo.email || tlf !== newCustomerInfo.tlf) {
                setNewCustomerInfo({
                    email,
                    tlf,
                });
            }
        }
    }, [newCustomerInfo.email, newCustomerInfo.tlf, yourCarInputs]);

    useEffect(() => {
        onChange({ id: generateFormId('ContactInfo', 'Email'), value: newCustomerInfo.email });
        onChange({ id: generateFormId('ContactInfo', 'Telephone number'), value: newCustomerInfo.tlf });

        disableInput({ id: generateFormId('ContactInfo', 'Email') });
        disableInput({ id: generateFormId('ContactInfo', 'Telephone number') });
    }, [customer, disableInput, newCustomerInfo, onChange, onChangeMultiple]);

    useEffect(() => {
        if (currentStep === stepNumber) {
            setStepValidity({ isValid: contactInfoStepIsValid && inputs.every(({ isValid }) => isValid), stepNumber });
        }
    }, [currentStep, contactInfoStepIsValid, setStepValidity, stepNumber, inputs]);

    const ui = useUI();

    const { trackBooking } = useGtmTracking();
    const tracker = trackBooking();
    const gtmData = useGTMBookingData();

    useEffect(() => {
        if (currentStep === stepNumber) {
            tracker.checkoutStep(4, [...(gtmData?.products ?? [])], {
                vaerksted: gtmData?.workshop.name,
            });
        }
    }, [currentStep, gtmData?.products, gtmData?.workshop.name, stepNumber, tracker]);

    const { city, postalCode, streetName, email, mobile, fullName } = useMemo(() => {
        if (currentStep !== stepNumber || isCompleted)
            return {
                city: '',
                postalCode: '',
                streetName: '',
                email: '',
                mobile: '',
                fullName: '',
            };

        const address = inputs
            .map((input) => (input.id === generateFormId('ContactInfo', 'Address') && input.type === 'address' ? input.inputs : undefined), [])
            .filter((val) => (val ? true : false))
            .reduce((acc, val) => (val ? [...(acc ? acc : []), ...val] : acc), []);

        const cityInput = address?.find(({ id }) => id === generateFormId('ContactInfo', 'Address', 'city'));
        const zipInput = address?.find(({ id }) => id === generateFormId('ContactInfo', 'Address', 'zip'));
        const streetInput = address?.find(({ id }) => id === generateFormId('ContactInfo', 'Address', 'address'));

        const emailInput = inputs.find(({ id }) => id === generateFormId('ContactInfo', 'Email'));
        const mobileInput = inputs.find(({ id }) => id === generateFormId('ContactInfo', 'Telephone number'));

        const nameInput = inputs.find(({ id }) => id === generateFormId('ContactInfo', 'Name'));

        const info: ContactInfo = {
            city: cityInput ? formInputValue(cityInput) : '',
            postalCode: zipInput ? formInputValue(zipInput) : '',
            streetName: streetInput ? formInputValue(streetInput) : '',

            email: emailInput ? formInputValue(emailInput) : '',
            mobile: mobileInput ? formInputValue(mobileInput) : '',
            fullName: nameInput ? formInputValue(nameInput).trim() : '',
        };

        return info;
    }, [currentStep, inputs, isCompleted, stepNumber]);

    useEffect(() => {
        if (currentStep !== stepNumber || isCompleted) return;

        setContactInfo({ city, postalCode, streetName, email, mobile, fullName });
    }, [city, currentStep, email, fullName, isCompleted, mobile, postalCode, setContactInfo, stepNumber, streetName]);

    const relevantPromotion = useMemo(() => {
        if (currentStep !== stepNumber || customerHasBenefitAgreement) return;
        const receiptStepContent = steps.find(filterStepType('Receipt'))?.content;
        return receiptStepContent?.benefitAgreements?.find((agreement) =>
            agreement.benefitAgreement.supportedMakes?.map((a) => a.make).includes(makeId)
        );
    }, [currentStep, customerHasBenefitAgreement, makeId, stepNumber, steps]);

    useEffect(() => {
        if (relevantPromotion?.benefitAgreementPage?.url) {
            setRelevantPromotion(relevantPromotion);
            fetchBenefitAgreementContentAsync();
        }
    }, [fetchBenefitAgreementContentAsync, relevantPromotion, setRelevantPromotion]);

    if (isCompleted) {
        return <ContactInfoSummary inputs={inputs} />;
    }

    return (
        <>
            <Wrapper>
                <BookingInputs inputs={inputs} canValidate={canValidateStep} />
                <Policies
                    showTerms={() => {
                        setShowTerms(true);
                        ui.removeScroll();
                    }}
                    content={content}
                    canValidate={canValidateStep}
                />
            </Wrapper>

            <SimpleModal
                headerText={content.termsModalHeader}
                isVisible={showTerms}
                closeAction={() => {
                    setShowTerms(false);
                    ui.applyScroll();
                }}
            >
                <TermsModalContent dangerouslySetInnerHTML={{ __html: replaceLineBreaks(content.termsContent) }} />
            </SimpleModal>
        </>
    );
};
