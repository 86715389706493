import styled from 'styled-components';
import { device } from '../../../lib/media-query';

const wrapperPadding = 'padding: 0 45px;';
const wrapperPaddingMobile = 'padding: 0 20px;';

export const BorderBox = styled.div`
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    padding: 20px 0;
    background-color: #ffffff;
    /* max-width: 485px; */

    align-self: center;

    @media ${device.tablet} {
        padding: 38px 0;
        align-self: revert;
    }
`;

export const Header = styled.p`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 25px;
    letter-spacing: 0;
    line-height: 30px;
    font-weight: 700;

    margin-bottom: 1.2rem;

    ${wrapperPaddingMobile}
    @media ${device.tablet} {
        ${wrapperPadding}
    }
`;

export const PriceList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

    ${wrapperPaddingMobile}
    @media ${device.tablet} {
        flex-direction: row;
        gap: 40px;

        ${wrapperPadding}
    }
`;

export const PriceWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Price = styled.p`
    color: ${({ theme }) => theme.palette.primary.main};
    font-family: 'Corporate E';
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
`;

export const PriceDescription = styled.p`
    color: #0b0b0b;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 15px;
    letter-spacing: 0;
    line-height: 23px;
`;

export const ButtonList = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1.2rem;

    ${wrapperPaddingMobile}
    @media ${device.tablet} {
        flex-direction: row;
        ${wrapperPadding}
        gap: revert;
    }
`;
