import { computed } from 'easy-peasy';
import { handleCarResources } from './car-resources.helper';
import { getAvailableConfigurations } from './configuration.helper';
import { calculateIsAFromPrice, getCashPrice, getDownPayment, getMonthlyPrice, getSummedEquipmentPrice } from './price.helper';
import { ProductDetailsState } from './product-details.types';
import { getDefaultSelectedTab, isLeasingAvailable } from './selected-tab.helper';

export const productDetailsState = (runtimeModel: Partial<ProductDetailsState> | undefined): ProductDetailsState => {
    const { resources: initialProductResources, matchFound: matchingResourceFound } = runtimeModel?.selectedProduct
        ? handleCarResources(runtimeModel.selectedProduct, runtimeModel.selectedColor)
        : { resources: undefined, matchFound: false };

    const elCarBatteryInfo =
        runtimeModel?.selectedProduct?.fuelType === 'El'
            ? {
                  capacity: runtimeModel?.selectedProduct.batteryCapacity ?? '',
                  range: runtimeModel?.selectedProduct.range ?? 0,
              }
            : undefined;

    let ownershipMode = runtimeModel?.ownershipMode;
    if (!ownershipMode) {
        ownershipMode = runtimeModel?.selectedProduct?.vehicleType === 'Van' && isLeasingAvailable(runtimeModel.selectedProduct) ? 'Leasing' : 'Køb';
    }

    return {
        carFamily: runtimeModel?.carFamily ?? [],
        allConfigurations: runtimeModel?.allConfigurations ?? {
            carTypes: [],
            fuelTypes: [],
            transmissionTypes: [],
            tractionWheels: [],
            batteryInfoList: [],
            heightTypes: [],
            lengthTypes: [],
            equipmentLines: [],
        },
        selectedProduct: runtimeModel?.selectedProduct,
        selectedCarType: runtimeModel?.selectedProduct?.carType,
        selectedFuelType: runtimeModel?.selectedProduct?.fuelType,
        selectedTransmissionType: runtimeModel?.selectedProduct?.transmissionType,
        selectedTractionWheels: runtimeModel?.selectedProduct?.tractionWheels,
        selectedLengthType: runtimeModel?.selectedProduct?.lengthType ?? '',
        selectedHeightType: runtimeModel?.selectedProduct?.heightType ?? '',
        selectedEquipmentLine: runtimeModel?.selectedProduct?.equipmentLine ?? '',
        selectedColor: runtimeModel?.selectedColor,
        queryColor: runtimeModel?.queryColor,
        selectedBatteryInformation: elCarBatteryInfo,

        availableConfiguration: getAvailableConfigurations({
            allConfigurations: runtimeModel?.allConfigurations,
            carFamily: runtimeModel?.carFamily,
            selectedProduct: runtimeModel?.selectedProduct,
            selectedColor: runtimeModel?.selectedColor,
        }),

        matchedResources: initialProductResources ?? [],
        colorAndResourceMatched: matchingResourceFound,
        priceDifferenceAcknowledged: false,

        selectedStartupDate: undefined,
        productUpdateFinished: false,
        includedEquipmentText: runtimeModel?.includedEquipmentText ?? 'Inkluderet',

        // #region Computed prices based on configuration
        optionalEquipmentPriceSum: computed((state) =>
            getSummedEquipmentPrice(state.selectedTab, state.selectedProduct?.optionalEquipment[state.selectedTab])
        ),
        extraEquipmentPriceSum: computed((state) =>
            getSummedEquipmentPrice(state.selectedTab, state.selectedProduct?.extraEquipment[state.selectedTab])
        ),
        extraEquipmentPackagePriceSum: computed((state) =>
            getSummedEquipmentPrice(state.selectedTab, state.selectedProduct?.extraEquipmentPackages[state.selectedTab])
        ),
        isAFromPrice: computed((state) => {
            const eq = calculateIsAFromPrice(state.selectedTab, state.selectedProduct?.extraEquipment[state.selectedTab]);
            const eqp = calculateIsAFromPrice(state.selectedTab, state.selectedProduct?.extraEquipmentPackages[state.selectedTab]);

            return eq || eqp;
        }),

        computedMonthlyPrice: computed((state) => {
            if (!state.selectedProduct) {
                return 0;
            }

            return getMonthlyPrice(
                state.selectedProduct.purchaseTypes,
                state.ownershipMode,
                state.selectedTab,
                state.selectedColor,
                state.extraEquipmentPackagePriceSum + state.extraEquipmentPriceSum + state.optionalEquipmentPriceSum
            );
        }),
        computedDownPayment: computed((state) => {
            if (!state.selectedProduct) {
                return 0;
            }
            return getDownPayment(state.selectedProduct.purchaseTypes, state.ownershipMode, state.selectedTab);
        }),
        computedCashPrice: computed((state) => {
            if (!state.selectedProduct) {
                return 0;
            }
            return getCashPrice(
                state.selectedProduct.purchaseTypes,
                state.ownershipMode,
                state.selectedTab,
                state.selectedColor,
                state.extraEquipmentPackagePriceSum + state.extraEquipmentPriceSum + state.optionalEquipmentPriceSum
            );
        }),
        computedColorPrice: computed((state) => {
            if (!state.selectedProduct) {
                return 0;
            }
            switch (state.selectedTab) {
                case 'Car Privat Leasing':
                    return state.selectedColor?.monthlyPrivateLeasingPrice ?? 0;
                case 'Car Finansiering':
                    return state.selectedColor?.monthlyFinancialLeasingPrice ?? 0;
                case 'Car Kontant':
                    return state.selectedColor?.cashPrice ?? 0;
                default:
                    return 0;
            }
        }),
        // #endregion

        ownershipMode: ownershipMode,
        selectedTab: runtimeModel?.selectedTab ?? getDefaultSelectedTab(ownershipMode, runtimeModel?.selectedProduct),
        relatedVehicles: runtimeModel?.relatedVehicles ?? [],
        globalPlpSettings: runtimeModel?.globalPlpSettings ?? undefined,
        financialPurchaseDownPaymentPercentage: 20, // Default value is 20%
        dealershipHasTestDrives: true,
        dealershipLocationLabel: undefined,
    };
};
