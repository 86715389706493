import { FC, useEffect, useMemo } from 'react';
import { ReceiptPlaceholder } from '../../../../../lib/api/models/umbraco';
import { BookingContactInfoStore } from '../../../../../lib/state/booking-contact-info';
import { BookingFormStore } from '../../../../../lib/state/booking-form';
import { generateFormId } from '../../../../../lib/state/booking-form/booking-form.helpers';
import { ReepayStore } from '../../../../../lib/state/reepay-form/reepay-form.store';
import { MEDIA_URL } from '../../../../../utils/environment-constants';
import { formInputValue } from '../../../../../utils/helpers';
import { CtaHandler } from '../../../../plus-sites-shared/content-handlers';
import { SvgIcon } from '../../../../shared/svg-icon';
import {
    BannerCTAContainer,
    BannerHeader,
    DesktopBannerImage,
    MobileBannerImage,
    PromotionContent,
    PromotionWrapper,
    SellingPoint,
    SellingPointsList,
} from './benefit-agreement-promotion.styled';
import { BookingReceiptStore } from '../../../../../lib/state/booking-receipt';

export const BenefitAgreementPromotionBanner: FC = () => {
    const { setCustomerInfo, setReceiptUrl } = ReepayStore.useStoreActions((actions) => actions);
    const mobile = BookingFormStore.useStoreState((state) => {
        const mobileInput = state.inputs.find(({ id }) => id === generateFormId('ContactInfo', 'Telephone number'));
        return mobileInput ? formInputValue(mobileInput) : null;
    });

    const contactInfo = BookingContactInfoStore.useStoreState((state) => state.contactInfo);
    const relevantPromotion = BookingReceiptStore.useStoreState((state) => state.relevantPromotion);

    const [promotionHeader, benefits] = useMemo(() => {
        const promotionHeader = relevantPromotion?.header.replace(
            ReceiptPlaceholder.AgreementName,
            relevantPromotion?.benefitAgreement.agreementName
        );
        const benefits = relevantPromotion?.benefitAgreement.benefits.slice(0, 3);
        if (relevantPromotion) setReceiptUrl(relevantPromotion.receiptUrl);

        return [promotionHeader, benefits];
    }, [relevantPromotion, setReceiptUrl]);

    useEffect(() => {
        if (!relevantPromotion) return;

        setCustomerInfo({
            ...contactInfo,
            phone: mobile ?? '',
            address: contactInfo.streetName,
            company: '',
        });
    }, [contactInfo, mobile, relevantPromotion, setCustomerInfo]);

    if (!relevantPromotion) return null;

    return (
        <PromotionWrapper>
            <DesktopBannerImage src={`${MEDIA_URL}/${relevantPromotion.image.src}`}></DesktopBannerImage>
            <MobileBannerImage src={`${MEDIA_URL}/${relevantPromotion.mobileImage?.src ?? relevantPromotion.image.src}`}></MobileBannerImage>
            <PromotionContent>
                <BannerHeader>{promotionHeader}</BannerHeader>
                <SellingPointsList>
                    {benefits &&
                        benefits.map((benefit) => (
                            <SellingPoint key={benefit}>
                                <SvgIcon iconName="checkmark" color="lightGray"></SvgIcon>
                                <span>{benefit}</span>
                            </SellingPoint>
                        ))}
                </SellingPointsList>
                <BannerCTAContainer>{relevantPromotion.ctas && <CtaHandler ctas={relevantPromotion.ctas}></CtaHandler>}</BannerCTAContainer>
            </PromotionContent>
        </PromotionWrapper>
    );
};
