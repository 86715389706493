import { FC, useEffect, useState } from 'react';
import { theme } from '../../../../themes/default-theme';
import { BlackPinUrl } from '../../helpers/organization-consts';

export const SimpleMarker: FC<google.maps.MarkerOptions> = (options) => {
    const [marker, setMarker] = useState<google.maps.Marker>();

    useEffect(() => {
        if (!marker) {
            setMarker(
                new google.maps.Marker({
                    label: {
                        text: 'A',
                        color: 'white',
                        fontFamily: theme.typography.fontFamily,
                        fontSize: '15px',
                        fontWeight: '500',
                    },
                    icon: {
                        url: `/${BlackPinUrl}`,
                        labelOrigin: new google.maps.Point(14.5, 17),
                        scaledSize: new google.maps.Size(30, 45),
                    },
                })
            );
        }

        // remove marker from map on unmount
        return () => {
            if (marker) {
                marker.setMap(null);
            }
        };
    }, [marker]);

    useEffect(() => {
        if (marker) {
            marker.setOptions(options);
        }
    }, [marker, options]);

    return null;
};
