import { VFC } from 'react';
import { sharedTypes } from '../../../lib/api/models/umbraco';
import { MEDIA_URL } from '../../../utils/environment-constants';
import { sanitizeMarkup } from '../../../utils/helpers';
import { Button } from '../button';
import {
    StyledSimpleTextAndImage,
    StyledCenteredBlock,
    Header,
    Description,
    ContentColumn,
    ImageColumn,
    StyledImage,
} from './simple-text-and-image.styled';

type SimpleTextAndImageSpotProps = { header: string; description: string; ctaText: string; ctaAction?: () => void; image: sharedTypes.Image };

export const SimpleTextAndImageSpot: VFC<SimpleTextAndImageSpotProps> = ({ header, description, ctaText, ctaAction, image }) => {
    return (
        <StyledCenteredBlock>
            <StyledSimpleTextAndImage>
                <ContentColumn>
                    <Header>{header}</Header>
                    <Description {...sanitizeMarkup(description)} />
                    {ctaAction ? (
                        <Button variant="primary" onClick={() => ctaAction()}>
                            <p>{ctaText}</p>
                        </Button>
                    ) : null}
                </ContentColumn>

                <ImageColumn>
                    <StyledImage src={MEDIA_URL + image?.src} alt={image?.name} />
                </ImageColumn>
            </StyledSimpleTextAndImage>
        </StyledCenteredBlock>
    );
};
