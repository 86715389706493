import styled from 'styled-components';
import { Size } from '../../../lib/ui-types';

function getFontSize(fontSize?: Size) {
    switch (fontSize) {
        case 'small':
            return '10px';
        case 'medium':
            return '12px';
        case 'large':
            return '16px';

        default:
            return '14px';
    }
}

export const LargeLink = styled.a`
    color: #fcfcfc;
    display: block;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 6px;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

export const StyledCtaText = styled.p<{ fontSize?: Size }>`
    font-size: ${(props) => getFontSize(props.fontSize)};
`;
