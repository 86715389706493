import { FC } from 'react';
import { StyledSpecsRibbonButton, StyledSpecsRibbonButtonWrapper } from './specs-ribbon-button.styled';

export type SpecsRibbonButtonProps = {
    className?: string;
    label: string;
    onClick: () => void;
};

export const SpecsRibbonButton: FC<SpecsRibbonButtonProps> = (props) => {
    return (
        <StyledSpecsRibbonButtonWrapper className={props.className}>
            <StyledSpecsRibbonButton onClick={props.onClick} type="button">
                {props.label}
            </StyledSpecsRibbonButton>
        </StyledSpecsRibbonButtonWrapper>
    );
};
