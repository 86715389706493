import { VFC } from 'react';
import { hesselViewModels } from '../../../../lib/view-models';
import { Button, CheckBox } from '../../../shared';
import {
    StyledEquipmentSection,
    StyledHeader,
    StyledPrice,
    StyledButtonText,
    StyledEquipmentText,
    AddOnInfoWrapper,
    CheckboxAndReadMore,
} from './equipment-card.styled';
import { useEquipmentData } from '../../hooks/use-equipment-data';

export type EquipmentSectionProps = {
    className?: string;
    header?: string;
    equipmentList: Array<hesselViewModels.Equipment>;
    equipmentPackage?: Array<hesselViewModels.EquipmentPackage>;
    onEquipmentSelect: (id: string, isSelected: boolean) => void;
    onEquipmentPackageSelect?: (id: string, isSelected: boolean) => void;
    showMoreInfoAboutEquipment: (args: hesselViewModels.Equipment | hesselViewModels.EquipmentPackage, isPackage: boolean) => void;
    equipmentIsIncluded: boolean;
    includedEquipmentText: string;
};

export const EquipmentSection: VFC<EquipmentSectionProps> = ({
    className,
    header,
    equipmentList,
    equipmentPackage,
    onEquipmentSelect,
    onEquipmentPackageSelect,
    showMoreInfoAboutEquipment,
    equipmentIsIncluded,
    includedEquipmentText,
}) => {
    const { getEquipmentPrice } = useEquipmentData();

    return (
        <StyledEquipmentSection className={className}>
            {header ? <StyledHeader>{header}</StyledHeader> : null}

            {equipmentList.map((equipment) => {
                const equipmentPrice = getEquipmentPrice(equipment);

                return (
                    <AddOnInfoWrapper key={`checkbox-and-action-${equipment.id}`}>
                        <CheckboxAndReadMore>
                            <CheckBox
                                id={equipment.id}
                                onChange={(e) => onEquipmentSelect(equipment.id, e)}
                                value={equipment.id}
                                checked={!!equipment.isSelected || equipmentIsIncluded}
                                disabled={equipmentIsIncluded}
                            >
                                <StyledEquipmentText>{equipment.name}</StyledEquipmentText>
                            </CheckBox>
                            {equipment.description && equipment.description.length > 0 ? (
                                <Button variant="link" onClick={() => showMoreInfoAboutEquipment(equipment, false)}>
                                    <StyledButtonText>Læs mere</StyledButtonText>
                                </Button>
                            ) : null}
                        </CheckboxAndReadMore>

                        <StyledPrice>{equipmentIsIncluded ? includedEquipmentText : `${equipmentPrice.formatted}`}</StyledPrice>
                    </AddOnInfoWrapper>
                );
            })}

            {equipmentPackage?.map((eqPackage) => {
                const equipmentPackagePrice = getEquipmentPrice(eqPackage);

                return (
                    <AddOnInfoWrapper key={`equipment-package-checkbox-and-action-${eqPackage.id}`}>
                        <CheckboxAndReadMore>
                            <CheckBox
                                id={`${eqPackage.id}-${eqPackage.packageIdValue}`}
                                onChange={(e) => onEquipmentPackageSelect?.(eqPackage.id, e)}
                                value={eqPackage.id}
                                checked={!!eqPackage.isSelected || equipmentIsIncluded}
                                disabled={equipmentIsIncluded}
                            >
                                <StyledEquipmentText>{eqPackage.name}</StyledEquipmentText>
                            </CheckBox>
                            {(eqPackage.description && eqPackage.description.length > 0) || eqPackage.equipment?.length > 0 ? (
                                <Button variant="link" onClick={() => showMoreInfoAboutEquipment(eqPackage, true)}>
                                    <StyledButtonText>Læs mere</StyledButtonText>
                                </Button>
                            ) : null}
                        </CheckboxAndReadMore>
                        <StyledPrice>{equipmentIsIncluded ? includedEquipmentText : equipmentPackagePrice.formatted}</StyledPrice>
                    </AddOnInfoWrapper>
                );
            })}
        </StyledEquipmentSection>
    );
};
