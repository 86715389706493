import styled from 'styled-components';

type StyledEnergyRatingProps = {
    backgroundColor: string;
};

export const StyledEnergyRating = styled.figure<StyledEnergyRatingProps>`
    background: ${(props) => props.backgroundColor};
    box-sizing: border-box;
    color: ${(props) => props.theme.palette.text.light};
    cursor: default;
    display: inline-block;
    font-family: ${(props) => props.theme.typography.fontFamily};
    line-height: 1.6em;
    min-width: 2.7em;
    overflow: visible;
    padding: 0 0 0 0.4em;
    position: relative;
    white-space: nowrap;

    &::after {
        background: ${(props) => props.backgroundColor};
        border-radius: 0 5px 0 0;
        content: '';
        display: block;
        height: 1.13em;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(50%, -50%) rotate(45deg);
        width: 1.13em;
        z-index: 1;
    }
`;

export const StyledEnergyRatingLabel = styled.span`
    position: relative;
    z-index: 2;
`;
