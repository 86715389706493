import { animated } from 'react-spring';
import styled from 'styled-components';
import { Button } from '../../../shared';

export const StyledStickySummary = styled(animated.div)`
    display: flex;
    position: fixed;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    isolation: isolate;
    z-index: 22;
    justify-content: space-between;
    align-items: center;
    padding: 22px 40px;

    box-shadow: ${({ theme }) => theme.shadows.large};

    @media (max-width: 800px) {
        display: none;
    }
`;

export const CarInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const BrandTitle = styled.p`
    color: #0b0b0b;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 14px;
    letter-spacing: 0;
`;

export const VariantTitle = styled.p`
    color: #0b0b0b;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 25px;
    font-weight: 600;
    letter-spacing: 0;
`;

export const FinancialInfoWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 19px;
`;

export const Prices = styled.div`
    display: flex;
    gap: 76px;
`;
export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const Title = styled.p`
    color: #0b0b0b;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;
`;

export const Value = styled.p`
    color: #636363;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 12px;
    letter-spacing: 0;
    font-weight: normal;
    line-height: 18px;
`;

export const DownPaymentText = styled.p``;

export const StyledButton = styled(Button)`
    min-width: 150px;
    max-width: 350px;
    align-self: flex-end;
`;

export const ButtonText = styled.p``;
