import { useState } from 'react';
import { DispatchMethod } from '../gtm-tracking.types';
import { ShopProductDetails } from '../../lib/api/models/shop';
import { CartObject, CartOrderline } from '../../lib/api/models/shop/cart';
import { ReepaySubscriptionResponse } from '../../lib/api/models/reepay';

const shopTrackingConsts = {
    type: 'Lifestyle/Ekstraudstyr Shop',
};

export type ShopTrackingEvents = {
    pdpView: (item: GtmTrackingProduct) => void;
    clearEcommerceObject: () => void;
    addToCart: (item: GtmTrackingProduct, quantity: number) => void;
    viewCart: (cart: CartObject) => void;
    startCheckout: (cart: CartObject) => void;
    deliveryStep: () => void;
    setShipping: (cart: CartObject, shippingMethod: string) => void;
    setPaymentMethod: (cart: CartObject, paymentMethod: string) => void;
    completePurchase: (cart: CartObject, reepayData: ReepaySubscriptionResponse) => void;
};

type GtmTrackingProduct = Pick<ShopProductDetails, 'id' | 'categories' | 'name' | 'brands' | 'price' | 'color'>;

const useShopEvents = (dispatch: DispatchMethod): ShopTrackingEvents => {
    return useState({
        pdpView: (item: GtmTrackingProduct) => {
            dispatch({
                event: 'view_item',
                type: shopTrackingConsts.type,
                ecommerce: {
                    currency: 'DKK',
                    items: [
                        {
                            ...getProductObject(item),
                        },
                    ],
                },
            });
        },
        clearEcommerceObject: () => {
            dispatch({
                event: '',
                type: '',
                ecommerce: null,
            });
        },
        addToCart: (item: GtmTrackingProduct, quantity: number) => {
            dispatch({
                event: 'add_to_cart',
                type: shopTrackingConsts.type,
                ecommerce: {
                    currency: 'DKK',
                    items: [
                        {
                            ...getProductObject(item),
                            quantity,
                        },
                    ],
                },
            });
        },
        viewCart: (cart: CartObject) => {
            dispatch({
                event: 'view_cart',
                type: shopTrackingConsts.type,
                ecommerce: {
                    currency: 'DKK',
                    items: [...cart.lineItems.map(getProductObjectsFromCartline)],
                },
            });
        },
        startCheckout: (cart: CartObject) => {
            dispatch({
                event: 'begin_checkout',
                type: shopTrackingConsts.type,
                ecommerce: {
                    items: [...cart.lineItems.map(getProductObjectsFromCartline)],
                    currency: 'DKK',
                },
            });
        },
        deliveryStep: () => {
            dispatch({
                event: 'checkout_step_2',
                type: shopTrackingConsts.type,
            });
        },
        setShipping: (cart: CartObject, shippingMethod: string) => {
            dispatch({
                event: 'add_shipping_info',
                type: shopTrackingConsts.type,
                ecommerce: {
                    shipping_tier: shippingMethod,
                    items: [...cart.lineItems.map(getProductObjectsFromCartline)],
                    currency: 'DKK',
                },
            });
        },
        setPaymentMethod: (cart: CartObject, paymentMethod: string) => {
            dispatch({
                event: 'add_payment_info',
                type: shopTrackingConsts.type,
                ecommerce: {
                    items: [...cart.lineItems.map(getProductObjectsFromCartline)],
                    currency: 'DKK',
                    payment_info: paymentMethod,
                },
            });
        },
        completePurchase: (cart: CartObject, reepayData: ReepaySubscriptionResponse) => {
            dispatch({
                event: 'purchase',
                type: shopTrackingConsts.type,
                ecommerce: {
                    items: [...cart.lineItems.map(getProductObjectsFromCartline)],
                    currency: 'DKK',
                    transaction_id: reepayData.id,
                    value: cart.totalPrice,
                    tax: cart.totalTax,
                    shipping: cart.delivery?.price ?? 0,
                    transaction_discount: cart.discounts.reduce((acc, curr) => acc + curr.discountAmount, 0),
                    ...getCouponCodes(cart),
                },
            });
        },
    })[0];
};

const getProductCategories = (item: GtmTrackingProduct) => {
    const acc: { [key: string]: unknown } = {};
    item.categories.forEach((x, idx) => {
        const prop = `item_category${idx > 0 ? `${idx + 1}` : ''}`;
        acc[prop] = x;
    });
    return acc;
};

const getProductBrands = (item: GtmTrackingProduct) => {
    const acc: { [key: string]: unknown } = {};
    item.brands.forEach((x, idx) => {
        const prop = `item_brand${idx > 0 ? `${idx + 1}` : ''}`;
        acc[prop] = x;
    });
    return acc;
};

const getProductObject = (item: GtmTrackingProduct) => {
    return {
        item_id: item.id,
        item_name: item.name,
        price: item.price,
        ...getProductBrands(item),
        ...getProductCategories(item),
    };
};

const getProductObjectsFromCartline = (orderline: CartOrderline) => {
    return {
        item_id: orderline.productId,
        item_name: orderline.productName,
        price: orderline.price,
        quantity: orderline.quantity,
        item_variant: orderline.color,
    };
};

const getCouponCodes = (cart: CartObject) => {
    const acc: { [key: string]: unknown } = {};
    cart.discounts.forEach((x, idx) => {
        const prop = `coupon${idx > 0 ? `${idx + 1}` : ''}`;
        acc[prop] = x.couponCode;
    });
    return acc;
};

export const trackShop = (dispatch: DispatchMethod): (() => ShopTrackingEvents) => {
    return () => useShopEvents(dispatch);
};
