import { useCallback } from 'react';
import { CarColor } from '../../../lib/api/models/hessel-api/hire';
import { hesselViewModels } from '../../../lib/view-models';
import { getFormattedValue } from '../../../utils/helpers';
import { Abbreviations } from '../../../constants/units-and-abbreviations';

type FilterPossibilities =
    | 'Car Type'
    | 'Fuel Type'
    | 'Transmission Type'
    | 'Traction Wheels'
    | 'Battery Capacity'
    | 'Height Type'
    | 'Length Type'
    | 'Equipment Line';

type PriceDifference = {
    prettified: string;
    raw: number;
};

type ConfigurationUtilities = {
    getPriceDifference: (configValue: string, filterAfter: FilterPossibilities, isMonthlyPrice?: boolean) => PriceDifference;
};

const getPriceDiffData = (comparedValue?: number, selectedValue?: number, isMonthlyPrice?: boolean): PriceDifference => {
    const priceDiff = (comparedValue || 0) - (selectedValue || 0);

    return {
        prettified: `${priceDiff < 0 ? '-' : '+'} ${getFormattedValue(Math.abs(priceDiff))} ${
            isMonthlyPrice ? Abbreviations.KR_SLASH_MD : Abbreviations.KR
        }`,
        raw: priceDiff,
    };
};

export const useConfigurationUtility = (
    carFamily: hesselViewModels.ProductDetails[],
    ownershipMode: hesselViewModels.OwnershipMode,
    selectedTab: hesselViewModels.OwnershipTab,
    selectedProduct?: hesselViewModels.ProductDetails,
    selectedColor?: CarColor
): ConfigurationUtilities => {
    const getPriceDifference = useCallback(
        (configValue: string, filterAfter: FilterPossibilities, isMonthlyPrice?: boolean): PriceDifference => {
            if (!selectedProduct) {
                return {
                    prettified: '',
                    raw: -1,
                };
            }

            // Find the car in the car family that matches the configValue
            const car = carFamily.find((x) => {
                if (filterAfter === 'Car Type' ? configValue !== x.carType : x.carType !== selectedProduct.carType) return false;
                if (filterAfter === 'Fuel Type' ? configValue !== x.fuelType : x.fuelType !== selectedProduct.fuelType) return false;
                if (
                    filterAfter === 'Transmission Type' ? configValue !== x.transmissionType : x.transmissionType !== selectedProduct.transmissionType
                )
                    return false;
                if (filterAfter === 'Traction Wheels' ? configValue !== x.tractionWheels : x.tractionWheels !== selectedProduct.tractionWheels)
                    return false;
                if (filterAfter === 'Battery Capacity' ? configValue !== x.batteryCapacity : x.batteryCapacity !== selectedProduct.batteryCapacity)
                    return false;
                if (filterAfter === 'Height Type' && configValue !== x.heightType) return false;
                if (filterAfter === 'Length Type' && configValue !== x.lengthType) return false;
                if (filterAfter === 'Equipment Line' && configValue !== x.equipmentLine) return false;
                if (!x.colors.some((x) => x.id === selectedColor?.id)) return false;

                return true;
            });

            if (!car) {
                return {
                    prettified: '+0 kr.',
                    raw: 0,
                };
            }

            if (ownershipMode === 'Køb') {
                if (selectedTab.includes('Finansiering')) {
                    return getPriceDiffData(car?.purchaseTypes?.financing.baseMonthlyPrice, selectedProduct.purchaseTypes.financing.baseMonthlyPrice);
                } else if (selectedTab.includes('Kontant')) {
                    return getPriceDiffData(car?.purchaseTypes?.cash.basePrice, selectedProduct.purchaseTypes.cash?.basePrice);
                }
            } else if (ownershipMode === 'Leasing') {
                if (selectedTab.includes('Operationel Leasing')) {
                    return getPriceDiffData(
                        car?.purchaseTypes?.operationalLeasing.baseMonthlyPrice,
                        selectedProduct.purchaseTypes.operationalLeasing.baseMonthlyPrice,
                        isMonthlyPrice
                    );
                } else if (selectedTab.includes('Finansiel Leasing')) {
                    return getPriceDiffData(
                        car?.purchaseTypes?.financialLeasing.baseMonthlyPrice,
                        selectedProduct.purchaseTypes.financialLeasing.baseMonthlyPrice,
                        isMonthlyPrice
                    );
                }
            }

            if (selectedTab.includes('HiRE')) {
                return getPriceDiffData(
                    car?.purchaseTypes?.hire.baseMonthlyPrice,
                    selectedProduct.purchaseTypes.hire.baseMonthlyPrice,
                    isMonthlyPrice
                );
            }

            if (selectedTab === 'Car Privat Leasing') {
                return getPriceDiffData(
                    car?.purchaseTypes?.privateLeasing.baseMonthlyPrice,
                    selectedProduct.purchaseTypes.privateLeasing.baseMonthlyPrice,
                    isMonthlyPrice
                );
            }

            return {
                prettified: '+0 kr.',
                raw: 0,
            };
        },
        [carFamily, selectedColor?.id, selectedProduct, selectedTab, ownershipMode]
    );

    return { getPriceDifference };
};
