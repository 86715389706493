import React, { VFC } from 'react';
import { FormsTrackingStrategy } from '../../../../lib/api/models/umbraco';
import { FormsSpot } from '../../../../lib/api/models/umbraco/content-spot';
import { sanitizeMarkup } from '../../../../utils/helpers';
import { SidePanel } from '../../../shared/modals/side-panel';
import { SidePanelLayout } from '../../../shared/modals/side-panel-layout/side-panel-layout.component';
import { VanContactUsModalBody, HeaderSection, SubHeader, Header } from './van-contact-us-modal.styled';

export type VanContactUsModalProps = {
    form: FormsSpot;
    trackingStrategy?: FormsTrackingStrategy;
    visible: boolean;
    onClose: () => void;
    pageId: string;
    dealershipId?: string;
};

const VanContactUsModalMemo: VFC<VanContactUsModalProps> = ({ form, visible, onClose, pageId, dealershipId }) => {
    return (
        <SidePanel cancelAction={onClose} isVisible={visible}>
            <SidePanelLayout closeSidePanel={onClose}>
                <HeaderSection>
                    <Header>{form.formHeader}</Header>
                    <SubHeader {...sanitizeMarkup(form.formSubtext)} />
                </HeaderSection>
                <VanContactUsModalBody
                    form={form.form}
                    pageId={pageId}
                    trackingStrategy={form.trackingStrategy?.[0]}
                    specialProps={dealershipId ? { dealershipId: dealershipId } : undefined}
                />
            </SidePanelLayout>
        </SidePanel>
    );
};

export const VanContactUsModal = React.memo(VanContactUsModalMemo);
