import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { FilterUpdateNotification, ProductListApiResponse, ProductListRequest } from '../../lib/api/models/hessel-api/hire';
import { BaseFilterSetup, PaymentType, ProductListConfig } from '../../lib/api/models/umbraco/content-spot';
import { FacetGroup, MarketingProductCard, ProductCard, ProductCardDetails, SendRequestCard } from '../../lib/view-models/vehicle';
import {
    formatTileText,
    getActiveFiltersFromQuery,
    getPageFromUrl,
    getSortingFromUrl,
    pushActiveFiltersToUrl,
    removeLoadMoreKeys,
    validateFilterTile,
} from '../../utils/helpers';
import { hesselApiTypes } from '../../lib/api/models/hessel-api';
import { getProductList } from '../../lib/api/vehicle/hessel-vehicle-plp-api';
import { mapCarDetailsToProductCard } from '../../lib/mappers/vehicle/product-card.mapper';
import { createFacetArray } from '../../lib/mappers/vehicle/product-list.mapper';
import { useAvailabilityValidator } from '../use-availability-validator';
import { DepartmentFilterResponse } from '../../lib/api/organization/hessel-dealership-api';
import { SortingLabel, UmbracoForm } from '../../lib/api/models/umbraco';
import { useCmsDealerships } from '../use-cms-dealerships';
import { getFilterRenderType } from '../../components/vehicle/vehicle-product-list/vehicle-product-list-filter/filter.helpers';
import {
    DefaultBaseFilterSetup,
    AvailabilityFilterKey,
    OrderAvailabilityKey,
    InStockAvailabilityKey,
    UsedAvailabilityKey,
    EngrosVanAvailabilityKey,
    DemoAvailabilityKey,
    PaymentTypeFilterKey,
    HirePriceFilterKey,
    PrivateLeasingPriceFilterKey,
    VanLeaseingPriceFilterKey,
    CashPriceFilterKey,
    FinancingPriceFilterKey,
    ModelTitleFilterKey,
    FamilyFilterKey,
} from '../../constants/site-consts';

export type filterTile = {
    label: string;
    values: string[];
    filterUpdateNotifications: FilterUpdateNotification[];
    valid: boolean;
};

type VehicleProductListConfig = {
    resultsText: string;
    paymentType: PaymentType | undefined;
    selectedSortingValue: string;
    isLoading: boolean;
    validAvailabilities: FacetGroup | undefined;
    anyResults: boolean;
    loadMoreClicked: MutableRefObject<boolean>;
    filterUpdate: (updates: FilterUpdateNotification[]) => void;
    productListData: ProductListApiResponse | null;
    allProductCards: ProductCard[];
    maximumSubfacetDepth: number;
    facets: FacetGroup[];
    productListItems: ProductCard[] | null;
    filterTiles: filterTile[];
    clearFiltersText: string;
    resetFilters: () => void;
    sortingOptions: SortingLabel[];
    departmentOptions: DepartmentFilterResponse[];
    productListRequest: hesselApiTypes.ProductListRequest;
    noResultsContactForm: {
        form: UmbracoForm | undefined;
        header: string;
        subText: string;
    };
    activeFilters: string[];
    updateProductListRequest: (request: ProductListRequest) => void;
    activeFiltersLength: number;
    baseFilterSetup: BaseFilterSetup;
    loadMore: (from: number, take: number) => void;
};

type Params = {
    plpConfig: ProductListConfig;
    productListData: ProductListApiResponse | null;
    initialFacetsArray: FacetGroup[] | undefined;
    initialActiveFilters: string | string[];
    initialProducts: ProductCard[] | null;
};

export const useHireProductList = (params: Params): VehicleProductListConfig => {
    const [firstRender, setFirstRender] = useState(true);
    const [facetsArray, setFacetsArray] = useState<FacetGroup[]>(params.initialFacetsArray ?? []);
    const [activeFilters, setActiveFilters] = useState<string[]>([
        ...getActiveFiltersFromQuery(params.initialActiveFilters ?? [], params.initialFacetsArray ?? []),
    ]);
    const [productListRequest, setProductListRequest] = useState<hesselApiTypes.ProductListRequest>({
        from: 0,
        take: params.plpConfig.globalPlpSettings.paginationSize,
        configurationId: params.plpConfig.filterConfiguration.id,
        filters: activeFilters,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [productListData, setProductListData] = useState<ProductListApiResponse | null>(params.productListData ?? null);
    const [productListItems, setProductListItems] = useState<ProductCard[] | null>(params.initialProducts ?? null);

    const updateProductListRequest = (request: hesselApiTypes.ProductListRequest) => {
        setProductListRequest(request);
    };

    const loadMoreClicked = useRef<boolean>(false);

    const baseFilterSetup: BaseFilterSetup = useMemo(() => {
        return params.plpConfig.baseFilterSetup && params.plpConfig.baseFilterSetup.length > 0
            ? params.plpConfig.baseFilterSetup[0]
            : DefaultBaseFilterSetup;
    }, [params.plpConfig.baseFilterSetup]);

    const selectedAvailability = useMemo(
        () => facetsArray.find((x) => x.key === AvailabilityFilterKey)?.facetOptions.find((x) => x.selected),
        [facetsArray]
    );

    const resultsText = useMemo(() => {
        if (params.productListData) {
            let availabilityKey = baseFilterSetup.defaultAvailability.toString();
            if (selectedAvailability) {
                availabilityKey = selectedAvailability.key;
            } else if (params.productListData.vehicles.length > 0) {
                availabilityKey = params.productListData.vehicles[0].availability;
            }
            if (availabilityKey === OrderAvailabilityKey) {
                return params.plpConfig.carsToOrderFoundText.length > 0
                    ? params.plpConfig.carsToOrderFoundText
                    : params.plpConfig.globalPlpSettings.carsToOrderFoundText;
            } else if (availabilityKey === InStockAvailabilityKey) {
                return params.plpConfig.carsInStockFoundText.length > 0
                    ? params.plpConfig.carsInStockFoundText
                    : params.plpConfig.globalPlpSettings.carsInStockFoundText;
            } else if (availabilityKey === UsedAvailabilityKey) {
                return params.plpConfig.brugteBilerFoundText.length > 0
                    ? params.plpConfig.brugteBilerFoundText
                    : params.plpConfig.globalPlpSettings.brugteBilerFoundText;
            } else if (availabilityKey === EngrosVanAvailabilityKey) {
                return params.plpConfig.engrosBilerFoundText.length > 0
                    ? params.plpConfig.engrosBilerFoundText
                    : params.plpConfig.globalPlpSettings.engrosBilerFoundText;
            } else if (availabilityKey === DemoAvailabilityKey) {
                return params.plpConfig.demobilerFoundText.length > 0
                    ? params.plpConfig.demobilerFoundText
                    : params.plpConfig.globalPlpSettings.demobilerFoundText;
            }
        }
        return '';
    }, [params.plpConfig, params.productListData, selectedAvailability, baseFilterSetup]);

    const paymentType = useMemo(() => {
        if (baseFilterSetup.alias === 'carBaseFilter' && baseFilterSetup.showAsCompanyCars) return 'Taxation';
        const ownershipToggleFacet = facetsArray.find((x) => x.key.startsWith(PaymentTypeFilterKey));
        if (ownershipToggleFacet) {
            const selected = ownershipToggleFacet.facetOptions.find((x) => x.selected);
            if (selected) {
                const splitValue = selected.filterQueryValue.split('__');
                if (splitValue.length === 2) {
                    return splitValue[1] as PaymentType;
                }
            }
        }
        return baseFilterSetup.defaultPaymentType ?? DefaultBaseFilterSetup.defaultPaymentType;
    }, [baseFilterSetup, facetsArray]);

    const selectedSortingValue = useMemo(() => {
        if (productListData) {
            return productListData.selectedSorting[0];
        }
        return 'Vælg sortering';
    }, [productListData]);

    const filterUpdate = (updates: FilterUpdateNotification[]) => {
        let accumulator = activeFilters;
        updates.forEach((update) => {
            if (update.action === 'Add') {
                accumulator = [...accumulator, update.query];
            } else if (update.action === 'Remove') {
                accumulator = [...accumulator.filter((x) => x !== update.query)];
            } else if (update.action === 'Replace') {
                const baseQuery = update.query.split('__')[0];
                accumulator = [...accumulator.filter((x) => !x.startsWith(baseQuery)), update.query];
            } else if (update.action === 'Remove all in key') {
                const baseQuery = update.query.split('__')[0];
                accumulator = [...accumulator.filter((x) => !x.startsWith(baseQuery))];
            }
        });

        //Ensure that we always have a payment type, and exclude filters that are not relevant for payment type
        let paymentInAccumulator = accumulator.find((x) => x.startsWith(PaymentTypeFilterKey))?.split('__')[1];
        if (!paymentInAccumulator) {
            accumulator = [...accumulator, `${PaymentTypeFilterKey}__${paymentType}`];
            paymentInAccumulator = paymentType;
        }
        if (paymentInAccumulator === 'Buy') {
            accumulator = [
                ...accumulator.filter(
                    (x) =>
                        !x.startsWith(HirePriceFilterKey) && !x.startsWith(PrivateLeasingPriceFilterKey) && !x.startsWith(VanLeaseingPriceFilterKey)
                ),
            ];
        } else if (paymentInAccumulator === 'Leasing') {
            accumulator = [...accumulator.filter((x) => !x.startsWith(CashPriceFilterKey) && !x.startsWith(FinancingPriceFilterKey))];
        }
        removeLoadMoreKeys();
        setActiveFilters(accumulator);
    };

    useEffect(() => {
        if (!(activeFilters.find((x) => x === `${AvailabilityFilterKey}__${baseFilterSetup.defaultAvailability}`) && activeFilters.length === 1)) {
            pushActiveFiltersToUrl(
                activeFilters.sort((a, b) => a.localeCompare(b, 'da-DK')),
                firstRender ? undefined : '0'
            );
        } else {
            pushActiveFiltersToUrl([], firstRender ? undefined : '0');
        }
        setProductListRequest({ ...productListRequest, filters: activeFilters, from: 0 });
        setFirstRender(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeFilters]);

    useEffect(() => {
        const getProductListConfig = async (pageNumber?: number) => {
            setIsLoading(true);
            const calculatedTakeValue = pageNumber !== undefined ? productListRequest.take * pageNumber : productListRequest.take;
            const marketingProductCardsWithinIndex = params.plpConfig.filterConfiguration.marketingProductCards.filter(
                (x) => x.index > productListRequest.from && x.index <= productListRequest.take + productListRequest.from
            );
            const vehicleType = baseFilterSetup.alias === 'carBaseFilter' ? 'Car' : 'Van';
            const paymentTypeInActiveFilters = productListRequest.filters.find((x) => x.indexOf(PaymentTypeFilterKey) > -1);
            const request: hesselApiTypes.ProductListRequest = paymentTypeInActiveFilters
                ? productListRequest
                : {
                      ...productListRequest,
                      filters: [...productListRequest.filters, `${PaymentTypeFilterKey}__${paymentType}`],
                  };
            const sortingFromUrl = getSortingFromUrl();
            const [results, error] = await getProductList(
                {
                    ...request,
                    take: calculatedTakeValue - marketingProductCardsWithinIndex.length,
                    sort: sortingFromUrl.length > 0 ? sortingFromUrl : productListRequest.sort,
                },
                vehicleType
            );
            setIsLoading(false);
            if (results && !error) {
                setProductListData(results);
                setProductListItems([
                    ...(productListRequest.from > 0 && productListItems ? productListItems : []),
                    ...results.vehicles.map((x) => mapCarDetailsToProductCard(x)),
                ]);
                setFacetsArray(createFacetArray(results, params.plpConfig.globalPlpSettings, baseFilterSetup));
                if (loadMoreClicked.current) {
                    loadMoreClicked.current = false;
                }
            }
        };
        const nonDefaultFilters = activeFilters.filter((x) => x !== `${AvailabilityFilterKey}__${baseFilterSetup.defaultAvailability}`);
        //prevent the call when the component initially renders
        const sortingFromUrl = getSortingFromUrl();
        const pageFromUrl = getPageFromUrl();
        if (
            nonDefaultFilters.length > 0 ||
            (firstRender && pageFromUrl.length > 0) ||
            productListRequest.from > 0 ||
            (params.initialActiveFilters && params.initialActiveFilters.length > 0) ||
            (productListRequest.sort && productListRequest.sort !== params.productListData?.selectedSorting[0]) ||
            sortingFromUrl.length > 0
        ) {
            getProductListConfig(firstRender && pageFromUrl.length > 0 ? +pageFromUrl : undefined);
        } else {
            setProductListItems(params.initialProducts ?? []);
            setFacetsArray(params.initialFacetsArray ?? []);
            setProductListData(params.productListData ?? null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productListRequest]);

    const availabilityFacet = useMemo(() => facetsArray.find((x) => x.key === AvailabilityFilterKey), [facetsArray]);
    const { validAvailabilities } = useAvailabilityValidator(availabilityFacet, baseFilterSetup, params.plpConfig.filterConfiguration.id);

    const anyResults = useMemo(() => (productListItems && productListItems.length > 0 ? true : false), [productListItems]);

    const facets = useMemo(
        () =>
            facetsArray.filter((x) => {
                if (x.key !== AvailabilityFilterKey) {
                    return true;
                }
                return false;
            }),
        [facetsArray]
    );

    const marketingProductCards: MarketingProductCard[] = useMemo(() => {
        return params.plpConfig.filterConfiguration.marketingProductCards.map((x) => {
            return { ...x, type: 'marketing' };
        });
    }, [params.plpConfig.filterConfiguration.marketingProductCards]);

    const sendRequestCard: SendRequestCard[] = useMemo(() => {
        return params.plpConfig.sendRequestCard?.map((x) => {
            return { ...x, type: 'sendRequest' };
        });
    }, [params.plpConfig.sendRequestCard]);

    const allProductCards: ProductCard[] = useMemo(() => {
        const allCards = [...(productListItems ?? [])];
        const listLength = productListItems ? productListItems.length : 0;
        const filteredMarketingCards = marketingProductCards.filter((x) => x.index <= listLength);
        for (const card of filteredMarketingCards) {
            allCards.splice(card.index - 1, 0, card);
        }
        if (productListItems && productListItems.length === productListData?.total) {
            allCards.push(...sendRequestCard);
        }
        return allCards;
    }, [marketingProductCards, productListData?.total, productListItems, sendRequestCard]);

    const maximumSubfacetDepth = useMemo(() => {
        if (availabilityFacet) {
            const activeAvailabilityFacet = availabilityFacet.facetOptions.find((x) => x.selected);
            if (activeAvailabilityFacet?.key === OrderAvailabilityKey) {
                return 1;
            }
        }
        return 99;
    }, [availabilityFacet]);

    const { allProductCardsAreDemo } = useMemo(() => {
        return {
            allProductCardsAreDemo: allProductCards
                ?.filter((x) => x.type === 'detailed')
                .every((y) => (y as ProductCardDetails).availability === 'Demo'),
        };
    }, [allProductCards]);

    const canFetchCmsDepartments = useMemo(() => {
        return facets.some((x) => getFilterRenderType(x) === 'Department') || allProductCardsAreDemo;
    }, [allProductCardsAreDemo, facets]);
    const { departmentOptions } = useCmsDealerships(canFetchCmsDepartments);

    const filterTiles = useMemo(() => {
        if (productListData) {
            const filters = activeFilters.filter((x) => x.split('__')[0] !== AvailabilityFilterKey && x.split('__')[0] !== PaymentTypeFilterKey);
            const filterTilesObjects = filters.map((x) => {
                const facetKey = x.split('__')[0];
                let facetMatchKey = Object.keys(productListData.facets).find((x) => x.indexOf(facetKey) >= 0);
                if (!facetMatchKey && facetKey === ModelTitleFilterKey) {
                    facetMatchKey = ModelTitleFilterKey;
                }
                if (!facetMatchKey && facetKey === FamilyFilterKey) {
                    facetMatchKey = FamilyFilterKey;
                }
                if (facetMatchKey) {
                    const labelTranslation = params.plpConfig.globalPlpSettings.filterGroupLabels.find((x) => x.filterGroupKey === facetMatchKey);
                    let tileText = formatTileText(x);
                    let validFacetOption = false;
                    if (facetMatchKey === 'locationId' && departmentOptions && departmentOptions.length > 0) {
                        const departmentMatch = departmentOptions.find((y) => y.hovedafdelingId === tileText);
                        if (departmentMatch) {
                            tileText =
                                departmentMatch.filterLabel && departmentMatch.filterLabel.length > 0
                                    ? departmentMatch.filterLabel
                                    : departmentMatch.dealershipLabel;
                            validFacetOption = true;
                        }
                    }
                    if (!validFacetOption) {
                        validFacetOption = validateFilterTile(productListData, facetMatchKey, tileText, x);
                    }
                    return {
                        label: labelTranslation?.filterGroupLabelText ?? facetMatchKey,
                        value: tileText,
                        filterUpdateNotification: { action: 'Remove', query: x } as FilterUpdateNotification,
                        valid: validFacetOption,
                    };
                }
                return null;
            });
            const reducedFilterTiles: filterTile[] = filterTilesObjects.reduce((acc, curr) => {
                if (curr) {
                    const matchingEntry = acc.find((x) => x.label === curr.label && x.valid === curr.valid);
                    if (matchingEntry) {
                        matchingEntry.values = [...matchingEntry.values, curr.value];
                        matchingEntry.filterUpdateNotifications = [...matchingEntry.filterUpdateNotifications, curr.filterUpdateNotification];
                        return acc.map((x) => (x.label === matchingEntry.label && x.valid === matchingEntry.valid ? matchingEntry : x));
                    }
                    return [
                        ...acc,
                        { label: curr.label, filterUpdateNotifications: [curr.filterUpdateNotification], values: [curr.value], valid: curr.valid },
                    ];
                }
                return acc;
            }, new Array<filterTile>());
            return reducedFilterTiles;
        }
        return [];
    }, [activeFilters, departmentOptions, params.plpConfig.globalPlpSettings.filterGroupLabels, productListData]);

    const activeFiltersLength = useMemo(() => {
        return activeFilters.filter((x) => !x.startsWith(`${AvailabilityFilterKey}__`) && !x.startsWith(`${PaymentTypeFilterKey}__`)).length;
    }, [activeFilters]);

    const clearFiltersText = useMemo(() => {
        const clearFiltersCTATextRaw =
            params.plpConfig.clearFiltersCTAText.length > 0
                ? params.plpConfig.clearFiltersCTAText
                : params.plpConfig.globalPlpSettings.clearFiltersCTAText;
        return clearFiltersCTATextRaw.replace('{{filterCount}}', activeFiltersLength.toString());
    }, [activeFiltersLength, params.plpConfig.clearFiltersCTAText, params.plpConfig.globalPlpSettings.clearFiltersCTAText]);

    const resetFilters = () => {
        setActiveFilters(activeFilters.filter((x) => x.startsWith(`${AvailabilityFilterKey}__`) || x.startsWith(`${PaymentTypeFilterKey}__`)));
    };

    const sortingOptions: SortingLabel[] = useMemo(() => {
        if (productListData?.sortOptions) {
            return [
                ...productListData.sortOptions.map((x) => {
                    const optionLabel = params.plpConfig.globalPlpSettings.sortingLabels.find((y) => y.sortingKey === x);
                    return {
                        sortingKey: x,
                        labelText: optionLabel ? optionLabel.labelText : x,
                    };
                }),
            ];
        } else {
            return [];
        }
    }, [params.plpConfig.globalPlpSettings.sortingLabels, productListData]);

    const noResultsContactForm = useMemo(() => {
        if (params.plpConfig.noResultsContactForm && params.plpConfig.noResultsContactForm.length > 0) {
            return {
                form: params.plpConfig.noResultsContactForm[0].form,
                header: params.plpConfig.noResultsContactForm[0].formHeader ?? '',
                subText: params.plpConfig.noResultsContactForm[0].formSubtext ?? '',
            };
        } else {
            return {
                form: params.plpConfig.globalPlpSettings.noResultsContactForm?.[0].form,
                header: params.plpConfig.globalPlpSettings.noResultsContactForm?.[0].formHeader ?? '',
                subText: params.plpConfig.globalPlpSettings.noResultsContactForm?.[0].formSubtext ?? '',
            };
        }
    }, [params.plpConfig.globalPlpSettings.noResultsContactForm, params.plpConfig.noResultsContactForm]);

    const loadMore = (from: number, take: number) => {
        pushActiveFiltersToUrl(activeFilters, (from / take + 1).toString());
        updateProductListRequest({
            ...productListRequest,
            from,
            take,
        });
    };

    return {
        resultsText,
        paymentType,
        selectedSortingValue,
        isLoading,
        validAvailabilities,
        anyResults,
        loadMoreClicked,
        filterUpdate,
        productListData,
        allProductCards,
        maximumSubfacetDepth,
        facets,
        productListItems,
        filterTiles,
        clearFiltersText,
        resetFilters,
        sortingOptions,
        departmentOptions: departmentOptions ?? [],
        productListRequest,
        noResultsContactForm,
        activeFilters,
        updateProductListRequest,
        activeFiltersLength,
        baseFilterSetup,
        loadMore,
    };
};
