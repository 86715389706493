import styled from 'styled-components';
import { device } from '../../../lib/media-query';
import { mixins } from '../../../themes';

export const SplitMediaListWrapper = styled.div<{ noMediaAvailable: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 40px 0;
    background-color: white;

    @media ${device.laptop} {
        gap: 40px;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
`;

export const SplitTextWrapper = styled.div<{ index: number }>`
    max-width: 575px;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media ${device.laptop} {
        margin-top: 40px;
        ${(props) => (props.index === 0 ? 'margin-right: 75px;' : 'margin-left: 75px;')}
    }
`;

export const SplitTextHeader = styled.p`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 25px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 30px;
`;

export const SplitTextContent = styled.div`
    overflow: hidden;
    & img {
        width: auto;
        max-width: 320px;
        max-height: 300px;
        object-fit: cover;

        @media ${device.tablet} {
            max-width: 500px;
        }
    }

    & p {
        font-weight: normal;
        font-size: 14px;
    }

    & a {
        font-weight: inherit;
        font-size: inherit;
    }

    & ul,
    & ol {
        list-style: revert;
        padding: revert;
        margin: revert;

        & li {
            font-size: 13px;
            font-weight: normal;
            @media ${device.tablet} {
                font-size: 14px;
            }
        }
    }

    @media ${device.tablet} {
        overflow: revert;
    }
`;

export const MediaContainer = styled.div``;

export const MediaImage = styled.img<{ useSquaredCorners?: boolean }>`
    max-width: 100%;
    ${({ useSquaredCorners }) => mixins.useSquaredCorners(useSquaredCorners)}
`;

export const MediaVideo = styled.video<{ useSquaredCorners?: boolean }>`
    max-width: 100%;
    ${({ useSquaredCorners }) => mixins.useSquaredCorners(useSquaredCorners)};
`;
