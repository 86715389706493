import styled from 'styled-components';
import { device } from '../../../lib/media-query';

export const Wrapper = styled.div<{ alignItems: 'center' | 'baseline' | 'top' }>`
    display: flex;
    align-items: ${(props) => props.alignItems};
    ${(props) => (props.alignItems === 'top' ? 'input { margin-top: 2px; }' : '')}
`;

/* The container */
export const StyledCheckBox = styled.input.attrs({ type: 'checkbox' })<{ error: boolean; size: number }>`
    --active: ${({ theme }) => theme.palette.primary.main};
    --active-inner: ${({ theme }) => theme.palette.common.white};
    --focus: 2px rgba(39, 94, 254, 0.3);
    --border: ${(props) => (props.error ? 'red' : props.theme.palette.primary.main)};
    --border-hover: ${({ theme }) => theme.palette.primary.main};
    --background: #fff;
    --disabled: ${({ theme }) => theme.palette.primary.main};
    --disabled-inner: ${({ theme }) => theme.palette.primary.main};
    -webkit-appearance: none;
    -moz-appearance: none;
    height: ${(props) => `${props.size}px`};
    min-height: ${(props) => `${props.size}px`};
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1.1px solid var(--border-color, var(--border));
    background: var(--bg-color, var(--background));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;

    &:after {
        content: '';
        display: block;
        left: 0;
        top: 0;
        position: absolute;
        transition: transform var(--duration-time, 0.3s) var(--ease-duration, ease), opacity var(--opacity-duration, 0.2s);
    }

    &:checked {
        --bg-color: ${({ theme }) => theme.palette.primary.main};
        --border-color: var(--active);
        --opacity-duration: 0.3s;
        --duration-time: 0.6s;
        --ease-duration: cubic-bezier(0.2, 0.85, 0.32, 1.2);
    }

    &:disabled {
        --bg-color: var(--disabled);
        cursor: not-allowed;
        opacity: 0.5;
        &:checked {
            --bg-color: var(--disabled-inner);
            --border-color: var(--border);
        }
        & + label {
            cursor: not-allowed;
        }
    }

    &:hover {
        &:not(:checked) {
            &:not(:disabled) {
                --border-color: var(--border-hover);
            }
        }
    }

    &:focus {
        box-shadow: 0 0 0 var(--focus);
    }

    & {
        width: ${(props) => `${props.size}px`};
        min-width: ${(props) => `${props.size}px`};
        &:after {
            opacity: var(--current-opacity, 0);
        }
        &:checked {
            --current-opacity: 1;
        }
    }

    & {
        border-radius: 0px;
        &:after {
            width: 5px;
            height: 9px;
            border: 2px solid var(--active-inner);
            border-top: 0;
            border-left: 0;
            left: ${(props) => `${Math.floor(props.size / 3.4)}px`};
            top: ${(props) => `${Math.floor(props.size / 8.5)}px`};
            transform: rotate(var(--rotation-degrees, 20deg));
        }
        &:checked {
            --rotation-degrees: 43deg;

            &:disabled {
                --active-inner: #fff;
            }
        }
    }
`;

export const StyledLabel = styled.label<{ expandLabel: boolean }>`
    display: flex;
    flex-direction: row;
    font-size: 10px;
    vertical-align: top;
    cursor: pointer;
    padding-left: 10px;
    width: ${(props) => (props.expandLabel ? '100%' : 'auto')};

    @media ${device.tablet} {
        font-size: 14px;
    }
`;

export const BorderBox = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledError = styled.p`
    display: flex;
    flex-direction: column;
    color: #d64469;
    font-size: ${({ theme: { typography } }) => typography.small.fontSize}px;
    margin-left: 10px;
`;
