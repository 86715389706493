import { FC, memo, useCallback } from 'react';
import { TabbedCard } from '../../../shared/tabbed-card/tabbed-card.component';
import { Props as DropdownInputProps } from '../../../forms/inputs/dropdown/dropdown-input.props';
import { IncludingDeliveryAndComplianceTextWrapper, PositionedComplianceText, StyledIncludingDeliveryText } from './car-leasing-tabs.styled';
import { hesselViewModels } from '../../../../lib/view-models';
import { OwnershipCard } from '../../ownership-card/ownership-card.component';
import { ProductDetailsPage, sharedTypes } from '../../../../lib/api/models/umbraco';
import { CarLeasingOwnershipPrivateLeasingTab } from '../../../../lib/api/models/umbraco/product-types/cars.types';
import { formatPrice, getFormattedValue } from '../../../../utils/helpers';
import { OpenDialog, usePdpModals } from '../../product-details-page/product-details-page.context';
import { useCarCtaConfig } from '../use-car-cta-config';
import { PriceByOwnership } from '../../hooks/price-by-ownership.types';
import { getCarPrivateLeasingDisclaimer } from '../../../../utils/helpers/price-by-ownership.helper';
import { ProductDetailsStore } from '../../../../lib/state/hessel-site/product-details';
import { Abbreviations, Units } from '../../../../constants/units-and-abbreviations';

export type CarLeasingCarProps = {
    className?: string;
    description: string;
    downPayment: number;
    dropdownProps: Pick<DropdownInputProps, 'label' | 'onChange' | 'options' | 'value'>;
    selectedOwnershipTab: hesselViewModels.OwnershipTab;
    onOpenCheckout: () => void;
    page: ProductDetailsPage;
    priceVisibilityChanged: (visible: boolean) => void;
    product: hesselViewModels.ProductDetails;
    setSelectedOwnershipTab: (tab: hesselViewModels.OwnershipTab) => void;
    showHigherEquipmentLabel: boolean;
    priceInformation: PriceByOwnership;
};

export const CarLeasingTabs: FC<CarLeasingCarProps> = memo(({ product, page, priceInformation, ...props }) => {
    const { openDialog } = usePdpModals();

    // Get the CTA config
    const CtaConfig = useCarCtaConfig(page, props.selectedOwnershipTab, product);

    const privateAndHireLeasingAvailable = !!product?.purchaseTypes.hire.show && !!product?.purchaseTypes.privateLeasing.show;

    const { isAFromPrice } = ProductDetailsStore.useStoreState((state) => state);

    // Array to hold all available leasing tabs.
    const leasingTabs = [];

    // TAB BUILDING STARTS HERE!

    // Get the CMS stuff
    const carPrivateLeasingCms = page.carFinancialSetup.find(
        (x) => x.alias === 'carLeasingOwnershipPrivateLeasingTab'
    ) as CarLeasingOwnershipPrivateLeasingTab;

    const getHireLeasingTabTotalExpenses = useCallback(
        (numOfMonths: number): string => {
            if (props.selectedOwnershipTab !== 'Car HiRE') {
                return '';
            }

            const result: Array<number> = [];

            for (let index = 0; index < numOfMonths - 3; index++) {
                // Month 1 = setup cost + 4 month leasing cost
                if (index === 0) {
                    result.push((product.purchaseTypes.hire.establishmentFee ?? 0) + 4 * priceInformation.price);
                } else {
                    result.push(priceInformation.price);
                }
            }

            // The last 3 months of a HiRE period are always without cost
            for (let index = 0; index < 3; index++) {
                result.push(0);
            }

            const calculatedTotalPrice = result?.reduce((acc, val) => val + acc, 0) ?? 0;
            return formatPrice(calculatedTotalPrice);
        },
        [priceInformation.price, product.purchaseTypes.hire.establishmentFee, props.selectedOwnershipTab]
    );

    /**
     * HiRE leasing tab
     */
    if (product.purchaseTypes.hire.show) {
        const hireLeasingTab = {
            id: 'Car HiRE',
            label: page.conceptLabel,
            description: props.description,
            onClick: () => props.setSelectedOwnershipTab('Car HiRE'),
            content: (
                <OwnershipCard
                    priceVisibilityChanged={props.priceVisibilityChanged}
                    primaryCta={{
                        label: CtaConfig[product.availability]?.labels.primary,
                        action:
                            props.selectedOwnershipTab === 'Car HiRE' && product.availability !== 'Used'
                                ? props.onOpenCheckout
                                : CtaConfig[product.availability]?.actions.primary,
                    }}
                    secondaryCta={{
                        label: CtaConfig[product.availability]?.labels.secondary,
                        action: CtaConfig[product.availability]?.actions.secondary,
                    }}
                    footerCta={{
                        label: CtaConfig[product.availability]?.labels.footerLink,
                        action: CtaConfig[product.availability]?.actions.footerLink,
                    }}
                    usps={[
                        {
                            isEmphasized: true,
                            label: product?.purchaseTypes.hire?.kilometersPerMonth
                                ? `${getFormattedValue(product?.purchaseTypes.hire.kilometersPerMonth)} ${Abbreviations.KM_SLASH_MD}`
                                : '-',
                            icon: {
                                src: page?.compareVansLeasingTypesModal?.[0]?.availableIcon.src,
                            },
                        } as sharedTypes.LabelAndIcon,
                        ...(page.sellingPoints.map((x) => ({
                            label: x.label,
                            icon: x.icon,
                            isEmphasized: false,
                            tooltip: x.tooltip,
                        })) as sharedTypes.LabelAndIcon[]),
                    ]}
                    price={priceInformation}
                    disclaimer={
                        <IncludingDeliveryAndComplianceTextWrapper>
                            {page?.carIncludingDeliveryText?.length ? (
                                <StyledIncludingDeliveryText>{page.carIncludingDeliveryText}</StyledIncludingDeliveryText>
                            ) : null}
                            <PositionedComplianceText
                                text={`Forbrug ved blandet kørsel: ${product.specifications.consumption} ${
                                    Abbreviations.KM_SLASH_L
                                }, CO2 udledning: ${product.specifications.co2Emission}, ${
                                    product.fuelType !== 'El' ? 'energimærke: ' + product.energyRating + '.' : ''
                                } Pris pr. md.: ${formatPrice(
                                    priceInformation.price
                                )} kr., førstegangsydelse/udbetaling: 0 kr., etableringsgebyr:  ${formatPrice(
                                    product.purchaseTypes.hire.establishmentFee ?? 0
                                )} kr., løbetid: ${product.purchaseTypes.hire.durationFrom}-${
                                    product.purchaseTypes.hire.durationTo
                                } mdr., tilgængelige kilometer pr. md.: ${formatPrice(product.purchaseTypes.hire.kilometersPerMonth)} ${
                                    Units.km
                                }, totalomkostninger i perioden fra: ${getHireLeasingTabTotalExpenses(
                                    props.dropdownProps.value?.value ? +props.dropdownProps.value.value : 0
                                )} kr. Aftale indeholder forsikring og grøn ejerafgift, og forudsætter positiv kreditgodkendelse. Der tages forbehold for fejl samt at biler kan være vist med ekstraudstyr.`}
                            />
                        </IncludingDeliveryAndComplianceTextWrapper>
                    }
                    linkCta={
                        privateAndHireLeasingAvailable
                            ? {
                                  label: carPrivateLeasingCms?.linkCtaText,
                                  action: () => openDialog({ dialog: OpenDialog.CompareCarsLeasingTypes }),
                              }
                            : {
                                  label: page.hesselHireBenefitsLabel,
                                  action: () => openDialog({ dialog: OpenDialog.Benefits, sidebarOpened: 'Hvorfor HesselHiRE' }),
                              }
                    }
                    dropdownProps={props.dropdownProps}
                />
            ),
        };

        leasingTabs.push(hireLeasingTab);
    }

    /**
     * Private Leasing tab
     **/
    if (product.purchaseTypes.privateLeasing.show) {
        const privateLeasingTab = carPrivateLeasingCms
            ? {
                  id: 'Car Privat Leasing',
                  label: carPrivateLeasingCms?.tabName ?? '',
                  description: product.purchaseTypes.privateLeasing.durationMonths + ' md.',
                  onClick: () => props.setSelectedOwnershipTab('Car Privat Leasing'),
                  content: (
                      <OwnershipCard
                          priceVisibilityChanged={props.priceVisibilityChanged}
                          primaryCta={{
                              label: CtaConfig[product.availability]?.labels.primary,
                              action: CtaConfig[product.availability]?.actions.primary,
                          }}
                          secondaryCta={{
                              label: CtaConfig[product.availability]?.labels.secondary,
                              action: CtaConfig[product.availability]?.actions.secondary,
                          }}
                          footerCta={{
                              label: CtaConfig[product.availability]?.labels.footerLink,
                              action: CtaConfig[product.availability]?.actions.footerLink,
                          }}
                          usps={[
                              {
                                  label: formatPrice(product.purchaseTypes.privateLeasing.kilometersPerYear / 12) + ` ${Abbreviations.KM_SLASH_MD}`,
                                  icon: carPrivateLeasingCms?.mileageIcon,
                                  isEmphasized: true,
                              },
                              ...(product.leasingPriceIncludesExtraBenefits //EJH-3927 temp solution, more details in jira task
                                  ? [
                                        {
                                            label: 'Forsikring',
                                            icon: page?.compareVansLeasingTypesModal?.[0]?.availableIcon,
                                        },
                                    ]
                                  : []),

                              ...(carPrivateLeasingCms?.usps ?? []),
                          ]}
                          price={priceInformation}
                          disclaimer={
                              <IncludingDeliveryAndComplianceTextWrapper>
                                  {page?.carIncludingDeliveryText?.length ? (
                                      <StyledIncludingDeliveryText>{page.carIncludingDeliveryText}</StyledIncludingDeliveryText>
                                  ) : null}
                                  <PositionedComplianceText
                                      text={getCarPrivateLeasingDisclaimer(
                                          product.specifications.consumption,
                                          product.specifications.co2Emission,
                                          product.fuelType !== 'El' ? 'energimærke: ' + product.energyRating : '',
                                          priceInformation.price,
                                          product.purchaseTypes.privateLeasing.downPayment,
                                          product.purchaseTypes.privateLeasing.establishmentFee ?? 0,
                                          priceInformation.price * product.purchaseTypes.privateLeasing.durationMonths +
                                              product.purchaseTypes.privateLeasing.downPayment +
                                              (product.purchaseTypes.privateLeasing.establishmentFee ?? 0),
                                          product.purchaseTypes.privateLeasing.durationMonths,
                                          product.purchaseTypes.privateLeasing.kilometersPerYear,
                                          isAFromPrice,
                                          product.leasingPriceIncludesExtraBenefits
                                      )}
                                  />
                              </IncludingDeliveryAndComplianceTextWrapper>
                          }
                          linkCta={
                              privateAndHireLeasingAvailable
                                  ? {
                                        label: carPrivateLeasingCms?.linkCtaText,
                                        action: () => openDialog({ dialog: OpenDialog.CompareCarsLeasingTypes }),
                                    }
                                  : undefined
                          }
                      />
                  ),
              }
            : undefined;
        privateLeasingTab && leasingTabs.push(privateLeasingTab);
    }

    return (
        <TabbedCard
            controlled
            activeTabId={props.selectedOwnershipTab}
            onAccesibilityTabSelected={(id) => props.setSelectedOwnershipTab(id as hesselViewModels.OwnershipTab)}
            className={props.className}
            tabs={leasingTabs}
        />
    );
});
