import { VFC } from 'react';
import { getUniqueId, sanitizeMarkup } from '../../utils/helpers';
import { SidePanel } from '../shared/modals/side-panel';
import { StyledHeader, StyledDescription, ListWrapper, RegionWrapper } from './dealership-list-dialog.styled';
import { DealershipInfo } from '../shared';
import { HireContentDisplayStore } from '../../lib/state/hessel-site/display-manager';
import { useUI } from '../ui';
import { SidePanelLayout } from '../shared/modals/side-panel-layout/side-panel-layout.component';
import { orgTypes } from '../../lib/api/models/umbraco';

export type DealershipListDialogProps = {
    dealershipSettings: orgTypes.DealershipListSettings;
};

export const DealershipListDialog: VFC<DealershipListDialogProps> = ({ dealershipSettings }) => {
    const { header, description, email, regions } = dealershipSettings;

    const { showDealerships } = HireContentDisplayStore.useStoreState((state) => ({
        showDealerships: state.showDealershipListDialog,
    }));

    const { setShowDealershipListDialog } = HireContentDisplayStore.useStoreActions((actions) => actions);

    const uiHandler = useUI();

    return (
        <SidePanel
            isVisible={showDealerships}
            cancelAction={() => {
                uiHandler.applyScroll();
                setShowDealershipListDialog(false);
            }}
        >
            <SidePanelLayout
                closeSidePanel={() => {
                    uiHandler.applyScroll();
                    setShowDealershipListDialog(false);
                }}
            >
                <StyledHeader>{header}</StyledHeader>
                <StyledDescription {...sanitizeMarkup(description?.replace('{{email}}', `<Link><a href=${`mailto:${email}`}>${email}</a></Link>`))} />
                <ListWrapper>
                    {regions.map((region, regionIndex) => {
                        return (
                            <RegionWrapper key={`region-${region.regionName}-${regionIndex}`}>
                                <StyledHeader>{region.regionName}</StyledHeader>
                                {region.dealerships.map((dealership, dealershipIndex) => {
                                    return (
                                        <DealershipInfo
                                            key={`dealershipInfo-${dealershipIndex}-${getUniqueId()}`}
                                            header={dealership.title}
                                            address={dealership.address}
                                            email={dealership.email}
                                            city={dealership.city}
                                            zipcode={dealership.zipCode}
                                            telephone={dealership.telephone}
                                            hideBorderBottom={dealershipIndex !== region.dealerships.length - 1}
                                        />
                                    );
                                })}
                            </RegionWrapper>
                        );
                    })}
                </ListWrapper>
            </SidePanelLayout>
        </SidePanel>
    );
};
