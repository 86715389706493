import { FC, useMemo } from 'react';
import { DeliveryType } from '../../../../../lib';
import { umbraco } from '../../../../../lib/api';
import { BookingWorkshopsStore } from '../../../../../lib/state/booking-workshops';
import { DateStyle, formatDate, getEndTime } from '../../../../../utils/helpers';
import { SubmittedInfo } from '../../../../shared/submitted-info';
import { CompletedWrapper } from './workshop-completed.styled';

type Props = {
    workshopContent: umbraco.BookingStepWorkshop;
};

export const CompletedWorkshop: FC<Props> = ({ workshopContent }) => {
    const { selectedDate, selectedWorkshop, selectedTimeSlot, workshops, deliveryType, selectedTimeSlotToStay } = BookingWorkshopsStore.useStoreState(
        (state) => state
    );
    const workshop = useMemo(() => workshops.find(({ sabId }) => sabId === selectedWorkshop), [workshops, selectedWorkshop]);

    const pickUpMethodDescription = useMemo(() => {
        switch (deliveryType) {
            case DeliveryType.ByVendor:
                return workshopContent.pickupMethodDescription;
            case DeliveryType.CustomerStays:
                return `${workshopContent.timeSlotPickupHeader} ${formatDate(selectedTimeSlotToStay?.endDateTime ?? 0, DateStyle.HH_mm)}`;

            default:
                return `${workshopContent.timeSlotPickupHeader} ${formatDate(
                    getEndTime(selectedTimeSlot?.endDateTime ?? new Date()),
                    DateStyle.HH_mm
                )}`;
        }
    }, [
        deliveryType,
        selectedTimeSlot?.endDateTime,
        selectedTimeSlotToStay?.endDateTime,
        workshopContent.pickupMethodDescription,
        workshopContent.timeSlotPickupHeader,
    ]);

    const getTime = (): string => {
        if (deliveryType === DeliveryType.CustomerStays) {
            return selectedTimeSlotToStay && selectedTimeSlotToStay.startDateTime
                ? formatDate(selectedTimeSlotToStay.startDateTime, DateStyle.HH_mm)
                : '';
        }

        return selectedTimeSlot && selectedTimeSlot.startDateTime ? formatDate(selectedTimeSlot.startDateTime, DateStyle.HH_mm) : '';
    };

    return (
        <CompletedWrapper>
            <SubmittedInfo label={workshopContent.workshopNameLabel} text={workshop?.name ?? ''} />
            <SubmittedInfo label={workshopContent.selectedDateLabel} text={formatDate(selectedDate, DateStyle.dk_full_text)} />

            {deliveryType !== DeliveryType.ByVendor && (
                <>
                    <SubmittedInfo label={workshopContent.deliveryTimeLabel} text={getTime()} />
                </>
            )}

            <SubmittedInfo label={workshopContent.pickupMethodLabel} text={pickUpMethodDescription} />
        </CompletedWrapper>
    );
};
