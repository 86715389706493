import styled from 'styled-components';

export const BorderBox = styled.article<{ hideBottomBorder: boolean }>`
    display: flex;
    flex-direction: column;
    border-top: 1px solid #d1d2d4;
    border-bottom: ${(props) => (props.hideBottomBorder ? 'none' : '1px solid #d1d2d4')};
    padding: 25px 0;
`;

export const Header = styled.p`
    color: #0b0b0b;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 23px;
    margin-bottom: 5px;
`;

export const Description = styled.p`
    color: #0b0b0b;
    font-weight: 400;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 14px;
    letter-spacing: 0;
    line-height: 23px;
`;
