import { useState, VFC } from 'react';
import reactStringReplace from 'react-string-replace';
import { useScrollLock } from '../../../hooks/use-scroll-lock';
import { orgTypes, sharedTypes } from '../../../lib/api/models/umbraco';
import { handleUrlClick, isNullOrEmpty } from '../../../utils/helpers';
import { Button } from '../../shared';
import { OpeningHours } from '../../shared/opening-hours/opening-hours.component';
import { EmergencyPhonesModal } from '../emergency-phones-modal/emergency-phones-modal.component';
import {
    HeaderAndDescription,
    StyledHeader,
    StyledDescription,
    StyledOpeningHoursModule,
    OpeningHoursColumn,
    Footnote,
    StyledCenteredBlock,
    StyledDisclaimerLink,
} from './opening-hours-module.styled';
import { SpecialDay } from '../../../lib/api/models/umbraco/organization.types';

type OpeningHoursModuleProps = {
    departments: Array<orgTypes.DealershipDepartment>;
    header: string;
    description: string;
    disclaimer: string;
    disclaimerLink: sharedTypes.CtaUrl;
    emergencyPhoneNumbers: Array<orgTypes.DepartmentEmergencyNumbers>;
    supportedBrands: Array<orgTypes.SupportedBrand>;
    dealershipAndTruckNumbers: Array<orgTypes.DealerShipAndTruck>;
    currentDealerId: string;
    defaultSpecialDays?: Array<SpecialDay>;
};

export const OpeningHoursModule: VFC<OpeningHoursModuleProps> = ({
    departments,
    defaultSpecialDays,
    header,
    description,
    disclaimer,
    disclaimerLink,
    emergencyPhoneNumbers,
    supportedBrands,
    dealershipAndTruckNumbers,
    currentDealerId,
}) => {
    const [showEmergencyModal, setShowEmergencyModal] = useState(false);
    const { applyScrollBars, hideScrollBars } = useScrollLock();

    return (
        <>
            <StyledCenteredBlock>
                <StyledOpeningHoursModule>
                    <HeaderAndDescription>
                        <StyledHeader>{header}</StyledHeader>
                        <StyledDescription>
                            {reactStringReplace(description, '{{emergency_link}}', () => (
                                <Button
                                    key={'emergency-link-button'}
                                    onClick={() => {
                                        hideScrollBars();
                                        setShowEmergencyModal(true);
                                    }}
                                    variant={'link'}
                                >
                                    <p>nødservice telefon</p>
                                </Button>
                            ))}
                        </StyledDescription>
                    </HeaderAndDescription>
                    <OpeningHoursColumn>
                        <OpeningHours departments={departments} defaultSpecialDays={defaultSpecialDays} />
                        {!isNullOrEmpty(disclaimer) ? (
                            <Footnote>
                                {reactStringReplace(disclaimer, '{{disclaimer_link}}', () =>
                                    disclaimerLink && !isNullOrEmpty(disclaimerLink.name) && !isNullOrEmpty(disclaimerLink.url) ? (
                                        <Button
                                            key={'disclaimer-button'}
                                            onClick={() => {
                                                handleUrlClick(disclaimerLink);
                                            }}
                                            variant={'link'}
                                        >
                                            <StyledDisclaimerLink>{disclaimerLink.name}</StyledDisclaimerLink>
                                        </Button>
                                    ) : null
                                )}
                            </Footnote>
                        ) : null}
                    </OpeningHoursColumn>
                </StyledOpeningHoursModule>
            </StyledCenteredBlock>
            <EmergencyPhonesModal
                visible={showEmergencyModal}
                emergencyPhoneNumbers={emergencyPhoneNumbers}
                supportedBrands={supportedBrands}
                dealershipAndTruckNumbers={dealershipAndTruckNumbers}
                closeModal={() => {
                    applyScrollBars();
                    setShowEmergencyModal(false);
                }}
                currentDealershipId={currentDealerId}
            />
        </>
    );
};
