import styled from 'styled-components';
import { device } from '../../lib/media-query';
import { animated } from 'react-spring';
import { CenteredBlock } from '../structural-blocks/centered-block.component';

export const StyledToggleWrapper = styled.div`
    margin: 5px 0 10px;
`;

export const StyledHeader = styled.header`
    position: relative;
    padding: 10px 0 0;
    background-color: white;
    z-index: 15;
`;

export const StyledTextLogo = styled.a`
    font-family: 'Corporate E';
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0.1em;
    color: #002134;
    line-height: 1;
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
    display: block;
    margin: 0 auto;
    @media ${device.laptopS} {
        font-size: 34px;
        margin: 0 auto 10px;
    }
    cursor: pointer;
`;

export const StyledNavWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: space-between;
    gap: 80px;
`;

export const StyledNav = styled.nav`
    display: flex;
    flex-grow: 1;
    gap: 30px;
`;

export const StyledBurgerItem = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
`;

export const StyledExtraOptions = styled.div`
    display: flex;
    gap: 10px;
    margin-left: auto;

    @media ${device.laptopS} {
        margin-bottom: 5px;
        gap: 30px;
    }
`;

export const StyledMobileNav = styled.nav`
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    padding: 10px 0 20px;
    align-items: center;
`;

export const StyledMegaMenuOverlay = styled(animated.div)`
    z-index: 14;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

export const StyledCenteredDeviceBlock = styled(CenteredBlock)`
    @media ${device.laptopS} {
        display: none;
    }
`;

export const StyledCenteredNonDeviceBlock = styled(CenteredBlock)`
    display: none;
    @media ${device.laptopS} {
        display: block;
    }
`;

export const StyledMegaMenuLogo = styled.img`
    width: 100%;
    max-width: 250px;
    max-height: 36px;
    margin-bottom: 10px;
    display: block;
    object-fit: cover;
    object-position: center center;
    cursor: pointer;
`;

export const StyledCartLink = styled.a`
    color: 0b0b0b;
    position: relative;
    &:hover {
        text-decoration: none;
    }
`;

export const StyledCartOrderlineCountIndicator = styled.span`
    position: absolute;
    top: -3px;
    right: -3px;
    height: 15px;
    width: 15px;
    background-color: #00adef;
    color: white;
    font-size: 8px;
    line-height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-radius: 50%;
    padding-right: 1px;
`;

export const StyledHireLeadLink = styled.span`
    position: relative;
    cursor: pointer;
`;

export const StyledLink = styled.a<{ isActive?: boolean }>`
    text-decoration: none;
    color: ${(props) => (props.isActive ? props.theme.palette.primary.main : '#0b0b0b')};
    font-size: clamp(13px, calc(0.625rem + ((1vw - 10px) * 0.5435)), 14px);
    min-height: 0vw; // Safari fix
    font-weight: 600;
    letter-spacing: 0;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
        text-decoration: none;
    }
`;

export const UtilityLink = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
`;
