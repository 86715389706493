import { FC, useMemo } from 'react';
import { useBenefitAgreementInfo } from '../../../../../hooks/booking/booking/use-benefit-agreement-info';
import { umbraco } from '../../../../../lib/api';
import { BookingFormStore } from '../../../../../lib/state/booking-form';
import { BookingStepsStore } from '../../../../../lib/state/booking-steps';
import { filterStepType } from '../../../../../lib/state/booking-steps/booking-steps.helpers';
import { Button } from '../../../../shared';
import { AgreementCard } from '../../../../shared/agreement-card';
import { SidePanel } from '../../../../shared/modals/side-panel';
import { SvgIcon } from '../../../../shared/svg-icon';
import { Wrapper, Close, CloseText, AgreementList, AgreementHeader } from './car-agreements.styled';

type Props = {
    isVisible: boolean;
    cancelAction: () => void;
};

export const CardAgreements: FC<Props> = ({ isVisible, cancelAction }) => {
    const content = BookingStepsStore.useStoreState(({ steps }) => {
        return steps.filter(filterStepType('YourCar')).find(() => true)?.content as umbraco.BookingStepYourCar;
    });

    const { serviceAgreement } = BookingFormStore.useStoreState(({ vehicle }) => ({
        serviceAgreement: vehicle?.serviceAgreement,
    }));

    const { summaryModalCloseText, summaryModalHeader, summaryModalBackButtonText, serviceAgreements, noAgreementsText } = content;

    const serviceAgreementInfo = useMemo(
        () => serviceAgreements.find(({ agreementID }) => serviceAgreement?.includes(agreementID)),
        [serviceAgreements, serviceAgreement]
    );
    const benefitAgreementInfo = useBenefitAgreementInfo();

    return (
        <SidePanel isVisible={isVisible} cancelAction={() => cancelAction()}>
            <Wrapper>
                <Close onClick={cancelAction}>
                    <CloseText>{summaryModalCloseText}</CloseText>
                    <SvgIcon iconName={'close'} />
                </Close>
                <AgreementHeader>{summaryModalHeader}</AgreementHeader>
                {serviceAgreementInfo || benefitAgreementInfo ? (
                    <AgreementList>
                        {serviceAgreementInfo && (
                            <AgreementCard title={serviceAgreementInfo.agreementName} benefits={serviceAgreementInfo.benefits} />
                        )}
                        {benefitAgreementInfo && (
                            <AgreementCard title={benefitAgreementInfo.agreementName} benefits={benefitAgreementInfo.benefits} />
                        )}
                    </AgreementList>
                ) : (
                    <p>{noAgreementsText}</p>
                )}
                <Button variant="primary" onClick={() => cancelAction()}>
                    <p>{summaryModalBackButtonText}</p>
                </Button>
            </Wrapper>
        </SidePanel>
    );
};
