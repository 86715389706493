import { VFC } from 'react';
import { Abbreviations } from '../../../constants/units-and-abbreviations';
import { Button, Separator } from '../../shared';
import { BorderBox, ButtonList, Header, Price, PriceDescription, PriceList, PriceWrapper } from './savings-result-card.styled';

type Props = {
    savings: {
        normalPackage?: string;
        completePackage?: string;
    };
    header?: string;
    completeMemberText?: string;
    calculationButtonText?: string;
    ctaText?: string;
    membershipText?: string;
    isBusinessCard?: boolean;
    showSavingsDetails: () => void;
    goToSignUp: () => void;
};

export const SavingsResultCard: VFC<Props> = ({
    savings,
    header,
    completeMemberText,
    membershipText,
    calculationButtonText,
    ctaText,
    showSavingsDetails,
    goToSignUp,
}) => {
    return (
        <BorderBox>
            <Header>{header}</Header>

            <PriceList>
                {savings.normalPackage && (
                    <PriceWrapper>
                        <Price>
                            {savings.normalPackage} {Abbreviations.KR_SLASH_AAR}
                        </Price>
                        <PriceDescription>{membershipText}</PriceDescription>
                    </PriceWrapper>
                )}

                {savings.completePackage && (
                    <PriceWrapper>
                        <Price>
                            {savings.completePackage} {Abbreviations.KR_SLASH_AAR}
                        </Price>
                        <PriceDescription>{completeMemberText}</PriceDescription>
                    </PriceWrapper>
                )}
            </PriceList>

            <Separator marginTop="1.5rem" marginBottom="1.5rem" />

            <ButtonList>
                <Button variant="link" onClick={showSavingsDetails}>
                    <p>{calculationButtonText}</p>
                </Button>

                <Button
                    variant="primary"
                    onClick={() => {
                        goToSignUp();
                    }}
                >
                    <p>{ctaText}</p>
                </Button>
            </ButtonList>
        </BorderBox>
    );
};
