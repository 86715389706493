import { action, computed } from 'easy-peasy';
import { createContextStoreWithRuntimeModel } from '../helpers/context-store.helpers';
import { ContactInfoState, ContactInfoStore } from './booking-contact-info.types';

export const BookingContactInfoStore = createContextStoreWithRuntimeModel<ContactInfoStore, ContactInfoState>(
    () => ({
        contactInfoStepIsValid: computed((state) => {
            return state.isPolicyAccepted ? true : false;
        }),
        setPolicyAccepted: action((state, payload) => {
            state.isPolicyAccepted = payload;
        }),
        setTermsAccepted: action((state, payload) => {
            state.isTermsAccepted = payload;
        }),
        setContactInfo: action((state, payload) => {
            state.contactInfo = payload;
        }),
        zipCodeExternallyValidated: false,
        contactInfo: {
            city: '',
            email: '',
            fullName: '',
            mobile: '',
            postalCode: '',
            streetName: '',
        },
    }),
    { name: 'ContactInformation' }
);
