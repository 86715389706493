/**
 * These will be removed to Umbraco in SOW3
 * Note that id's should not be changed
 */
export const listOfDepartments = [
    { id: 4, city: 'Aalborg' },
    { id: 12, city: 'Holstebro' },
    { id: 9, city: 'Viborg' },
    { id: 2, city: 'Randers' },

    { id: 10, city: 'Herning' },
    { id: 8, city: 'Silkeborg' },
    // Renault/Dacia
    { id: 5, city: 'Århus' },
    // Mercedes
    { id: 14, city: 'Århus' },

    { id: 11, city: 'Horsens' },
    { id: 6, city: 'Vejle' },
    { id: 22, city: 'Slagelse' },

    { id: 20, city: 'Næstved' },
    { id: 18, city: 'Ringsted' },
    { id: 21, city: 'Holbæk' },
    { id: 17, city: 'Roskilde' },
].sort((a, b) => {
    if (a.city < b.city) {
        return -1;
    }

    if (a.city > b.city) {
        return 1;
    }

    return 0;
});
