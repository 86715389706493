import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useAgreements } from '../../../../hooks/use-agreements';
import { useGtmTracking } from '../../../../gtm-tracking/hooks/use-gtm-tracking';
import { hesselApi, umbraco } from '../../../../lib/api';
import { BookingFormStore } from '../../../../lib/state/booking-form';
import { generateFormId } from '../../../../lib/state/booking-form/booking-form.helpers';
import { BookingServiceProductsStore } from '../../../../lib/state/booking-service-products';
import { BookingStepsStore } from '../../../../lib/state/booking-steps';
import { filterStepType } from '../../../../lib/state/booking-steps/booking-steps.helpers';
import { BookingWorkshopsStore } from '../../../../lib/state/booking-workshops';
import { formInputValue } from '../../../../utils/helpers';
import { CommentWrapper, ServiceCardWrapper, StyledCustomerAgreementTextContainer } from './booking-services.styled';
import { ServiceSummary } from './summary';
import { YoutubeVideoModal } from '../../youtube-modal';
import { ServiceCard } from './service-card';
import { Comment } from './comment';
import CustomerAgreementText from './customer-agreement-text/customer-agreement-text.component';

type Props = {
    content: umbraco.BookingStepServices;
    isComplete: boolean;
    stepNumber: number;
};

export const BookingServices: FC<Props> = ({ content, isComplete, stepNumber }) => {
    const [params, setParams] = useState<hesselApi.ServiceProductsParams>({
        make: '',
        workshopId: '',
        vehicleClass: '',
        email: '',
        licensePlate: '',
    });

    const [showYoutubeVideoModal, setShowYoutubeVideoModal] = useState(false);
    const [youtubeVideoModalContent, setYoutubeVideoModalContent] = useState({
        title: '',
        subTitle: '',
        watchId: '',
    });
    const { makeId, vehicleClass, licensePlate, email, inputs, yourCarFormValid } = BookingFormStore.useStoreState(({ vehicle, inputs }) => {
        const emailInput = inputs.find(({ id }) => id === generateFormId('YourCar', 'Email'));
        const licensePlateInput = inputs.find((s) => s.id === generateFormId('YourCar', 'Car Registration Number'));
        const yourCarStepIsValid = inputs
            .filter(({ step }) => step === 'YourCar')
            .filter(({ type }) => type !== 'workshop')
            .every(({ isValid }) => isValid);

        return {
            makeId: vehicle?.makeId,
            vehicleClass: vehicle?.class,
            licensePlate: licensePlateInput ? formInputValue(licensePlateInput) : '',
            inputs: inputs,
            email: emailInput ? formInputValue(emailInput) : '',
            yourCarFormValid: yourCarStepIsValid,
        };
    });

    const { selectedWorkshop, workshops: availableWorkshops } = BookingWorkshopsStore.useStoreState(({ selectedWorkshop, workshops }) => ({
        selectedWorkshop,
        workshops,
    }));

    const { loading, availableServiceProducts, selectedServiceProductsIds, comment, flattenedSubProducts, flattenedProductsOptions } =
        BookingServiceProductsStore.useStoreState(
            ({ loading, availableServiceProducts, selectedServiceProductsIds, comment, flattenedSubProducts, flattenedProductsOptions }) => ({
                loading,
                availableServiceProducts,
                selectedServiceProductsIds,
                comment,
                flattenedSubProducts,
                flattenedProductsOptions,
            })
        );
    const { getAvailableServiceProducts } = BookingServiceProductsStore.useStoreActions((actions) => actions);

    const { setStepValidity } = BookingStepsStore.useStoreActions((actions) => actions);
    const { currentStep } = BookingStepsStore.useStoreState((state) => ({
        stepContent: state.steps
            .filter(filterStepType('YourCar'))
            .map(({ content }) => content)
            .reduce((acc, content) => ({ ...acc, ...content })),
        currentStep: state.currentStep,
    }));

    const anyProductsWithOptionsNotSelected = useMemo(() => {
        const selectedProductsWithOptions = selectedServiceProductsIds
            .map((id) => availableServiceProducts.find((product) => product.id === id))
            .filter((product) => !!product?.defaultOption);

        for (const product of selectedProductsWithOptions) {
            if (!product?.defaultOption || !product.productOptions) continue;

            const allOptions = [...(product?.productOptions ?? []), product?.defaultOption];
            const anyOptionSelected = allOptions.some((option) => selectedServiceProductsIds.includes(option.id));
            if (!anyOptionSelected) return true;
        }
        return false;
    }, [availableServiceProducts, selectedServiceProductsIds]);

    useEffect(() => {
        if (currentStep === stepNumber) {
            setStepValidity({ isValid: !anyProductsWithOptionsNotSelected, stepNumber });
        }
    }, [anyProductsWithOptionsNotSelected, currentStep, setStepValidity, stepNumber]);

    const anyInputsIsInFocus = useCallback(() => {
        const inputsToWatch = [
            generateFormId('YourCar', 'Email'),
            generateFormId('YourCar', 'Telephone number'),
            generateFormId('YourCar', 'Car Registration Number'),
        ];
        return inputs.filter((i) => inputsToWatch.includes(i.id)).some((i) => i.isFocused);
    }, [inputs]);

    useEffect(() => {
        if (loading || currentStep > stepNumber || anyInputsIsInFocus()) return;

        const hasNeededInformation = makeId && vehicleClass && selectedWorkshop;

        const selectedWorkshopIsValid = availableWorkshops.some((w) => w.sabId === selectedWorkshop);

        const userInformationChanged =
            makeId !== params.make ||
            vehicleClass !== params.vehicleClass ||
            selectedWorkshop !== params.workshopId ||
            email !== params.email ||
            licensePlate !== params.licensePlate;

        if (hasNeededInformation && yourCarFormValid && userInformationChanged && selectedWorkshopIsValid) {
            const newParams: hesselApi.ServiceProductsParams = {
                make: makeId,
                vehicleClass,
                workshopId: selectedWorkshop,
                email,
                licensePlate,
            };
            setParams(newParams);
            getAvailableServiceProducts(newParams);
        }
    }, [
        loading,
        makeId,
        params,
        vehicleClass,
        selectedWorkshop,
        getAvailableServiceProducts,
        email,
        licensePlate,
        currentStep,
        stepNumber,
        anyInputsIsInFocus,
        availableWorkshops,
        yourCarFormValid,
    ]);

    const selectedProductsAndSubProducts = useMemo(() => {
        const selectedSubProducts = flattenedSubProducts.filter(({ id }) => selectedServiceProductsIds.includes(id));
        const selectedTopProducts = availableServiceProducts.filter(({ id }) => selectedServiceProductsIds.includes(id));

        const selectedTopAndSubProducts = selectedTopProducts.concat(selectedSubProducts);

        return selectedTopAndSubProducts;
    }, [availableServiceProducts, flattenedSubProducts, selectedServiceProductsIds]);

    const youtubeClickHandler = useCallback((watchId: string, serviceProduct: hesselApi.ServiceProduct) => {
        if (watchId !== undefined) {
            setYoutubeVideoModalContent({
                title: serviceProduct.name,
                subTitle: serviceProduct.description,
                watchId: watchId,
            });
            setShowYoutubeVideoModal(true);
        }
    }, []);

    const hasAgreements = useAgreements();

    const { trackBooking } = useGtmTracking();
    const tracker = trackBooking();

    useEffect(() => {
        if (currentStep === stepNumber) {
            tracker.checkoutStep(2, []);
        }
    }, [currentStep, stepNumber, tracker]);

    return (
        <>
            {isComplete ? (
                <ServiceSummary
                    selectedProducts={selectedProductsAndSubProducts}
                    selectedProductOptions={flattenedProductsOptions.filter(({ id }) => selectedServiceProductsIds.includes(id))}
                    freeServiceLabel={content.freeServiceLabel}
                    comment={comment}
                />
            ) : (
                <>
                    <StyledCustomerAgreementTextContainer>
                        <CustomerAgreementText
                            hasAgreements={hasAgreements}
                            preLinkText={content.descriptionText}
                            linkText={content.openAgreementsModalText}
                        />
                    </StyledCustomerAgreementTextContainer>
                    {availableServiceProducts.map((serviceProduct) => (
                        <ServiceCardWrapper key={serviceProduct.id}>
                            <ServiceCard
                                content={content}
                                serviceProduct={serviceProduct}
                                onYoutubeModalLinkClicked={(watchId) => youtubeClickHandler(watchId, serviceProduct)}
                                isServiceInspection={content.serviceInspectionLinks?.some(({ serviceId }) => serviceId === serviceProduct.id)}
                            ></ServiceCard>
                        </ServiceCardWrapper>
                    ))}
                    <CommentWrapper>
                        <Comment />
                    </CommentWrapper>
                    <YoutubeVideoModal
                        title={youtubeVideoModalContent.title}
                        subTitle={youtubeVideoModalContent.subTitle}
                        watchId={youtubeVideoModalContent.watchId}
                        isVisible={showYoutubeVideoModal}
                        onClose={() => {
                            setShowYoutubeVideoModal(false);
                        }}
                    />
                </>
            )}
        </>
    );
};
