import React, { useState, FC, useEffect } from 'react';
import { useSpring, config } from 'react-spring';
import { useMeasure } from 'react-use';
import { SvgIcon } from '../svg-icon';
import { IAccordion } from './accordion.props';
import {
    AnimatedWrapper,
    StyledContent,
    StyledHeader,
    Header,
    StyledWrapper,
    StyledIcon,
    PlusIndicator,
    PlusWrapper,
    StyledAccordion,
    StyledAccordionHeaderSpot,
} from './accordion.styled';
import { isNullOrEmpty } from '../../../utils/helpers';

export const Accordion: FC<IAccordion> = ({
    title,
    titleType,
    titleSize,
    children,
    onClick: handleClick,
    isOpen,
    index,
    variant = 'secondary',
    textColor,
    backgroundColor,
    headerSize = 'sm',
    border = 'top and bottom',
    contentPadding = 0,
    customTitle,
    toggleIndicator = 'chevron',
    alignCenter = false,
}) => {
    const defaultHeight: string | undefined = '0px';

    // Gets the height of the element (ref)
    const [heightRef, { height }] = useMeasure();

    const [contentHeight, setContentHeight] = useState(defaultHeight || height);

    useEffect(() => {
        //Sets initial height
        setContentHeight(height);
    }, [height]);

    // Animations
    const expand = useSpring({
        config: { ...config.slow, friction: 50 },
        height: isOpen ? `${contentHeight}px` : defaultHeight,
        overflow: !isOpen ? 'hidden' : '',
    });

    const fadeContent = useSpring({
        config: { ...config.slow, friction: 50 },
        opacity: isOpen ? 1 : 0,
        delay: 300,
    });

    const spin = useSpring({
        transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    });

    const turn = useSpring({
        config: {
            ...config.stiff,
            duration: 20,
        },
        transform: isOpen ? 'rotate(0deg)' : 'rotate(90deg)',
    });

    return (
        <StyledAccordion variant={variant} border={border} background={backgroundColor ?? ''}>
            <StyledHeader onClick={() => handleClick(index)} contentPadding={contentPadding} alignCenter={alignCenter}>
                {customTitle ? (
                    customTitle
                ) : isNullOrEmpty(titleSize) ? (
                    <Header variant={variant} size={headerSize} textColor={textColor ?? ''} alignCenter={alignCenter} as={titleType}>
                        {title}
                    </Header>
                ) : (
                    <StyledAccordionHeaderSpot
                        headerType={titleType}
                        headerSize={titleSize}
                        variant={variant}
                        size={headerSize}
                        textColor={textColor ?? ''}
                        alignCenter={alignCenter}
                    >
                        {title}
                    </StyledAccordionHeaderSpot>
                )}
                {toggleIndicator === 'chevron' && (
                    <StyledIcon style={spin}>
                        <SvgIcon iconName="chevron/down" color={variant === 'primary' ? 'primary' : 'light'} />
                    </StyledIcon>
                )}
                {toggleIndicator === 'plus' && (
                    <PlusWrapper>
                        <PlusIndicator style={turn}></PlusIndicator>
                        <PlusIndicator></PlusIndicator>
                    </PlusWrapper>
                )}
            </StyledHeader>
            <AnimatedWrapper style={expand}>
                <StyledContent ref={heightRef as any} variant={variant} contentPadding={contentPadding} textColor={textColor ?? ''}>
                    <StyledWrapper style={fadeContent}>{children}</StyledWrapper>
                </StyledContent>
            </AnimatedWrapper>
        </StyledAccordion>
    );
};
