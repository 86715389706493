import { FC } from 'react';
import {
    StyledCartCta,
    StyledDiscountRemoveLink,
    StyledSafePaymentContainer,
    StyledSummaryFooter,
    StyledSummaryFooterItemContainer,
    StyledSummaryHeader,
    StyledSummaryLine,
    StyledSummaryLineKey,
    StyledSummaryLineValue,
    StyledSummaryPriceTotalWrapper,
    StyledSummaryWrapper,
} from './cart-summary.styled';
import { getShopPrice } from '../../../../utils/helpers';
import { CartStoreContext } from '../../../../lib/state/shop/cart/cart.store';
import router from 'next/router';
import { sharedTypes } from '../../../../lib/api/models/umbraco';
import { MEDIA_URL } from '../../../../utils/environment-constants';
import { SvgIcon } from '../../../shared/svg-icon';

type IProps = {
    paymentIcons: Array<sharedTypes.Image>;
};

export const CartSummary: FC<IProps> = ({ paymentIcons }) => {
    const { cart, checkoutUrl } = CartStoreContext.useStoreState((state) => state);
    const { removeVoucherFromCart } = CartStoreContext.useStoreActions((actions) => actions);
    if (!cart) return null;
    return (
        <div>
            <StyledSummaryHeader>Din ordre</StyledSummaryHeader>
            <StyledSummaryWrapper>
                <StyledSummaryLine>
                    <StyledSummaryLineKey>Subtotal</StyledSummaryLineKey>
                    <StyledSummaryLineValue>{getShopPrice(cart.totalPrice)}</StyledSummaryLineValue>
                </StyledSummaryLine>
                {cart.discounts.map((x) => {
                    return (
                        <StyledSummaryLine key={x.couponCode}>
                            <StyledSummaryLineKey>
                                {x.couponCode.length > 0 ? `Gavekode, "${x.discountName}".` : `${x.discountName}`}
                                {x.couponCode.length > 0 && (
                                    <StyledDiscountRemoveLink onClick={() => removeVoucherFromCart({ voucherCode: x.couponCode, cart })}>
                                        Fjern
                                    </StyledDiscountRemoveLink>
                                )}
                            </StyledSummaryLineKey>
                            <StyledSummaryLineValue>{getShopPrice(x.discountAmount * -1)}</StyledSummaryLineValue>
                        </StyledSummaryLine>
                    );
                })}
                <StyledSummaryLine>
                    <StyledSummaryLineKey>{cart.delivery ? cart.delivery.label : 'Levering / afhentning fra'}</StyledSummaryLineKey>
                    <StyledSummaryLineValue>{getShopPrice(cart.delivery ? cart.delivery.price : 0)}</StyledSummaryLineValue>
                </StyledSummaryLine>
            </StyledSummaryWrapper>
            <StyledSummaryPriceTotalWrapper>
                <span>Pris i alt inkl. moms</span>
                <span>{getShopPrice(cart.totalPrice)}</span>
            </StyledSummaryPriceTotalWrapper>
            <StyledCartCta onClick={() => router.push(checkoutUrl)} variant="primary">
                GÅ TIL KASSEN
            </StyledCartCta>
            <StyledSummaryFooter>
                <StyledSafePaymentContainer>
                    <SvgIcon iconName="guarantee" />
                    <span>Sikker betaling</span>
                </StyledSafePaymentContainer>
                <StyledSummaryFooterItemContainer>
                    {paymentIcons.map((x, idx) => {
                        return <img key={idx} src={`${MEDIA_URL}${x.src}`} alt={x.name} height={30} />;
                    })}
                </StyledSummaryFooterItemContainer>
            </StyledSummaryFooter>
        </div>
    );
};
