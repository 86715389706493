import { animated } from 'react-spring';
import styled from 'styled-components';

export const StyledToolTipContainer = styled.div`
    position: relative;
`;

export const StyledToolTipText = styled(animated.span)<{ disableMinWidth: boolean }>`
    position: absolute;
    bottom: calc(100% + 10px);
    right: -10px;
    background-color: #d8d8d8;
    padding: 15px;
    color: #636363;
    min-width: ${(props) => (props.disableMinWidth ? 'unset' : '250px')};
    max-width: 250px;
    line-height: 16px;
    font-size: 11px;

    &:after {
        content: ' ';
        width: 15px;
        height: 15px;
        background-color: #d8d8d8;
        position: absolute;
        bottom: 0;
        right: 10px;
        transform: translateY(50%) rotate(45deg);
    }
`;
