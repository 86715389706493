import styled from 'styled-components';

export const StyledHeader = styled.p`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    margin-bottom: 15px;
`;

export const StyledDescription = styled.p`
    color: #0b0b0b;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 23px;
    margin-bottom: 52px;
`;

export const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 5rem;
`;

export const RegionWrapper = styled.div``;
