import React, { useEffect, useState, VFC } from 'react';
import { useAccordionController } from '../../../hooks/use-accordion-controller';
import { orgTypes } from '../../../lib/api/models/umbraco';
import { DropdownInput } from '../../forms/inputs';
import { DropdownOption } from '../../shared/date-picker-dropdown/date-picker-dropdown.component';
import { SidePanel } from '../../shared/modals/side-panel';
import { SidePanelLayout } from '../../shared/modals/side-panel-layout/side-panel-layout.component';
import { SimpleAccordion } from '../../shared/simple-accordion';
import {
    AccordionContent,
    PhoneAndDepartmentDropdown,
    StyledAccordionTitle,
    StyledDescription,
    StyledHeader,
    StyledLabel,
    StyledOrgPhone,
} from './emergency-phones-modal.styled';

type EmergencyPhonesModalProps = {
    visible: boolean;
    closeModal: () => void;
    emergencyPhoneNumbers: Array<orgTypes.DepartmentEmergencyNumbers>;
    supportedBrands: Array<orgTypes.SupportedBrand>;
    dealershipAndTruckNumbers: Array<orgTypes.DealerShipAndTruck>;
    currentDealershipId: string;
};

const EmergencyPhonesModalMemo: VFC<EmergencyPhonesModalProps> = ({
    visible,
    closeModal,
    emergencyPhoneNumbers,
    supportedBrands,
    dealershipAndTruckNumbers,
    currentDealershipId,
}) => {
    const { registerAccordion } = useAccordionController();

    const [selectedTruckDealership, setSelectedTruckDealership] = useState<DropdownOption<string> | undefined>(undefined);

    useEffect(() => {
        const truckDealership = dealershipAndTruckNumbers.find((x) => x.autolineId === currentDealershipId);
        if (truckDealership) {
            setSelectedTruckDealership({
                value: truckDealership.autolineId,
                displayValue: truckDealership.displayName,
            });
        }
    }, [currentDealershipId, dealershipAndTruckNumbers]);

    const closeSidePanel = () => {
        const { onClick: resetAccordionList } = registerAccordion(-1);
        resetAccordionList?.();
        closeModal();
    };

    return (
        <SidePanel cancelAction={() => closeSidePanel()} isVisible={visible}>
            <SidePanelLayout closeSidePanel={() => closeSidePanel()}>
                <StyledHeader>Nødservice telefon</StyledHeader>

                <StyledDescription>
                    Det er altid beroligende at være godt sikret, hvis uheldet er ude. Ved en punktering, et mindre uheld, en ulykke eller hærværk
                    sørger vi for, at du kommer sikkert videre.
                </StyledDescription>

                <StyledDescription>
                    I så tilfælde finder du vores nødservice telefonnumre herunder, hvor der vil være venlig respons døgnet rundt:
                </StyledDescription>

                {supportedBrands
                    .sort((a, b) => a.brand.localeCompare(b.brand, 'da-DK'))
                    .map((sb, index) => {
                        const phoneNumbers = emergencyPhoneNumbers.find((x) => x.brand === sb.brand);
                        return (
                            <SimpleAccordion
                                key={`org-emergency-phones:${sb.brand}:${index}`}
                                title={''}
                                border={index === supportedBrands.length - 1 ? 'top and bottom' : 'top'}
                                headerSize="0px"
                                textColor={'0B0B0B'}
                                {...registerAccordion(index)}
                                contentPadding={'20.5px 0px'}
                                toggleIndicator="plus"
                                customTitle={<StyledAccordionTitle>{sb.brand}</StyledAccordionTitle>}
                            >
                                <AccordionContent>
                                    {sb.brand === 'Mercedes-Benz' && phoneNumbers?.alias === 'mercedesBenzNumbers' ? (
                                        <React.Fragment key={`${sb.brand}:${index}`}>
                                            {sb.vehiclesInWorkshop.some((x) => x === 'Cars' || x === 'Vans') && (
                                                <StyledOrgPhone>
                                                    <StyledLabel>Personbil/varebil:</StyledLabel>{' '}
                                                    <a href={`tel:${phoneNumbers.carsAndVansMainNumber}`}>{phoneNumbers.carsAndVansMainNumber}</a>
                                                </StyledOrgPhone>
                                            )}
                                            <StyledOrgPhone>
                                                <StyledLabel>Reservedele:</StyledLabel>{' '}
                                                <a href={`tel:${phoneNumbers.sparePartsNumber}`}>{phoneNumbers.sparePartsNumber}</a>
                                            </StyledOrgPhone>
                                            <StyledOrgPhone>
                                                <StyledLabel>Bus:</StyledLabel> <a href={`tel:${phoneNumbers.busNumber}`}>{phoneNumbers.busNumber}</a>
                                            </StyledOrgPhone>
                                            {sb.vehiclesInWorkshop.some((x) => x === 'Trucks') && (
                                                <PhoneAndDepartmentDropdown>
                                                    <StyledOrgPhone>
                                                        <StyledLabel>Lastbil:</StyledLabel>{' '}
                                                        {selectedTruckDealership ? (
                                                            <a href={`tel:${selectedTruckDealership.value}`}>
                                                                {
                                                                    dealershipAndTruckNumbers.find(
                                                                        (x) => x.autolineId === selectedTruckDealership.value
                                                                    )?.emergencyNumber
                                                                }
                                                            </a>
                                                        ) : (
                                                            'Vælg afdeling for at se telefonnr.'
                                                        )}
                                                    </StyledOrgPhone>

                                                    {selectedTruckDealership ? (
                                                        <DropdownInput
                                                            options={dealershipAndTruckNumbers
                                                                .map((x) => ({
                                                                    value: x.autolineId,
                                                                    displayValue: x.displayName,
                                                                }))
                                                                .sort((a, b) => {
                                                                    if (a.displayValue < b.displayValue) {
                                                                        return -1;
                                                                    }
                                                                    if (a.displayValue > b.displayValue) {
                                                                        return 1;
                                                                    }
                                                                    return 0;
                                                                })}
                                                            value={selectedTruckDealership}
                                                            id="org-emergency-phones-dropdown"
                                                            label={'Afdeling'}
                                                            placeholder={'Vælg afdeling'}
                                                            disabled={supportedBrands.length === 0}
                                                            onChange={(option) => {
                                                                if (option) {
                                                                    setSelectedTruckDealership(option);
                                                                }
                                                            }}
                                                            isValid={true}
                                                            canValidateInputField={false}
                                                            onInputBlur={() => null}
                                                        />
                                                    ) : null}
                                                </PhoneAndDepartmentDropdown>
                                            )}
                                        </React.Fragment>
                                    ) : null}

                                    {(sb.brand === 'Ford' && phoneNumbers?.alias === 'fordNumbers') ||
                                    (sb.brand === 'Dacia' && phoneNumbers?.alias === 'daciaNumbers') ||
                                    (sb.brand === 'FUSO' && phoneNumbers?.alias === 'fusoNumbers') ||
                                    (sb.brand === 'Smart' && phoneNumbers?.alias === 'smartNumbers') ? (
                                        <React.Fragment key={`${sb.brand}:${index}`}>
                                            <StyledOrgPhone>
                                                <StyledLabel>Hovednummer:</StyledLabel>{' '}
                                                <a href={`tel:${phoneNumbers.mainNumber}`}>{phoneNumbers.mainNumber}</a>
                                            </StyledOrgPhone>
                                        </React.Fragment>
                                    ) : null}

                                    {sb.brand === 'Renault' && phoneNumbers?.alias === 'renaultNumbers' ? (
                                        <React.Fragment key={`${sb.brand}:${index}`}>
                                            <StyledOrgPhone>
                                                <StyledLabel>Hovednummer:</StyledLabel>{' '}
                                                <a href={`tel:${phoneNumbers.mainNumber}`}>{phoneNumbers.mainNumber}</a>
                                            </StyledOrgPhone>
                                            <StyledOrgPhone>
                                                <StyledLabel>I udlandet:</StyledLabel>{' '}
                                                <a href={`tel:${phoneNumbers.abroadNumber}`}>{phoneNumbers.abroadNumber}</a>
                                            </StyledOrgPhone>
                                            <StyledOrgPhone>
                                                <StyledLabel>For elbiler:</StyledLabel>{' '}
                                                <a href={`tel:${phoneNumbers.elCarsNumber}`}>{phoneNumbers.elCarsNumber}</a>
                                            </StyledOrgPhone>
                                            <StyledOrgPhone>
                                                <StyledLabel>Reservedele:</StyledLabel>{' '}
                                                <a href={`tel:${phoneNumbers.sparePartsNumber}`}>{phoneNumbers.sparePartsNumber}</a>
                                            </StyledOrgPhone>
                                        </React.Fragment>
                                    ) : null}

                                    {sb.brand === 'Setra' && phoneNumbers?.alias === 'setraNumbers' ? (
                                        <React.Fragment key={`${sb.brand}:${index}`}>
                                            <StyledOrgPhone>
                                                <StyledLabel>Bus:</StyledLabel> <a href={`tel:${phoneNumbers.busNumber}`}>{phoneNumbers.busNumber}</a>
                                            </StyledOrgPhone>
                                        </React.Fragment>
                                    ) : null}
                                </AccordionContent>
                            </SimpleAccordion>
                        );
                    })}
            </SidePanelLayout>
        </SidePanel>
    );
};

export const EmergencyPhonesModal = React.memo(EmergencyPhonesModalMemo);
