import { FC } from 'react';
import {
    StyledText,
    StyledImage,
    StyledName,
    StyledShopProductCard,
    StyledFooter,
    StyledPriceNowAndThen,
    StyledPriceNow,
    ImageAndBadgeWrapper,
    StyledProductInformationWrapper,
    StyledPriceThen,
} from './shop-product-card.styled';
import { ShopProductDetails } from '../../../lib/api/models/shop';
import { getImageCropUrl, getShopPrice } from '../../../utils/helpers';
import { StockIndicator } from '../stock-indicator/stock-indicator.component';

type ShopProductCardProps = {
    className?: string;
    product: ShopProductDetails;
};

export const ShopProductCard: FC<ShopProductCardProps> = ({ className, product }: ShopProductCardProps) => {
    return (
        <StyledShopProductCard className={className}>
            <ImageAndBadgeWrapper>
                <StyledImage src={getImageCropUrl(product.resources[0].data, 'productlist')} alt={product.name} />

                {/* <StyledBadgeWrapper>
                    {badgeList?.map((badge) => (
                        <StyledBadge key={badge}>{badge}</StyledBadge>
                        ))}
                    </StyledBadgeWrapper> */}
            </ImageAndBadgeWrapper>
            <StyledProductInformationWrapper>
                <StyledName>{product.name}</StyledName>
                {product.shortDescription && product.shortDescription.length > 0 ? (
                    <StyledText>{`${product.shortDescription.substring(0, 140)}${product.shortDescription.length > 140 ? '...' : ''}`}</StyledText>
                ) : null}
                <StyledFooter>
                    <StockIndicator inventory={1} stockStatus={product.stockStatus} />
                    <StyledPriceNowAndThen>
                        {product.originalPrice !== product.price ? (
                            <>
                                <StyledPriceThen>{getShopPrice(product.originalPrice)}</StyledPriceThen>
                                <StyledPriceNow color="primary">{getShopPrice(product.price)}</StyledPriceNow>
                            </>
                        ) : (
                            <StyledPriceNow color="default">{getShopPrice(product.price)}</StyledPriceNow>
                        )}
                    </StyledPriceNowAndThen>
                </StyledFooter>
            </StyledProductInformationWrapper>
        </StyledShopProductCard>
    );
};
