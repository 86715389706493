import { action, thunk } from 'easy-peasy';
import { createContextStoreWithRuntimeModel } from '../helpers/context-store.helpers';
import { BookingReceiptActions, BookingReceiptState, BookingReceiptThunks, ReceiptStore } from './booking-receipt.types';
import { fetchContentAPI } from '../../api/helpers';
import { umbraco } from '../../api';

const bookingReceiptDefaultState = (): BookingReceiptState => ({
    subscriptionPage: undefined,
    siteSettings: undefined,
    relevantPromotion: undefined,
});

const bookingReceiptActions = (): BookingReceiptActions => ({
    setSubscriptionPage: action((state, payload) => {
        state.subscriptionPage = payload;
    }),
    setSiteSettings: action((state, payload) => {
        state.siteSettings = payload;
    }),
    setRelevantPromotion: action((state, payload) => {
        state.relevantPromotion = payload;
    }),
});

const bookingReceiptThunks = (): BookingReceiptThunks => ({
    fetchBenefitAgreementContentAsync: thunk(async (actions, _, helpers) => {
        const storeState = helpers.getState();
        if (storeState.subscriptionPage != null) return;

        const contentUrl = storeState.relevantPromotion?.benefitAgreementPage?.url;
        if (!contentUrl) return;

        const [content, error] = await fetchContentAPI<umbraco.PlusSubscriptionPage>('hessel' + contentUrl);
        if (error == null && content != null) actions.setSubscriptionPage(content);
    }),
});

export const BookingReceiptStore = createContextStoreWithRuntimeModel<ReceiptStore, BookingReceiptState>(
    () => ({
        ...bookingReceiptDefaultState(),
        ...bookingReceiptActions(),
        ...bookingReceiptThunks(),
    }),
    { name: 'ReceiptStore' }
);
