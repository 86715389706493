import { FC } from 'react';
import { StyledSpecsRibbonItem, StyledSpecsRibbonItemLabel, StyledSpecsRibbonItemUnit, StyledSpecsRibbonItemValue } from './specs-ribbon-item.styled';

export type SpecsRibbonItemProps = {
    className?: string;
    label: string;
    value: string;
    unit?: string;
};

export const SpecsRibbonItem: FC<SpecsRibbonItemProps> = (props) => {
    return (
        <StyledSpecsRibbonItem className={props.className}>
            <StyledSpecsRibbonItemLabel>{props.label}</StyledSpecsRibbonItemLabel>
            <StyledSpecsRibbonItemValue>
                {props.value} <StyledSpecsRibbonItemUnit>{props.unit}</StyledSpecsRibbonItemUnit>
            </StyledSpecsRibbonItemValue>
        </StyledSpecsRibbonItem>
    );
};
