import styled from 'styled-components';

export const StyledPriceListLink = styled.a`
    color: ${(props) => props.theme.palette.common.black};
    display: inline-grid;
    font: 500 12px/24px ${({ theme }) => theme.typography.fontFamily}, Sans-serif;
    gap: 5px;
    grid: auto / 25px 1fr;

    &:focus-visible {
        outline: 1px dotted currentColor;
        outline-offset: 2px;
    }
`;

export const StyledPriceListLinkIcon = styled.img`
    height: 25px;
    object-fit: contain;
    width: 25px;
`;

export const StyledPriceListLinkLabel = styled.span``;
