import { VFC } from 'react';
import { Button } from '../../shared';
import { SidePanel } from '../../shared/modals/side-panel';
import { OrderSummaryDesktop } from '../../hire/hire-order-summary/desktop-version';
import { Header, Description, CtaWrapper, Disclaimer } from './mini-basket-modal.styled';
import { hesselViewModels } from '../../../lib/view-models';
import { MiniBasketStore } from '../../../lib/state/hessel-site/mini-basket';
import { ProductDetailsStore } from '../../../lib/state/hessel-site/product-details';
import { umbraco } from '../../../lib/api';
import { SidePanelLayout } from '../../shared/modals/side-panel-layout/side-panel-layout.component';
import { formatPrice, getFormattedValue } from '../../../utils/helpers';
import { convertProductToGtmData } from '../../../hooks/lead-checkout/use-hire-gtm-tracking-data';
import { useGtmTracking } from '../../../gtm-tracking/hooks/use-gtm-tracking';
import { useRouter } from 'next/router';
import { Abbreviations, Units } from '../../../constants/units-and-abbreviations';
import { LoadFromMiniBasketKey, lead_checkout_url } from '../../../constants/site-consts';

type MiniBasketModalProps = {
    showMiniBasket: boolean;
    hideMiniBasket: () => void;
    miniBasketSettings: umbraco.HireMiniBasketSettings;
};

const generatePdpUrl = (product: hesselViewModels.ProductDetails, colorName?: string) => {
    if (product.availability === 'Order') {
        const url = `${product.url}?color=${colorName}`.toLocaleLowerCase();
        return `/${url}`;
    }

    if (product.availability === 'InStock') {
        return product.url.toLocaleLowerCase();
    }

    console.error('Invalid product card type');

    return '';
};

export const MiniBasketModal: VFC<MiniBasketModalProps> = ({ showMiniBasket, hideMiniBasket, miniBasketSettings }) => {
    // Product details store
    const { fillStoreFromMiniBasket } = ProductDetailsStore.useStoreActions((actions) => actions);
    const { persist: pdpPersist } = ProductDetailsStore.useStore();

    // Mini basket
    const { persist: miniBasketPersist, getState: getMiniBasketStoreState } = MiniBasketStore.useStore();
    const { productData } = MiniBasketStore.useStoreState((state) => state);
    const { setProductData } = MiniBasketStore.useStoreActions((state) => state);

    // GTM
    const { trackHireLeadCheckout } = useGtmTracking();
    const leadCheckoutTracker = trackHireLeadCheckout();

    const router = useRouter();

    if (!productData || !productData.selectedProduct) {
        return (
            <SidePanel isVisible={showMiniBasket} cancelAction={() => hideMiniBasket()}>
                <SidePanelLayout closeSidePanel={() => hideMiniBasket()}>
                    <p>{miniBasketSettings.emptyMiniBasketText}</p>
                </SidePanelLayout>
            </SidePanel>
        );
    }

    const imageUrl =
        productData.selectedProduct?.resources.find((x) => x.type === 'image' && x.colorCode === productData.selectedColor?.code) ??
        productData.selectedProduct?.resources.find((x) => x.type === 'image');

    const totalPriceWithMoms =
        (productData.selectedProduct?.purchaseTypes?.hire?.baseMonthlyPrice ?? 0) +
        productData.extraEquipmentPriceSum +
        productData.extraEquipmentPackagePriceSum +
        productData.optionalEquipmentPriceSum +
        (productData.selectedColor?.monthlyHirePrice ?? 0);

    const hireDownPayment = 0;
    const durationAsNumber = +(productData?.selectedLeasingPeriod?.value ?? 0);
    const totalPriceWithMomsTimesDuration = totalPriceWithMoms * durationAsNumber;
    const hireTotalPriceInEntirePeriod = formatPrice(totalPriceWithMomsTimesDuration + hireDownPayment);

    return (
        <SidePanel isVisible={showMiniBasket} cancelAction={() => hideMiniBasket()}>
            <SidePanelLayout closeSidePanel={() => hideMiniBasket()}>
                <Header>{miniBasketSettings?.header}</Header>
                <Description>{miniBasketSettings?.description}</Description>
                <OrderSummaryDesktop
                    imageUrl={imageUrl && imageUrl.type === 'image' ? imageUrl?.url : '/'}
                    colorName={productData.selectedColor?.name ?? ''}
                    colorImageUrl={productData.selectedColor?.resource.data ?? ''}
                    model={productData?.selectedProduct?.variantTitle ?? ''}
                    leasingPeriod={productData?.selectedLeasingPeriod?.displayValue ?? ''}
                    carPrice={`${getFormattedValue(
                        productData.selectedProduct?.purchaseTypes?.hire.baseMonthlyPrice + (productData.selectedColor?.monthlyHirePrice ?? 0)
                    )} ${Abbreviations.KR_SLASH_MD}`}
                    extraEquipmentPrice={`${getFormattedValue(productData.extraEquipmentPriceSum + productData.extraEquipmentPackagePriceSum)} ${
                        Abbreviations.KR_SLASH_MD
                    }`}
                    optionalEquipmentPrice={`${getFormattedValue(productData.optionalEquipmentPriceSum)} ${Abbreviations.KR_SLASH_MD}`}
                    totalPriceIncludingMoms={totalPriceWithMoms ? `${getFormattedValue(totalPriceWithMoms)} ${Abbreviations.KR_SLASH_MD}` : '-'}
                    totalPriceExcludingMoms={totalPriceWithMoms ? `${getFormattedValue(totalPriceWithMoms * 0.8)} ${Abbreviations.KR_SLASH_MD}` : '-'}
                    startupDate={undefined}
                    showRemoveButton={true}
                    clearMiniBasket={() => {
                        setProductData(undefined);
                        pdpPersist.clear();
                        miniBasketPersist.clear();
                    }}
                    availability={productData.selectedProduct.availability}
                    brandTitle={productData.selectedProduct.brandTitle}
                    variantTitle={productData.selectedProduct.variantTitle}
                    showBusinessSection={false}
                />

                <CtaWrapper>
                    <Button
                        onClick={() => {
                            const miniBasketState = getMiniBasketStoreState().productData;

                            if (miniBasketState) {
                                fillStoreFromMiniBasket(miniBasketState);

                                // GTM - Loading first step
                                const convertedProduct = convertProductToGtmData(miniBasketState.selectedProduct, miniBasketState.selectedColor);
                                if (convertedProduct && convertedProduct.carData) {
                                    leadCheckoutTracker.stepLoaded({ event: 'begin_checkout', ecommerce: convertedProduct.carData.ecommerce });
                                }

                                hideMiniBasket();
                                router.push(lead_checkout_url);
                            }
                        }}
                        variant="primary"
                    >
                        <p>{miniBasketSettings?.goToCheckoutButton}</p>
                    </Button>

                    <Button
                        onClick={() => {
                            if (productData.selectedProduct) {
                                const miniBasketState = getMiniBasketStoreState().productData;
                                if (miniBasketState) {
                                    sessionStorage.setItem(LoadFromMiniBasketKey, 'true');
                                    router.push(generatePdpUrl(productData.selectedProduct, productData.selectedColor?.name));
                                    hideMiniBasket();
                                }
                            }
                        }}
                        variant="light"
                    >
                        <p>{miniBasketSettings?.seeTheCarAgainButton}</p>
                    </Button>
                </CtaWrapper>
                <Disclaimer>
                    Totalpris i hele leasingperioden: {hireTotalPriceInEntirePeriod} kr. Total antal kilometer i hele leasingperioden:{' '}
                    {formatPrice(productData.selectedProduct.purchaseTypes.hire.kilometersPerMonth * 6)} {Units.km} Løbetid:{' '}
                    {productData?.selectedLeasingPeriod?.displayValue ?? ''} måneder.
                </Disclaimer>
            </SidePanelLayout>
        </SidePanel>
    );
};
