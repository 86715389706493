import styled from 'styled-components';

export const StyledDeliveryAddressHeader = styled.p`
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
`;

export const StyledCustomerInfo = styled.p`
    font-size: 12px;
    line-height: 17px;
`;
