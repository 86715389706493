import styled from 'styled-components';
import { device } from '../../../lib/media-query';

/**
 * Note: Add newer styling as they are decide
 */
export const StyledRichTextContent = styled.div<{ width?: string }>`
    @media ${device.laptop} {
        align-self: center;
        width: ${(props) => props.width ?? '100%'};
    }

    h1,
    h2,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Corporate E';
        font-weight: 700;
        color: unset;
        margin-bottom: 10px;
        word-break: break-word;
        hyphens: auto;
    }

    h1 {
        font-size: 40px;
    }

    h2 {
        font-size: 30px;
    }

    h3 {
        font-size: 25px;
    }

    p {
        font-size: 14px;
    }
    p:last-of-type {
        margin-bottom: 25px;
    }

    & ul,
    & ol {
        font-weight: normal;
        list-style: revert;
        padding: revert;
        margin: revert;
    }

    & ul li,
    & ol li {
        font-weight: normal;
    }
    strong * {
        font-weight: bold;
    }
    a {
        font-size: inherit;
        font-weight: inherit;
    }
`;
