import { VFC } from 'react';
import { PdpVanMeasurementModal } from '../../../lib/api/models/umbraco/product-details';
import { TabbedCard, TabbedCardTab } from '../../shared/tabbed-card/tabbed-card.component';
import { ConfigurationAccordion } from '../configuration-accordion/configuration-accordion.component';
import { EquipmentAndAddons } from '../equipment-and-addons/equipment-and-addons.component';

type Props = {
    vanHeightModal: PdpVanMeasurementModal;
    vanLengthModal: PdpVanMeasurementModal;
};

export const CarConfigurationCard: VFC<Props> = (props) => {
    const tab: TabbedCardTab = {
        label: 'Eksteriør & Motor',
        content: <ConfigurationAccordion {...props} />,
    };

    const tab2: TabbedCardTab = {
        label: 'Udstyr & Tilvalg',
        content: <EquipmentAndAddons />,
    };

    return <TabbedCard tabs={[tab, tab2]} />;
};
