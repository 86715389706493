import styled from 'styled-components';
import { UmbracoForms } from '../../../spots/umbraco-forms';

export const HeaderSection = styled.header``;

export const Header = styled.p`
    font: 700 25px/30px 'Corporate E', Serif;
    margin: 0 0 14px;
`;

export const SubHeader = styled.p`
    font-weight: 400;
`;

export const VanContactUsModalBody = styled(UmbracoForms)``;
