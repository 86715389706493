import styled from 'styled-components';
import { Tag } from '../../shared/tag';

export const StyledStandardEquipment = styled.section`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

export const StyledHeader = styled.p`
    color: #0b0b0b;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 26px;
`;

export const StyledEquipmentList = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 5px;
    flex-wrap: wrap;
    max-width: 470px;
`;

export const StyledEquipment = styled(Tag)<{ hasDescription: boolean }>`
    height: 25px;
    background-color: #f1f1f2;
    color: ${(props) => (props.hasDescription ? props.theme.palette.primary.main : '#231f20')};
    cursor: ${(props) => (props.hasDescription ? 'pointer' : 'default')};
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
`;

export const StyledSeeMore = styled(Tag)`
    height: 25px;
    background-color: #f1f1f2;
    color: #fff;
    background-color: ${({ theme }) => theme.palette.primary.main};
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
`;
