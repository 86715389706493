import { VFC, useMemo } from 'react';
import { Accordion } from '../../accordion';

import {
    CustomerInfoMobile,
    StyledFooter,
    InfoSection,
    Content,
    OpeningHoursWrapper,
    OpeningHoursContent,
    MasterData,
    PaymentWrapper,
    CreditsWrapper,
    CreditCard,
    CtaHandlerWrapperMobile,
} from './footer-mobile.styled';

import Link from 'next/link';
import { SmallText, TextGroup, StyledBrand, SmallLink, OpeningHoursTitle, StyledFooterLogo } from '../footer.styled';
import { useAccordionController } from '../../../../hooks/use-accordion-controller';
import { umbraco } from '../../../../lib/api';
import { CtaHandler } from '../../../plus-sites-shared/content-handlers';
import { MEDIA_URL } from '../../../../utils/environment-constants';
import { MenuContext } from '../../../mega-menu/mega-menu.component';

type IProps = umbraco.FooterContent & {
    menuContext?: MenuContext;
};

export const FooterMobile: VFC<IProps> = ({
    footerLogo,
    companyName,
    companyAddress,
    companyEmail,
    cvr,
    telephone,
    columns,
    erhvervColumns,
    openingHours = [],
    paymentIcons,
    menuContext,
}) => {
    const { registerAccordion } = useAccordionController();

    const columnsToRender = useMemo(() => {
        if (menuContext === 'erhverv') return erhvervColumns;
        return columns;
    }, [columns, erhvervColumns, menuContext]);

    return (
        <StyledFooter>
            <InfoSection>
                {columnsToRender.map((x, index) => {
                    return (
                        <Content key={`footer-mobile-col-${x.title}-${index}`}>
                            <Accordion title={x.title} headerSize="md" border="bottom" {...registerAccordion(index)}>
                                <CtaHandlerWrapperMobile>
                                    <CtaHandler ctas={x.links} pageSection="Footer" />
                                </CtaHandlerWrapperMobile>
                            </Accordion>
                        </Content>
                    );
                })}
            </InfoSection>

            {footerLogo ? <StyledFooterLogo src={MEDIA_URL + footerLogo.src} alt={footerLogo.name} /> : <StyledBrand>Ejner Hessel</StyledBrand>}

            <CustomerInfoMobile>
                <MasterData>
                    <TextGroup>
                        <p>{companyName}</p>
                    </TextGroup>

                    <TextGroup>
                        <p>{companyAddress}</p>
                    </TextGroup>

                    <TextGroup>
                        <SmallText>CVR nr. {cvr}</SmallText>
                    </TextGroup>
                    <TextGroup>
                        <p>Tlf. nr.:</p>
                        <Link href={`tel:${telephone}`} passHref={true}>
                            <SmallLink>{telephone}</SmallLink>
                        </Link>
                    </TextGroup>
                    <TextGroup>
                        <p>E-mail:</p>
                        <Link href={`mailto:${companyEmail}`} passHref={true}>
                            <SmallLink>{companyEmail}</SmallLink>
                        </Link>
                    </TextGroup>
                </MasterData>

                <OpeningHoursWrapper>
                    {openingHours && openingHours.length > 0 ? <OpeningHoursTitle>Åbningstider:</OpeningHoursTitle> : null}
                    <>
                        {openingHours.map((x, index) => {
                            return (
                                <OpeningHoursContent key={`${companyName}-${index}`}>
                                    <SmallText>{x.days}</SmallText>

                                    <SmallText showAsItalic={x.isOpen}>{x.hours}</SmallText>
                                </OpeningHoursContent>
                            );
                        })}
                    </>
                </OpeningHoursWrapper>

                {paymentIcons && paymentIcons.length > 0 ? (
                    <PaymentWrapper>
                        <p>Sikker betaling</p>
                        <CreditsWrapper>
                            {paymentIcons.map((x, index) => {
                                return (
                                    <CreditCard key={`footer-mobile-payment-${index}`}>
                                        <img src={`${MEDIA_URL}/${x.src}`} alt={x.name} loading="lazy" />
                                    </CreditCard>
                                );
                            })}
                        </CreditsWrapper>
                    </PaymentWrapper>
                ) : null}
            </CustomerInfoMobile>
        </StyledFooter>
    );
};
