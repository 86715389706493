import { VFC } from 'react';
import { sharedTypes } from '../../../lib/api/models/umbraco';
import { cmsUrlWithoutSiteId, isExternalLink } from '../../../utils/helpers';
import { Button } from '..';
import Link from 'next/link';
import { LargeLink, StyledCtaText } from './cta-handler.styled';
import { useCtaHandler } from '../../plus-sites-shared/hooks';
import { Size } from '../../../lib/ui-types';

type Props = {
    ctas: Array<sharedTypes.MixedCta>;
    pageSection?: sharedTypes.PageSection;
    fontSize?: Size;
    extraClickActions?: () => void;
};

export const CtaHandler: VFC<Props> = ({ ctas, pageSection, fontSize, extraClickActions }) => {
    const { handleCtaClick } = useCtaHandler();

    return (
        <>
            {ctas.map((cta, index) => {
                if (pageSection === 'Footer') {
                    if (cta.alias === 'cTAWithLink') {
                        return (
                            <Link href={cmsUrlWithoutSiteId(cta.linkUrl?.url)} passHref={true} key={`footer-link-${cta.alias}-${index}`}>
                                <LargeLink target={isExternalLink(cta.linkUrl) ? '_blank' : '_self'}>{cta.text}</LargeLink>
                            </Link>
                        );
                    }

                    if (cta.alias === 'cTAWithAction') {
                        return (
                            <LargeLink key={`footer-link-${cta.alias}-${index}`} onClick={() => handleCtaClick(cta)}>
                                {cta.text}
                            </LargeLink>
                        );
                    }
                }

                return (
                    <Button
                        key={`cta-${cta.alias}-${index}`}
                        variant={cta.color}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleCtaClick(cta, () => extraClickActions?.());
                        }}
                    >
                        <StyledCtaText fontSize={fontSize}>{cta.text}</StyledCtaText>
                    </Button>
                );
            })}
        </>
    );
};
