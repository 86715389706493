import styled from 'styled-components';
import { device } from '../../lib/media-query';
import { Button } from '../shared';
import { SpotHeader } from '../shared/typography/spot-header/spot-header.component';

export const StyledUspList = styled.section`
    padding: 40px 10px;
    @media ${device.tablet} {
        padding: 85px 0;
    }
`;

export const UspBarListWrapper = styled.div<{ uspPerRow: number }>`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

    gap: 2rem;
    background-color: #fff;
    justify-content: center;
    justify-items: center;

    @media ${device.laptopS} {
        grid-template-columns: repeat(${(props) => props.uspPerRow}, 1fr);
        place-items: center;
    }
`;

export const StyledSpotHeader = styled.h2`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 5px;
    line-height: 24px;
`;

export const StyledSpotHeaderComponent = styled(SpotHeader)`
    text-align: center;
    margin-bottom: 5px;
`;

export const StyledSpotDescription = styled.div`
    & p {
        font-weight: normal;
        text-align: center;
        color: #0b0b0b;
        font-size: 14px;
        letter-spacing: 0;
        text-align: center;
    }
`;

export const StyledHeader = styled.h2`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    margin-bottom: 15px;
`;

export const StyledDescription = styled.p`
    color: #0b0b0b;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 23px;
    font-weight: normal;
`;

export const StyledCloseButton = styled(Button)`
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
`;

export const StyledCta = styled(Button)`
    margin-top: 41px;
    margin-left: auto;
    margin-right: auto;
`;
