import styled from 'styled-components';
import { device } from '../../../lib/media-query';

export const BorderBox = styled.div`
    color: #0b0b0b;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    position: relative;
    overflow: auto;
`;

export const Table = styled.table`
    width: 100%;
    border-bottom: 1px solid #d1d2d4;
    border-spacing: 0;
`;

export const Thead = styled.thead``;

export const Tbody = styled.tbody`
    & > tr:nth-of-type(1) > td,
    & > tr:nth-of-type(1) > th,
    & > tr:last-child > td,
    & > tr:last-child > th {
        border-top: 1px solid #d1d2d4;
    }
`;

export const Th = styled.th`
    min-width: 150px;
    font-size: 12px;

    @media ${device.tablet} {
        font-size: 13px;
    }
`;

export const StickyTh = styled.th<{ isHighlighted?: boolean }>`
    position: sticky;
    left: 0px;

    min-width: 200px;

    text-align: left;
    padding: 20px;

    background-color: ${(props) => (props.isHighlighted ? '#f1f1f2' : '#f7f7f8')};

    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);

    z-index: 1000;
    @media ${device.tablet} {
        max-width: 370px;
        z-index: revert;
    }

    @media ${device.laptopS} {
        box-shadow: none;
        z-index: revert;
    }
`;

export const Tr = styled.tr<{ isHighlighted?: boolean }>`
    background-color: ${(props) => (props.isHighlighted ? '#f1f1f2' : '#f7f7f8')};

    & > td:not(:first-child):not(:last-child) {
        border-right: 1px solid #d1d2d4;
    }
    & > td:nth-child(2) {
        border-left: 1px solid #d1d2d4;
    }
`;

export const Td = styled.td`
    & > p {
        text-align: center;
        color: #0a2841;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 14px;
        text-align: center;
    }
`;

export const CellWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    @media ${device.tablet} {
        grid-template-columns: 30px 1fr;
        grid-column-gap: 1.5rem;
    }
`;

export const StyledIcon = styled.img`
    display: block;
    display: none;
    @media ${device.tablet} {
        display: revert;
    }
    width: 35px;
`;

export const StyledTitle = styled.p`
    font-size: 12px;

    @media ${device.tablet} {
        font-size: 13px;
    }
`;
