import styled from 'styled-components';

export const specsRibbonButtonLineHeight = 1.71;

export const StyledSpecsRibbonButtonWrapper = styled.li`
    list-style: none;
    text-align: center;
`;

export const StyledSpecsRibbonButton = styled.button.attrs(() => ({
    buttonLineHeight: specsRibbonButtonLineHeight,
}))`
    appearance: none;
    background: transparent;
    border: none;
    color: ${(props) => props.theme.palette.primary.main};
    cursor: pointer;
    line-height: ${(props) => props.buttonLineHeight};
    padding: 0;
    text-decoration: underline;
    white-space: nowrap;

    &:focus-visible {
        outline: 1px dotted #0008;
        outline-offset: 0.1em;
    }
`;
