import React, { FC, useState } from 'react';
import { useKeenSlider } from 'keen-slider/react';
import {
    Header,
    DescriptionAndCtas,
    Description,
    Ctas,
    Cta,
    StyledIconText,
    StyledSpotHeader,
} from '../vehicle-product-ribbon/vehicle-product-ribbon-layout.styled';
import { SvgIcon } from '../../shared/svg-icon';
import { CenteredBlock } from '../../structural-blocks/centered-block.component';
import { RibbonWrapper } from '../vehicle-product-ribbon/vehicle-product-ribbon.styled';
import Link from 'next/link';
import { StyledSeeAllLink } from './vehicle-product-card-carousel.styled';
import { sharedTypes } from '../../../lib/api/models/umbraco';
import { cmsUrlWithoutSiteId, isNullOrEmpty } from '../../../utils/helpers';
import { ContentSpotRichText } from '../../shared/content-spot-rich-text/content-spot-rich-text.component';
import { uiTypes } from '../../../lib';

const sliderPositionOptions = {
    Beginning: 'beginning',
    end: 'end',
    inBetween: 'In Between',
    noSlides: 'No Slides',
} as const;

type SliderPosition = typeof sliderPositionOptions[keyof typeof sliderPositionOptions];

type IProps = {
    header: string;
    headerType?: uiTypes.HeaderType;
    headerSize?: uiTypes.HeaderSize;
    text: string;
    link?: sharedTypes.CtaUrl;
    initialIndex?: number;
    controlsColor?: string;
};

export const VehicleProductCardCarousel: FC<IProps> = ({
    header,
    text,
    link,
    initialIndex,
    children,
    headerType,
    headerSize,
    controlsColor = '#000000',
}) => {
    const [loaded, setLoaded] = useState(false);

    const [sliderPosition, setSliderPosition] = useState<SliderPosition>();

    const [sliderRef, instanceRef] = useKeenSlider({
        initial: initialIndex ?? 0,
        loop: false,
        mode: 'snap',
        slides: {
            perView: 1,
            spacing: 20,
        },

        detailsChanged(instance) {
            if (!instance.track || !instance.track.details) {
                return;
            }

            if (isNaN(instance.track.details.progress)) {
                setSliderPosition('No Slides');
            } else if (instance.track.details.position === instance.track.details.max) {
                setSliderPosition('end');
            } else if (instance.track.details.position === instance.track.details.min) {
                setSliderPosition('beginning');
            } else {
                setSliderPosition('In Between');
            }
        },
        created() {
            setLoaded(true);
        },
        breakpoints: {
            '(min-width: 570px)': {
                slides: {
                    perView: 2,
                    spacing: 20,
                },
            },
            '(min-width: 1024px)': {
                slides: {
                    perView: 3,
                    spacing: 20,
                },
            },
            '(min-width: 1366px)': {
                slides: {
                    perView: 4,
                    spacing: 20,
                },
            },
        },
    });

    const previousButtonIsDisabled = sliderPosition === 'beginning' || sliderPosition === 'No Slides';
    const nextButtonIsDisabled = sliderPosition === 'end' || sliderPosition === 'No Slides';
    const carrouselScrollingNotPossible = (previousButtonIsDisabled && nextButtonIsDisabled) || React.Children.count(children) === 0;

    return (
        <CenteredBlock>
            {header.length > 0 ? (
                isNullOrEmpty(headerSize) ? (
                    <Header as={headerType}>{header}</Header>
                ) : (
                    <StyledSpotHeader headerType={headerType} headerSize={headerSize}>
                        {header}
                    </StyledSpotHeader>
                )
            ) : null}

            <DescriptionAndCtas>
                <Description>
                    <ContentSpotRichText text={text}></ContentSpotRichText>
                </Description>
                {loaded && instanceRef.current ? (
                    <Ctas>
                        {link && link.url && link.url.length > 0 ? (
                            <Link href={cmsUrlWithoutSiteId(link.url)} passHref>
                                <StyledSeeAllLink target={link.target}>{!isNullOrEmpty(link.name) ? link.name : 'Se alle'}</StyledSeeAllLink>
                            </Link>
                        ) : null}

                        {carrouselScrollingNotPossible ? null : (
                            <>
                                <Cta
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        instanceRef.current?.prev();
                                    }}
                                    disabled={previousButtonIsDisabled}
                                    customColor={controlsColor}
                                >
                                    <SvgIcon iconName="chevron/left" customColor={controlsColor} />
                                    <StyledIconText customColor={controlsColor}>Forrige</StyledIconText>
                                </Cta>

                                <Cta
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        instanceRef.current?.next();
                                    }}
                                    disabled={nextButtonIsDisabled}
                                    customColor={controlsColor}
                                >
                                    <StyledIconText customColor={controlsColor}>Næste</StyledIconText>
                                    <SvgIcon iconName="chevron/right" customColor={controlsColor} />
                                </Cta>
                            </>
                        )}
                    </Ctas>
                ) : null}
            </DescriptionAndCtas>

            <RibbonWrapper ref={sliderRef} className="keen-slider" allowOverflow={carrouselScrollingNotPossible}>
                {children}
            </RibbonWrapper>
        </CenteredBlock>
    );
};
