import styled from 'styled-components';
import { device } from '../../../../../../lib/media-query';

export const StyledCheckboxContainer = styled.div`
    background-color: white;
    border: 1px solid ${(props) => props.theme.palette.grey.medium};
    margin: 0 15px 15px 10px;
    padding: 15px;

    @media ${device.tablet} {
        margin-top: 0;
        margin-left: 0;
    }
`;

export const YoutubeLinkContent = styled.p`
    font-size: 12px;
    @media ${device.tablet} {
        font-size: revert;
    }

    text-align: left;
`;
export const TirehotelText = styled.p`
    p {
        font-size: 12px;
        @media ${device.tablet} {
            font-size: revert;
        }
    }
`;
