import { useEffect, VFC } from 'react';
import { MarkerInfo } from './marker-info/marker-info.component';
import * as ReactDOM from 'react-dom';
import { BlackPinUrl, BluePinUrl } from '../../helpers/organization-consts';
import { FindDealershipsStore } from '../../../../lib/state/find-dealership';
import { orgTypes } from '../../../../lib/api/models/umbraco';
import { cmsUrlWithoutSiteId } from '../../../../utils/helpers';
import { useMediaQuery } from '../../../../hooks/use-media-query';
import { useUI } from '../../../ui';
import { theme } from '../../../../themes/default-theme';
import { useRouter } from 'next/router';
import { MAP_ZOOM_LEVEL } from '../../../../constants/organization-consts';
import { getLetter } from '../../../../utils/helpers/array.helpers';

type MarkerProps = {
    map?: google.maps.Map;
};

export const MarkerList: VFC<MarkerProps> = ({ map }) => {
    const { filteredLocations, mapData } = FindDealershipsStore.useStoreState((state) => state);
    const { setDealershipId, setMapData, setShowEmergencyNumbersSidePanel, createMarkerClusterer } = FindDealershipsStore.useStoreActions(
        (actions) => actions
    );
    const resetMakers: Array<google.maps.Marker> = [];
    const isMobile = useMediaQuery({ target: 'tablet' });
    const uiHandler = useUI();
    const router = useRouter();

    const mapPinPosition = new google.maps.Point(14.5, 17);
    const mapPinSizeScale = new google.maps.Size(30, 45);

    useEffect(() => {
        const infoWindow = new google.maps.InfoWindow({
            content: '',
            disableAutoPan: true,
            pixelOffset: new google.maps.Size(0, -15),
        });

        const markers: Array<orgTypes.DealershipMapData> = filteredLocations.map((loc, idx) => {
            mapData?.forEach((x) => {
                x.marker?.setMap(null);
                x.marker.setVisible(false);
            });
            const marker = new google.maps.Marker({
                position: {
                    lat: loc.lat,
                    lng: loc.lng,
                },
                label: {
                    text: isMobile ? 'EH' : getLetter(idx),
                    color: 'white',
                    fontFamily: theme.typography.fontFamily,
                    fontSize: isMobile ? '12px' : '15px',
                    fontWeight: '500',
                },
                icon: {
                    url: `/${BlackPinUrl}`,
                    labelOrigin: mapPinPosition,
                    scaledSize: mapPinSizeScale,
                },
            });

            resetMakers.push(marker);

            marker.addListener('click', () => {
                const el = document.createElement('div');
                el.id = 'google-maps-dealership-popup';
                ReactDOM.render(
                    <MarkerInfo
                        header={loc.header}
                        address={loc.address ?? ''}
                        zipcode={loc.zipcode ?? ''}
                        phone={loc.phone ?? ''}
                        ctaAction={() => router.push(cmsUrlWithoutSiteId(loc.url.replace('/hire-forside', '')))}
                        openEmergencyPhoneNumberSidePanel={() => {
                            if (isMobile) {
                                uiHandler.removeScrollAndLock();
                            } else {
                                uiHandler.removeScroll();
                            }
                            setShowEmergencyNumbersSidePanel({ visible: true, dealershipId: loc.dealershipId });
                        }}
                        departments={loc.departments}
                    />,
                    el
                );

                infoWindow?.setContent(el);
                infoWindow?.open({
                    anchor: marker,
                    map: map,
                    shouldFocus: false,
                });

                const markerPosition = marker.getPosition();
                if (markerPosition) {
                    map?.setZoom(MAP_ZOOM_LEVEL);
                    map?.setCenter(markerPosition);
                    map?.panBy(0, -150);
                }

                resetMakers.forEach((m) =>
                    m.setIcon({
                        url: `/${BlackPinUrl}`,
                        labelOrigin: mapPinPosition,
                        scaledSize: mapPinSizeScale,
                    })
                );

                setDealershipId(loc.dealershipId);

                marker.setIcon({
                    url: `/${BluePinUrl}`,
                    labelOrigin: mapPinPosition,
                    scaledSize: mapPinSizeScale,
                });
            });

            infoWindow?.addListener('closeclick', () => {
                marker.setIcon({
                    url: `/${BlackPinUrl}`,
                    labelOrigin: mapPinPosition,
                    scaledSize: mapPinSizeScale,
                });
            });

            return { id: loc.dealershipId, marker, infoWindow, infoWindowVisible: false, map: map };
        });

        setMapData(markers);

        const mappedMarker = markers.map((x) => x.marker);
        createMarkerClusterer({ markers: mappedMarker });

        // remove marker from map on unmount
        return () => {
            mapData?.forEach((m) => m.marker.setMap(null));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredLocations, map]);

    return null;
};
