import styled from 'styled-components';

type StyledVehicleAvailabilityProps = {
    backgroundColor: string;
    foregroundColor: string;
};

export const StyledVehicleAvailability = styled.figure<StyledVehicleAvailabilityProps>`
    background: ${(props) => props.backgroundColor};
    color: ${(props) => props.foregroundColor};
    cursor: default;
    font-family: ${(props) => props.theme.typography.fontFamily};
    line-height: 1.6em;
    padding: 0 0.4em;
    white-space: nowrap;
`;
