import styled from 'styled-components';

type StyledTabbedCardTabPanelProps = {
    isActive: boolean;
};

export const StyledTabbedCard = styled.section`
    background: ${(props) => props.theme.palette.common.white};
    box-shadow: ${(props) => props.theme.shadows.large};
`;

export const StyledTabbedCardTabList = styled.div`
    display: flex;
    font-size: 14px;
    gap: 1em;
    padding: 1em 22px 0;
`;

export const StyledTabbedCardTab = styled.button<StyledTabbedCardTabPanelProps>`
    appearance: none;
    background: transparent;
    border-color: ${(props) => (props.isActive ? props.theme.palette.common.black : 'transparent')};
    border-style: solid;
    border-width: 0 0 2px;
    cursor: pointer;
    flex: 0 1 100%;
    overflow: hidden;
    padding: 0 0 0.25em;
    text-align: left;
    transition: transform 150ms;
    color: ${(props) => (props.isActive ? '#0b0b0b' : '#636363')};

    &:focus-visible {
        outline: 2px dotted ${(props) => props.theme.palette.primary.main};
    }

    &:active {
        color: ${(props) => props.theme.palette.primary.main};
        border-color: ${(props) => props.theme.palette.primary.main};
    }
`;

export const LabelAndBadgeWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`;

export const StyledTabbedCardLabel = styled.div<StyledTabbedCardTabPanelProps>`
    line-height: 1.71;
    font-weight: ${(props) => (props.isActive ? 600 : 400)};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const StyledTabbedCardDescription = styled.div`
    font-size: 0.78em;
    line-height: 2.18;
    margin: -0.55em 0 0;
    opacity: 0.5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const StyledTabbedCardTabPanel = styled.div.attrs<StyledTabbedCardTabPanelProps>((props) => ({
    style: {
        display: props.isActive ? undefined : 'none',
    },
}))<StyledTabbedCardTabPanelProps>`
    &:focus-visible {
        outline: 2px dotted ${(props) => props.theme.palette.primary.main};
    }
`;
