import { parseISO } from 'date-fns';
import React, { VFC } from 'react';
import { orgTypes } from '../../../../lib/api/models/umbraco';
import { DateStyle, formatDate } from '../../../../utils/helpers';

import { StyledAccordionHeader, HeaderLabel, OpeningInfoWrapper, OpeningStatus, OpeningStatusText, Hours } from './header.styled';

export type OpeningHoursHeaderProps = {
    className?: string;
    label: string;
    isOpen: boolean;
    openingHour?: orgTypes.OpeningHour;
};

const OpeningHoursHeaderMemo: VFC<OpeningHoursHeaderProps> = ({ className, label, isOpen, openingHour }) => {
    return openingHour ? (
        <StyledAccordionHeader className={className}>
            <HeaderLabel>{label}</HeaderLabel>
            <OpeningInfoWrapper>
                <OpeningStatus isOpen={isOpen}>
                    <OpeningStatusText>{isOpen ? 'Åben' : 'Lukket'}</OpeningStatusText>
                </OpeningStatus>
                <Hours>
                    Idag&nbsp;{formatDate(parseISO(openingHour.from), DateStyle.HH_mm)}-{formatDate(parseISO(openingHour.to), DateStyle.HH_mm)}
                </Hours>
            </OpeningInfoWrapper>
        </StyledAccordionHeader>
    ) : null;
};

export const OpeningHoursAccordionHeader = React.memo(OpeningHoursHeaderMemo);
