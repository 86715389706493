import DefaultErrorPage from 'next/error';
import { useRouter } from 'next/router';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useMeasure } from 'react-use';
import { LoadFromMiniBasketKey, lead_checkout_url } from '../../../constants/site-consts';
import { useGtmTracking } from '../../../gtm-tracking/hooks/use-gtm-tracking';
import { convertProductToGtmData } from '../../../hooks/lead-checkout/use-hire-gtm-tracking-data';
import { useMediaQuery } from '../../../hooks/use-media-query';
import { useScrollDirection } from '../../../hooks/use-scroll-direction';
import { useScrollLock } from '../../../hooks/use-scroll-lock';
import { getPage, getPageById, umbraco } from '../../../lib/api';
import { GlobalProductListSettings, SizeGuideForModel, ProductDetailsPage as UmbracoProductDetailsPage } from '../../../lib/api/models/umbraco';
import { FullScreenCampaignElement, SizeGuideSpot } from '../../../lib/api/models/umbraco/content-spot';
import { PdpUspBar } from '../../../lib/api/models/umbraco/product-details';
import { getDeliveryTime } from '../../../lib/mappers/vehicle/product-details.mapper';
import { HireContentDisplayStore } from '../../../lib/state/hessel-site/display-manager';
import { MiniBasketStore } from '../../../lib/state/hessel-site/mini-basket';
import { ProductDetailsStore } from '../../../lib/state/hessel-site/product-details';
import { isLeasingAvailable, isPurchaseAvailable } from '../../../lib/state/hessel-site/product-details/selected-tab.helper';
import { FormsManagerStore } from '../../../lib/state/plus-sites/forms-display-manager';
import { hesselViewModels } from '../../../lib/view-models';
import { BrandsConsts } from '../../../lib/view-models/vehicle';
import { MEDIA_URL } from '../../../utils/environment-constants';
import {
    getPdpMetaDescription,
    getPdpPageTitle,
    isNullOrEmpty,
    mapCarPromotionAvailabilityToVehicleAvailability,
    showHigherEquipmentLabel,
} from '../../../utils/helpers';
import { findMinMax, generateRange } from '../../../utils/helpers/array.helpers';
import { DropdownOption } from '../../forms/inputs/dropdown/dropdown-input.props';
import { CompactCarousel } from '../../shared/carousels/compact-carousel/compact-carousel.component';
import { SimpleCarousel } from '../../shared/carousels/simple-carousel/simple-carousel.component';
import { TabbedCarouselDialog } from '../../shared/carousels/tabbed-carousel/tabbed-carousel-dialog.component';
import { FullScreenCampaignItem } from '../../spots/full-screen-campaign/campaign-item';
import { SizeGuide } from '../../spots/size-guide/size-guide.component';
import { CarConfigurationCard } from '../car-configuration-card/car-configuration-card.component';
import { CarLeasingTabs } from '../cars/car-leasing-tabs/car-leasing-tabs.component';
import { CarPurchaseTabs } from '../cars/car-purchase-tabs/car-purchase-tabs.component';
import { CompanyCarTabs } from '../company-car/company-car-price-card/company-car-price-card.component';
import { usePriceByOwnership } from '../hooks/use-price-by-ownership';
import { PartOfBundle } from '../part-of-bundle/part-of-bundle.component';
import { PdpFindDealership } from '../pdp-find-dealership/pdp-find-dealership.component';
import { ProductDetailsModals } from '../product-details-modals/product-details-modals.component';
import { SpecificationsDialog } from '../specifications-dialog/specifications-dialog.component';
import { SpecsRibbonItem } from '../specs-ribbon/specs-ribbon-item.component';
import { StickySummary } from '../sticky-summary/sticky-summary.component';
import { VanLeasingTabs } from '../vans/van-ownership-tabs/leasing/van-leasing-tabs.component';
import { VanPurchaseTabs } from '../vans/van-ownership-tabs/purchase/van-purchase-tabs.component';
import { PdpRelatedVehiclesRibbon } from './pdp-related-vehicles/pdp-related-vehicles.component';
import { OpenDialog, ProductDetailsPageModalsProvider, usePdpModals } from './product-details-page.context';
import {
    FullEquipmentLink,
    FullEquipmentListIcon,
    FullEquipmentListLinkAndIcon,
    OwnershipWrapper,
    StyledAcknowledgementContainer,
    StyledCarouselContainer,
    StyledCenteredBlock,
    StyledInfoCardsWrapper,
    StyledOrderCarDisclaimer,
    StyledOwnershipToggle,
    StyledPdpUspTemplateBar,
    StyledProductDetailsHeader,
    StyledProductDetailsPage,
    StyledProductDetailsPageMain,
    StyledPromotionsWrapper,
    StyledShortInfoCard,
    StyledSidePanel,
    StyledSizeGuideButton,
    StyledSpecsRibbon,
    StyledZoomButton,
} from './product-details-page.styled';
import { VehiclePriceLinks } from './vehicle-price-links/vehicle-price-links.component';

export type ProductDetailsPageProps = {
    page: UmbracoProductDetailsPage;
    globalEcomSettings: GlobalProductListSettings;
    siteSettings: umbraco.SiteSettings;
};

type WaitingDays = {
    orderCarWaitingDays: number;
    inStockCarWaitingDays: number;
};

const brandsArray: string[] = Object.values(BrandsConsts);

const ProductDetailsPageBase: FC<ProductDetailsPageProps> = (props) => {
    const [activeSlide, setActiveSlide] = useState(0);
    const [specsRibbonRef, { width: specsRibbonWidth }] = useMeasure<HTMLUListElement>();
    const { formList } = FormsManagerStore.useStoreState((state) => state);
    const { setShowPaymentPlanSidebar } = HireContentDisplayStore.useStoreActions((actions) => actions);
    const { showPaymentPlanSidebar } = HireContentDisplayStore.useStoreState((state) => state);
    const [showStickyPrice, setShowStickyPrice] = useState(false);
    const [sizeGuides, setSizeGuides] = useState<SizeGuideForModel[]>([]);
    const [carTypeImages, setCarTypeImages] = useState<umbraco.CarTypeAndImageList[]>([]);

    const [waitingDays, setWaitingDays] = useState<WaitingDays>({ inStockCarWaitingDays: 0, orderCarWaitingDays: 0 });
    const router = useRouter();
    const isMobile = useMediaQuery({ target: 'tablet' });
    const { hideScrollBars } = useScrollLock();
    const { closeModal, openDialog, openedDialog } = usePdpModals();

    const scrollDirection = useScrollDirection();

    // Product details store
    const {
        selectedProduct,
        selectedColor,
        queryColor,
        colorAndResourceMatched,
        priceDifferenceAcknowledged,
        extraEquipmentPriceSum,
        extraEquipmentPackagePriceSum,
        optionalEquipmentPriceSum,
        selectedLeasingPeriod,
        matchedResources,
        ownershipMode,
        selectedTab,
        computedDownPayment,
        selectedHeightType,
        selectedLengthType,
        dealershipLocationLabel,
    } = ProductDetailsStore.useStoreState((state) => state);

    const { setSelectedLeasingPeriod, fillStoreFromMiniBasket, setOwnershipMode, setSelectedTab, setColor } = ProductDetailsStore.useStoreActions(
        (actions) => actions
    );
    const { getState: getProductDetailsStoreState } = ProductDetailsStore.useStore();

    // Mini-Basket Store
    const { setProductData: setMiniBasketProduct } = MiniBasketStore.useStoreActions((actions) => actions);
    const { getState: getMiniBasketStoreState } = MiniBasketStore.useStore();
    const miniBasketStoreHydrated = MiniBasketStore.useStoreRehydrated();

    //#region Product Details and equipment

    // GTM
    const { trackHirePdp, trackHireLeadCheckout } = useGtmTracking();
    const tracker = trackHirePdp();
    const leadCheckoutTracker = trackHireLeadCheckout();
    const convertedProduct = convertProductToGtmData(selectedProduct, selectedColor);

    const firstVisitTrackingSent = useRef<boolean>(false);
    useEffect(() => {
        if (!firstVisitTrackingSent.current) {
            const convertedProduct = convertProductToGtmData(selectedProduct, selectedColor);

            if (convertedProduct && convertedProduct.carData) {
                tracker.viewItem(convertedProduct.carData);
                firstVisitTrackingSent.current = true;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProduct, tracker]);

    // SEO
    useEffect(() => {
        if (selectedProduct) {
            document.title = getPdpPageTitle(selectedProduct, selectedColor?.name ?? '');
            document
                .querySelector('meta[name="description"]')
                ?.setAttribute('content', getPdpMetaDescription(selectedProduct, selectedColor?.name ?? ''));
        }
    });

    useEffect(() => {
        const canLoadMiniBasket = sessionStorage.getItem(LoadFromMiniBasketKey);
        if (canLoadMiniBasket === 'true' && miniBasketStoreHydrated) {
            const miniBasketState = getMiniBasketStoreState().productData;

            if (miniBasketState) {
                fillStoreFromMiniBasket(miniBasketState);
            }
            sessionStorage.removeItem(LoadFromMiniBasketKey);
            return;
        }
    }, [fillStoreFromMiniBasket, getMiniBasketStoreState, miniBasketStoreHydrated]);

    //#endregion Product Details and equipment

    useEffect(() => {
        const isSomeFormOpen = formList?.reduce((a, v) => a || v.isVisible, false);
        if (isSomeFormOpen) openDialog({ dialog: OpenDialog.Form });
    }, [formList, openDialog]);

    useEffect(() => {
        if (showPaymentPlanSidebar) openDialog({ dialog: OpenDialog.PaymentPlans });
    }, [showPaymentPlanSidebar, openDialog]);

    const leasingMonths = useMemo(() => {
        return [selectedProduct?.purchaseTypes.hire.durationFrom ?? 0, selectedProduct?.purchaseTypes.hire.durationTo ?? 0];
    }, [selectedProduct]);

    const [leasingPeriodMin, leasingPeriodMax] = useMemo(() => findMinMax(leasingMonths), [leasingMonths]);

    const leasingPeriodDescription = useMemo(() => {
        return leasingPeriodMin === leasingPeriodMax ? `${leasingPeriodMin} md.` : `${leasingPeriodMin}-${leasingPeriodMax} md.`;
    }, [leasingPeriodMax, leasingPeriodMin]);

    const leasingPeriodOptions = useMemo(() => {
        const [start, stop] = leasingMonths;

        const fullList = generateRange(start, stop, 1);

        return fullList
            .sort((a, b) => a - b)
            .map<DropdownOption<string>>((p) => ({
                displayValue: `${p} mdr.`,
                value: `${p}`,
            }));
    }, [leasingMonths]);

    const ownershipOptions = useMemo(() => {
        const options = [];

        if (isPurchaseAvailable(selectedProduct)) {
            options.push({ label: 'Køb', value: 'Køb' });
        }
        if (isLeasingAvailable(selectedProduct) && selectedProduct?.availability !== 'Used') {
            options.push({ label: 'Leasing', value: 'Leasing' });
        }

        return options;
    }, [selectedProduct]);

    /**
     * Make sure carousel starts from scratch when color is changes since we only show matching images
     * per color selection.
     */
    const selectedColorRef = useRef<string | undefined>(selectedColor?.id);
    const [resetCarousel, setResetCarousel] = useState(false);

    useEffect(() => {
        if (selectedColorRef.current !== selectedColor?.id) {
            selectedColorRef.current = selectedColor?.id;
            setActiveSlide(0);
            setResetCarousel(true);
        }
    }, [selectedColor?.id]);

    const slicedHighlightedSpec = useMemo(() => {
        const isHybrid = selectedProduct?.fuelType.toLowerCase().includes('hybrid');
        const items = Math.floor(specsRibbonWidth / 90);
        const numberOfItemsToDisplay = isHybrid && specsRibbonWidth < 600 ? 3 : items + 1;
        return selectedProduct?.highlightedSpecs?.slice(0, !isMobile ? numberOfItemsToDisplay : 4);
    }, [isMobile, selectedProduct?.fuelType, selectedProduct?.highlightedSpecs, specsRibbonWidth]);

    const priceListHref = useMemo(() => {
        if (selectedProduct) {
            const carBrand = selectedProduct.brand;
            const fuelType = selectedProduct.fuelType.toLowerCase();
            const isElectricOrHybrid = fuelType === 'el' || fuelType.includes('hybrid');
            if (carBrand === 'Mercedes-Benz') {
                if (isElectricOrHybrid) {
                    return props.page.mercedesBenzPriceListElectric;
                } else {
                    return props.page.mercedesBenzPriceList;
                }
            } else if (carBrand === 'Ford') {
                if (isElectricOrHybrid) {
                    return props.page.fordPriceListElectric;
                } else {
                    return props.page.fordPriceList;
                }
            } else if (carBrand === 'Dacia') {
                if (isElectricOrHybrid) {
                    return props.page.daciaPriceListElectric;
                } else {
                    return props.page.daciaPriceList;
                }
            } else if (carBrand === 'Renault') {
                if (isElectricOrHybrid) {
                    return props.page.renaultPriceListElectric;
                } else {
                    return props.page.renaultPriceList;
                }
            }
        }
        return undefined;
    }, [props.page, selectedProduct]);

    const goToCheckout = () => {
        const miniBasketData = getProductDetailsStoreState();
        setMiniBasketProduct({ ...miniBasketData, extraEquipmentPriceSum, extraEquipmentPackagePriceSum, optionalEquipmentPriceSum });

        // GTM - Loading first step
        if (convertedProduct && convertedProduct.carData) {
            leadCheckoutTracker.stepLoaded({ event: 'begin_checkout', ecommerce: convertedProduct.carData.ecommerce });
        }

        router.push(lead_checkout_url);
    };

    function prepareDataAndGoToCheckout() {
        if (selectedProduct && selectedProduct.availability === 'Order' && convertedProduct) {
            const { carData, extraEquipmentData, extraEquipmentPackageData, addonsData } = convertedProduct;
            tracker.addToCart({
                ecommerce: {
                    items: [
                        ...(carData?.ecommerce?.items ?? []),
                        ...(extraEquipmentData ?? []),
                        ...(extraEquipmentPackageData ?? []),
                        ...(addonsData ?? []),
                    ],
                },
            });
        }
        goToCheckout();
    }

    const handleSelectedLeasingPeriod = (
        leasingPeriod: hesselViewModels.ProductDetailsLeasingPeriod | undefined
    ): DropdownOption<string> | undefined => {
        if (!leasingPeriod) {
            setSelectedLeasingPeriod(leasingPeriodOptions?.[0]);
        }
        return selectedLeasingPeriod;
    };

    /**
     * Note that users set up a list of usps in the Ny Bil node in the CMS.
     * Here in FE, we filter out the usps that are relevant for the selected product and the selected payment method/tab.
     * Note that, Umbraco dropdowns are aligned with the payment method names in the code to avoid confusion and declaring multiple types.
     */
    const filteredUspBarFromCms: PdpUspBar | undefined = useMemo(() => {
        if (!selectedProduct) {
            return undefined;
        }

        const hireUsps = props.page.pdpUpsBarList.filter((x) => x.payment === selectedTab);

        switch (selectedProduct?.availability) {
            case 'Order':
                return hireUsps.find((x) => x.condition === 'New to order');
            case 'InStock':
                return hireUsps.find((x) => x.condition === 'New in stock');
            case 'Used':
                if (
                    selectedTab === 'Van Operationel Leasing' ||
                    selectedTab === 'Van Finansiel Leasing' ||
                    selectedTab === 'Car HiRE' ||
                    selectedTab === 'Car Privat Leasing'
                ) {
                    return undefined;
                }
                return hireUsps.find((x) => x.condition === 'Used');
            case 'Engros': {
                if (selectedTab === 'Van Operationel Leasing' || selectedTab === 'Van Finansiel Leasing') {
                    return undefined;
                }
                return hireUsps.find((x) => x.condition === 'Engros');
            }
            case 'Demo':
                return undefined;
            default:
                return undefined;
        }
    }, [props.page.pdpUpsBarList, selectedProduct, selectedTab]);

    /**
     * Note that users set up a list of promotions in the Ny Bil node in the CMS.
     * Here in FE, we filter out the promotions that are relevant for the selected product and the selected payment method/tab.
     * Note that, Umbraco dropdowns are aligned with types uses in FE code to avoid confusion and declaring multiple types.
     */
    const filteredPromotionsFromCms = useMemo(() => {
        if (!selectedProduct) {
            return [];
        }

        /**
         * Note: ownershipType & fuelType are optional in the CMS.
         * If they are undefined, we should not filter by them.
         */
        if (selectedProduct.vehicleType === 'Car') {
            return props.page.promotions.filter((x) =>
                x.cars.find((y) => {
                    const ownershipMatched = y.ownershipList ? y.ownershipList.includes(selectedTab) : true;

                    const fuelMatched = y.fuelList && y.fuelList.length > 0 ? y.fuelList.includes(selectedProduct?.fuelType) : true;

                    const availabilityMatched =
                        y.availabilityList && y.availabilityList.length > 0
                            ? mapCarPromotionAvailabilityToVehicleAvailability(y.availabilityList).includes(selectedProduct.availability)
                            : true;

                    const brandMatched = !y.brandList?.includes('Non-Hessel brands')
                        ? y.brandList?.includes(selectedProduct?.brand)
                        : !brandsArray.includes(selectedProduct?.brand);

                    return (y.brandList && y.brandList.length > 0 ? brandMatched : true) && ownershipMatched && fuelMatched && availabilityMatched;
                })
            );
        }

        if (selectedProduct.vehicleType === 'Van') {
            return props.page.promotions.filter((x) =>
                x.vans.find((y) => {
                    const availabilityMatched =
                        y.availabilityList && y.availabilityList.length > 0
                            ? mapCarPromotionAvailabilityToVehicleAvailability(y.availabilityList).includes(selectedProduct.availability)
                            : true;

                    const ownershipMatched = y.vanOwnershipList && y.vanOwnershipList.length > 0 ? y.vanOwnershipList.includes(selectedTab) : true;

                    const fuelMatched = y.fuelList && y.fuelList.length > 0 ? y.fuelList.includes(selectedProduct?.fuelType) : true;

                    const brandMatched = !y.brandList.includes('Non-Hessel brands')
                        ? y.brandList.includes(selectedProduct?.brand)
                        : brandsArray.includes(selectedProduct?.brand);

                    return (y.brandList && y.brandList.length > 0 ? brandMatched : true) && ownershipMatched && availabilityMatched && fuelMatched;
                })
            );
        }

        return [];
    }, [props.page.promotions, selectedProduct, selectedTab]);

    const isCompanyCar = useMemo(() => {
        const companyCar = router.query.company;
        if (!companyCar || Array.isArray(companyCar)) {
            return false;
        }
        return companyCar.toLowerCase() === 'true';
    }, [router.query.company]);

    useEffect(() => {
        if (!selectedProduct) {
            return;
        }
        const fetchSizeGuides = async () => {
            if (props.page.sizeGuideLibrary) {
                const [sizeGuideLibrary, error] = await getPageById(props.page.sizeGuideLibrary.id);
                if (sizeGuideLibrary && sizeGuideLibrary.length > 0 && !error) {
                    const sizeGuideChildren = sizeGuideLibrary[0].children;
                    if (sizeGuideChildren && sizeGuideChildren?.length > 0) {
                        const sizeGuideCastAsType = sizeGuideChildren.map((x) => x as SizeGuideForModel);
                        setSizeGuides(sizeGuideCastAsType);
                    }
                }
            }
        };
        const fetchCarTypeImages = async () => {
            if (props.page.carTypeImages) {
                const [carTypeImages, carTypeAndImagesError] = await getPageById(props.page.carTypeImages.id);
                if (carTypeImages && carTypeImages.length > 0 && !carTypeAndImagesError) {
                    const cartTypeImagesChildren = carTypeImages[0].children;
                    if (cartTypeImagesChildren && cartTypeImagesChildren?.length > 0) {
                        const mappedCarTypeImages = cartTypeImagesChildren.map((x) => x as umbraco.CarTypeAndImageLibrary);
                        setCarTypeImages(mappedCarTypeImages[0].contentList);
                    }
                }
            }
        };

        const fetchWaitingDays = async () => {
            const [checkoutPage, checkoutPageError] = await getPage(`/checkout`);
            if (!checkoutPageError && checkoutPage && checkoutPage.contentTypeAlias === 'checkoutPage') {
                const durationDeliveryStep = checkoutPage.stepList.find((x) => x.alias === 'checkoutStepDurationAndDelivery');

                if (durationDeliveryStep && durationDeliveryStep.alias === 'checkoutStepDurationAndDelivery') {
                    setWaitingDays({
                        orderCarWaitingDays: durationDeliveryStep.orderCarWaitingDays,
                        inStockCarWaitingDays: durationDeliveryStep.inStockCarWaitingDays,
                    });
                }
            }
        };
        fetchWaitingDays();
        fetchCarTypeImages();
        fetchSizeGuides();
    }, [isCompanyCar, props.page.carTypeImages, props.page.pdpRelatedCars, props.page.sizeGuideLibrary, selectedProduct]);

    const specificationGroups = useMemo(() => {
        if (!selectedProduct) {
            return [];
        }

        const groupToFormat = selectedProduct.specificationGroups.find((x) => x.heading === 'Køretøj');
        if (groupToFormat) {
            const newGroup: hesselViewModels.SpecificationGroup = {
                ...groupToFormat,
                specifications: groupToFormat.specifications.map((x) => {
                    if (x && x.value === '{{deliveryPlaceholder}}') {
                        const isInStock = selectedProduct.availability === 'InStock';

                        const isUsedOrEngrosVan =
                            (selectedProduct.availability === 'Used' || selectedProduct.availability === 'Engros') &&
                            selectedProduct.vehicleType === 'Van';

                        const isUsedCar = selectedProduct.availability === 'Used' && selectedProduct.vehicleType === 'Car';

                        return {
                            label: x.label,
                            value:
                                isInStock || isUsedOrEngrosVan || isUsedCar
                                    ? getDeliveryTime(waitingDays.inStockCarWaitingDays)
                                    : getDeliveryTime(waitingDays.orderCarWaitingDays),
                        };
                    }
                    return x;
                }),
            };
            return selectedProduct.specificationGroups.map((sg) => (sg.heading === groupToFormat.heading ? newGroup : sg));
        }
        return selectedProduct.specificationGroups;
    }, [selectedProduct, waitingDays]);

    const vanDimensions = useMemo(
        () => ({
            height: selectedHeightType ?? '',
            length: selectedLengthType ?? '',
        }),
        [selectedHeightType, selectedLengthType]
    );

    const pdpFindDealershipSpotId = useMemo(() => {
        return `pdp-find-dealership-${selectedProduct?.id}`;
    }, [selectedProduct?.id]);

    const { priceInformation, santanderFinancing } = usePriceByOwnership(props.page);

    useEffect(() => {
        if (ownershipMode === 'Køb') {
            if (selectedColor?.cashPrice === null || selectedColor?.cashPrice === undefined) {
                const newColor = selectedProduct?.colors.find((x) => x.cashPrice !== undefined && x.cashPrice !== null);
                if (newColor) setColor(newColor);
            }
        } else if (selectedTab === 'Car HiRE' && (selectedColor?.monthlyHirePrice === undefined || selectedColor?.monthlyHirePrice === null)) {
            const newColor = selectedProduct?.colors.find((x) => x.monthlyHirePrice !== undefined && x.monthlyHirePrice !== null);
            if (newColor) setColor(newColor);
        } else if (
            selectedTab === 'Car Privat Leasing' &&
            (selectedColor?.monthlyPrivateLeasingPrice === undefined || selectedColor?.monthlyPrivateLeasingPrice === null)
        ) {
            const newColor = selectedProduct?.colors.find((x) => x.monthlyPrivateLeasingPrice !== undefined && x.monthlyPrivateLeasingPrice !== null);
            if (newColor) setColor(newColor);
        } else if (
            selectedTab === 'Van Operationel Leasing' &&
            (selectedColor?.monthlyOperationalLeasingPrice === undefined || selectedColor?.monthlyOperationalLeasingPrice === null)
        ) {
            const newColor = selectedProduct?.colors.find(
                (x) => x.monthlyOperationalLeasingPrice !== undefined && x.monthlyOperationalLeasingPrice !== null
            );
            if (newColor) setColor(newColor);
        } else if (
            selectedTab === 'Van Finansiel Leasing' &&
            (selectedColor?.monthlyFinancialLeasingPrice === undefined || selectedColor?.monthlyFinancialLeasingPrice === null)
        ) {
            const newColor = selectedProduct?.colors.find(
                (x) => x.monthlyFinancialLeasingPrice !== undefined && x.monthlyFinancialLeasingPrice !== null
            );
            if (newColor) setColor(newColor);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ownershipMode, selectedColor, selectedTab, setColor]);

    useEffect(() => {
        if (!selectedColor) {
            return;
        }
        // If the URL doesn't have the color query parameter and selectedColor.name exists in the store
        if (!router.query.color && selectedColor.name) {
            // Update the URL without causing a navigation
            const newPath = {
                pathname: router.pathname,
                query: {
                    ...router.query,
                    color: selectedColor.name,
                },
            };
            router.replace(newPath, undefined, { shallow: true });
        }
    }, [router, selectedColor]);

    const findDealershipSpotStaticImage = useMemo(() => {
        if (!props.page.findDealershipSpotImages) {
            return undefined;
        }

        const staticImage = props.page.findDealershipSpotImages.find((x) => x.brand === selectedProduct?.brand);

        return staticImage?.image;
    }, [props.page.findDealershipSpotImages, selectedProduct?.brand]);

    if (!selectedProduct) {
        return <DefaultErrorPage statusCode={404} />;
    }

    return (
        <StyledProductDetailsPageMain>
            <StickySummary
                visible={showStickyPrice && scrollDirection === 'Down'}
                brandTitle={selectedProduct?.brand ?? ''}
                variantTitle={selectedProduct?.variantTitle ?? ''}
                period={`${selectedProduct?.purchaseTypes.hire.durationFrom ?? 'n/a'}-${
                    selectedProduct?.purchaseTypes.hire.durationTo ?? 'n/a'
                } mdr.`}
                onOpenCheckout={() => prepareDataAndGoToCheckout()}
                page={props.page}
                selectedOwnershipTab={selectedTab}
                product={selectedProduct}
                priceInformation={priceInformation}
                isCompanyCar={isCompanyCar}
            />

            <StyledCenteredBlock>
                <StyledProductDetailsPage>
                    <StyledProductDetailsHeader
                        availability={selectedProduct?.availability}
                        brandTitle={selectedProduct?.brand ?? ''}
                        energyRating={selectedProduct?.energyRating}
                        variantTitle={selectedProduct?.variantTitle ?? ''}
                        vehicleType={selectedProduct?.vehicleType}
                        vanAutoDesktopCampaignLabels={[]}
                        campaigns={selectedProduct.campaigns}
                        dimensions={vanDimensions}
                        location={dealershipLocationLabel && dealershipLocationLabel.length > 0 ? dealershipLocationLabel : undefined}
                        onLocationClick={() => {
                            // Scroll to the map pdpFindDealershipSpotId
                            const element = document.getElementById(pdpFindDealershipSpotId);
                            if (element) {
                                element.scrollIntoView({ behavior: 'smooth' });
                            }
                        }}
                        modelTitle={selectedProduct?.modelTitle}
                    />

                    <StyledCarouselContainer>
                        {isMobile ? (
                            <CompactCarousel slides={matchedResources} isAboveFold={true} />
                        ) : (
                            <SimpleCarousel
                                renderControls={(activeIndex) => (
                                    <StyledZoomButton
                                        label="Åben fuldskærmsvisning"
                                        onClick={() => {
                                            setActiveSlide(activeIndex);
                                            openDialog({ dialog: OpenDialog.Media });
                                        }}
                                        size={65}
                                    />
                                )}
                                slides={matchedResources}
                                resetCarousel={resetCarousel}
                                onCarouselReset={() => setResetCarousel(false)}
                                isAboveFold={true}
                            />
                        )}
                    </StyledCarouselContainer>

                    <StyledAcknowledgementContainer>
                        {!colorAndResourceMatched && selectedProduct?.availability === 'Order' ? (
                            <StyledOrderCarDisclaimer>
                                Farven <strong>{selectedColor?.name}</strong> kan ikke vises på bilen
                            </StyledOrderCarDisclaimer>
                        ) : null}
                        {!priceDifferenceAcknowledged && selectedProduct?.availability === 'Order' && selectedColor?.id !== queryColor?.id ? (
                            <StyledOrderCarDisclaimer>
                                {props.page.carPriceDifferenceDisclaimer?.length > 0
                                    ? props.page.carPriceDifferenceDisclaimer
                                    : 'Bilen vises i en standard farve. Vælg eventuelt en anden farve'}
                            </StyledOrderCarDisclaimer>
                        ) : null}
                    </StyledAcknowledgementContainer>

                    <StyledSpecsRibbon
                        buttonLabel={props.page.viewAllSpecificationsLabel}
                        onButtonClick={() => openDialog({ dialog: OpenDialog.Specifications })}
                        ref={specsRibbonRef}
                    >
                        {slicedHighlightedSpec?.map(({ value, label, unit }) => (
                            <SpecsRibbonItem key={label} label={label} value={value} unit={unit} />
                        ))}
                    </StyledSpecsRibbon>

                    <StyledSidePanel>
                        {/* Ownership toggle tab */}
                        {!isCompanyCar && ownershipOptions.length > 1 ? (
                            <OwnershipWrapper>
                                <StyledOwnershipToggle
                                    options={ownershipOptions}
                                    selectedOption={ownershipMode}
                                    onChange={(selection) => {
                                        const ownership = selection as hesselViewModels.OwnershipMode;
                                        setOwnershipMode(ownership);
                                    }}
                                />
                            </OwnershipWrapper>
                        ) : null}
                        {/* Car Purchase tabs */}
                        {selectedProduct?.vehicleType === 'Car' && ownershipMode === 'Køb' && !isCompanyCar ? (
                            <CarPurchaseTabs
                                priceVisibilityChanged={(visible) => setShowStickyPrice(visible)}
                                page={props.page}
                                carFinancialSetup={props.page.carFinancialSetup}
                                selectedOwnershipTab={selectedTab}
                                setSelectedOwnershipTab={(tab) => setSelectedTab(tab)}
                                product={selectedProduct}
                                priceInformation={priceInformation}
                                santanderFinancing={santanderFinancing}
                            />
                        ) : null}
                        {/* Car Leasing tabs */}
                        {selectedProduct?.vehicleType === 'Car' && ownershipMode === 'Leasing' && !isCompanyCar ? (
                            <CarLeasingTabs
                                downPayment={computedDownPayment}
                                page={props.page}
                                priceVisibilityChanged={(visible) => setShowStickyPrice(visible)}
                                description={leasingPeriodDescription}
                                selectedOwnershipTab={selectedTab}
                                onOpenCheckout={prepareDataAndGoToCheckout}
                                dropdownProps={{
                                    label: 'Periode',
                                    onChange: (option) => option && setSelectedLeasingPeriod(option),
                                    options: leasingPeriodOptions,
                                    value: handleSelectedLeasingPeriod(selectedLeasingPeriod),
                                }}
                                showHigherEquipmentLabel={showHigherEquipmentLabel(selectedProduct, selectedTab)}
                                setSelectedOwnershipTab={(tab) => setSelectedTab(tab)}
                                product={selectedProduct}
                                priceInformation={priceInformation}
                            />
                        ) : null}
                        {/* Car company tabs */}
                        {isCompanyCar && selectedProduct ? (
                            <CompanyCarTabs
                                ownershipMode={ownershipMode}
                                companyCarSetup={props.page.carFinancialSetup}
                                priceVisibilityChanged={(visible) => setShowStickyPrice(visible)}
                            />
                        ) : null}
                        {/* Van Purchase tabs */}
                        {selectedProduct?.vehicleType === 'Van' && !isCompanyCar && ownershipMode === 'Køb' ? (
                            <VanPurchaseTabs
                                page={props.page}
                                product={selectedProduct}
                                selectedOwnershipTab={selectedTab}
                                setSelectedOwnershipTab={(tab) => setSelectedTab(tab)}
                                vanFinancialSetup={props.page.vanFinancialSetup}
                                priceVisibilityChanged={(visible) => setShowStickyPrice(visible)}
                                priceInformation={priceInformation}
                                santanderFinancing={santanderFinancing}
                            />
                        ) : null}
                        {/* Van Leasing tabs */}
                        {selectedProduct?.vehicleType === 'Van' && !isCompanyCar && ownershipMode === 'Leasing' ? (
                            <VanLeasingTabs
                                product={selectedProduct}
                                selectedOwnershipTab={selectedTab}
                                setSelectedOwnershipTab={(tab) => setSelectedTab(tab)}
                                vanFinancialSetup={props.page.vanFinancialSetup}
                                page={props.page}
                                priceVisibilityChanged={(visible) => setShowStickyPrice(visible)}
                                priceInformation={priceInformation}
                            />
                        ) : null}

                        <VehiclePriceLinks
                            hirePriceListLink={priceListHref}
                            downloadPriceListIcon={props.page.downloadPriceListIcon}
                            paymentPlanLabel={props.page.paymentPlanLabel}
                            paymentPlanIcon={props.page.paymentPlanIcon}
                            brochureIcon={props.page.downloadPriceListIcon}
                            onPaymentPlanClick={() => {
                                setShowPaymentPlanSidebar({ showSidebar: true });
                                hideScrollBars();
                                leadCheckoutTracker.sidebarOpened('Betalingsplan');
                            }}
                            ownershipMode={ownershipMode}
                            selectedTab={selectedTab}
                            product={selectedProduct}
                        />

                        <CarConfigurationCard vanHeightModal={props.page.vanHeightModal?.[0]} vanLengthModal={props.page.vanLengthModal?.[0]} />

                        {selectedProduct && sizeGuides?.some((x) => x.modelCode.split(',').includes(selectedProduct.variantModelCode)) ? (
                            <StyledSizeGuideButton
                                variant="light"
                                onClick={() => {
                                    const sizeGuideSpot = sizeGuides.find((x) => x.modelCode.split(',').includes(selectedProduct.variantModelCode));

                                    if (sizeGuideSpot) {
                                        const element = document.querySelectorAll(`[id="${selectedProduct.variantModelCode}" i]`);

                                        if (element && element.length > 0) {
                                            element[0].scrollIntoView({
                                                behavior: 'smooth',
                                                block: 'center',
                                            });
                                        }
                                    }
                                }}
                            >
                                <p>Størrelsesguide</p>
                            </StyledSizeGuideButton>
                        ) : null}
                        {selectedTab !== 'Car HiRE' &&
                        !isNullOrEmpty(selectedProduct?.fullEquipmentListUrl) &&
                        selectedProduct.availability !== 'Used' &&
                        selectedProduct.availability !== 'Engros' ? (
                            <FullEquipmentListLinkAndIcon>
                                {props.page.fullEquipmentListLinkIcon ? (
                                    <FullEquipmentListIcon src={MEDIA_URL + props.page.fullEquipmentListLinkIcon.src} />
                                ) : null}
                                <FullEquipmentLink href={selectedProduct.fullEquipmentListUrl} target="_blank">
                                    Se fuld udstyrsliste
                                </FullEquipmentLink>
                            </FullEquipmentListLinkAndIcon>
                        ) : null}
                        {selectedProduct?.description ? (
                            <StyledInfoCardsWrapper>
                                <StyledShortInfoCard
                                    header={'Kort info om bilen'}
                                    description={selectedProduct.description}
                                    buttonText={'Læs mere'}
                                    sidebarHeader={'Kort info om bilen'}
                                />
                            </StyledInfoCardsWrapper>
                        ) : null}
                    </StyledSidePanel>
                </StyledProductDetailsPage>

                <SpecificationsDialog
                    content={props.page.specificationsDialog[0]}
                    isVisible={openedDialog === OpenDialog.Specifications}
                    onClose={closeModal}
                    specificationGroups={specificationGroups}
                    images={carTypeImages.find((x) => x.carType === selectedProduct?.carType)?.images ?? []}
                />

                <TabbedCarouselDialog
                    initialIndex={activeSlide}
                    isVisible={openedDialog === OpenDialog.Media}
                    onClose={closeModal}
                    slides={matchedResources ?? []}
                />
            </StyledCenteredBlock>

            {/* The order, after which spots should be listed is described here: https://jira.impact.dk/browse/EJH-1417 */}
            <StyledPdpUspTemplateBar uspBar={filteredUspBarFromCms} />

            {selectedProduct
                ? sizeGuides
                      .filter((x) => x.modelCode.split(',').indexOf(selectedProduct.variantModelCode) > -1)
                      .map((x, idx) => {
                          const spot: SizeGuideSpot = {
                              alias: 'sizeGuideSpot',
                              description: x.description,
                              dropdownLabel: x.dropdownLabel,
                              header: x.header,
                              headerType: !isNullOrEmpty(x.headerType) ? x.headerType : 'h4',
                              headerSize: x.headerSize,
                              tabs: x.tabs,
                              scrollAnchorId: x.modelCode?.split(',').find((x) => x === selectedProduct.variantModelCode) ?? '',
                              hideBlock: false,
                              fromDate: new Date(),
                              toDate: new Date(),
                          };
                          return <SizeGuide key={idx} spot={spot} isPdpPlugin={true} />;
                      })
                : null}

            <PdpRelatedVehiclesRibbon isCompanyCar={isCompanyCar} page={props.page} priceInformation={priceInformation} />

            {selectedProduct ? (
                <PartOfBundle
                    globalEcomSettings={props.globalEcomSettings}
                    pageId={props.page.id}
                    header={props.page.partOfBundleHeader}
                    bodyText={props.page.partOfBundleText}
                    contactForm={props.page.partOfBundleContactForm}
                    vehicleId={selectedProduct.id}
                />
            ) : null}

            {filteredPromotionsFromCms.map((x, idx) => {
                const content = x.promotionContent[0];
                const elementListWithHeading = content.elementList.map((x) => ({ ...x, headerType: 'h4' } as FullScreenCampaignElement));
                return (
                    <StyledPromotionsWrapper key={idx}>
                        <FullScreenCampaignItem
                            imageUrl={content.image ? `${MEDIA_URL}/${content.image?.src}` : undefined}
                            imageMobileUrl={content.imageMobile ? `${MEDIA_URL}/${content.imageMobile?.src}` : undefined}
                            videoUrl={content.video ? `${MEDIA_URL}/${content.video?.src}` : undefined}
                            videoMobileUrl={content.videoMobile ? `${MEDIA_URL}/${content.videoMobile?.src}` : undefined}
                            backgroundColor={content.backgroundColor}
                            elements={elementListWithHeading}
                            slideCta={content.ctaForEntireSlide}
                            slideFootnote={content.slideFootnote}
                            carouselIndex={idx}
                            isAboveFold={false}
                        />
                    </StyledPromotionsWrapper>
                );
            })}

            {selectedProduct ? (
                <PdpFindDealership
                    spotId={pdpFindDealershipSpotId}
                    selectedProduct={selectedProduct}
                    pageId={props.page.id}
                    staticImage={findDealershipSpotStaticImage}
                />
            ) : null}

            <ProductDetailsModals page={props.page} siteSettings={props.siteSettings} />
        </StyledProductDetailsPageMain>
    );
};

export const ProductDetailsPage: FC<ProductDetailsPageProps> = (props) => {
    return (
        <ProductDetailsPageModalsProvider>
            <ProductDetailsPageBase {...props} />
        </ProductDetailsPageModalsProvider>
    );
};
