import styled from 'styled-components';
import { device } from '../../../../lib/media-query';
import { uiTypes } from '../../../../lib';

export const StyledSpotHeader = styled.p<{ headerSize: uiTypes.SpotHeaderSize }>`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: ${({ headerSize }) => headerSize.mobile};
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;

    @media ${device.tablet} {
        font-size: ${({ headerSize }) => headerSize.desktop};
        line-height: 35px;
    }
`;
