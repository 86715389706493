import { APIResponse } from '..';
import { getAPI, postMethod } from '../helpers';
import { hesselApiTypes } from '../models/hessel-api';

const Hire_Lead_Checkout_BASE_API_URL = `api/v1`;

export const submitHireLeadCheckout = async (data: hesselApiTypes.HireLeadCheckoutSubmit): APIResponse<hesselApiTypes.HireLeadCheckoutResponse> => {
    return postMethod<hesselApiTypes.HireLeadCheckoutResponse>(`api/v3/lead/hire`, data);
};

export const getHireEmployees = async (
    departmentId: number,
    brand: string,
    params?: hesselApiTypes.EmployeeParams
): APIResponse<Array<hesselApiTypes.HireEmployeeResponse>> => {
    return getAPI<Array<hesselApiTypes.HireEmployeeResponse>>(`${Hire_Lead_Checkout_BASE_API_URL}/employee/${departmentId}/${brand}`, params);
};
