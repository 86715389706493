import styled from 'styled-components';
import { Button } from '../../../shared';

export const StyledDisabledOverlay = styled.div<{ enabled: boolean }>`
    ${(props) => (props.enabled ? 'opacity: 0.4;' : '')}
`;

export const StyledHeader = styled.p`
    margin: 10px 0;
    font-weight: ${(props) => props.theme.typography.fontWeight.bold};
`;

export const StyledButton = styled(Button)`
    align-self: flex-start;
    justify-self: flex-end;
    margin: 15px 0;
`;

export const StyledSubheader = styled.pre`
    margin: 10px 0;
    font-size: 14px;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    white-space: pre-wrap;
`;

export const StyledStepContainer = styled.div`
    position: relative;
`;
