import styled from 'styled-components';

export const StyledEquipmentAndAddons = styled.div`
    padding: 2rem 22px; // This is to match padding given in tabbed-card.
`;

export const StyledEquipmentCards = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
`;
