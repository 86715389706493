import styled from 'styled-components';
import { device } from '../../../lib/media-query';
import { Button } from '../../shared';
import { CenteredBlock } from '../../structural-blocks/centered-block.component';

export const StyledCenteredBlock = styled(CenteredBlock)`
    background-color: #f7f7f8;
`;

export const StyledBrandAndServiceModule = styled.section`
    display: flex;
    flex-direction: column;
    padding: 40px 0;
    @media ${device.tablet} {
        padding: 85px 0;
    }
`;

export const Header = styled.h2`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 10px;
    line-height: 26px;

    @media ${device.tablet} {
        font-size: 30px;
        margin-bottom: 11px;
    }
`;

export const Description = styled.p`
    color: #0b0b0b;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 13px;
    letter-spacing: 0;
    font-weight: normal;
    text-align: left;
    line-height: 23px;
    max-width: 500px;
    margin-bottom: 20px;
    @media ${device.tablet} {
        margin-bottom: 10px;
        font-size: 14px;
    }
`;

export const BookTimeButton = styled(Button)`
    display: none;
    align-self: center;
    margin-top: 31px;
    @media ${device.tablet} {
        display: revert;
    }
`;

export const ButtonText = styled.p`
    color: #fafafa;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.3px;
    line-height: 18px;
    text-align: center;
`;

export const CertificateListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    gap: 20px;

    @media ${device.tablet} {
        flex-direction: revert;
        justify-content: revert;
        flex-wrap: wrap;
        gap: 100px;
        align-self: center;
    }
`;

export const CertificateContent = styled.div``;

export const CertificateImageWrapper = styled.div`
    width: 200px;
    height: 50px;
    overflow: hidden;
`;

export const CertificateImage = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
`;

export const CertificateText = styled.p`
    opacity: 0.5;
    color: #0b0b0b;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 12px;
    letter-spacing: 0;
    text-align: center;
`;
