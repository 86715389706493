import { useMemo } from 'react';
import { Agreement } from '../../../lib/api/models/umbraco';
import { BookingFormStore } from '../../../lib/state/booking-form';
import { BookingStepsStore } from '../../../lib/state/booking-steps';
import { filterStepType } from '../../../lib/state/booking-steps/booking-steps.helpers';

export const useBenefitAgreementInfo = (): Agreement | undefined => {
    const { benefitAgreements } = BookingStepsStore.useStoreState(({ steps }) => ({
        benefitAgreements: steps
            .filter(filterStepType('YourCar'))
            .map(({ content }) => content.benefitAgreements)
            .reduce((acc, content) => ({ ...acc, ...content })),
    }));

    const benefitAgreement = BookingFormStore.useStoreState(({ customer }) => customer?.benefitAgreement);

    const benefitAgreementInfo = useMemo(() => {
        return benefitAgreements.find((agreement) => agreement.agreementID === benefitAgreement);
    }, [benefitAgreement, benefitAgreements]);

    return benefitAgreementInfo;
};
