import router from 'next/router';
import { FC, useState } from 'react';
import { ContactSection as ContactSectionModel } from '../../../lib/api/models/umbraco/mega-menu.types';
import { cmsUrlWithoutSiteId } from '../../../utils/helpers';
import { Button } from '../../shared';
import { SidePanel } from '../../shared/modals/side-panel';
import { SvgIcon } from '../../shared/svg-icon';
import { UmbracoForms } from '../../spots/umbraco-forms';
import { StyledCloseIcon } from '../burger-menu-desktop/burger-menu-desktop.styled';
import { StyledBackButton } from '../burger-menu-mobile/burger-menu-mobile-link/burger-menu-mobile-link.styled';
import {
    StyledCloseActions,
    StyledCompanyCVR,
    StyledCompanyInfo,
    StyledCompanyInfoWrapper,
    StyledContactSectionHeader,
    StyledContactSectionWrapper,
    StyledCtaWrapper,
    StyledFormWrapper,
} from './contact-section.styled';

type IProps = {
    contactSection: ContactSectionModel;
    pageId: string;
    closeAction: () => void;
};

export const ContactSection: FC<IProps> = ({ contactSection, pageId, closeAction }) => {
    const [contactFormOpen, setContactFormOpen] = useState(false);

    const goToDepartments = () => {
        closeAction();
        router.push(cmsUrlWithoutSiteId(contactSection.findDepartmentLink.url));
    };

    const closeFromForm = () => {
        closeAction();
        setContactFormOpen(false);
    };

    return (
        <>
            <StyledContactSectionWrapper>
                <StyledContactSectionHeader>{contactSection.contactHeader}</StyledContactSectionHeader>
                <p>{contactSection.contactSubText}</p>
                <StyledCtaWrapper>
                    <Button onClick={() => setContactFormOpen(true)} variant="primary">
                        Kontakt os
                    </Button>
                    {contactSection.findDepartmentLink ? <Button onClick={() => goToDepartments()}>Find afdeling</Button> : null}
                </StyledCtaWrapper>
                <StyledCompanyInfoWrapper>
                    <StyledCompanyInfo>{contactSection.companyName}</StyledCompanyInfo>
                    <StyledCompanyInfo>{contactSection.companyAddress}</StyledCompanyInfo>
                    <StyledCompanyCVR>{contactSection.companyCVR}</StyledCompanyCVR>
                </StyledCompanyInfoWrapper>
            </StyledContactSectionWrapper>
            <SidePanel isVisible={contactFormOpen} cancelAction={() => setContactFormOpen(false)}>
                <StyledFormWrapper>
                    <StyledCloseActions>
                        <StyledBackButton onClick={() => setContactFormOpen(false)}>
                            <SvgIcon iconName="chevron/left" /> Tilbage
                        </StyledBackButton>
                        <StyledCloseIcon onClick={() => closeFromForm()}>
                            <SvgIcon iconName="close" />
                        </StyledCloseIcon>
                    </StyledCloseActions>
                    <UmbracoForms
                        pageId={pageId}
                        form={contactSection.contactForm[0].form}
                        header={contactSection.contactForm[0].formHeader}
                        subtext={contactSection.contactForm[0].formSubtext}
                    />
                </StyledFormWrapper>
            </SidePanel>
        </>
    );
};
