import { FC } from 'react';
import {
    StyledCard,
    StyledCardHeader,
    StyledDescription,
    StyledImage,
    StyledLink,
    StyledLinkWrapper,
    StyledManchet,
    StyledName,
    StyledPrice,
} from '../car-exploration.styled';
import Link from 'next/link';
import { CtaUrl } from '../../../../lib/api/models/umbraco/shared-types';

type IProps = {
    link: CtaUrl;
    imageUrl: string;
    imageAlt: string;
    name: string;
    price: string;
    manchet: string;
    description: string;
    linkText: string;
    useSquaredCornersForImages?: boolean;
};

export const CarExplorationCard: FC<IProps> = (props) => {
    return (
        <StyledCard>
            <Link href={props.link.url} passHref>
                <StyledLinkWrapper target={props.link.target}>
                    <StyledImage alt={props.imageAlt} src={props.imageUrl} useSquaredCorners={props.useSquaredCornersForImages} />
                </StyledLinkWrapper>
            </Link>
            <StyledCardHeader>
                <StyledName>{props.name}</StyledName>
                {props.price && props.price.length > 0 ? <StyledPrice>{props.price}</StyledPrice> : null}
            </StyledCardHeader>
            <StyledManchet>{props.manchet}</StyledManchet>
            <StyledDescription>{props.description}</StyledDescription>
            <Link href={props.link.url} passHref>
                <StyledLinkWrapper target={props.link.target}>
                    <StyledLink>{props.linkText}</StyledLink>
                </StyledLinkWrapper>
            </Link>
        </StyledCard>
    );
};
