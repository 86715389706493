import { useMemo, VFC } from 'react';
import { orgTypes } from '../../../../lib/api/models/umbraco';
import { vehicleIsSupported, getBrandLogo, getSupportedVehicleIcon } from '../../../../utils/helpers/supported-brands.helpers';
import { Button } from '../../../shared';
import {
    LogoWrapper,
    ServiceIconWrapper,
    ServicesWrapper,
    StyledButtonText,
    StyledContentWrapperMobile,
    StyledImage,
    HeaderAndServices,
    Header,
    StyleImage,
} from './brand-and-service-mobile.styled';

type BrandAndServiceMobileProps = {
    brandInfo: orgTypes.SupportedBrand;
    onCtaClick?: () => void;
};

export const BrandAndServiceMobile: VFC<BrandAndServiceMobileProps> = ({ brandInfo, onCtaClick }) => {
    const hasBus = useMemo(() => vehicleIsSupported(brandInfo, 'Bus', 'Sales') || vehicleIsSupported(brandInfo, 'Bus', 'Workshop'), [brandInfo])
    
    return (
        <StyledContentWrapperMobile>
            <LogoWrapper>
                <StyleImage src={getBrandLogo(brandInfo)} alt="Brand Logo" />
            </LogoWrapper>

            <HeaderAndServices>
                <Header>Salg</Header>
                <ServicesWrapper>
                    <ServiceIconWrapper>
                        <StyledImage src={getSupportedVehicleIcon(brandInfo, 'Cars', 'Sales')} alt="Car Logo" />
                    </ServiceIconWrapper>
                    <ServiceIconWrapper>
                        <StyledImage src={getSupportedVehicleIcon(brandInfo, 'Vans', 'Sales')} alt="Car Logo" />
                    </ServiceIconWrapper>
                    <ServiceIconWrapper>
                        <StyledImage src={getSupportedVehicleIcon(brandInfo, 'Trucks', 'Sales')} alt="Car Logo" />
                    </ServiceIconWrapper>
                    {hasBus && (
                        <ServiceIconWrapper>
                            <StyledImage src={getSupportedVehicleIcon(brandInfo, 'Bus', 'Sales')} alt="Car Logo" />
                        </ServiceIconWrapper>
                    )}
                </ServicesWrapper>
            </HeaderAndServices>

            <HeaderAndServices>
                <Header>Værksted</Header>
                <ServicesWrapper>
                    <ServiceIconWrapper>
                        <StyledImage src={getSupportedVehicleIcon(brandInfo, 'Cars', 'Workshop')} alt="Car Logo" />
                    </ServiceIconWrapper>
                    <ServiceIconWrapper>
                        <StyledImage src={getSupportedVehicleIcon(brandInfo, 'Vans', 'Workshop')} alt="Car Logo" />
                    </ServiceIconWrapper>
                    <ServiceIconWrapper>
                        <StyledImage src={getSupportedVehicleIcon(brandInfo, 'Trucks', 'Workshop')} alt="Car Logo" />
                    </ServiceIconWrapper>
                    {hasBus && (
                        <ServiceIconWrapper>
                            <StyledImage src={getSupportedVehicleIcon(brandInfo, 'Bus', 'Workshop')} alt="Car Logo" />
                        </ServiceIconWrapper>
                    )}
                </ServicesWrapper>
            </HeaderAndServices>

            {brandInfo.vehiclesInWorkshop.length > 0 && onCtaClick ? (
                <Button onClick={() => onCtaClick()} variant="primary">
                    <StyledButtonText>Book tid på værksted</StyledButtonText>
                </Button>
            ) : null}
        </StyledContentWrapperMobile>
    );
};
