import React, { createRef, FC, KeyboardEvent, ReactNode, useCallback, useMemo, useState } from 'react';
import { next } from '../../../utils/helpers/incremental-integer.helper';
import {
    LabelAndBadgeWrapper,
    StyledTabbedCard,
    StyledTabbedCardDescription,
    StyledTabbedCardLabel,
    StyledTabbedCardTab,
    StyledTabbedCardTabList,
    StyledTabbedCardTabPanel,
} from './tabbed-card.styled';

export type TabbedCardTab = {
    /**
     * An id for the tab. If not provided, one will be generated.
     * This is required if controlled is true.
     */
    id?: string;
    content: ReactNode;
    description?: string;
    label: string;
    badge?: ReactNode;
    onClick?: () => void;
};

export type TabbedCardProps = {
    className?: string;
    /**
     * If true, the tabbed card will be controlled by the parent component using TabbedCardTab.id.
     * You must provide an activeTabId and onAccesibilityTabSelected callback.
     * If false, the tabbed card will be controlled by the TabbedCard component.
     * activeTabId should then be changed using TabbedCardTab.onClick or onAccessibilityTabSelected.
     */
    controlled?: boolean;
    /**
     * Controls which tab is active. This is required if controlled is true and ignored if false.
     */
    activeTabId?: string;
    /**
     * A callback which will be called when the user presses the left or right arrow key. Should be used with controlled.
     */
    onAccesibilityTabSelected?: (tabId: string) => void;
    /**
     * A name for the tab list, which will be mapped to the accessibility API.
     */
    label?: string;
    /**
     * Tab definitions which will be rendered as tabs and panels.
     */
    tabs: Array<TabbedCardTab>;
};

export const TabbedCard: FC<TabbedCardProps> = (props) => {
    const [domId] = useState(`tabbed-card-${next()}`);
    const [uncontrolledActiveTabId, setUncontrolledActiveTabId] = useState(`${domId}-0`);
    const tabListRef = createRef<HTMLDivElement>();

    const mappedTabs = useMemo(
        () =>
            props.tabs.map((tab, i) => {
                const tabId = tab.id || `${domId}-${i}`;
                return {
                    ...tab,
                    id: tabId,
                    activate: () => {
                        !props.controlled && setUncontrolledActiveTabId(tabId);
                        tab.onClick?.();
                    },
                    buttonDomId: `${domId}-${i}-button`,
                    panelDomId: `${domId}-${i}-panel`,
                };
            }),
        [domId, props.tabs, props.controlled]
    );

    const nextTab = useCallback(() => {
        const currentTabIndex = mappedTabs.findIndex((tab) => (props.controlled ? tab.id === props.activeTabId : tab.id === uncontrolledActiveTabId));
        if (currentTabIndex > mappedTabs.length - 1) {
            const nextTabId = mappedTabs[currentTabIndex + 1].id;
            if (props.controlled) {
                props.onAccesibilityTabSelected?.(nextTabId);
                return;
            } else {
                setUncontrolledActiveTabId(nextTabId);
            }
        }
    }, [props.controlled, props.activeTabId, props.onAccesibilityTabSelected, uncontrolledActiveTabId, mappedTabs]);

    const previousTab = useCallback(() => {
        const currentTabIndex = mappedTabs.findIndex((tab) => (props.controlled ? tab.id === props.activeTabId : tab.id === uncontrolledActiveTabId));
        if (currentTabIndex > 0) {
            const previousTabId = mappedTabs[currentTabIndex - 1].id;
            if (props.controlled) {
                props.onAccesibilityTabSelected?.(previousTabId);
                return;
            } else {
                setUncontrolledActiveTabId(previousTabId);
            }
        }
    }, [props.controlled, props.activeTabId, props.onAccesibilityTabSelected, uncontrolledActiveTabId, mappedTabs]);

    const onKeyDown = (event: KeyboardEvent) => {
        switch (event.key) {
            case 'ArrowLeft':
                previousTab();
                event.preventDefault();
                break;
            case 'ArrowRight':
                nextTab();
                event.preventDefault();
                break;
            default:
                break;
        }
    };

    /**
     * Label description foo
     * @param props.label
     */
    return (
        <StyledTabbedCard className={props.className}>
            <StyledTabbedCardTabList aria-label={props.label} onKeyDown={onKeyDown} ref={tabListRef} role="tablist">
                {mappedTabs.map((tab) => {
                    const tabIsActive = props.controlled ? tab.id === props.activeTabId : tab.id === uncontrolledActiveTabId;
                    return (
                        <StyledTabbedCardTab
                            aria-controls={tab.panelDomId}
                            aria-selected={tabIsActive}
                            id={tab.buttonDomId}
                            isActive={tabIsActive}
                            key={tab.buttonDomId}
                            onClick={tab.activate}
                            role="tab"
                            tabIndex={tabIsActive ? undefined : -1}
                        >
                            {React.isValidElement(tab.badge) ? (
                                <LabelAndBadgeWrapper>
                                    <StyledTabbedCardLabel isActive={tabIsActive}>{tab.label}</StyledTabbedCardLabel>
                                    {tab.badge}
                                </LabelAndBadgeWrapper>
                            ) : (
                                <StyledTabbedCardLabel isActive={tabIsActive}>{tab.label}</StyledTabbedCardLabel>
                            )}

                            {tab.description && <StyledTabbedCardDescription>{tab.description}</StyledTabbedCardDescription>}
                        </StyledTabbedCardTab>
                    );
                })}
            </StyledTabbedCardTabList>
            {mappedTabs.map((tab) => {
                const tabIsActive = props.controlled ? tab.id === props.activeTabId : tab.id === uncontrolledActiveTabId;

                return (
                    <StyledTabbedCardTabPanel
                        aria-labelledby={tab.buttonDomId}
                        id={tab.panelDomId}
                        isActive={tabIsActive}
                        key={tab.panelDomId}
                        role="tabpanel"
                        tabIndex={0}
                    >
                        {tab.content}
                    </StyledTabbedCardTabPanel>
                );
            })}
        </StyledTabbedCard>
    );
};
