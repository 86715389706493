import { useMemo } from 'react';
import { BookingFormStore } from '../lib/state/booking-form';
import { BookingStepsStore } from '../lib/state/booking-steps';
import { filterStepType } from '../lib/state/booking-steps/booking-steps.helpers';
import { useBenefitAgreementInfo } from './booking/booking/use-benefit-agreement-info';

export const useAgreements = (): boolean => {
    const { serviceAgreements } = BookingStepsStore.useStoreState(({ steps }) => ({
        serviceAgreements: steps
            .filter(filterStepType('YourCar'))
            .map(({ content }) => content.serviceAgreements)
            .reduce((acc, content) => ({ ...acc, ...content })),
    }));

    const { serviceAgreement } = BookingFormStore.useStoreState(({ vehicle }) => ({
        serviceAgreement: vehicle?.serviceAgreement,
    }));

    const serviceAgreementInfo = useMemo(
        () => serviceAgreements.find(({ agreementID }) => serviceAgreement === agreementID),
        [serviceAgreements, serviceAgreement]
    );

    const benefitAgreementInfo = useBenefitAgreementInfo();

    return useMemo(() => (serviceAgreementInfo || benefitAgreementInfo ? true : false), [benefitAgreementInfo, serviceAgreementInfo]);
};
