import styled from 'styled-components';
import { device } from '../../../../lib/media-query';

export const ColumnWrapper = styled.div<{ columnCount: number }>`
    @media ${device.tablet} {
        display: grid;
        grid-template-columns: repeat(${(props) => props.columnCount}, 1fr);
        gap: 2rem;
    }
`;

export const BorderBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
`;

export const MultiInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const StyledError = styled.p`
    display: flex;
    flex-direction: column;
    color: #d64469;
    font-size: ${({ theme: { typography } }) => typography.small.fontSize}px;
`;

export const FormGroupCaption = styled.p`
    font-size: 14px;
    font-weight: bold;
`;

export const CheckboxesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5px 0 5px 0;

    @media ${device.tablet} {
        padding: 10px 0 10px 0;
        gap: 30px;
        flex-direction: revert;
    }
`;

export const StyledRedLabel = styled.span`
    color: #ff0000;
`;
