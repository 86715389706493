import { useKeenSlider } from 'keen-slider/react';
import { FC } from 'react';
import styled from 'styled-components';

type IProps = {
    initialIndex?: number;
    spacing?: number;
    onLoad?: () => void;
    perView?: number | 'auto';
};

export const HorizontalDragSlider: FC<IProps> = ({ initialIndex, spacing = 20, children, onLoad, perView = 'auto' }) => {
    const [sliderRef] = useKeenSlider({
        initial: initialIndex ?? 0,
        loop: false,
        mode: 'free',
        slides: {
            perView,
            spacing,
        },
        created: onLoad,
    });
    return (
        <StyledHorizontalSliderWrapper ref={sliderRef} className="keen-slider">
            {children}
        </StyledHorizontalSliderWrapper>
    );
};

const StyledHorizontalSliderWrapper = styled.div`
    display: flex;
    user-select: none;
    touch-action: pan-y;
    width: 100%;
`;
