import styled, { css } from 'styled-components';
import { device } from '../../../../lib/media-query';

export const StyledProductInformationHeader = styled.h1`
    font-family: 'Corporate E';
    font-size: 20px;
    margin-bottom: 10px;

    @media ${device.mobile_tablet} {
        font-size: 25px;
    }
`;

export const StyledInformationSectionWrapper = styled.div`
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 10px 0px;
`;

export const StyledProductKeyInfoWrapper = styled.div`
    padding: 25px;
`;

export const StyledProductInformationSubheader = styled.h2`
    font-weight: 500;
    font-size: 14px;
`;

export const StyledPrice = styled.p<{ discounted: boolean }>`
    font-size: 20px;
    line-height: 30px;
    font-family: 'Corporate E';
    font-weight: 700;
    margin-top: 25px;
    padding-top: 20px;
    position: relative;
    display: flex;
    align-items: flex-end;
    gap: 10px;
    ${(props) => (props.discounted ? 'color: #0089D1;' : null)}

    @media ${device.mobile_tablet} {
        font-size: 25px;
    }

    &:before {
        position: absolute;
        top: 0;
        display: block;
        content: ' ';
        width: 30px;
        height: 1px;
        background-color: #979797;
        opacity: 0.5;
    }
`;

export const StyledBeforePrice = styled.span`
    text-decoration: line-through;
    color: #aeaeaf;
    font-size: 20px;
    line-height: 26px;
`;

export const StyledProductCtaSection = styled.div`
    border-top: 1px solid #e2e2e4;
    padding: 25px;
`;

export const StyledStockAndProductNoWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
`;

export const StyledProductNo = styled.p`
    font-size: 10px;
    font-weight: 500;
    color: #807f80;
`;

export const StyledSizeAndCtaWrapper = styled.div<{ hasSkus: boolean }>`
    ${(props) =>
        props.hasSkus
            ? css`
                  display: flex;
                  flex-direction: column;
                  gap: 20px;
                  @media ${device.mobileL} {
                      display: grid;
                      grid-template-columns: 1fr 1fr;
                      gap: 10px;
                  }
              `
            : ''}
`;

export const StyledColorPickerWrapper = styled.div`
    margin-top: 15px;
`;

export const StyledChooseColorText = styled.span`
    font-weight: 600;
`;

export const StyledColorPreviewGrid = styled.div`
    display: flex;
    gap: 12px;
    margin-top: 10px;
`;

export const StyledColorPreviewBox = styled.div<{ selected: boolean }>`
    max-width: 68px;
    max-height: 76px;
    border: 1px solid ${(props) => (props.selected ? '#0089D1' : '#E2E2E4')};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    padding: 5px;

    &:hover {
        border: 1px solid #0089d1;
    }

    img {
        max-width: 100%;
        max-height: 100%;
    }
`;

export const StyledBasketToast = styled.div``;
export const StyledBasketToastText = styled.p``;
