import styled from 'styled-components';
import { specsRibbonButtonLineHeight } from './specs-ribbon-button.styled';

export const SpecsContentWrapper = styled.div``;

export const StyledSpecsRibbon = styled.ul.attrs(() => ({
    buttonLineHeight: specsRibbonButtonLineHeight,
    buttonSpacing: 1.08,
}))`
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 0.9em;
    margin-bottom: 20px;
    margin-top: 30px;

    > * {
        flex: 1 1 8em;
    }
`;
