import { FC, useCallback } from 'react';
import { StepsListWrapper } from './hire-step-list.styled';
import { LeadCheckoutStepsStore, HireStep } from '../../../../lib/state/hessel-site/lead-checkout/steps';
import { Step } from '../step';
import { UserInformation } from '../checkout-steps/user-information';
import { UserInfoSummary } from '../checkout-steps/user-information/user-info-summary';
import { DurationDelivery } from '../checkout-steps/duration-delivery';
import { Receipt } from '../checkout-steps/receipt';
import { AddonsAndEquipment } from '../checkout-steps/addons-and-equipment';
import { useHireLeadCheckoutData } from '../../../../hooks/lead-checkout/use-hire-lead-checkout-data';
import { HireCheckoutMode, LeadCheckoutFormsStore } from '../../../../lib/state/hessel-site/lead-checkout/forms';
import { NoResultWithCta } from '../../../shared';
import { umbraco } from '../../../../lib/api';
import { DurationDeliverySummary } from '../checkout-steps/duration-delivery/summary';
import { ProductDetailsStore } from '../../../../lib/state/hessel-site/product-details';
import { EquipmentSummary } from '../checkout-steps/addons-and-equipment/equipment-summary';
import { MiniBasketStore } from '../../../../lib/state/hessel-site/mini-basket';
import { useGtmTracking } from '../../../../gtm-tracking/hooks/use-gtm-tracking';
import { hesselViewModels } from '../../../../lib/view-models';
import { LeadDurationDeliveryStore } from '../../../../lib/state/hessel-site/lead-checkout/duration-delivery';
import { listOfDepartments } from '../checkout-steps/duration-delivery/department-list';
import { convertProductToGtmData, getGtmTotalPrice } from '../../../../hooks/lead-checkout/use-hire-gtm-tracking-data';

type HireLeadCheckoutStepListProps = {
    noCardData?: umbraco.HireLeadCheckoutNoCar;
};

export const HireLeadCheckoutStepList: FC<HireLeadCheckoutStepListProps> = ({ noCardData }) => {
    // Step Store
    const { steps, currentStep } = LeadCheckoutStepsStore.useStoreState((state) => state);
    const { completeStep, editStep, setCanValidateHireStep, setCurrentStepLoading } = LeadCheckoutStepsStore.useStoreActions((actions) => actions);

    // Forms Store
    const { isCheckoutComplete, checkoutMode } = LeadCheckoutFormsStore.useStoreState((state) => state);
    const { confirmHireLeadCheckout } = LeadCheckoutFormsStore.useStoreActions((actions) => actions);

    // Product Details Store
    const { persist: hireProductDetailsPersist } = ProductDetailsStore.useStore();
    const { selectedProduct, selectedColor, selectedLeasingPeriod } = ProductDetailsStore.useStoreState((state) => state);

    // LeadDurationDeliveryStore
    const { selectedDepartmentId, deliveryMode } = LeadDurationDeliveryStore.useStoreState((state) => state);

    // Mini Basket Store
    const { setProductData: setProductDataInMinBasket } = MiniBasketStore.useStoreActions((state) => state);
    const { persist: miniBasketPersist } = MiniBasketStore.useStore();

    const leadCheckoutData = useHireLeadCheckoutData();

    const scrollToStep = (idx: number, delay: number) => {
        const stepElement = document.getElementById(`hire-step-wrapper-${idx}`);

        if (stepElement) {
            setTimeout(function () {
                stepElement.scrollIntoView({ behavior: 'smooth' });
            }, delay);
        }
    };

    // GTM Tracking
    const { trackHireLeadCheckout } = useGtmTracking();
    const tracker = trackHireLeadCheckout();

    const loadStepContent = (step: HireStep, isCurrentStep: boolean, stepNumber: number) => {
        switch (step.type) {
            case 'checkoutStepUserInformation': {
                return step.isComplete ? (
                    <UserInfoSummary />
                ) : isCurrentStep ? (
                    <UserInformation contentHeader={step.content.contentHeader} canValidateStep={step.canValidateHireStep} />
                ) : null;
            }
            case 'checkoutStepAddonsAndEquipment': {
                return step.isComplete ? (
                    <EquipmentSummary selectedProduct={selectedProduct} />
                ) : isCurrentStep ? (
                    <AddonsAndEquipment genericBenefits={step.content.genericBenefits} stepNumber={stepNumber} />
                ) : null;
            }
            case 'checkoutStepDurationAndDelivery': {
                return step.isComplete ? (
                    <DurationDeliverySummary content={step.content} />
                ) : isCurrentStep ? (
                    <DurationDelivery content={step.content} stepNumber={stepNumber} canValidateStep={step.canValidateHireStep} />
                ) : null;
            }
            case 'checkoutStepReceipt': {
                return step.isComplete ? <h3>Completed</h3> : isCurrentStep ? <Receipt content={step.content} /> : null;
            }

            default:
                <p>Unknown step</p>;
        }
    };

    const getEquipmentAndEquipmentPackageNames = (selectedProduct?: hesselViewModels.ProductDetails) => {
        if (!selectedProduct) {
            return [];
        }

        const extraEquipment = selectedProduct.extraEquipment['Car HiRE'].reduce((acc, equipment) => {
            if (equipment.isSelected) {
                return [...acc, equipment.name];
            }

            return acc;
        }, [] as Array<string>);

        const extraEquipmentPackageList = selectedProduct.extraEquipmentPackages['Car HiRE'].reduce((acc, equipmentPkg) => {
            if (equipmentPkg.isSelected) {
                return [...acc, equipmentPkg.name ?? ''];
            }

            return acc;
        }, [] as Array<string>);

        return [...extraEquipment, ...extraEquipmentPackageList];
    };

    const nextStepGTMDataPush = useCallback(
        (step: HireStep) => {
            switch (step.type) {
                case 'checkoutStepUserInformation':
                    tracker.stepChanged({
                        step: '1',
                        event: 'checkout_option',
                        choice: checkoutMode === HireCheckoutMode.Private ? 'Privat' : 'Firma',
                    });
                    return;
                case 'checkoutStepAddonsAndEquipment':
                    tracker.stepChanged({
                        step: '2',
                        event: 'checkout_option',
                        tilvalg: selectedProduct?.optionalEquipment['Car HiRE'].reduce((acc, equipment) => {
                            if (equipment.isSelected) {
                                return [...acc, equipment.name];
                            }

                            return acc;
                        }, [] as Array<string>),
                        ekstraudstyr: getEquipmentAndEquipmentPackageNames(selectedProduct),
                    });
                    return;
                case 'checkoutStepDurationAndDelivery':
                    tracker.stepChanged({
                        step: '3',
                        event: 'checkout_option',
                        leasingperiode: selectedLeasingPeriod?.displayValue ?? '',
                        levering: deliveryMode === 'dealershipPickup' ? 'Afhent i afdeling' : 'Levering til adresse',
                        afdeling: selectedDepartmentId ? listOfDepartments.find((x) => x.id === selectedDepartmentId)?.city : undefined,
                    });
                    return;
            }
        },
        [checkoutMode, deliveryMode, selectedDepartmentId, selectedLeasingPeriod?.displayValue, selectedProduct, tracker]
    );

    const stepLoadedGTMDataPush = useCallback(
        (step: HireStep) => {
            const convertedProduct = convertProductToGtmData(selectedProduct, selectedColor);

            switch (step.type) {
                case 'checkoutStepUserInformation':
                    if (convertedProduct && convertedProduct.carData) {
                        tracker.stepLoaded({ event: 'checkout', step: '2' });
                    }
                    return;

                case 'checkoutStepAddonsAndEquipment':
                    if (convertedProduct && convertedProduct.carData) {
                        tracker.stepLoaded({ event: 'checkout', step: '3' });
                    }
                    return;
                case 'checkoutStepDurationAndDelivery': {
                    const product = convertProductToGtmData(selectedProduct, selectedColor, 'Finansiering');
                    const totalPrice = getGtmTotalPrice(product, selectedColor?.monthlyHirePrice ?? 0);
                    if (product && product.carData) {
                        tracker.stepLoaded({
                            event: 'purchase',
                            transaction_id: '',
                            shipping: 0,
                            value: totalPrice,
                            tax: totalPrice * 0.2,
                            ecommerce: product.carData.ecommerce,
                            affiliation: 'Finansiering',
                        });
                    }
                    return;
                }
            }
        },
        [selectedColor, selectedProduct, tracker]
    );

    if (!selectedProduct) {
        return (
            <NoResultWithCta
                header={noCardData?.header ?? ''}
                description={noCardData?.description ?? ''}
                ctaText={noCardData?.ctaText ?? ''}
                ctaUrl={noCardData?.ctaUrl}
            />
        );
    }

    return (
        <StepsListWrapper>
            {steps?.map((step, idx) => (
                <Step
                    key={`${idx}:${step.type}`}
                    goNext={async () => {
                        nextStepGTMDataPush(step);
                        if (step.type === 'checkoutStepDurationAndDelivery' && leadCheckoutData) {
                            setCurrentStepLoading({ isLoading: true, stepNumber: idx });
                            const error = await confirmHireLeadCheckout(leadCheckoutData);
                            setCurrentStepLoading({ isLoading: false, stepNumber: idx });

                            if (!error) {
                                hireProductDetailsPersist.clear();
                                setProductDataInMinBasket(undefined);
                                miniBasketPersist.clear();
                                completeStep(idx);
                                scrollToStep(idx + 2, 900);
                                stepLoadedGTMDataPush(step);
                            }
                        } else {
                            completeStep(idx);
                            stepLoadedGTMDataPush(step);
                            scrollToStep(idx + 2, 900);
                        }
                    }}
                    edit={() => {
                        editStep(idx);
                        scrollToStep(idx + 1, 300);
                    }}
                    stepNumber={idx + 1}
                    totalSteps={steps.length}
                    title={step.content.title}
                    nextStep={step.content.submitButton}
                    isCurrentStep={idx === currentStep}
                    isCompleted={step.isComplete}
                    footNote={step.content.footnote ?? ''}
                    isStepValid={step.isValid}
                    isStepLoading={step.isStepLoading}
                    setCanValidateStep={() => setCanValidateHireStep({ validateStep: true, stepNumber: idx })}
                    stepType={step.type}
                    isCheckoutComplete={isCheckoutComplete}
                    submitButtonLink={step.type === 'checkoutStepReceipt' ? step.content.submitButtonLink : undefined}
                >
                    {loadStepContent(step, idx === currentStep, idx)}
                </Step>
            ))}
        </StepsListWrapper>
    );
};
