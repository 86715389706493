import { VFC } from 'react';
import { DealershipInfoWrapper, StyledHeading, StyledText, StyledLink, Container, Label } from './dealership-info.styled';
import Link from 'next/link';

export type DealershipInfoProps = {
    header?: string;
    address?: string;
    zipcode?: string;
    city?: string;
    telephone?: string;
    email?: string;
    hideBorderBottom?: boolean;
};

export const DealershipInfo: VFC<DealershipInfoProps> = ({ header, address, zipcode, city, telephone, email, hideBorderBottom = false }) => {
    return (
        <DealershipInfoWrapper hideBorderBottom={hideBorderBottom}>
            <StyledHeading>{header}</StyledHeading>
            <StyledText>{address}</StyledText>
            <StyledText>
                {zipcode} {city}
            </StyledText>

            {email && email.length > 0 ? (
                <Container>
                    <Label>E-mail:</Label>
                    <Link href={`mailto:${email}`} passHref={true}>
                        <StyledLink>{email}</StyledLink>
                    </Link>
                </Container>
            ) : null}

            {telephone && telephone.length > 0 ? (
                <Container>
                    <Label>Tlf:</Label>
                    <Link href={`tel:${telephone.split(' ').join('')}`} passHref={true}>
                        <StyledLink>{telephone}</StyledLink>
                    </Link>
                </Container>
            ) : null}
        </DealershipInfoWrapper>
    );
};
