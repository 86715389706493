import { hesselApi } from '../models';
import { getAPI, postAPI } from '../helpers';
import { APIResponse } from '../types';
import { ServiceAgreementRequest, ServiceAgreementResponse } from '../models/hessel-api/service-agreement-calculator.types';

export const getVehicleServiceAgreements = async (licensePlate: string): APIResponse<hesselApi.VehicleServiceAgreementResponse> => {
    return getAPI(`https://78d6f3bf-3751-43fa-9a2a-2c861927d140.mock.pstmn.io/getAgreements`, { licensePlate });
};

export const submitServiceAgreement = async (serviceAgreementRequest: ServiceAgreementRequest): APIResponse<ServiceAgreementResponse> => {
    try {
        return await postAPI(`https://78d6f3bf-3751-43fa-9a2a-2c861927d140.mock.pstmn.io/postAgreement`, serviceAgreementRequest);
    } catch {
        return [undefined, undefined];
    }
};
