import { FC, useEffect, useMemo, useState } from 'react';
import { hesselApi, umbraco } from '../../../../../lib/api';
import { BookingServiceProductsStore } from '../../../../../lib/state/booking-service-products';
import { BookingStepsStore } from '../../../../../lib/state/booking-steps';
import { filterStepType } from '../../../../../lib/state/booking-steps/booking-steps.helpers';
import { DropdownInput } from '../../../../forms/inputs';
import { DropdownOption } from '../../../../forms/inputs/dropdown/dropdown-input.props';
import { RadioButton } from '../../../../shared';
import { Container, DropdownInputContainer, RadioContainer } from './product-options.styled';

type Props = {
    serviceProduct: hesselApi.ServiceProduct;
};

const ProductOptions: FC<Props> = ({ serviceProduct }) => {
    const { selectedServiceProductsIds } = BookingServiceProductsStore.useStoreState((state) => state);
    const { setProductOption, clearProductOptions } = BookingServiceProductsStore.useStoreActions((actions) => actions);
    const { content, canValidateStep } = BookingStepsStore.useStoreState(({ steps }) => {
        const step = steps.find(filterStepType('Services'));
        return {
            content: step?.content as umbraco.BookingStepServices,
            canValidateStep: step?.canValidateStep,
        };
    });

    const [showProductOptions, setShowProductOptions] = useState(false);
    const optionSelected = useMemo(() => {
        return serviceProduct.productOptions?.some((product) => selectedServiceProductsIds.includes(product.id)) ?? false;
    }, [selectedServiceProductsIds, serviceProduct.productOptions]);

    const dropdownProductOptions = useMemo(
        () => (serviceProduct.productOptions ?? []).map((option): DropdownOption<string> => ({ value: option.id, displayValue: option.name })),
        [serviceProduct.productOptions]
    );

    useEffect(() => {
        const anyOptionsSelected = serviceProduct.productOptions?.some((product) => selectedServiceProductsIds.includes(product.id)) ?? false;
        if (anyOptionsSelected) {
            setShowProductOptions(anyOptionsSelected);
            return;
        }

        if (serviceProduct.defaultOption) setProductOption({ parentId: serviceProduct.id, optionId: serviceProduct.defaultOption.id });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container>
            <RadioContainer>
                <RadioButton
                    id={`service-without-code-${serviceProduct.id}`}
                    value=""
                    checked={!showProductOptions}
                    action={() => {
                        setShowProductOptions(false);
                        if (serviceProduct.defaultOption)
                            setProductOption({ parentId: serviceProduct.id, optionId: serviceProduct.defaultOption.id });
                    }}
                >
                    <p>{content.serviceInspectionWithoutServiceCodeLabel}</p>
                </RadioButton>
                <RadioButton
                    id={`service-with-code-${serviceProduct.id}`}
                    value=""
                    checked={showProductOptions}
                    action={() => {
                        setShowProductOptions(true);
                        clearProductOptions(serviceProduct.id);
                    }}
                >
                    <p>{content.serviceInspectionWithServiceCodeLabel}</p>
                </RadioButton>
            </RadioContainer>
            {showProductOptions && (
                <DropdownInputContainer>
                    <DropdownInput
                        id={`service-code-dropdown-${serviceProduct.id}`}
                        isValid={optionSelected}
                        canValidateInputField={!!canValidateStep}
                        validationMessage="Du skal vælge en servicekode"
                        label={content.serviceInspectionCodeSelectionLabel}
                        placeholder={content.serviceInspectionCodeSelectionPlaceholder}
                        options={dropdownProductOptions}
                        onChange={(value) => {
                            if (value) {
                                setProductOption({ parentId: serviceProduct.id, optionId: value.value });
                            }
                        }}
                        value={dropdownProductOptions.find(({ value }) => selectedServiceProductsIds.includes(value))}
                    />
                </DropdownInputContainer>
            )}
        </Container>
    );
};

export default ProductOptions;
