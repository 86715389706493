import styled from 'styled-components';

export const StyledFooter = styled.footer`
    width: 100%;
    color: #fcfcfc;
    background-color: ${({ theme }) => theme.palette.common.darkBlue};
    padding: 2.875rem 1.25rem;
    display: flex;
    flex-direction: column;
    font-size: 0.75rem;
    letter-spacing: 0.5px;
    line-height: 16px;
`;

export const InfoSection = styled.section`
    margin-bottom: 3.125rem;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 1.25rem;
`;

export const CustomerInfoMobile = styled.section`
    display: flex;
    flex-direction: column;
`;

export const MasterData = styled.section``;

export const OpeningHoursWrapper = styled.section`
    margin: 1.5rem 0;
`;

export const OpeningHoursContent = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 175px;
`;

export const PaymentWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const CreditsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 200px;
`;

export const CreditCard = styled.div`
    width: 40px;
    height: 40px;

    & img {
        display: block;
        height: 100%;
        width: 100%;
    }
`;

export const CtaHandlerWrapperMobile = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;
