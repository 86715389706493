import { FC, useEffect, useState } from 'react';
import { ShopStepProps } from '../shop-checkout-page.component';
import { Step } from '../../../hire/hire-lead-checkout/step';
import { StyledConsentWrapper, StyledDescription, StyledPaymentIconsWrapper } from './shop-payment-step.styled';
import { CheckBox } from '../../../shared';
import { CartStoreContext } from '../../../../lib/state/shop/cart/cart.store';
import { ReepaySubscriptionResponse } from '../../../../lib/api/models/reepay';
import { sharedTypes } from '../../../../lib/api/models/umbraco';
import { MEDIA_URL } from '../../../../utils/environment-constants';
import { useGtmTracking } from '../../../../gtm-tracking/hooks/use-gtm-tracking';

type IProps = ShopStepProps & {
    paymentIcons: Array<sharedTypes.Image>;
};

export const ShopPaymentStep: FC<IProps> = ({ currentStep, setActiveStep, paymentIcons }) => {
    const [dataConsent, setDataConsent] = useState(false);
    const [marketingConsent, setMarketingConsent] = useState(false);
    const { reepayCheckout, cart, shopCheckoutComplete } = CartStoreContext.useStoreState((state) => state);
    const { startReepayCheckout, setShopCheckoutComplete, setCartToCartReceipt, stopReepayCheckout } = CartStoreContext.useStoreActions(
        (actions) => actions
    );

    const { trackShopEvents } = useGtmTracking();
    const tracker = trackShopEvents();

    useEffect(() => {
        if (currentStep !== 3) {
            stopReepayCheckout();
        }
    }, [currentStep, stopReepayCheckout]);

    const goToPayment = () => {
        if (cart) {
            startReepayCheckout({
                consent: {
                    code: 0,
                    consent: true,
                    dateTime: new Date(),
                    text: 'Jeg accepterer',
                },
                cart,
            });
        }
    };

    useEffect(() => {
        if (reepayCheckout && !document.getElementById('shopReepayScript')) {
            const reepayScript = document.createElement('script');
            reepayScript.setAttribute('src', 'https://checkout.reepay.com/checkout.js');
            reepayScript.id = 'shopReepayScript';
            document.head.appendChild(reepayScript);

            reepayScript.onload = () => {
                const rp = new window.Reepay.EmbeddedCheckout(reepayCheckout.id, { html_element: 'rp_shop_container' });
                rp.addEventHandler(window.Reepay.Event.Accept, function (data: ReepaySubscriptionResponse) {
                    if (cart) {
                        tracker.setPaymentMethod(cart, data.payment_method);
                        tracker.completePurchase(cart, data);
                    }
                    setTimeout(() => {
                        rp.destroy();
                        setShopCheckoutComplete(true);
                        setCartToCartReceipt();
                        setActiveStep(4);
                        reepayScript.remove();
                    }, 1700);
                });
            };
        }
        return () => {
            const element = document.getElementById('shopReepayScript');
            if (element) {
                document.head.removeChild(element);
            }
        };
    }, [cart, currentStep, reepayCheckout, setActiveStep, setCartToCartReceipt, setShopCheckoutComplete, tracker]);

    return (
        <Step
            edit={() => console.log('edit')}
            goNext={() => goToPayment()}
            isCompleted={shopCheckoutComplete}
            isCurrentStep={currentStep === 3}
            isStepLoading={false}
            isStepValid={dataConsent}
            setCanValidateStep={() => console.log('set can validate step')}
            stepNumber={3}
            stepType={'Unknown'}
            title="Betaling"
            totalSteps={4}
            isCheckoutComplete={shopCheckoutComplete}
            nextStep="Fortsæt"
            hideCta={reepayCheckout !== null}
        >
            {reepayCheckout && !shopCheckoutComplete ? <div style={{ height: '850px', width: '100%' }} id="rp_shop_container"></div> : null}
            {!reepayCheckout && !shopCheckoutComplete ? (
                <>
                    <StyledDescription>
                        Du har mulighed for at betale med betalingskort eller MobilePay gennem vores sikre betalingsløsning.
                    </StyledDescription>
                    <StyledPaymentIconsWrapper>
                        {paymentIcons.map((x, idx) => {
                            return <img key={idx} src={`${MEDIA_URL}${x.src}`} alt={x.name} height={65} />;
                        })}
                    </StyledPaymentIconsWrapper>
                    <StyledConsentWrapper>
                        <CheckBox
                            id="data-consent"
                            checked={dataConsent}
                            onChange={(c) => setDataConsent(c)}
                            value="data-consent"
                            required={true}
                            canValidate={false}
                        >
                            Jeg godkender Ejner Hessels persondatapolitik
                        </CheckBox>
                        <CheckBox
                            id="marketing-consent"
                            checked={marketingConsent}
                            onChange={(c) => setMarketingConsent(c)}
                            value="marketing-consent"
                        >
                            Jeg vil gerne modtage inspirerende indhold, gode tilbud og nyheder fra Ejners Hessel A/S.
                        </CheckBox>
                    </StyledConsentWrapper>
                </>
            ) : null}
        </Step>
    );
};
