import styled from 'styled-components';
import { PaymentPlanButton } from '../../payment-plan-button/payment-plan-button.component';
import { PriceListLink } from '../../price-list-link/price-list-link.component';

export const StyledPriceLinks = styled.div`
    justify-items: flex-start;
    display: grid;
    grid: auto / 1fr 1fr;
    margin: 20px 0 30px;
`;

export const StyledPriceListLink = styled(PriceListLink)``;

export const StyledPaymentPlanButton = styled(PaymentPlanButton)``;
