import styled, { css } from 'styled-components';
import { device } from '../../../lib/media-query';

export const BorderBox = styled.div<{ isCentered?: boolean }>`
    color: #0b0b0b;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    position: relative;
    ${(props) =>
        props.isCentered === true &&
        css`
            display: flex;
            justify-content: center;
        `}
`;

export const Table = styled.table<{ width?: string }>`
    width: 100%;
    border-spacing: 0;

    @media ${device.laptop} {
        align-self: center;
        width: ${(props) => props.width ?? '100%'};
    }
`;

export const Thead = styled.thead``;

export const Tbody = styled.tbody`
    & > tr:nth-of-type(1) > td,
    & > tr:nth-of-type(1) > th {
        border-top: 1px solid #d1d2d4;
    }
`;

export const Th = styled.th`
    min-width: 80px;
    @media ${device.mobile_tablet} {
        min-width: 150px;
    }
`;

export const StickyTh = styled.th`
    position: sticky;
    left: 0px;

    min-width: 80px;

    text-align: left;
    padding: 10px;

    background-color: inherit;

    word-wrap: break-word;

    @media ${device.mobile_tablet} {
        min-width: 200px;
    }

    @media ${device.tablet} {
        max-width: 370px;
    }

    @media ${device.laptop} {
        padding: 20px;
            }

    @media ${device.laptopS} {
        box-shadow: none;
    }

    z-index: 2;
`;

export const StickyFootNoteTh = styled(StickyTh)`
    padding-left: 0;
`;

export const Tr = styled.tr<{ isHighlighted?: boolean }>`
    background-color: ${(props) => (props.isHighlighted ? '#f1f1f2' : '#f7f7f8')};

    & > td:not(:first-child):not(:last-child) {
        border-right: 1px solid #d1d2d4;
    }
    & > td:nth-child(2) {
        border-left: 1px solid #d1d2d4;
    }
`;

export const TrWithCta = styled.tr<{ isHighlighted?: boolean }>`
    background-color: ${(props) => (props.isHighlighted ? '#f1f1f2' : '#f7f7f8')};

    p {
        font-size: 10px;
        line-height: 10px;
    }

    button {
        padding: 13px;
        height: min-content;
    }

    @media ${device.mobileL} {
        p {
            font-size: 13px;
            line-height: 13px;
        }

        button {
            padding: 10px 30px;
        }
    }

    @media ${device.mobile_tablet} {
        p {
            font-size: 14px;
            line-height: 24px;
        }
    }
`;

export const Td = styled.td`
    text-align: center;
    min-width: 50px;

    & > p {
        color: #0a2841;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 14px;
        word-wrap: break-word;
    }

    @media ${device.desktop} {
        min-width: revert;
    }
`;

export const CtaForTd = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 30px 0;
`;

export const ColumnTextWrapper = styled.div`
    padding: 10px;
`;

export const ColumnHeader = styled.p`
    color: #0b0b0b;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    font-size: 12px;
    @media ${device.tablet} {
        font-size: 13px;
    }
`;

export const ColumnSubHeader = styled.p`
    color: #0b0b0b;
    letter-spacing: 0;
    font-weight: normal;
    line-height: 23px;
    font-size: 12px;
    word-wrap: break-word;
    @media ${device.tablet} {
        font-size: 13px;
    }
`;

export const StyledIcon = styled.img`
    max-width: 50px;
`;

export const FootNoteWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const FootnoteText = styled.p`
    color: #807f80;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 12px;

    display: none;
    @media ${device.tablet} {
        display: revert;
    }
`;
