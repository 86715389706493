import { animated } from 'react-spring';
import styled from 'styled-components';
import { device } from '../../../../lib/media-query';
import { Button } from '../../../shared';

export const StyledStickySummary = styled(animated.div)`
    position: fixed;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    isolation: isolate;
    z-index: 24;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    padding-bottom: 14px;
    box-shadow: ${({ theme }) => theme.shadows.large};

    @media ${device.tablet} {
        display: none;
    }
`;

export const TopSection = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const CarInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const BrandTitle = styled.p`
    color: #0b0b0b;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
`;

export const VariantTitle = styled.p`
    color: #0b0b0b;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0;
`;

export const Prices = styled.div`
    display: flex;
    justify-content: space-between;
`;
export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const Title = styled.p`
    color: #0b0b0b;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;
`;

export const Value = styled.p`
    color: #636363;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 12px;
    letter-spacing: 0;
    line-height: 24px;
    font-weight: normal;
    line-height: 18px;
`;

export const StyledButton = styled(Button)`
    align-self: flex-end;
`;

export const ButtonText = styled.p``;
