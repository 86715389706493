import styled from 'styled-components';

export const StyledShortInfoCardContent = styled.section`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
`;

export const StyledHeader = styled.p`
    color: #0b0b0b;
    font-size: 14px;
    letter-spacing: 0;
    font-weight: 600;
`;

export const StyledDescription = styled.div`
    &,
    & p {
        font-size: 14px;
        letter-spacing: 0;
        line-height: 23px;
    }
`;

export const StyledButtonText = styled.p`
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: 14px;
    letter-spacing: 0;
    white-space: nowrap;
`;

export const StyledHeaderAndDescription = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const SidebarHeader = styled.p`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
`;
