import styled from 'styled-components';
import { device } from '../../../../lib/media-query';

export const StyledContainer = styled.div`
    background-color: white;
    border: 1px solid ${({ theme }) => theme.palette.grey.medium};
    margin: 10px 0;
    padding: 15px;

    @media ${device.mobile_tablet} {
        padding-right: 30px;
    }
`;

export const StyledContainerHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
`;

export const StyledContainerBody = styled.div`
    margin-top: 0.5rem;
    @media ${device.mobile_tablet} {
        margin-left: 1.75rem;
    }
`;

export const StyledSliderContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 0.25rem;
`;

export const StyledLabel = styled.p`
    font-weight: 800;
    font-size: 12px;
    margin-bottom: 1rem;
    margin-top: 1rem;
`;

export const StyledPrice = styled.p<{ checked: boolean }>`
    font-size: 12px;
    font-weight: 800;
    white-space: nowrap;
    color: ${({ checked, theme }) => (checked ? theme.palette.primary.main : '#807f80')};
`;

export const StyledEditButtonContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
`;

export const StyledAgreementSubheader = styled.p`
    color: gray;
    font-size: 12px;
`;

export const StyledCustomerInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    label {
        font-size: 10px;
    }
`;
