import styled from 'styled-components';
import { StyledLayoutWrapper as StyledLayoutWrapperMobile } from '../../shared/modals/side-panel-layout/mobile/side-panel-layout-mobile.styled';
import { StyledLayoutWrapper as StyledLayoutWrapperDesktop } from '../../shared/modals/side-panel-layout/desktop/side-panel-layout-desktop.styled';

export const StyledSubheader = styled.pre`
    font-size: 14px;
    margin-bottom: 20px;
    display: block;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    white-space: pre-wrap;
`;

export const StyledHeader = styled.h1`
    margin-bottom: 15px;
`;

export const StyledStepWrapper = styled.div`
    padding-top: 25px;
`;

export const StyledHr = styled.hr`
    color: ${(props) => props.theme.palette.grey.medium};
    border: 1px solid ${(props) => props.theme.palette.grey.medium};
`;

export const StyledSidePanelLayoutWrapper = styled.div<{ titleIsHidden?: boolean }>`
    ${StyledLayoutWrapperMobile} {
        padding-bottom: 0px !important;
    }
    ${StyledLayoutWrapperDesktop} {
        padding-bottom: 0px !important;
    }

    ${({ titleIsHidden }) => titleIsHidden && 'margin-top: -25px;'}
`;
