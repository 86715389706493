import { VFC } from 'react';
import { umbraco } from '../../../lib/api';
import { ContentSpots } from '../../spots';
import ReepayReceipt from '../../plus-sites-shared/reepay/receipt/reepay-receipt.component';
import { SavingsContentHandler, FormsContentHandler, MyHesselStarPlusHandler } from '../../plus-sites-shared/content-handlers';
import { PlusSiteProvider } from '../../plus-sites-shared/reepay/plus-site-provider';
import { PlusSite as PlusSiteType } from '../../../lib/api/models/umbraco';

export type PlusSiteProps = { page: umbraco.Page; siteSettings: umbraco.SiteSettings };

export const PlusSite: VFC<PlusSiteProps> = ({ page, siteSettings }) => {
    const loadPage = (page: umbraco.Page) => {
        switch (page.contentTypeAlias) {
            case 'plusRootPage':
            case 'starPlusRootPage':
            case 'fordPlusRootPage':
            case 'contentPage':
            case 'plusSubscriptionPage':
                return (
                    <ContentSpots
                        contentSpotSettings={page.filteredContentSpots ? page.filteredContentSpots : page.contentSpotsSettings}
                        context={{ pageId: page.id }}
                    />
                );
            case 'receiptPage':
                return (
                    <ReepayReceipt
                        pageContent={{
                            header: page.header,
                            receiptText: page.receiptText,
                            formSummaryHeader: page.formSummaryHeader,
                            emailLabel: page.emailLabel,
                            phoneLabel: page.phoneLabel,
                            ctas: page.ctas,
                        }}
                    />
                );

            default:
                return <h3>Invalid page content type alias: {page.contentTypeAlias}</h3>;
        }
    };

    return (
        <PlusSiteProvider page={page} siteSettings={siteSettings} customerSavings={page as PlusSiteType}>
            <FormsContentHandler context={{ pageId: page.id }} />
            <SavingsContentHandler savingsContent={page as PlusSiteType} />
            <MyHesselStarPlusHandler siteSettings={siteSettings} />

            <>{loadPage(page)}</>
        </PlusSiteProvider>
    );
};
