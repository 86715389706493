import { useMemo, VFC } from 'react';
import { useMediaQuery } from '../../../hooks/use-media-query';
import { contentSpotTypes } from '../../../lib/api';
import { ContentSpotRichText } from '../../shared/content-spot-rich-text/content-spot-rich-text.component';
import { CenteredBlock } from '../../structural-blocks/centered-block.component';
import {
    MediaContainer,
    MediaImage,
    MediaVideo,
    SplitMediaListWrapper,
    SplitTextContent,
    SplitTextHeader,
    SplitTextWrapper,
} from './split-media-list.styled';
import { SpotHeader } from '../../shared/typography/spot-header/spot-header.component';

export type SplitMediaListProps = {
    content: contentSpotTypes.SplitMediaList;
};

const MEDIA_URL = process.env.NEXT_PUBLIC_MEDIA_URL;

export const SplitMediaList: VFC<SplitMediaListProps> = ({ content }) => {
    const isMobile = useMediaQuery({ target: 'tablet' });
    const cloned: Array<contentSpotTypes.SplitMedia | contentSpotTypes.SplitText> = useMemo(
        () => JSON.parse(JSON.stringify(content.items)),
        [content.items]
    );
    const spotItems: (contentSpotTypes.SplitMedia | contentSpotTypes.SplitText)[] = useMemo(() => {
        return isMobile
            ? cloned.sort((a, b) => {
                  if (a.alias === 'splitMedia') {
                      return -1;
                  }
                  if (b.alias === 'splitText') {
                      return 1;
                  }
                  return 0;
              })
            : content.items;
    }, [cloned, content.items, isMobile]);

    if (!content.items || content.items.length === 0) {
        return null;
    }

    const noMediaAvailable = content.items.find((x) => x.alias === 'splitMedia') === undefined;

    const getMediaSrc = (media: contentSpotTypes.SplitMedia) => {
        if (isMobile && media.mediaMobile?.src) {
            return `${MEDIA_URL}/${media.mediaMobile.src}`;
        }
        return `${MEDIA_URL}/${media.media.src}`;
    };

    const getRenderedMedia = (media: contentSpotTypes.SplitMedia, isAboveFold: boolean) => {
        const isWebp = media.media.extension?.toLowerCase() === 'webp';

        if (
            (isMobile && media.mediaMobile?.type === 'file' && !isWebp) ||
            ((!media.mediaMobile || !isMobile) && media.media.type === 'file' && !isWebp)
        ) {
            const autoPlay = media.autoplayVideo;
            const loop = media.loopVideo;
            return (
                <MediaVideo useSquaredCorners={media.useSquaredCorners} playsInline={true} autoPlay={autoPlay} muted={true} loop={loop} controls>
                    <source src={`${getMediaSrc(media)}#t=0.001`} type="video/mp4"></source>
                    Your browser does not support HTML5 video.
                </MediaVideo>
            );
        }
        return (
            <MediaImage
                useSquaredCorners={media.useSquaredCorners}
                src={`${getMediaSrc(media)}`}
                alt={media?.media?.name ?? ''}
                loading={isAboveFold ? 'eager' : 'lazy'}
            />
        );
    };

    return (
        <CenteredBlock maxWidth={1110}>
            <SplitMediaListWrapper noMediaAvailable={noMediaAvailable} id={content.scrollAnchorId}>
                {spotItems.map((x, index) => {
                    if (x.alias === 'splitText') {
                        return (
                            <SplitTextWrapper key={`${x.alias}-${index}`} index={index}>
                                {x.headerSize && x.headerSize.length > 0 ? (
                                    <SpotHeader headerType={x.headerType} headerSize={x.headerSize}>
                                        {x.headerText}
                                    </SpotHeader>
                                ) : (
                                    <SplitTextHeader as={x.headerType}>{x.headerText}</SplitTextHeader>
                                )}
                                <SplitTextContent>
                                    <ContentSpotRichText text={x.text.replace('/media/', `${MEDIA_URL}/media/`)} />
                                </SplitTextContent>
                            </SplitTextWrapper>
                        );
                    }

                    if (x.alias === 'splitMedia') {
                        return <MediaContainer key={`${x.alias}-${index}`}>{getRenderedMedia(x, !!content.isAboveFold)}</MediaContainer>;
                    }

                    return null;
                })}
            </SplitMediaListWrapper>
        </CenteredBlock>
    );
};
