import { forwardRef, ReactNode } from 'react';
import { SpecsRibbonButton } from './specs-ribbon-button.component';
import { SpecsContentWrapper, StyledSpecsRibbon } from './specs-ribbon.styled';

export type SpecsRibbonProps = {
    buttonLabel: string;
    children: ReactNode;
    className?: string;
    onButtonClick: () => void;
};

export const SpecsRibbon = forwardRef<HTMLUListElement, SpecsRibbonProps>((props, ref) => {
    return (
        <SpecsContentWrapper>
            <StyledSpecsRibbon className={props.className} ref={ref}>
                {props.children}
            </StyledSpecsRibbon>
            <SpecsRibbonButton label={props.buttonLabel} onClick={props.onButtonClick} />
        </SpecsContentWrapper>
    );
});

SpecsRibbon.displayName = 'SpecsRibbon';
