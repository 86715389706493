import { FC } from 'react';
import { umbraco } from '../../../../../lib/api';
import { BookingServiceProductsStore } from '../../../../../lib/state/booking-service-products';
import { BookingStepsStore } from '../../../../../lib/state/booking-steps';
import { BookingTextareaInput } from '../booking-services.styled';
import { CommentWrapper } from './comment.styled';

const fitTextareaToContent = (element: HTMLTextAreaElement) => {
    element.style.height = 'auto';
    if (element.scrollHeight >= element.clientHeight) {
        element.style.height = `${element.scrollHeight}px`;
    }
};

export const Comment: FC = () => {
    const { serviceContent } = BookingStepsStore.useStoreState((state) => ({
        serviceContent: state.steps.find(({ type }) => type === 'Services')?.content as umbraco.BookingStepServices,
    }));

    const { comment } = BookingServiceProductsStore.useStoreState((state) => state);
    const { setComment } = BookingServiceProductsStore.useStoreActions((state) => state);

    return (
        <CommentWrapper>
            <BookingTextareaInput
                hideValidation={true}
                id="service-comment"
                isValid={true}
                label={serviceContent.serviceInspectionCommentLabel}
                placeholder={serviceContent.serviceInspectionCommentPlaceholder}
                value={comment}
                onChange={(event) => {
                    setComment(event.target.value);
                    fitTextareaToContent(event.target);
                }}
            />
        </CommentWrapper>
    );
};
