import { VFC } from 'react';
import { ShopPdpUspBar, PdpUspBar } from '../../../lib/api/models/umbraco/product-details';
import { UspTemplate } from '../../shared/usp-template/usp-template.component';
import { StyledPdpUspBar, StyledCenteredBlock } from './pdp-usp-template-bar.styled';

type PdpUspBarProps = {
    className?: string;
    uspBar?: PdpUspBar | ShopPdpUspBar;
};

export const PdpUspTemplateBar: VFC<PdpUspBarProps> = ({ className, uspBar }) => {
    return uspBar ? (
        <StyledCenteredBlock className={className}>
            <StyledPdpUspBar>
                {uspBar.uspList.map((x, index) => {
                    return (
                        <UspTemplate
                            key={`usp-template-${index}`}
                            header={x.header}
                            description={x.description}
                            topSideIcon={x.topSideIcon}
                            bottomSideIcon={x.bottomSideIcon}
                            isAboveFold={false}
                        />
                    );
                })}
            </StyledPdpUspBar>
        </StyledCenteredBlock>
    ) : null;
};
