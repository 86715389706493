import { animated } from 'react-spring';
import styled from 'styled-components';
import { device } from '../../../../../lib/media-query';
import { mixins } from '../../../../../themes';

export const ComboBoxDropdownContainer = styled.div<{ isOpen?: boolean }>`
    position: relative;
    box-shadow: ${({ isOpen, theme: { shadows } }) => (isOpen ? shadows.large : '0px')};
    border-radius: ${({ theme: { shape } }) => `${shape.rounded.borderRadius}px`};
    cursor: pointer;
    min-width: revert;
    @media ${device.tablet} {
        min-width: 250px;
    }
`;

export const ComboListContainer = styled.div<{ isOpen?: boolean }>`
    width: 100%;
    position: absolute;
    z-index: 10;
    top: 50px;
    background-color: ${({ theme: { palette } }) => palette.common.white};
    border-bottom-left-radius: ${({ theme: { shape } }) => `${shape.rounded.borderRadius}px`};
    border-bottom-right-radius: ${({ theme: { shape } }) => `${shape.rounded.borderRadius}px`};
    border-color: ${({ theme: { palette } }) => palette.grey.main};
    border-style: solid;
    border-width: ${({ isOpen }) => (isOpen ? '0px 1px 1px' : '0px')};
    padding-bottom: ${({ isOpen }) => (isOpen ? '15px' : '0px')};
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
`;

export const StyledList = styled.ul`
    background-color: ${({ theme: { palette } }) => palette.common.white};
    ${mixins.typography('inputValue')}
    width: 100%;
    max-height: 250px;
    overflow-y: auto;

    :focus-visible {
        outline: none;
    }

    :focus {
        outline: none;
    }

    scrollbar-width: thin;
`;

export const StyledListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 10px;
    padding-top: 20px;
`;

export const StyledListFilters = styled.div<{ columns?: number }>`
    display: grid;
    @media ${device.tablet} {
        grid-template-columns: ${({ columns }) => (columns !== undefined ? `repeat(${columns}, auto)` : 'repeat(auto-fill, minmax(130px, 1fr))')};
    }
    overflow: hidden;
`;

export const StyledListItem = styled.li<{ disabled: boolean }>`
    ${mixins.typography('inputPlaceholder')}
    padding: 4px 10px;
    ${(props) => (props.disabled ? 'pointer-events: none;opacity:.5;cursor: not-allowed;' : '')};
    width: min-content;
`;

export const StyledPlaceholder = styled(animated.span)`
    background-color: rgba(0, 0, 0, 0);
    ${mixins.typography('inputPlaceholder')};
    opacity: 0.6;
    padding: 1px 2px;
    white-space: nowrap;

    :focus {
        outline: none;
    }
    :focus-visible {
        outline: none;
    }
`;

export const StyledValue = styled(animated.span)`
    background-color: rgba(0, 0, 0, 0);
    ${mixins.typography('inputValue')};
    padding: 1px 2px;
    width: 100%;
    text-transform: uppercase;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: absolute;
    bottom: -10px;

    :focus {
        outline: none;
    }
    :focus-visible {
        outline: none;
    }
`;

export const Placeholder = styled.div`
    width: 100%;
    display: flex;
    justify-items: center;

    :focus {
        outline: none;
    }
    :focus-visible {
        outline: none;
    }
`;

export const StyledOption = styled.p`
    color: #0b0b0b;
    font-size: 12px;
    letter-spacing: 0;
    white-space: nowrap;
`;
