import styled from 'styled-components';
import { mixins } from '../../../themes';

export const StyledInspirationalLinkBox = styled.a`
    display: flex;
    flex-direction: column;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
`;

export const StyledInspirationalImage = styled.img<{ useSquaredCorners?: boolean }>`
    display: block;
    width: 100%;
    ${({ useSquaredCorners }) => mixins.useSquaredCorners(useSquaredCorners)}
`;

export const StyledHeader = styled.p`
    font-size: 14px;
    color: #222222;
    font-weight: 600;
    margin-top: 15px;
`;

export const StyledSubText = styled.p`
    font-size: 12px;
    color: #0b0b0b;
    opacity: 0.5;
    line-height: 16px;
    font-weight: 500;
`;
