import React, { FC, useMemo, useCallback } from 'react';
import { umbraco } from '../../../../../lib/api';
import { ServiceProductPrice } from '../../../../../lib/api/models/hessel-api';
import { BookingFormStore } from '../../../../../lib/state/booking-form';
import { BookingServiceProductsStore } from '../../../../../lib/state/booking-service-products';
import { ServiceCardItem } from '../service-card-item';
import { Props } from './service-card.props';

function getServiceLink(
    serviceInspectionList: umbraco.ServiceInspectionLinks | undefined,
    serviceId: string
): { name: string; url: string } | undefined {
    if (!serviceInspectionList || serviceInspectionList.length === 0) {
        return undefined;
    }

    const link = serviceInspectionList.find((x) => x.serviceId === serviceId)?.serviceLink;

    if (link) {
        return {
            name: link.name,
            url: link.url,
        };
    }

    return undefined;
}

function getServiceCurrentPriceLabel(serviceId: string, content: umbraco.BookingStepServices, price?: number): string | undefined {
    const serviceHasLink = getServiceLink(content.serviceInspectionLinks, serviceId);

    if (serviceHasLink || price == null) {
        return undefined;
    }
    if (price === 0) {
        return content.freeServiceLabel;
    }

    return `${price.toLocaleString('da-DK')} kr`;
}

const ServiceCard: FC<Props> = ({ onYoutubeModalLinkClicked, serviceProduct, content, isServiceInspection = false }) => {
    const hasStoredTires = BookingFormStore.useStoreState(({ vehicle }) => vehicle?.hasStoredTires);

    const { selectedServiceProductsIds, flattenedSubProducts, flattenedProductsAndSubProducts, flattenedProductsOptions } =
        BookingServiceProductsStore.useStoreState((state) => state);
    const { setServiceProducts, setServiceProductSelected, setProductOption, clearProductOptions } = BookingServiceProductsStore.useStoreActions(
        (state) => state
    );

    const isSelected = useMemo(() => selectedServiceProductsIds.includes(serviceProduct.id), [selectedServiceProductsIds, serviceProduct.id]);

    const unselectSubProductsWhenTopProductIsUnselected = useCallback(
        (id: string) => {
            const product = flattenedProductsAndSubProducts.find((product) => product.id === id);
            if (product && (product.productOptions ?? []).length > 0) clearProductOptions(id);

            const tirehotelParentProducts = flattenedProductsAndSubProducts.filter((p) =>
                content.showTirehotelOnProductIds?.some(({ productId }) => productId === p.id)
            );
            const isTirehotelParentProduct = tirehotelParentProducts?.some((p) => p.id === id);
            if (isTirehotelParentProduct) {
                const anyOtherTirehotelParentProductsSelected = tirehotelParentProducts.some(
                    (p) => selectedServiceProductsIds.includes(p.id) && p.id !== id
                );
                if (anyOtherTirehotelParentProductsSelected) return;
            }

            const isSubProduct = flattenedSubProducts.some((product) => product.id === id);
            if (isSubProduct) return;

            if (isSelected) {
                serviceProduct.subProducts?.filter(({ id }) => selectedServiceProductsIds.includes(id)).forEach((p) => setServiceProducts(p.id));
            }
        },
        [
            flattenedProductsAndSubProducts,
            clearProductOptions,
            flattenedSubProducts,
            isSelected,
            content.showTirehotelOnProductIds,
            selectedServiceProductsIds,
            serviceProduct.subProducts,
            setServiceProducts,
        ]
    );

    const selectTirehotelIfApplicable = useCallback(
        (id: string) => {
            const clickedServiceProduct = flattenedProductsAndSubProducts.find((x) => x.id === id);

            const tirehotelSubProduct = clickedServiceProduct?.subProducts?.find((x) =>
                content.tirehotelProductIds?.some((y) => y.productId === x.id)
            );
            const selectedProductIsWheelChange = tirehotelSubProduct != null;
            const wheelChangeNotAlreadySelected = !selectedServiceProductsIds.includes(id);

            if (hasStoredTires && selectedProductIsWheelChange && wheelChangeNotAlreadySelected) {
                setServiceProductSelected({ productId: tirehotelSubProduct.id, newState: true });
            }
        },
        [content.tirehotelProductIds, flattenedProductsAndSubProducts, hasStoredTires, selectedServiceProductsIds, setServiceProductSelected]
    );

    const productPrice: ServiceProductPrice | undefined = useMemo(() => {
        const discount = content.servicesWithDiscount.find((x) => x.serviceId === serviceProduct.id);

        if (discount?.discountPrice == null) return serviceProduct.price;

        return {
            beforeValue: serviceProduct.price?.value,
            value: discount.discountPrice,
            discountType: undefined,
        };
    }, [content.servicesWithDiscount, serviceProduct.id, serviceProduct.price]);

    return (
        <ServiceCardItem
            title={serviceProduct.name}
            link={getServiceLink(content.serviceInspectionLinks, serviceProduct.id)}
            currentPriceLabel={getServiceCurrentPriceLabel(serviceProduct.id, content, productPrice?.value)}
            isSelected={isSelected}
            serviceProductOption={content.serviceProductOptions.find((x) => x.serviceProductId === serviceProduct.id)}
            onServiceSelected={(id) => {
                unselectSubProductsWhenTopProductIsUnselected(id);
                selectTirehotelIfApplicable(id);
                const isProductOption = flattenedProductsOptions.some((option) => option.id === id);

                if (!isProductOption) {
                    setServiceProducts(id);
                    return;
                }

                const selectedProductOption = serviceProduct?.productOptions?.find((option) => option.id === id);
                if (selectedProductOption && isProductOption) setProductOption({ parentId: serviceProduct.id, optionId: selectedProductOption.id });
            }}
            onYoutubeModalLinkClicked={onYoutubeModalLinkClicked}
            price={productPrice}
            content={content}
            isServiceInspection={isServiceInspection}
            serviceProduct={serviceProduct}
        />
    );
};

export default ServiceCard;
