import styled from 'styled-components';
import { device } from '../../../../lib/media-query';
import { InnerCarousel } from '../inner-carousel/inner-carousel.component';

type StyledCompactCarouselMarkerProps = {
    isActive: boolean;
};

export const StyledCompactCarousel = styled.section`
    aspect-ratio: 4 / 3;
    position: relative;
`;

export const StyledCompactInnerCarousel = styled(InnerCarousel)`
    height: 100%;
`;

export const StyledCompactCarouselMarkerList = styled.div`
    bottom: -0.71em;
    display: flex;
    gap: 0.71em;
    justify-content: center;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
`;

export const StyledCompactCarouselMarker = styled.div<StyledCompactCarouselMarkerProps>`
    background: ${(props) => props.theme.palette.common.black};
    border-radius: 100%;
    height: 0.71em;
    opacity: ${(props) => (props.isActive ? 1 : 0.4)};
    transform: scale(${(props) => (props.isActive ? 1 : 0.8)});
    width: 0.71em;
`;

export const StyledCarouselControlsBottomRight = styled.div`
    position: absolute;
    bottom: 0;
    right: 50%;
    transform: translate(50%, 50%);
    display: flex;
    gap: 10px;

    @media ${device.laptop} {
        bottom: 20px;
        right: 20px;
        transform: none;
    }
`;

export const StyledCarouselControlsBottomRightIndexIndicator = styled.div`
    background-color: white;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    cursor: default;
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 5%);
`;
